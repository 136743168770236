import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(private httpClient:HttpClient) { }
  
  public downloadFile() {
    return this.httpClient.get("https://rdssindia.org/master/api/andolan-letter-format", 
    {observe: 'response', responseType: 'blob'})
  }
}
