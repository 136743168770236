import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from '../../_helpers/mustmatch.validator';
import { ConfirmedValidator } from 'src/app/_helpers/confirmed.validators';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
import { Data } from 'src/app/models/data';
import { Customevalidators } from '../../_helpers/customevalidators';

declare var jQuery: any;
@Component({
  selector: 'app-our-movement',
  templateUrl: './our-movement.component.html',
  styles: [
    `.modal{background: rgba(0,0,0, .5)};`
  ],
  styleUrls: ['./our-movement.component.scss']
})
export class OurMovementComponent implements OnInit {
  newuser_submitted = false;
  NewUserFrom: FormGroup;
  message: any;
  enva: any;
  showMe: boolean = true;
  showMe2: boolean = true;
  showMe3: boolean = false;
  showMe4: boolean = true;
  showMe5: boolean = false;
  showMe6: boolean = true;
  mob_no: any;
  mob_no2: number;
  email2: any;
  full_name: any;
  full_name2: any;
  movementForm: FormGroup;
  registrationForm: FormGroup;
  newregistrationFrom: FormGroup;
  newregister_submitter = false;
  registratiopn_submitted = false;
  MOTPForm: FormGroup;
  OTPVForm: FormGroup;
  countryArray: any = [];
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  mrotp_submitted = false;
  MROTPForm: FormGroup;
  disableBtn = false;
  motp_submitted = false;
  data: any = [];
  BoxesContentArray: any = [];
  LWPForm: FormGroup;
  data1: Data;
  statesArray: any = [];
  password: any;
  password_confirmation: any;
  cityArray: any = [];
  movementArray: any = [];
  ForgotOTPForm: FormGroup;
  ForgotROTPForm: FormGroup;
  time: any;
  pass: any;
  email: any; name: string; mobile: number;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";
  contact: string;

  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private RestAPI: RESTApiService, private auth: AuthenticationService) {
    this.enva = environment.assetsURL;
  }

  ngOnInit(): void {
    this.newregistrationFrom = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      whatsapp_number: [''],
      country: ['', [Validators.required]],
      alternate_mobile_number: [''],
      password_confirmation: ['', [Validators.required]],
      city: ['', [Validators.required]]
    })
    this.movementForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]]
    });
    this.registrationForm = this.formBuilder.group({
      name: [''],
      /* email: ['', [Validators.required, Validators.email]], */
      contact: ['', [Validators.required, , Validators.maxLength(10)]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: [''],
      otp: ['', Validators.required]
    });
    this.MOTPForm = this.formBuilder.group({
      contact: [''],
      name: [''],
      email: ['', []],
      /* otp: ['', Validators.required], */
      password: ['', []],
      password_confirmation: ['', []]
    }
    );
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.MROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.route.paramMap.subscribe(params => {
      this.email = params.get('email');
      this.name = params.get('name');
      this.contact = params.get('contact');
    });
    this.NewUserFrom = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      pancard: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });
    this.fetchCountry();
    this.fetchMovement();
    this.fetchboxes();
  }

  toogleTag() {
    this.showMe = false
    /* this.showMe2 = true */
    this.showMe4 = true
  }
  toogleTag2() {
    /* this.showMe3 = true */
    /* this.showMe = false */
    /* this.showMe4 = false */
  }
  toogleTag3() {
    /* this.showMe2 = true
    this.showMe3 = false
    this.showMe4 = false */
  }

  get m() { return this.movementForm.controls; }
  get mo() { return this.MOTPForm.controls; }
  get vrfy() { return this.OTPVForm.controls; }
  get r() { return this.registrationForm.controls; }
  get lwp() { return this.LWPForm.controls; }
  get new() { return this.NewUserFrom.controls; }

  newregistrationSubmit() {
    this.newregister_submitter = true;
    // stop here if form is invalid
    if (this.newregistrationFrom.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.movementForm.getRawValue();
      this.data1 = this.newregistrationFrom.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.full_name = this.data1.name;
        console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.newregistrationFrom.reset();
        this.newregister_submitter = false;
        // jQuery('#movement-verification').modal('show');
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }


  movementSubmit() {
    this.movement_submitted = true;
    // stop here if form is invalid
    if (this.movementForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      this.data1 = this.movementForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        // console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.movementForm.reset();
        this.movement_submitted = false;
        this.showMe = true;
        this.showMe2 = false;
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        // this.toast.error(err.error.error);
        this.toast.error('पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।');
        console.log(err);
      });
    }
  }
  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.full_name = this.data1.name;
        this.full_name2 = this.data1.name;
        this.mob_no2 = this.data1.contact;
        console.log(this.mob_no);
        // this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.message = "ओटीपी सफलतापूर्वक भेजा गया"
        /*  */
        this.registratiopn_submitted = false;
        this.showMe4 = false;
        this.showMe3 = true;
        this.showMe5 = true;
        
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }
  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/our-movement"]);
          jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  Logout() {
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigate(["/home"]);
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें ओटीपी 6 अंकों का होना चाहिए');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        // this.toast.success('नंबर सत्यापित हो गया है');
        this.message = "नंबर सत्यापित हो गया है"
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.showMe2 = false;
        this.showMe3 = false;
        this.showMe4 = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }

  MOTPSubmit() {
    this.motp_submitted = true;
    // stop here if form is invalid
    if (this.MOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MOTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('फ़ॉर्म सफलतापूर्वक सबमिट किया गया');
        this.MOTPForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.motp_submitted = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  MROTPSubmit() {
    this.mrotp_submitted = true;
    // stop here if form is invalid
    if (this.MROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        this.mrotp_submitted = false;
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchMovement() {
    this.RestAPI.get(environment.apiURL + environment.MovementAPI).subscribe(data => {
      this.data = data;
      this.movementArray = this.data.data.movement;
      console.log(this.movementArray);
    }, err => {
      console.log(err);
    });
  }

  // fetchState() {
  //   this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
  //     this.data = data;
  //     this.statesArray = this.data.data.state;
  //     console.log(this.statesArray);
  //   }, err => {
  //     console.log(err);
  //   });
  // }
  fetchCountry() {
    this.RestAPI.get(environment.apiURL + environment.countryAPI).subscribe(data => {
      this.data = data;
      this.countryArray = this.data.data.countries;
      console.log(this.countryArray);
    }, err => {
      console.log(err);
    });
  }
  fetchState(e) {
    this.RestAPI.get(environment.apiURL + environment.stateAPI1 + e.target.value).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  fetchboxes() {
    this.RestAPI.get(environment.apiURL + environment.HomepageAPI).subscribe(data => {
      this.data = data;
      this.BoxesContentArray = this.data.data.homepage_box;
      console.log(this.BoxesContentArray);
    }, err => {
      console.log(err);
    });
  }

  LoggedIn() {
    return localStorage.getItem('token') != null;
  }

  NewuserSubmit(){
    this.newuser_submitted = true;
    // stop here if form is invalid
    if (this.NewUserFrom.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.NewUserFrom.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        // this.mob_no = this.data1.contact;
        // console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.NewUserFrom.reset();
        this.newuser_submitted  = false;
        this.showMe = false;
        // jQuery('#bfi-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  clearForm() {
    this.NewUserFrom.get('email').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('address').reset();
    this.NewUserFrom.get('state').reset();
    this.NewUserFrom.get('is_indian_citizen').reset();
    this.NewUserFrom.get('gender').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('whatsapp_number').reset();
    this.NewUserFrom.get('alternate_mobile_number').reset();
    this.NewUserFrom.get('city').reset();
    this.NewUserFrom.get('country').reset();
    this.NewUserFrom.get('tehsil').reset();
    this.NewUserFrom.get('password_confirmation').reset();
    this.NewUserFrom.get('pancard').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }
}
