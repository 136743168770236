import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';


@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {
  enva:any;
  imageurl:any;
  data: any =[];
  SliderArray: any = [];
  constructor(private restAPI:RESTApiService) { }

  ngOnInit(): void {
    this.imageurl = environment.imageURL;
    this.enva = environment.assetsURL;
    this.fetchSlider();
  }

  fetchSlider(){
    this.restAPI.get(environment.apiURL + environment.sliderAPI).subscribe(data =>{
      this.data = data;
      this.SliderArray = this.data.data.slider;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
