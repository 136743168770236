<section class="page-header-section" style="background:
  linear-gradient(#020202c7, #02020273), url(breadcrumm.jpg);background-repeat:
  no-repeat;
  background-size: cover;">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h3>संगठन में शामिल हों</h3>
        <div class="breadcrumb-sec">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
              <!-- <li class="breadcrumb-item active" aria-current="page">संगठन में शामिल हों</li> -->
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="membership-section">
  <div class="container">
    <div class="row">
      <div class="row" *ngIf="showMeMember">
        <div class="col-sm-12 text-center">
          <h1 style="color: #171a80; font-weight: bold;">प्रिव्यू पृष्ठ</h1>
          <br>
        </div>

        <div class="row">

          <div class="col-sm-7" *ngIf="showMeMember">
            <div>
              <form [formGroup]="UploadSSForm">
                <div class="form-group">
                  <div class="col-sm-12" style="font-weight: 700">
                    <label class="label-control" style="font-weight: 700;
                        font-size: 32px;
                        line-height: 53px;
                        letter-spacing: 0.02em;
                        
                        color: #F58634;">सदस्यता विकल्प चुनें</label>
                    <br>
                    <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="1100" />
                    वार्षिक सदस्यता @ रु.
                    1100/-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="8000" />
                    आजीवन सदस्यता @ रु. 8000/-
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <label class="label-control">पूरा नाम <span style="color: red">*</span></label>
                    <input type="text" [(ngModel)]="donation_name"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                      formControlName="name" />
                    <!-- <div *ngIf="newuser_submitted && new.name.errors" class="invalid-login">
                                <div *ngIf="new.name.errors.required">पूरा नाम आवश्यक है</div>
                            </div>
                            <span *ngIf="new.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                  </div>
                  <div class="col-sm-6">
                    <label class="label-control">मोबाइल नंबर<span style="color: red">*</span></label>
                    <br />
                    <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                      [(ngModel)]="mob_no3" disabled class="text-control" formControlName="contact" />
                    <p style="color: green">{{ message }}</p>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <label class="label-control">लिंग <span style="color: red">*</span></label>
                    <br />
                    <input type="radio" formControlName="gender" [(ngModel)]="donation_gender" value="male" />
                    पुरुष&nbsp;&nbsp;
                    <input type="radio" formControlName="gender" [(ngModel)]="donation_gender" value="female" /> स्त्री
                    <br />
                  </div>
                  <div class="col-sm-6">
                    <label class="label-control">क्या आप भारत के नागरिक हैं ?
                      <span style="color: red">*</span></label>
                    <br />
                    <input type="radio" formControlName="is_indian_citizen" [(ngModel)]="donation_is_indian_citizen"
                      value="yes" /> हां&nbsp;&nbsp;
                    <input type="radio" formControlName="is_indian_citizen" [(ngModel)]="donation_is_indian_citizen"
                      value="no" /> नहीं
                    <br />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <label class="label-control">व्हाट्सएप नं</label>
                    <input type="whatsapp_number" [(ngModel)]="donation_whatsapp"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                      formControlName="whatsapp_number" />
                  </div>
                  <div class="col-sm-6">
                    <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                    <input type="alternate_mobile_number" [(ngModel)]="donation_alternate"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                      [pattern]="mobNumberPattern" formControlName="alternate_mobile_number" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <label class="label-control">ईमेल <span style="color: red">*</span></label>
                    <input type="text" [(ngModel)]="donation_email"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                      formControlName="email" [ngClass]="{'is-invalid': newuser_submitted && new.email.errors}" />
                    <!-- <div *ngIf="newuser_submitted && new.email.errors" class="invalid-login">
                                <div *ngIf="new.email.errors.required">ईमेल आवश्यक है</div>
                                <div *ngIf="new.email.errors.email">
                                    ईमेल एक वैध ईमेल पता होना चाहिए
                                </div>
                            </div>
                            <span *ngIf="new.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                  </div>
                  <div class="col-sm-6">
                    <label class="label-control">आवास का पता <span style="color: red">*</span></label>
                    <input type="text" [(ngModel)]="donation_address"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                      formControlName="address" [ngClass]="{'is-invalid': newuser_submitted && new.address.errors}" />
                    <!-- <div *ngIf="newuser_submitted && new.address.errors" class="invalid-login">
                                <div *ngIf="new.address.errors.required">
                                    आवास का पता आवश्यक है
                                </div>
                            </div>
                            <span *ngIf="new.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <label class="label-control">वर्तमान निवास का देश
                      <span class="required-span">*</span></label>
                    <select class="form-control" [(ngModel)]="donation_country"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="country"
                      (change)="fetchState($event)" [ngClass]="{'is-invalid': newuser_submitted && new.country.errors}">
                      <option value="" selected>देश चुनें</option>
                      <option *ngFor="let item of countryArray" value="{{ item.id }}">
                        {{ item.name }}
                      </option>
                    </select>
                    <!-- <span *ngIf="new.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक
                                है</span>
                            <div *ngIf="newuser_submitted && new.country.errors" class="invalid-login">
                                <div *ngIf="new.country.errors.required">
                                    वर्तमान निवास का देश आवश्यक है
                                </div>
                            </div> -->
                  </div>
                  <div class="col-sm-6">
                    <label class="label-control">राज्य <span style="color: red">*</span></label>
                    <select class="form-control" [(ngModel)]="donation_state"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="state"
                      (change)="fetchCity($event)" [ngClass]="{'is-invalid': newuser_submitted && new.state.errors}">
                      <option value="" [selected]="true">राज्य चुनें</option>
                      <option *ngFor="let item of statesArray" value="{{ item.id }}">
                        {{ item.name }}
                      </option>
                    </select>
                    <!-- <span *ngIf="new.state.invalid" class="errormssg">राज्य आवश्यक है</span>
                            <div *ngIf="newuser_submitted && new.state.errors" class="invalid-login">
                                <div *ngIf="new.state.errors.required">राज्य आवश्यक है</div>
                            </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <label class="label-control">जिला <span style="color: red">*</span></label>
                    <select class="form-control" [(ngModel)]="donation_city"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="city"
                      [ngClass]="{'is-invalid': newuser_submitted && new.city.errors}">
                      <option value="" [selected]="true">जिला चुनें</option>
                      <option *ngFor="let item of cityArray" value="{{ item.id }}">
                        {{ item.name }}
                      </option>
                    </select>
                    <!-- <span *ngIf="new.city.invalid" class="errormssg">जिला आवश्यक है</span>
                            <div *ngIf="newuser_submitted && new.city.errors" class="invalid-login">
                                <div *ngIf="new.city.errors.required">जिला आवश्यक है</div>
                            </div> -->
                  </div>
                  <div class="col-sm-6">
                    <label class="label-control">तहसील का नाम
                      <span style="color: red">*</span></label>
                    <input type="text" [(ngModel)]="donation_tehsil"
                      style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                      formControlName="tehsil" [ngClass]="{'is-invalid': newuser_submitted && new.tehsil.errors}" />
                    <!-- <span *ngIf="new.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक है</span>
                            <div *ngIf="newuser_submitted && new.teshil.errors" class="invalid-login">
                                <div *ngIf="new.tehsil.errors.required">
                                    तहसील का नाम दर्ज करें आवश्यक है
                                </div>
                            </div> -->
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-6">
                    <label class="label-control">पेशा <span style="color: red">*</span></label>
                    <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                      [(ngModel)]="donation_profession" formControlName="profession"
                      [ngClass]="{ 'is-invalid': newuser_submitted && new.profession.errors}" />
                    <!-- <span *ngIf="new.profession.invalid" class="errormssg">पेशा आवश्यक है</span>
                            <div *ngIf="newuser_submitted && new.profession.errors" class="invalid-login">
                                <div *ngIf="new.profession.errors.required">
                                    पेशा आवश्यक है
                                </div>
                            </div> -->
                  </div>
                </div>
                <!-- <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark" (click)="clearForm()" style="background-color: #555555; border-color: #555555; width: 120px;height: 48px;left: 1060px;top: 1373px;">
                                रीसेट करें
                            </button>
                            &nbsp;&nbsp;
                            <button class="btn btn-dark" [disabled]="NewUserFrom.invalid" (click)="NewuserSubmit()"  style="background-color: #f58634; border-color: #f58634; width: 120px;height: 48px;left: 1060px;top: 1373px;" >
                                अभी जमा करे
                            </button>
                        </div>
                    </div> -->
              </form>
            </div>
          </div>

          <div class="col-sm-5" *ngIf="showMeMember">
            <table style="background-color: #EEEEEE; width: 500px;
                height: 500px;
                left: 940px;
                top: 790px;">
              <br>
              <tr>
                <td style="font-weight: bold">खाता का नाम</td>
                <td>राष्ट्रधर्म सेवा संगठन</td>
              </tr>
              <tr>
                <td style="font-weight: bold">खाता संख्या</td>
                <td>33852355461</td>
              </tr>
              <tr>
                <td style="font-weight: bold">बैंक</td>
                <td>स्टेट बैंक आफ इंडिया</td>
              </tr>
              <tr>
                <td style="font-weight: bold">शाखा</td>
                <td>मुख्य शाखा,गाजीपुर</td>
              </tr>
              <tr>
                <td style="font-weight: bold">जिला</td>
                <td>गाजीपुर</td>
              </tr>
              <tr>
                <td style="font-weight: bold">राज्य</td>
                <td>उत्तर प्रदेश</td>
              </tr>
              <tr>
                <td style="font-weight: bold">ब्रान्च कोड</td>
                <td>000080</td>
              </tr>
              <tr>
                <td style="font-weight: bold">आर्ई एफ एस सी कोड</td>
                <td>SBIN0000080 (5 वर्ण शून्य है)</td>
              </tr>
              <tr>
                <td style="font-weight: bold">खाता का प्रकार</td>
                <td>चालू खाता</td>
              </tr>
              <br>
            </table>
            <br>
            <form [formGroup]="UploadSSForm">
              <div class="form-group">
                <!-- <div class="">
                            <label class="label-control" style="color: #f58634;">यहाँ पर जमापर्ची अपलोड करे </label> <br>
                            <input type="file" class="text-control" formControlName="image"
                              (change)="uploadImage($event)">
                            <div *ngIf="uploadss_submitted && ss.image.errors" class="invalid-login">
                              <div *ngIf="ss.image.errors.required">यहाँ पर जमापर्ची अपलोड करे</div>
                            </div>
                            <button style="    margin-left: 358px;
                            margin-top: -60px;background-color: #f58634;
                            border-color: #f58634" class="btn btn-dark"(click)="UploadssSubmit()">अपलोड करे</button>
                        </div> -->
                <div class="col-sm-6" style="font-weight: bold; font-size: 14px;">
                  <br />
                  <input type="radio" formControlName="payment_mode" value="bank_transfer" (click)="toggle3()" /> यहाँ
                  पर जमापर्ची अपलोड करे&nbsp;&nbsp;
                  <br />
                </div>
                <div class="col-sm-6" style="font-weight: bold; font-size: 14px;" *ngIf="showMe7">
                  <br />
                  <input type="file" class="text-control" formControlName="payment_receipt"
                    (change)="uploadImage($event)">
                  <br />
                </div>
                <div class="col-sm-6" style="font-weight: bold; font-size: 14px;">
                  <br />
                  <input type="radio" formControlName="payment_mode" value="online" (click)="toggle4()" /> ऑनलाइन भुगतान
                  करें
                  <br />
                </div>
              </div>
            </form>
            <div style="margin-left: 130px;">
              <br>
              <button class="btn btn-dark" (click)="toggle5()"
                style="background-color: #555555; border-color: #555555; width: 120px; height: 48px; left: 1060px; top: 1373px;">
                कैंसिल </button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button class="btn btn-dark" *ngIf="showMe9" (click)="UploadssSubmit()"
                style="background-color: #f58634; border-color: #f58634; width: 120px;height: 48px;left: 1060px;top: 1373px;">सबमिट
              </button>
              <button class="btn btn-dark" *ngIf="showMe8" (click)="UploadssSubmit2()"
                style="background-color: #f58634; border-color: #f58634; width: 120px;height: 48px;left: 1060px;top: 1373px;">सबमिट.
              </button>
              <!-- <form [formGroup]="payuform" style="display: none" ngNoForm
                        action="https://test.payu.in/_payment" name="payuform" method="POST"
                        target="payment_popup">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">name </label><br />
                                <input type="text" class="text-control" formControlName="firstname" />
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">दान राशि </label><br />
                                <input type="text" [(ngModel)]="donation_amount2" disabled class="text-control"
                                    formControlName="amount" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">key </label><br />
                                <input type="text" class="text-control" formControlName="key" />
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">productinfo </label><br />
                                <input type="text" class="text-control" formControlName="productinfo" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">email </label><br />
                                <input type="text" class="text-control" formControlName="email" />
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">surl </label><br />
                                <input type="text" class="text-control" formControlName="surl" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">furl </label><br />
                                <input type="text" class="text-control" formControlName="furl" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">phone</label><br />
                                <input type="text" class="text-control" formControlName="phone" />
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">hash </label><br />
                                <input type="text" class="text-control" formControlName="hash" />
                            </div>
                        </div>

                    </form> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-9 col-md-9 col-lg-9 col-12" *ngIf="showMeMemberHide">
        <div class="membership-left-form">
          <div class="membership-content-main">
            <p [innerHTML]="MembershipContentData.content"></p>
          </div>
          <div class="form-main-step1">
            <div class="heading-mem text-center">
              <h3>संगठन में शामिल हों फॉर्म</h3>
            </div>
            <br>
            <div>
              <div class="" *ngIf="showMeMemberHide">
                <div *ngIf="!LoggedIn()">
                  <form [formGroup]="NewUserFrom">
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">पूरा नाम <span
                            style="color: red">*</span></label>
                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" formControlName="name" />
                        <div *ngIf="newuser_submitted && new.name.errors" class="invalid-login">
                          <div *ngIf="new.name.errors.required">पूरा नाम आवश्यक है</div>
                        </div>
                        <div *ngIf="new.name.touched && new.name.invalid" style="color: red">
                          <div *ngIf="new.name.errors.required">
                            पूरा नाम आवश्यक है
                          </div>
                        </div>
                        <!-- <span *ngIf="new.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                      </div>
                      <div class="col-sm-6" *ngIf="showMe5">
                        <label class="label-control" style="font-weight: bold;">मोबाइल नंबर<span
                            style="color: red">*</span></label>
                        <br />
                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          [(ngModel)]="mob_no2" disabled class="text-control" formControlName="contact" />
                        <p style="color: green">{{ message }}</p>
                      </div>
                      <div class="col-sm-6">
                        <form [formGroup]="registrationForm">
                          <div class="form-group row">
                            <div class="col-sm-6" style="display: none">
                              <label class="label-control" style="font-weight: bold;">पूरा नाम <span
                                  style="color: red">*</span></label>
                              <input type="text" class="form-control" formControlName="name"
                                [ngClass]="{'is-invalid': registratiopn_submitted && r.name.errors}" />
                              <div *ngIf="registratiopn_submitted && r.name.errors" class="invalid-login">
                                <!-- <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div> -->
                              </div>
                              <span *ngIf="r.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span>
                            </div>
                            <div *ngIf="showMe4" style="margin-left: 13px;">
                              <label class="label-control" style="font-weight: bold;">मोबाइल नंबर <span
                                  style="color: red">*</span></label>
                              <input type="number" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                                class="form-control" [pattern]="mobNumberPattern" formControlName="contact"
                                [ngClass]="{'is-invalid': registratiopn_submitted && r.contact.errors}"
                                maxlength="10" />
                              <!-- <span *ngIf="r.contact.invalid" class="errormssg">मोबाइल नंबर आवश्यक
                                है</span> -->
                              <div *ngIf="new.contact.touched && new.contact.invalid" style="color: red">
                                <div *ngIf="new.contact.errors.required">
                                  मोबाइल नंबर आवश्यक है
                                </div>
                              </div>
                              <div *ngIf="r.contact.touched &&
                                                    r.contact.invalid" style="color: red">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                            <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                                नंबर 10 अंकों का होना चाहिए
                                                            </div>
                                                    </div>
                              <button class="btn btn-dark" (click)="registrationSubmit()" [hidden]="r.contact.invalid"
                                *ngIf="showMe2"
                                style="margin-top: 25px; background-color: #f58634; border-color: #f58634; height: 48px;left: 1060px;top: 1373px;">
                                अपना नंबर सत्यापित करें
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <form [formGroup]="OTPVForm" (ngSubmit)="OTPVerifySubmit()"
                      style="padding: 30px 14px; padding-top: 0px;" *ngIf="showMe3">
                      <div class="form-group row" style="display: none">
                        <div class="col-sm-6">
                          <label class="label-control" style="font-weight: bold;">पूरा नाम <span
                              style="color: red">*</span></label>
                          <input [(ngModel)]="full_name2" type="text" class="form-control" formControlName="name"
                            [ngClass]="{'is-invalid': otpverify_submitted && vrfy.name.errors}" />
                        </div>
                        <div class="col-sm-6">
                          <label class="label-control" style="font-weight: bold;">
                            मोबाइल नंबर <span style="color: red">*</span></label>
                          <br />
                          <input type="number" [(ngModel)]="mob_no" class="text-control" formControlName="contact"
                            [ngClass]="{'is-invalid': otpverify_submitted && vrfy.contact.errors}" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-4">
                          <label class="label-control" style="font-weight: bold;">6 अंक ओटीपी <span
                              style="color: red">*</span></label>
                          <input type="number" style="width: 235px; height: 46px; left: 530px; top: 677px;"
                            class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp"
                            [ngClass]="{'is-invalid': otpverify_submitted && vrfy.otp.errors}" />
                          <div *ngIf="otpverify_submitted && vrfy.contact.errors" class="invalid-login">
                            <div *ngIf="vrfy.otp.errors.required">ओटीपी आवश्यक है</div>
                            <div *ngIf="vrfy.otp.errors.pattern">
                              ओटीपी 6 अंकों का होना चाहिए
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <button class="btn btn-dark" type="submit"
                            style="margin-top: 28px; background-color: #f58634; border-color: #f58634; height: 48px;left: 1060px;top: 1373px;">
                            ओटीपी सत्यापित करें
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">ईमेल <span
                            style="color: red">*</span></label>
                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" formControlName="email"
                          [ngClass]="{'is-invalid': newuser_submitted && new.email.errors}" />
                        <div *ngIf="newuser_submitted && new.email.errors" class="invalid-login">
                          <div *ngIf="new.email.errors.required">ईमेल आवश्यक है</div>
                          <div *ngIf="new.email.errors.email">
                            ईमेल एक वैध ईमेल पता होना चाहिए
                          </div>
                        </div>
                        <div *ngIf="new.email.touched && new.email.invalid" style="color: red">
                          <div *ngIf="new.email.errors.required">
                              ईमेल आवश्यक है
                          </div>
                          <div *ngIf="new.email.errors.email">
                              ईमेल एक वैध ईमेल पता होना चाहिए
                          </div>
                      </div>
                        <!-- <span *ngIf="new.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                      </div>
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">वैकल्पिक मोबाइल नंबर</label>
                        <input type="number" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" [pattern]="mobNumberPattern" formControlName="alternate_mobile_number" />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">लिंग <span
                            style="color: red">*</span></label>
                        <br />
                        <input type="radio" formControlName="gender" value="male" />
                        पुरुष&nbsp;&nbsp;
                        <input type="radio" formControlName="gender" value="female" />
                        स्त्री
                        <br />
                        <div *ngIf="new.gender.touched && new.gender.invalid" style="color: red">
                          <div *ngIf="new.gender.errors.required">
                            लिंग आवश्यक है
                          </div>
                        </div>
                        <!-- <span *ngIf="new.gender.invalid" class="errormssg">लिंग आवश्यक है</span> -->
                      </div>
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">क्या आप भारत के नागरिक हैं ?
                          <span style="color: red">*</span></label>
                        <br />
                        <input type="radio" formControlName="is_indian_citizen" value="yes" /> हां&nbsp;&nbsp;
                        <input type="radio" formControlName="is_indian_citizen" value="no" /> नहीं
                        <br />
                        <div *ngIf="new.is_indian_citizen.touched && new.is_indian_citizen.invalid" style="color: red">
                          <div *ngIf="new.is_indian_citizen.errors.required">
                            क्या आप भारत के नागरिक हैं? आवश्यक है
                          </div>
                        </div>
                        <!-- <span *ngIf="new.is_indian_citizen.invalid" class="errormssg">क्या आप भारत के नागरिक हैं? आवश्यक
                          है</span> -->
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">व्हाट्सएप नं</label>
                        <input type="number" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" formControlName="whatsapp_number" />
                      </div>
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">आवास का पता <span
                            style="color: red">*</span></label>
                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" formControlName="address"
                          [ngClass]="{'is-invalid': newuser_submitted && new.address.errors}" />
                        <div *ngIf="newuser_submitted && new.address.errors" class="invalid-login">
                          <div *ngIf="new.address.errors.required">
                            आवास का पता आवश्यक है
                          </div>
                        </div>
                        <div *ngIf="new.address.touched && new.address.invalid" style="color: red">
                          <div *ngIf="new.address.errors.required">
                            आवास का पता आवश्यक है
                          </div>
                        </div>
                        <!-- <span *ngIf="new.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">वर्तमान निवास का देश
                          <span style="color: red;">*</span></label>
                        <select class="form-control" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          formControlName="country" (change)="fetchState($event)"
                          [ngClass]="{'is-invalid': newuser_submitted && new.country.errors}">
                          <option value="" selected>देश चुनें</option>
                          <option *ngFor="let item of countryArray" value="{{ item.id }}">
                            {{ item.name }}
                          </option>
                        </select>
                        <!-- <span *ngIf="new.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक है</span> -->
                        <div *ngIf="newuser_submitted && new.country.errors" class="invalid-login">
                          <div *ngIf="new.country.errors.required"> वर्तमान निवास का देश आवश्यक है
                          </div>
                        </div>
                        <div *ngIf="new.country.touched && new.country.invalid" style="color: red">
                          <div *ngIf="new.country.errors.required">
                            वर्तमान निवास का देश आवश्यक है
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">राज्य <span
                            style="color: red">*</span></label>
                        <select class="form-control" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          formControlName="state" (change)="fetchCity($event)"
                          [ngClass]="{'is-invalid': newuser_submitted && new.state.errors}">
                          <option value="" [selected]="true">राज्य चुनें</option>
                          <option *ngFor="let item of statesArray" value="{{ item.id }}">
                            {{ item.name }}
                          </option>
                        </select>
                        <!-- <span *ngIf="new.state.invalid" class="errormssg">राज्य आवश्यक है</span> -->
                        <div *ngIf="newuser_submitted && new.state.errors" class="invalid-login">
                          <div *ngIf="new.state.errors.required">राज्य आवश्यक है</div>
                        </div>
                        <div *ngIf="new.state.touched && new.state.invalid" style="color: red">
                          <div *ngIf="new.state.errors.required">
                            राज्य आवश्यक है
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">जिला <span
                            style="color: red">*</span></label>
                        <select class="form-control" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          formControlName="city" [ngClass]="{'is-invalid': newuser_submitted && new.city.errors}">
                          <option value="" [selected]="true">जिला चुनें</option>
                          <option *ngFor="let item of cityArray" value="{{ item.id }}">
                            {{ item.name }}
                          </option>
                        </select>
                        <!-- <span *ngIf="new.city.invalid" class="errormssg">जिला आवश्यक है</span> -->
                        <div *ngIf="newuser_submitted && new.city.errors" class="invalid-login">
                          <div *ngIf="new.city.errors.required">जिला आवश्यक है</div>
                        </div>
                        <div *ngIf="new.city.touched && new.city.invalid" style="color: red">
                          <div *ngIf="new.city.errors.required">
                            जिला आवश्यक है
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">तहसील का नाम दर्ज करें
                          <span style="color: red">*</span></label>
                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" formControlName="tehsil"
                          [ngClass]="{'is-invalid': newuser_submitted && new.tehsil.errors}" />
                        <!-- <span *ngIf="new.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक है</span> -->
                        <div *ngIf="newuser_submitted && new.teshil.errors" class="invalid-login">
                          <div *ngIf="new.tehsil.errors.required"> तहसील का नाम दर्ज करें आवश्यक है
                          </div>
                        </div>
                        <div *ngIf="new.tehsil.touched && new.tehsil.invalid" style="color: red">
                          <div *ngIf="new.tehsil.errors.required">
                            तहसील का नाम दर्ज करें आवश्यक है
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">पेशा <span
                            style="color: red">*</span></label>
                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" formControlName="profession"
                          [ngClass]="{'is-invalid': newuser_submitted && new.profession.errors}" />
                        <!-- <span *ngIf="new.profession.invalid" class="errormssg">पेशा आवश्यक है</span> -->
                        <div *ngIf="newuser_submitted && new.profession.errors" class="invalid-login">
                          <div *ngIf="new.profession.errors.required"> पेशा आवश्यक है
                          </div>
                        </div>
                        <div *ngIf="new.profession.touched && new.profession.invalid" style="color: red">
                          <div *ngIf="new.profession.errors.required">
                            पेशा आवश्यक है
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">सदस्यता चुनें
                          <span style="color: red;">*</span></label>
                        <br>
                        <input type="radio" formControlName="amount" value="1100" /> वार्षिक सदस्यता रु. 1100/-&nbsp;
                        <input type="radio" formControlName="amount" value="8000" /> आजीवन सदस्यता रु. 8000/-
                        <br>
                        <div *ngIf="new.amount.touched && new.amount.invalid" style="color: red">
                          <div *ngIf="new.amount.errors.required">
                            सदस्यता चुनें आवश्यक है
                          </div>
                        </div>
                        <!-- <span *ngIf="new.amount.invalid" class="errormssg">सदस्यता चुनें आवश्यक है</span> -->
                      </div>
                    </div>
                    <div class="form-group row justify-content-center">
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">पासवर्ड <span
                            style="color: red;">*</span></label>
                        <input type="password" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" [(ngModel)]="password" formControlName="password" />
                        <!-- <span *ngIf="new.password.invalid" class="errormssg">पासवर्ड कम से कम 8 वर्णों का होना
                          चाहिए</span> -->
                        <div *ngIf="new.password.touched && new.password.invalid" style="color: red">
                          <!-- <div *ngIf="new.password.errors.required">
                            पासवर्ड की आवश्यकता है।
                          </div> -->
                          <div *ngIf="new.password.errors.required">
                            पासवर्ड कम से कम 8 वर्णों का होना चाहिए
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="label-control" style="font-weight: bold;">पासवर्ड की पुष्टि कीजिये <span
                            style="color: red;">*</span></label>
                        <input type="password" style="width: 335px; height: 46px; left: 530px; top: 677px;"
                          class="form-control" formControlName="password_confirmation" pattern="{{ password }}"
                          [(ngModel)]="password_confirmation" />
                        <div *ngIf="new.password_confirmation.touched && new.password_confirmation.invalid"
                          style="color: red">
                          <div *ngIf="new.password_confirmation.invalid">
                            पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                          </div>
                          <!-- <div *ngIf="new.password_confirmation.errors.pattern">
                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                            </div> -->

                        </div>
                        <!-- <span *ngIf="new.password_confirmation.invalid" class="errormssg">पासवर्ड और कन्फर्म पासवर्ड का
                          मिलान होना चाहिए।</span> -->
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        <button class="btn btn-dark" (click)="clearForm()"
                          style="background-color: #555555; border-color: #555555; width: 120px;height: 48px;left: 1060px;top: 1373px;">
                          रीसेट करें
                        </button>
                        &nbsp;&nbsp;
                        <button class="btn btn-dark" [disabled]="NewUserFrom.invalid" (click)="NewuserSubmit()"
                          style="background-color: #f58634; border-color: #f58634; width: 120px;height: 48px;left: 1060px;top: 1373px;">
                          अभी जमा करे
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <!-- <div *ngIf="LoggedIn()">
                    <form [formGroup]="donateForm" (ngSubmit)="donateSubmit()"
                        style="background-color: #f6f6f6; padding: 30px">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">पूरा नाम</label>
                                <input type="text" [(ngModel)]="full_name" readonly class="form-control" 'is-invalid': donate_submitted && d.name.errors}" />
                                <div *ngIf="donate_submitted && d.name.errors" class="invalid-login">
                                    <div *ngIf="d.name.errors.required">पूरा नाम आवश्यक है</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">ईमेल</label>
                                <input type="text" class="form-control" formControlName="email" [ngClass]="{'is-invalid': donate_submitted && d.email.errors }" />
                                <div *ngIf="donate_submitted && d.email.errors" class="invalid-login">
                                    <div *ngIf="d.email.errors.required">ईमेल आवश्यक है</div>
                                    <div *ngIf="d.email.errors.email">
                                        ईमेल एक वैध ईमेल पता होना चाहिए
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">मोबाइल नंबर</label>
                                <input type="text" [(ngModel)]="mob_no2" readonly class="form-control"
                                    [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{
                    'is-invalid': donate_submitted && d.contact.errors
                  }" />
                                <div *ngIf="donate_submitted && d.contact.errors" class="invalid-login">
                                    <div *ngIf="d.contact.errors.required">
                                        मोबाइल नंबर आवश्यक है
                                    </div>
                                    <div *ngIf="d.contact.errors.pattern">
                                        मोबाइल नंबर 10 अंकों का होना चाहिए
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                    formControlName="alternate_mobile_number" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6" formControlName="gender">
                                <label class="label-control">लिंग </label> <br />
                                <input type="radio" formControlName="gender" value="male" />
                                पुरुष&nbsp;&nbsp;
                                <input type="radio" formControlName="gender" value="female" />
                                स्त्री
                            </div>
                            <div class="col-sm-6" formControlName="is_indian_citizen">
                                <label class="label-control">क्या आप भारत के नागरिक हैं ?
                                </label>
                                <br />
                                <input type="radio" formControlName="is_indian_citizen" value="yes" />
                                हां&nbsp;&nbsp;
                                <input type="radio" formControlName="is_indian_citizen" value="no" />
                                नहीं
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">व्हाट्सएप नं </label>
                                <input type="text" class="form-control" formControlName="whatsapp_number" [ngClass]="{
                    'is-invalid': donate_submitted && d.whatsapp_number.errors
                  }" />
                                <div *ngIf="donate_submitted && d.whatsapp_number.errors" class="invalid-login">
                                    <div *ngIf="d.whatsapp_number.errors.required">
                                        व्हाट्सएप नं आवश्यक है
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">आवास का पता </label>
                                <input type="text" class="form-control" formControlName="address" [ngClass]="{
                    'is-invalid': donate_submitted && d.address.errors
                  }" />
                                <div *ngIf="donate_submitted && d.address.errors" class="invalid-login">
                                    <div *ngIf="d.address.errors.required">
                                        आवास का पता आवश्यक है
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">राज्य</label>
                                <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                    [ngClass]="{
                    'is-invalid': donate_submitted && d.state.errors
                  }">
                                    <option value="" [selected]="true">राज्य चुनें</option>
                                    <option *ngFor="let item of statesArray" value="{{ item.id }}">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="donate_submitted && d.state.errors" class="invalid-login">
                                    <div *ngIf="d.state.errors.required">राज्य आवश्यक है</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">जिला</label>
                                <select class="form-control" formControlName="city" [ngClass]="{
                    'is-invalid': donate_submitted && d.city.errors
                  }">
                                    <option value="" [selected]="true">जिला चुनें</option>
                                    <option *ngFor="let item of cityArray" value="{{ item.id }}">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="donate_submitted && d.city.errors" class="invalid-login">
                                    <div *ngIf="d.city.errors.required">जिला आवश्यक है</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row justify-content-center">
                            <div class="col-sm-6">
                                <label>पासवर्ड</label>
                                <input type="password" class="form-control" [(ngModel)]="pass"
                                    formControlName="password" />

                                <div *ngIf="mo.password.touched && mo.password.invalid" class="alert alert-danger">
                                    <div *ngIf="mo.password.errors.required">
                                        Password is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>पासवर्ड की पुष्टि कीजिये</label>
                                <input type="password" class="form-control" formControlName="password_confirmation" />
                                <div *ngIf="
                    mo.password_confirmation.touched &&
                    mo.password_confirmation.invalid
                  " class="alert alert-danger">
                                    <div *ngIf="mo.password_confirmation.errors.required">
                                        Confirm पासवर्ड की आवश्यकता है.
                                    </div>
                                    <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                        पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="form-group row">
                            <div class="col-sm-12 text-center">
                                <button class="btn btn-dark" type="reset">रीसेट करें</button>
                                &nbsp;&nbsp;
                                <button class="btn btn-dark" type="submit">अभी जमा करे</button>
                            </div>
                        </div>
                    </form>
                </div> -->
              </div>
            </div>
            <!-- <form [formGroup]="BFIForm" style="background: #f2f2f2; padding: 30px 14px" (ngSubmit)="BFISubmit()"
              *ngIf="showMe2">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                  <input type="text" [(ngModel)]="full_name" class="form-control" formControlName="name"
                    [ngClass]="{ 'is-invalid': bfi_submitted && b.name.errors }">
                  <div *ngIf="bfi_submitted && b.name.errors" class="invalid-login">
                    <div *ngIf="b.name.errors.required">पूरा नाम आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">मोबाइल</label>
                  <input type="number" [(ngModel)]="mob_no" readonly class="text-control" [pattern]="mobNumberPattern"
                    formControlName="contact" [ngClass]="{ 'is-invalid': bfi_submitted && b.contact.errors }">
                  <div *ngIf="bfi_submitted && b.contact.errors" class="invalid-login">
                    <div *ngIf="b.contact.errors.required">मोबाइल नंबर चाहिए</div>
                    <div *ngIf="b.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                  <input type="text" class="form-control" formControlName="email"
                    [ngClass]="{ 'is-invalid': bfi_submitted && b.email.errors }">
                  <div *ngIf="bfi_submitted && b.email.errors" class="invalid-login">
                    <div *ngIf="b.email.errors.required">ईमेल आवश्यक है</div>
                    <div *ngIf="b.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                  <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contact">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="label-control">व्हाट्सएप नं <span style="color: red;">*</span></label>
                  <input type="text" class="form-control" formControlName="whatsapp_number"
                    [ngClass]="{ 'is-invalid': bfi_submitted && b.whatsapp_number.errors }">
                  <div *ngIf="bfi_submitted && b.whatsapp_number.errors" class="invalid-login">
                    <div *ngIf="b.whatsapp_number.errors.required">व्हाट्सएप नं आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6" formControlName="gender">
                  <label class="label-control">लिंग <span style="color: red;">*</span></label> <br>
                  <input type="radio" name="gender" value="male"> पुरुष&nbsp;&nbsp;
                  <input type="radio" name="gender" value="female"> स्त्री
                </div>
                <div class="col-sm-6" formControlName="is_indian_citizen">
                  <label class="label-control">क्या आप भारत के नागरिक हैं ? <span style="color: red;">*</span></label>
                  <br>
                  <input type="radio" name="is_indian_citizen" value="yes"> हां&nbsp;&nbsp;
                  <input type="radio" name="is_indian_citizen" value="no"> नहीं
                </div>
              </div>


              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="label-control">आवास का पता <span style="color: red;">*</span></label>
                  <input type="text" class="form-control" formControlName="address"
                    [ngClass]="{ 'is-invalid': bfi_submitted && b.address.errors }">
                  <div *ngIf="bfi_submitted && b.address.errors" class="invalid-login">
                    <div *ngIf="b.address.errors.required">आवास का पता आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">वर्तमान निवास का देश <span class="required-span">*</span></label>
                  <select class="form-control" formControlName="country" (change)="fetchState($event)"
                    [ngClass]="{ 'is-invalid': bfi_submitted && b.country .errors }">
                    <option value="" selected>देश चुनें</option>
                    <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="bfi_submitted && b.country.errors" class="invalid-login">
                    <div *ngIf="b.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="label-control">राज्य <span style="color: red;">*</span></label>
                  <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                    [ngClass]="{ 'is-invalid': bfi_submitted && b.state.errors }">
                    <option value="" [selected]="true">राज्य चुनें</option>
                    <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="bfi_submitted && b.state.errors" class="invalid-login">
                    <div *ngIf="b.state.errors.required">राज्य आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="label-control">जिला <span style="color: red;">*</span></label>
                  <select class="form-control" formControlName="city"
                    [ngClass]="{ 'is-invalid': bfi_submitted && b.city.errors }">
                    <option value="" [selected]="true">जिला चुनें</option>
                    <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="bfi_submitted && b.city.errors" class="invalid-login">
                    <div *ngIf="b.city.errors.required">जिला आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row justify-content-center">
                <div class="col-sm-6">
                  <label>पासवर्ड</label>
                  <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password">
                  <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                          'orangered', 'orange']"></password-strength-meter>
                      <small id="passwordHelpBlock" class="form-text
                          text-muted">

                          आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                          चार और कम से कम 8 वर्ण होने चाहिए
                      </small>
                  <div *ngIf="mo.password.touched &&
                          mo.password.invalid" class="alert alert-danger">
                    <div *ngIf="mo.password.errors.required">Password
                      is required.</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label>पासवर्ड की पुष्टि कीजिये</label>
                  <input type="password" class="form-control" formControlName="password_confirmation">
                  <div *ngIf="mo.password_confirmation.touched &&
                          mo.password_confirmation.invalid" class="alert
                          alert-danger">
                    <div *ngIf="mo.password_confirmation.errors.required">Confirm
                      पासवर्ड की आवश्यकता है.</div>
                    <div *ngIf="mo.password_confirmation.errors.mustMatch">
                      पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 text-center">
                  <button class="btn btn-dark" type="reset">रीसेट करें</button> &nbsp;&nbsp;
                  <button class="btn btn-dark" type="submit">अभी जमा करे</button>
                </div>
              </div>
            </form> -->
            <!-- <form [formGroup]="MembershipForm" (ngSubmit)="MembershipSubmit()"
              style="background-color: #f6f6f6; padding: 30px" *ngIf="showMe2">
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">पूरा नाम</label>
                  <input type="text" class="form-control" formControlName="name"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.name.errors }">
                  <div *ngIf="membership_submitted && m.name.errors" class="invalid-login">
                    <div *ngIf="m.name.errors.required">पूरा नाम आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">मोबाइल नंबर</label>
                  <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contact"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.contact.errors }">
                  <div *ngIf="membership_submitted && m.contact.errors" class="invalid-login">
                    <div *ngIf="m.contact.errors.required">मोबाइल नंबर आवश्यक है</div>
                    <div *ngIf="m.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">ईमेल</label>
                  <input type="text" class="form-control" formControlName="email"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.email.errors }">
                  <div *ngIf="membership_submitted && m.email.errors" class="invalid-login">
                    <div *ngIf="m.email.errors.required">ईमेल आवश्यक है</div>
                    <div *ngIf="m.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                
                <div class="col-sm-6">
                  <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                  <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contact">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6" formControlName="gender">
                  <label class="label-control">लिंग </label> <br>
                  <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                  <input type="radio" formControlName="gender" value="female"> स्त्री
                </div>
                <div class="col-sm-6" formControlName="is_indian_citizen">
                  <label class="label-control">क्या आप भारत के नागरिक हैं ? </label>
                  <br>
                  <input type="radio" formControlName="is_indian_citizen" value="yes"> हां&nbsp;&nbsp;
                  <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">व्हाट्सएप नं </label>
                  <input type="text" class="form-control" formControlName="whatsapp_number"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.whatsapp_number.errors }">
                  <div *ngIf="membership_submitted && m.whatsapp_number.errors" class="invalid-login">
                    <div *ngIf="m.whatsapp_number.errors.required">व्हाट्सएप नं आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">आवास का पता </label>
                  <input type="text" class="form-control" formControlName="address"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.address.errors }">
                  <div *ngIf="membership_submitted && m.address.errors" class="invalid-login">
                    <div *ngIf="m.address.errors.required">आवास का पता आवश्यक है</div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">राज्य</label>
                  <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.state.errors }">
                    <option value="" [selected]="true">राज्य चुनें</option>
                    <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="membership_submitted && m.state.errors" class="invalid-login">
                    <div *ngIf="m.state.errors.required">राज्य आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">जिला</label>
                  <select class="form-control" formControlName="city"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.city.errors }">
                    <option value="" [selected]="true">जिला चुनें</option>
                    <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="membership_submitted && m.city.errors" class="invalid-login">
                    <div *ngIf="m.city.errors.required">जिला आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row justify-content-center">
                <div class="col-sm-6">
                  <label>पासवर्ड</label>
                  <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password">
                  <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                        'orangered', 'orange']"></password-strength-meter>
                    <small id="passwordHelpBlock" class="form-text
                        text-muted">

                        आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                        चार और कम से कम 8 वर्ण होने चाहिए
                    </small>
                  <div *ngIf="mo.password.touched &&
                        mo.password.invalid" class="alert alert-danger">
                    <div *ngIf="mo.password.errors.required">Password
                      is required.</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label>पासवर्ड की पुष्टि कीजिये</label>
                  <input type="password" class="form-control" formControlName="password_confirmation">
                  <div *ngIf="mo.password_confirmation.touched &&
                        mo.password_confirmation.invalid" class="alert
                        alert-danger">
                    <div *ngIf="mo.password_confirmation.errors.required">Confirm
                      पासवर्ड की आवश्यकता है.</div>
                    <div *ngIf="mo.password_confirmation.errors.mustMatch">
                      पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group row">
                <div class="col-sm-12 text-center">
                  <button class="btn btn-dark" type="reset">रीसेट करें</button> &nbsp;&nbsp;
                  <button class="btn btn-dark" type="submit">अभी जमा करे</button>
                </div>
              </div>
            </form> -->
            <form [formGroup]="MembershipForm" (ngSubmit)="MembershipSubmit()" *ngIf="LoggedIn()">
              <div class="form-goroup row">
                <div class="col-sm-6">
                  <label class="label-control">दिनांक : <span style="color: red;">*</span></label>
                  <input type="date" class="form-control" formControlName="date"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.date.errors }">
                  <div *ngIf="membership_submitted && m.date.errors" class="invalid-login">
                    <div *ngIf="m.date.errors.required">दिनांक आवश्यक है</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">पूरा नाम <span class="required-span">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="full_name" readonly formControlName="name"
                    [ngClass]="{ 'is-in valid': membership_submitted && m.name.errors }">
                  <div *ngIf="membership_submitted && m.name.errors" class="invalid-login">
                    <div *ngIf="m.name.errors.required">पूरा नाम आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">पिता का नाम <span class="required-span">*</span></label>
                  <input type="text" class="form-control" formControlName="father_name"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.father_name.errors }">
                  <div *ngIf="membership_submitted && m.father_name.errors" class="invalid-login">
                    <div *ngIf="m.father_name.errors.required">पिता का नाम आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">ईमेल <span class="required-span">*</span></label>
                  <input type="text" class="form-control" formControlName="email"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.email.errors }">
                  <div *ngIf="membership_submitted && m.email.errors" class="invalid-login">
                    <div *ngIf="m.email.errors.required">ईमेल आवश्यक है</div>
                    <div *ngIf="m.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                  </div>
                </div>
                <div class="col-sm-6" formControlName="gender">
                  <label class="label-control">लिंग <span class="required-span">*</span></label>
                  <br>
                  <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                  <input type="radio" formControlName="gender" value="female"> स्त्री
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">मोबाइल नंबर <span class="required-span">*</span></label>
                  <input type="text" class="form-control" [(ngModel)]="mob_no" readonly [pattern]="mobNumberPattern"
                    formControlName="contact" [ngClass]="{ 'is-invalid': membership_submitted && m.contact.errors }">
                  <div *ngIf="membership_submitted && m.contact.errors" class="invalid-login">
                    <div *ngIf="m.contact.errors.required">मोबाइल 1 आवश्यक है</div>
                    <div *ngIf="m.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">वैकल्पिक मोबाइल नंबर<span class="required-span">*</span></label>
                  <input type="text" class="form-control" [pattern]="mobNumberPattern"
                    formControlName="alternate_mobile_number">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-6" formControlName="is_indian_citizen">
                  <label class="label-control">क्या आप भारत के नागरिक हैं ?
                    <span class="required-span">*</span></label>
                  <br>
                  <input type="radio" formControlName="is_indian_citizen" value="yes"> हां &nbsp;&nbsp;
                  <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                  <div *ngIf="membership_submitted && m.is_indian_citizen.errors" class="invalid-login">
                    <div *ngIf="m.is_indian_citizen.errors.required">क्या आप
                      भारत के नागरिक हैं आवश्यक है</div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <label class="label-control">व्यवसाय <span class="required-span">*</span></label>
                  <input type="text" class="form-control" formControlName="business"
                    [ngClass]="{ 'is-in valid': membership_submitted && m.business.errors }">
                  <div *ngIf="membership_submitted && m.business .errors" class="invalid-login">
                    <div *ngIf="m.business.errors.required">व्यवसाय आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6" formControlName="gender">
                  <label class="label-control">जन्मतिथि <span class="required-span">*</span></label>
                  <input type="date" class="form-control" formControlName="dob">
                  <div *ngIf="membership_submitted && m.dob.errors" class="invalid-login">
                    <div *ngIf="m.dob.errors.required">जन्मतिथि आवश्यक है</div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <label class="label-control">आधार नंo <span class="required-span">*</span></label>
                  <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="adhaar_number"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.adhaar_number.errors }">
                  <div *ngIf="membership_submitted && m.adhaar_number.errors" class="invalid-login">
                    <div *ngIf="m.adhaar_number.errors.required">आधार नंo आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">व्हाट्सएप नं <span class="required-span">*</span></label>
                  <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="whatsapp_number"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.whatsapp_number.errors }">
                  <div *ngIf="membership_submitted && m.whatsapp_number.errors" class="invalid-login">
                    <div *ngIf="m.whatsapp_number.errors.required">व्हाट्सएप नं आवश्यक है</div>

                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">फेसबुक युआरएल <span class="required-span">*</span></label>
                  <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="facebook_url"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.contact.errors }">
                  <div *ngIf="membership_submitted && m.facebook_url.errors" class="invalid-login">
                    <div *ngIf="m.facebook_url.errors.required">फेसबुक युआरएल आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">शैक्षणिक योग्यता <span class="required-span">*</span></label>
                  <input type="text" class="form-control" formControlName="educational_qualification"
                    [ngClass]="{ 'is-invalid': membership_submitted &&  m.educational_qualification.errors }">
                  <div *ngIf="membership_submitted && m.educational_qualification.errors" class="invalid-login">
                    <div *ngIf="m.educational_qualification.errors.required">शैक्षणिक योग्यता आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control"> आईडी कार्ड जमा करें <span class="required-span">*</span></label>
                  <input type="file" class="form-control" [pattern]="mobNumberPattern" formControlName="identity_card"
                    (change)="uploadIdentityCard($event)">
                  <div *ngIf="membership_submitted && m.identity_card.errors" class="invalid-login">
                    <div *ngIf="m.identity_card.errors.required">आईडी कार्ड जमा करें आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control"> आवास का पता <span class="required-span">*</span></label>
                  <input type="text" class="form-control" formControlName="address"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.address.errors }">
                  <div *ngIf="membership_submitted && m.address.errors" class="invalid-login">
                    <div *ngIf="m.address.errors.required">आवास का पता आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">वर्तमान निवास का देश <span class="required-span">*</span></label>
                  <select class="form-control" formControlName="country" (change)="fetchState($event)"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.country .errors }">
                    <option value="" selected>देश चुनें</option>
                    <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="membership_submitted && m.country.errors" class="invalid-login">
                    <div *ngIf="m.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">राज्य <span class="required-span">*</span></label>
                  <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.state.errors }">
                    <option value="" selected>राज्य चुनें</option>
                    <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="membership_submitted && m.state.errors" class="invalid-login">
                    <div *ngIf="m.state.errors.required">राज्य आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">जनपद <span class="required-span">*</span></label>
                  <select class="form-control" formControlName="city"
                    [ngClass]="{ 'is-invalid': membership_submitted && m.city.errors }">
                    <option value="" [selected]="true">जनपद चुनें</option>
                    <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                  </select>
                  <div *ngIf="membership_submitted && m.city.errors" class="invalid-login">
                    <div *ngIf="m.city.errors.required">जनपद आवश्यक है</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control"> पिन कोड <span class="required-span">*</span></label>
                  <input type="text" class="form-control" formControlName="pincode"
                    [ngClass]="{ 'is-invalid': membership_submitted &&  m.pincode.errors }">
                  <div *ngIf="membership_submitted && m.pincode.errors" class="invalid-login">
                    <div *ngIf="m.pincode.errors.required">पिन कोड आवश्यक है</div>
                  </div>
                </div>
              </div>
              <div class="form-group row justify-content-center">
                <div class="col-sm-6">
                  <label>पासवर्ड</label>
                  <input type="password" class="form-control" [(ngModel)]="pass" minlength="6"
                    formControlName="password">
                  <!-- <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                        'orangered', 'orange']"></password-strength-meter>
                    <small id="passwordHelpBlock" class="form-text
                        text-muted">

                        आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                        चार और कम से कम 8 वर्ण होने चाहिए
                    </small> -->
                  <div *ngIf="m.password.touched && m.password.invalid" class="alert alert-danger">
                    <div *ngIf="m.password.errors.required">पासवर्ड की आवश्यकता है।</div>
                    <div *ngIf="m.password.errors.minlength">पासवर्ड कम से कम 6 वर्णों का होना चाहिए</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label>पासवर्ड की पुष्टि कीजिये</label>
                  <input type="password" class="form-control" formControlName="password_confirmation">
                  <div *ngIf="m.password_confirmation.touched && m.password_confirmation.invalid" class="alert
                    alert-danger">
                    <div *ngIf="m.password_confirmation.errors.required">कन्फर्म पासवर्ड की आवश्यकता है.</div>
                    <div *ngIf="m.password_confirmation.errors.mustMatch">
                      पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">
                    <li>राष्ट्रधर्म सेवा संगठन के किस उद्देश्य के लिये योगदान व
                      सहयोग देना
                      चाहते हैं ? आप अपनी जानकारी व रुचि के अनुसार निम्न में से
                      चयन करें- <span class="required-span">*</span></li>
                  </label>
                  <br><br>
                  <div class="contribution-checboxes" style="font-size: 14px;" formControlName="member_role">
                    <ul>
                      <li><label><input type="checkbox" formControlName="member_role" value="1"> कैरियर
                          गाइडेंस
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="2"> रोजगार
                          गाइडेंस
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="3"> रोजगार
                          प्रशिक्षण
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="4"> प्लेसमेन्ट
                          सेल
                        </label>
                      </li>
                      <li><label><input type="checkbox" formControlName="member_role" value="5"> शिक्षा
                          व्यवस्था</label>
                      </li>
                      <li><label><input type="checkbox" formControlName="member_role" value="6"> प्रतियोगी
                          कोचिंग
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="7"> जागरुक भारत
                        </label>
                      </li>
                      <li><label><input type="checkbox" formControlName="member_role" value="8"> पारदर्शी
                          भारत</label>
                      </li>
                      <li><label><input type="checkbox" formControlName="member_role" value="9"> घूसखोरी
                          मुक्त भारत
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="10"> बेरोजगारी
                          मुक्त भारत
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="11"> औद्योगिक
                          विकास</label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="12"> व्यापार
                          वृद्धि
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="13"> एक्टिविटीज
                        </label>
                      </li>
                      <li><label><input type="checkbox" formControlName="member_role" value="14"> कल्याण
                        </label></li>
                      <li><label><input type="checkbox" formControlName="member_role" value="15"> व्यवस्था
                          शोध</label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">
                    <li>आप संगठन के किस समिति, परिषद के सदस्य के रुप में सेवा
                      देना चाहते हैं?
                      इसमें से योग्यता व अनुभव के आधार पर चयन करें - <span class="required-span">*</span></li>
                  </label>
                  <br><br>
                  <div class="contribution-checboxes" style="font-size: 14px;" formControlName="member_type">
                    <ul>
                      <li><label><input type="radio" formControlName="member_type" value="1"> कार्यकारिणी
                          समिति
                        </label></li>
                      <li><label><input type="radio" formControlName="member_type" value="2"> सेवा समिति
                        </label></li>
                      <li><label><input type="radio" formControlName="member_type" value="3"> स्वयंसेवक
                          समिति
                        </label></li>
                      <li><label><input type="radio" formControlName="member_type" value="4"> सलाहकार
                          परिषद </label>
                      </li>
                    </ul>
                  </div>
                  <br>
                  <label class="label-control">
                    <li>यदि आपको राष्ट्रधर्म सेवा संगठन के किसी भी पुराने सदस्य
                      से संगठन के
                      बारे में जानकारी मिली है, तो उस पुराने सदस्य का नाम व पूरा
                      पता यहाँ जरूर लिखें -<span class="required-span">*</span></li>
                  </label>
                  <br>
                  <textarea type="text" id="message" name="message" rows="2" class="form-control md-textarea"
                    formControlName="member_level"></textarea>

                  <div class="form-group row">
                    <div class="col-sm">
                    </div>
                    <div class="col-sm">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-6">
                  <label class="label-control">
                    <li>आप संघ के किस स्तर के साथ जुड़ना चाहते है ? आप अपनी
                      क्षमता, समय व
                      कार्यशैली के अनुसार निम्न में से चयन करे - <span class="required-span">*</span></li>
                  </label>
                  <br><br>
                  <div class="contribution-checboxes" style="font-size: 14px;" formControlName="member_organization">
                    <input id="राष्ट्रीय" type="radio" name="member_organization" formControlName="member_organization"
                      value="1">
                    <label for="राष्ट्रीय"> &nbsp; राष्ट्रीय </label><br>
                    <input id="राष्ट्रीय" type="radio" name="member_organization" formControlName="member_organization"
                      value="2">
                    <label for="प्रदेश">&nbsp; प्रदेश</label><br>
                    <input id="मंडल" type="radio" name="member_organization" formControlName="member_organization"
                      value="3">
                    <label for="मंडल"> &nbsp; मंडल</label><br>
                    <input id="जिला" type="radio" name="member_organization" formControlName="member_organization"
                      value="4">
                    <label for="जिला">&nbsp; जिला </label><br>
                    <input id="तहसील" type="radio" name="member_organization" formControlName="member_organization"
                      value="5">
                    <label for="तहसील">&nbsp; तहसील</label><br>
                    <input id="ब्लाक" type="radio" name="member_organization" formControlName="member_organization"
                      value="6">
                    <label for="ब्लाक">&nbsp; ब्लाक</label><br>
                    <input id="वार्ड" type="radio" name="member_organization" formControlName="member_organization"
                      value="7">
                    <label for="वार्ड">&nbsp; वार्ड</label><br>
                    <input id="ग्राम" type="radio" name="member_organization" formControlName="member_organization"
                      value="8">
                    <label for="ग्राम">&nbsp; ग्राम</label><br>

                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="label-control">
                    <li>आपको संगठन के बारे में जानकारी कैसे मिली -<span class="required-span">*</span></li>
                  </label>
                  <br><br>
                  <div class="contribution-checboxes" style="font-size: 14px;" formControlName="referral_detail">
                    <div class="row">
                      <div class="col-6">
                        <input type="radio" id="होर्डिंग" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="होर्डिंग">&nbsp; होर्डिंग</label><br>
                        <input type="radio" id="फ्लेक्स" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="फ्लेक्स"> &nbsp; फ्लेक्स</label><br>
                        <input type="radio" id="बैनर" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="बैनर">&nbsp; बैनर</label><br>
                        <input type="radio" id="हैण्डबिल" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="हैण्डबिल">&nbsp; हैण्डबिल </label><br>
                        <input type="radio" id="स्टीकर" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="स्टीकर">&nbsp; स्टीकर</label><br>
                        <input type="radio" id="वॉलपेंटिंग" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="वॉलपेंटिंग"> &nbsp; वॉलपेंटिंग</label><br>
                        <input type="radio" id="समाचार_पत्र" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="समाचार_पत्र"> &nbsp; समाचार पत्र</label><br>
                        <input type="radio" id="वेकेन्सी_फोर्म" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="वेकेन्सी_फोर्म">&nbsp; वेकेन्सी फोर्म</label><br>
                        <input type="radio" id="फोन" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="फोन"> &nbsp; फोन कॉलिंग</label><br>
                        <input type="radio" id="एसएमएस" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="एसएमएस">&nbsp; एसएमएस</label> <br>
                        <input type="radio" id="ईमेल" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="ईमेल">&nbsp; ईमेल</label><br>
                      </div>
                      <div class="col-6">

                        <input type="radio" id="वेबसाइट" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="वेबसाइट">&nbsp; वेबसाइट </label><br>
                        <input type="radio" id="फेसबुक" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="फेसबुक">&nbsp; फेसबुक</label><br>
                        <input type="radio" id="ट्विटर" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="ट्विटर">&nbsp; ट्विटर</label><br>
                        <input type="radio" id="यूट्यूब" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="यूट्यूब">&nbsp; यूट्यूब</label><br>
                        <input type="radio" id="व्हाट्सएप" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="व्हाट्सएप">&nbsp; व्हाट्सएप </label><br>
                        <input type="radio" id="न्यूज़" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="न्यूज़">&nbsp; न्यूज़ चैनल</label><br>
                        <input type="radio" id="टी" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="टी">&nbsp; टी वी एड</label><br>
                        <input type="radio" id="ऑनलाइन" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="ऑनलाइन">&nbsp; ऑनलाइन न्यूज़</label><br>
                        <input type="radio" id="पुराने" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="पुराने"> &nbsp; पुराने सदस्य</label><br>
                        <input type="radio" id="अन्य" name="referral_detail" value="referral_detail"
                          formControlName="referral_detail">
                        <label for="अन्य"> &nbsp; अन्य</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12" style="font-weight: 500;">
                  <input type="checkbox" id="html" name="fav_language" value="">&nbsp;
                  मैं घोषणा करता हूँ कि मैं
                  राष्ट्रधर्म सेवा संगठन के मिशन, आन्दोलन, क्रान्ति, अभियान व
                  इससे जुड़ी सभी जानकारी तथा सूचनाओं से
                  पूर्णरुप से सहमत व वचनबद्ध हूँ। मेरे द्वारा यहाँ प्रस्तुत की
                  गयी सूचनाएँ पूर्णरुप से सत्य है।
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 text-center">
                  <button class="btn btn-dark" type="reset" value="Reset">अभी
                    रीसेट करें &nbsp; <i class="fas fa-chevron-circle-right"></i></button>&nbsp;&nbsp;&nbsp;
                  <button class="btn btn-dark" type="submit">जमा करे &nbsp;<i
                      class="fas fa-chevron-circle-right"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br>
      <div class="col-sm-3 col-lg-3 col-md-3 col-12" *ngIf="cardShow">
        <div class="membership-right-sec">
          <div class="boxes-section">
            <div class="row">
              <div class="col-sm-12">
                <div class="tab">
                  <i class="fa fa-hand-holding-heart"></i>
                  <h4>सहयोग करें</h4>
                  <p>‘‘राष्ट्रधर्म सेवा संगठन’’ को सहयोग करने हेतु इच्छुक
                    दानदाता स्टेट बैंक आफ इणिडया..</p>
                  <a href="#/donate-us">सहयोग करें</a>
                </div>
                <div class="tab2">
                  <i class="fa fa-lightbulb"></i>
                  <h4>सजेस्ट इंडिया</h4>
                  <p>राष्ट्रधर्म सेवा संगठन भारत की सबसे तेजी से बढ़ती राजनीतिक
                    पार्टी है। हमें सुझाव दें ताकि हम अपनी
                    वेबसाइट पर और अधिक</p>
                  <a href="#/suggest-india">अभी सुझाव दें </a>
                </div>
                <div class="tab3">
                  <i class="fa fa-bullhorn"></i>
                  <h4>हमारे अभियान</h4>
                  <p>गुप्त रूप से कार्यरत और सक्रिय कामकाजी और सेवानिवृत्त
                    अधिकारी, क्लर्क, सैनिक, कर्मचारी, रिश्वतखोरी
                    में भ्रष्ट को</p>
                  <a href="#/expose-campaign">अभियान में भाग लें </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="modal fade otp-modal" id="membership-verification" data-keyboard="false" data-backdrop="static"
  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="MOTPForm" (ngSubmit)="MOTPSubmit()">
          <div class="form-group row" style="display: none;">
            <div class="col-sm-12">
              <label class="label-control">मोबाइल नंबर
              </label>
              <input type="text" class="form-control" placeholder="Enter Mobile
                No." [(ngModel)]="mob_no" readonly formControlName="contact">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <div class="head-main-otp">
                <h3>
                  कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                <p>
                  एक ओटीपी भेजा गया है{{mob_no}}</p>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-6">
              <label class="label-control">पूरा नाम </label>
              <input type="text" class="form-control" [(ngModel)]="full_name" disabled formControlName="name">
            </div>
            <div class="col-sm-6">
              <label class="label-control">ईमेल</label>
              <input type="text" class="form-control" formControlName="email" disabled [(ngModel)]="email2">
            </div>
          </div>
          <div class="form-group row">

          </div>
          <div class="form-group row justify-content-center">
            <div class="col-sm-6">
              <label>पासवर्ड</label>
              <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password">
              <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                'orangered', 'orange']"></password-strength-meter>
              <small id="passwordHelpBlock" class="form-text
                text-muted">

                आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                चार और कम से कम 8 वर्ण होने चाहिए
              </small>
              <div *ngIf="mo.password.touched &&
                mo.password.invalid" class="alert alert-danger">
                <div *ngIf="mo.password.errors.required">Password
                  is required.</div>
              </div>
            </div>
            <div class="col-sm-6">
              <label>पासवर्ड की पुष्टि कीजिये</label>
              <input type="password" class="form-control" formControlName="password_confirmation">
              <div *ngIf="mo.password_confirmation.touched &&
                mo.password_confirmation.invalid" class="alert
                alert-danger">
                <div *ngIf="mo.password_confirmation.errors.required">Confirm
                  पासवर्ड की आवश्यकता है.</div>
                <div *ngIf="mo.password_confirmation.errors.mustMatch">
                  पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
              </div>
            </div>
          </div>
          <div class="form-group row justify-content-center">
            <div class="col-sm-6">
              <label class="label-control">6
                अंक ओटीपी</label>
              <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                formControlName="otp" [ngClass]="{ 'is-invalid':
                membershipotp_submitted && mo.otp.errors }">
              <div *ngIf="membershipotp_submitted && mo.contact.errors" class="invalid-login">
                <div *ngIf="mo.otp.errors.required">OTP is
                  required</div>
                <div *ngIf="mo.otp.errors.pattern">OTP should be
                  6 digit</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12 text-center">
              <button class="btn btn-dark btn-validate" type="submit">ओटीपी
                सत्यापित करें
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <form [formGroup]="MROTPForm" (ngSubmit)="MROTPSubmit()">
          <div class="form-group row" style="display: none;">
            <div class="col-sm-6">
              <label class="label-control">मोबाइल नंबर</label>
              <input type="text" class="form-control" placeholder="Enter Mobile
                No." [(ngModel)]="mob_no" readonly formControlName="contact">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-8 text-center">
              <span class="wish-otp">यदि आपको ओटीपी प्राप्त नहीं हुआ है, आप 30 .
                के बाद एक नए ओटीपी का अनुरोध कर सकते
                हैं सेकंड</span><br>
            </div>
            <div class="col-sm-4 text-center align-self-center">
              <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">पुन: भेजें ओटीपी</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class=" modal fade otp-modal" id="membership-verification" data-keyboard="false" data-backdrop="static"
  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">OTP Verification</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="MOTPForm" (ngSubmit)="MOTPSubmit()">
          <div class="form-group row" style="display: none;">
            <div class="col-sm-6">
              <label class="label-control">Mobile Number</label>
              <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly
                formControlName="contact">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">
              <div class="head-main-otp">
                <h3>Please enter the OTP to verify your mobile number</h3>
                <p>A OTP has sent to {{mob_no}}</p>
              </div>
            </div>
          </div>

          <div class="form-group row justify-content-center">
            <div class="col-sm-6">
              <label class="label-control">6 Digit OTP</label>
              <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                formControlName="otp" [ngClass]="{ 'is-invalid': membershipotp_submitted && mo.otp.errors }">
              <div *ngIf="membershipotp_submitted && mo.otp.errors" class="invalid-login">
                <div *ngIf="mo.otp.errors.required">OTP is required</div>
                <div *ngIf="mo.otp.errors.pattern">OTP should be 6 digit</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12 text-center">
              <button class="btn btn-dark btn-validate" type="submit">Validate OTP</button>
            </div>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <form [formGroup]="MROTPForm" (ngSubmit)="MROTPSubmit()">
          <div class="form-group row" style="display: none;">
            <div class="col-sm-6">
              <label class="label-control">Mobile Number</label>
              <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly
                formControlName="contact">
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-8 text-center">
              <span class="wish-otp">If you did not received OTP, you can request a new OTP after 30
                seconds</span><br>
            </div>
            <div class="col-sm-4 text-center align-self-center">
              <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend
                OTP</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->