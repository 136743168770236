import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: ['./refund-policy.component.scss']
})
export class RefundPolicyComponent implements OnInit {
  imageurl:any;
  data:any=[];
  enva:any;
  RPArray:any=[];
  constructor(private restAPI: RESTApiService) {
    this.imageurl = environment.imageURL;
    this.enva = environment.assetsURL;
  }
  ngOnInit(): void {
    this.fetchRP();
  }

  fetchRP(){
    this.restAPI.get(environment.apiURL + environment.RPAPI).subscribe(data =>{
      this.data = data;
      this.RPArray = this.data.data.refund_policy;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
