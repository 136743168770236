<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>समाचार का विवरण</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/news']">समाचार</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">समाचार का विवरण</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="news-detail-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-7 col-md-7 col-lg-8">
                <div class="news-detail-con">
                    <span class="category-tag">{{NewsData.category_name}}</span>
                    <h3>{{NewsData.title}}</h3>
                    <ul>
                        <li><i class="fas fa-user"></i> {{NewsData.posted_by}}</li>
                        <li><i class="fas fa-clock"></i> {{NewsData.created_at}}</li>
                        <li><i class="fas fa-eye"></i> {{countNewsViews}}</li>
                    </ul>
                    <img src="{{imageurl}}{{NewsData.image}}" class="img-fluid">
                    <div class="news-detail-p" [innerHTML]="NewsData.content"></div>
                    <!--<div class="tags-area">
                        <div class="post-tags"> 
                            <span>Tags:</span> 
                            <a href="#"># Business</a> 
                            <a href="#"># Corporate</a> 
                            <a href="#"># Services</a>
                            <a href="#"># Customer</a>					
                        </div>
                    </div>-->
                </div>
            </div>

            <div class="col-sm-5 col-md-5 col-lg-4">
                <div class="news-sidebar">
                    <div class="news-sidebar-widgets">
                        <div class="news-detail-wrap">
                            <div class="news-detail-wrap-header">
                                <h4>रिलेटेड पोस्ट</h4>
                            </div>
                            <div class="news-detail-wrap-body">
                                <ul class="news-post">
                                    <li *ngFor="let item of NewsArray">
                                        <div class="news-li">
                                            <div class="news-img">
                                                <a [routerLink]="['/news-detail', item.slug]"><img src="{{imageurl}}{{item.image}}" alt=""></a>
                                            </div>
                                            <div class="news-cont">
                                                <h6><a [routerLink]="['/news-detail', item.slug]">{{item.title}}</a></h6>
                                                <p>{{item.short_description}}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>