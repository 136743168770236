<section class="navigation-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav class="navbar navbar-expand-md">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        मैं मेनू <span class="fas fa-bars"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto">
                            <li class="nav-item active">
                                <a class="nav-link" [routerLink]="['/']">मुख्य पृष्ठ</a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown-about" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    हमारे बारे में
                                </a>
                            
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown-about">
                                    <a class="dropdown-item" [routerLink]="['/organization-founder']">संगठन संस्थापक</a>
                                    <a class="dropdown-item" [routerLink]="['/our-identity']">हमारी पहचान</a>
                                    <a class="dropdown-item" [routerLink]="['/our-vision']">हमारी दृष्टि</a>
                                    <a class="dropdown-item" [routerLink]="['/our-objective']">हमारा उद्देश्य</a>
                                    <!-- <a class="dropdown-item" [routerLink]="['/our-mission']">हमारा लक्ष्य</a> -->
                                    <a class="dropdown-item" [routerLink]="['/our-strength']">हमारी ताकत</a>                                
                                    <a class="dropdown-item" [routerLink]="['/organization-structure']">संगठन संरचना</a>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="dropdown-item" [routerLink]="['/our-movement']">हमारा आंदोलन</a>
                            </li>
                            <li class="nav-item">
                                <a class="dropdown-item" [routerLink]="['/bribery-free-india']">घूसखोरी मुक्त भारत</a>
                            </li>
                            <li class="nav-item">
                                <a class="dropdown-item" [routerLink]="['/busted-revolution']">भंडाफोड़ क्रांति</a>
                            </li>
                            <li class="nav-item">
                                <a class="dropdown-item" [routerLink]="['/expose-campaign']">पर्दाफाश अभियान</a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown-about" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    सेना भर्ती आन्दोलन
                                </a>
                            
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown-about">
                                    <a class="dropdown-item" [routerLink]="['/about-us-andolan']">हमारे बारे में</a>
                                    <a class="dropdown-item" [routerLink]="['/committee-founder']">समिति संस्थापक</a>
                                    <a class="dropdown-item" [routerLink]="['/our-identity-andolan']">हमारी पहचान</a>
                                    <a class="dropdown-item" [routerLink]="['/our-vision-andolan']">हमारी दृष्टि</a>
                                    <a class="dropdown-item" [routerLink]="['our-mision-andolan']">हमारा उद्देश्य</a>
                                    <a class="dropdown-item" [routerLink]="['/our-strength-andolan']">हमारी ताकत</a>
                                    <a class="dropdown-item" [routerLink]="['/committee-structure']">समिति संरचना</a>
                                    <a class="dropdown-item" [routerLink]="['/membership']">हमसे जुड़ें</a>
                                    <a class="dropdown-item" [routerLink]="['/donate-us']">हमारा सहयोग करें</a>
                                    <!-- <a class="dropdown-item" [routerLink]="['/gallery-andolan']">गैलरी </a> -->
                                       
                                </div>
                            </li>
                            
                            <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown-about" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">आंदोलन और क्रांति</a>
                            
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown-about">
                                    
                                    
                                </div>
                            </li> -->

                            <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown-about" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    जॉब करियर
                                </a>
                            
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown-about">
                                    <a class="dropdown-item" [routerLink]="['/jobs']">जॉब ढूंढे</a>
                                    <a class="dropdown-item" [routerLink]="['/career-counselling']">जॉब काउंसलिंग</a>
                                    <a class="dropdown-item" [routerLink]="['/jobs']">जॉब की सुचना</a>
                                </div>
                            </li> -->

                            <!-- <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/']">हमारे उत्पाद</a>
                            </li> -->
                            
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown-gallery" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    गैलरी
                                </a>
                            
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown-gallery">
                                    <a class="dropdown-item" [routerLink]="['/image-gallery']">इमेज गैलरी</a>
                                    <a class="dropdown-item" [routerLink]="['/video-gallery']">वीडियो गैलरी</a>
                                </div>
                            </li>

                            <!-- <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" id="navbarDropdown-contact" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">संपर्क करें
                            </a>
                            
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown-contact">
                                    <a class="dropdown-item" [routerLink]="['/contact-us']">संपर्क करें</a>
                                    <a class="dropdown-item" [routerLink]="['/locate-us']">हमारी शाखाएँ</a>
                                </div>
                            </li> -->
                        </ul>
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item highlight-li">
                                <a class="nav-link" [routerLink]="['/donate-us']">सहयोग करें</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</section>