import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ouridentity',
  templateUrl: './ouridentity.component.html',
  styleUrls: ['./ouridentity.component.scss']
})
export class OuridentityComponent implements OnInit {
  data:any=[];
  IdentityArray:any=[];
  enva:any;
  BoxesContentArray:any=[];
  IdentityLogoArray:any=[];
  imageurl:any;
  constructor(private restAPI: RESTApiService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.fetchIdentity();
    this.fetchboxes();
  }

  fetchIdentity(){
    this.restAPI.get(environment.apiURL + environment.identityAPI).subscribe(data => {
      this.data = data;
      this.IdentityArray = this.data.data.manageidentity;
      this.IdentityLogoArray = this.data.data.logo;
      console.log(this.IdentityLogoArray);
      console.log(this.IdentityArray);
    }, err => {
      console.log(err);
    });
  }

  fetchboxes(){
    this.restAPI.get(environment.apiURL + environment.HomepageAPI).subscribe(data =>{
      this.data = data;
      this.BoxesContentArray = this.data.data.homepage_box;
      console.log(this.BoxesContentArray);
    }, err => {
      console.log(err);
    });
  }

}
