import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles:[
    `.modal{background: rgba(0,0,0, .5);z-index:999999999;}`
  ],
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  enva: any;
  HomepageAboutArray:any=[];
  HomePageBoxArray:any=[];
  HomePageFooLogo:any=[];
  HomePageBlogArray:any=[];
  HomePageWidgetsArray:any=[];
  HomePageVideoArray:any=[];
  MissionArrayCarouse:any=[];
  data:any = [];
  imageurl:any=[];
  HomePageNewsArray:any=[];
  TeamArray:any =[];
  ImageGalleryArray:any=[]; //Image gallery Array
  constructor(private toast: ToastrService,
    private restAPI: RESTApiService) {
    this.imageurl = environment.imageURL;
    this.enva = environment.assetsURL;
   }

  ngOnInit(): void {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.fetchTeamMembers();
    this.fetchHomepage();
    this.fetchMissionsC();
  }

  fetchTeamMembers(){
    this.restAPI.get(environment.apiURL + environment.TeamAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.TeamArray = this.data.data.team;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchHomepage(){
    this.restAPI.get(environment.apiURL + environment.HomepageAPI).subscribe(data =>{
      this.data = data;
      this.HomepageAboutArray = this.data.data.homepage_about[0];
      this.HomePageBoxArray = this.data.data.homepage_box;
      this.HomePageFooLogo = this.data.data.logo;
      this.ImageGalleryArray = this.data.data.imagegallery;
      this.HomePageVideoArray = this.data.data.video_gallery;
      this.HomePageNewsArray = this.data.data.newspost;
      this.HomePageBlogArray = this.data.data.blog;
      this.HomePageWidgetsArray = this.data.data.homepage_widget;
      console.log(this.HomePageVideoArray);
    }, err => {
      console.log(err);
    });
  }

  fetchMissionsC(){
    this.restAPI.get(environment.apiURL + environment.missionProblemAPI).subscribe(data =>{
      this.data = data;
      this.MissionArrayCarouse = this.data.data[0];
      console.log(this.MissionArrayCarouse);
    }, err => {
      console.log(err);
    });
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    autoplay:true,
    autoplayTimeout:2000,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      740: {
        items: 5
      },
      940: {
        items: 7
      }
    },
    nav: true
  }

  customMOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    margin:20,
    autoplay:true,
    autoplayTimeout:2000,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

}
