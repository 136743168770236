<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>उपयोगकर्ता अद्यतन</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">उपयोगकर्ता अद्यतन</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <app-user-sidebar></app-user-sidebar>
            </div>
            <div class="col-sm-9">
                <div class="main-area-dash">
                    <div class="profile-tabs">
                        <ul class="nav nav-tabs">
                            <li *ngIf="UserUpdatesUSArray.mission == 'yes'"><a class="active" data-toggle="tab" href="#mission">मिशन</a></li>
                            <li *ngIf="UserUpdatesUSArray.objective == 'yes'"><a data-toggle="tab" href="#objectives">उद्देश्य</a></li>
                            <li *ngIf="UserUpdatesUSArray.movement == 'yes'"><a data-toggle="tab" href="#movements">आंदोलन</a></li>
                            <li *ngIf="UserUpdatesUSArray.bfi == 'yes'"><a data-toggle="tab" href="#bfi">बी ऍफ़ आई</a></li>
                            <li *ngIf="UserUpdatesUSArray.busted == 'yes'"><a data-toggle="tab" href="#busted">फटी हुई क्रांति</a></li>
                            <li *ngIf="UserUpdatesUSArray.exposed == 'yes'"><a data-toggle="tab" href="#expose-campaign">अभियान का खुलासा</a></li>
                            <li *ngIf="UserUpdatesUSArray.career == 'yes'"><a data-toggle="tab" href="#career">व्यवसाय</a></li>
                            <li><a data-toggle="tab" href="#jobs">नौकरियां</a></li>
                        </ul>
                        
                        <div class="tab-content">
                            <div id="mission" class="tab-pane fade in active show">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12" *ngFor="let item of UserUpdatesMArray; let i = index">
                                                <div class="updates-box">
                                                    <div class="update-head">
                                                        <h1><a [href]="[item.link]" appExternalUrl>{{item.title}} <img src="{{enva}}assets/images/new.gif" *ngIf="i == 0 || i == 1 || i == 2" class="img-fluid"></a></h1>
                                                        <div [innerHTML]="item.description"></div>
                                                        <div class="update-from">{{item.mission_name}}</div>
                                                    </div>
                                                    
                                                    <div class="update-right-side">
                                                        <a href="{{imageurl}}{{item.attachment}}" appExternalUrl>अनुलग्नक देखें</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="objectives" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12" *ngFor="let item of UserUpdatesOArray; let i = index">
                                                <div class="updates-box">
                                                    <div class="update-head">
                                                        <h1><a [href]="[item.link]" appExternalUrl>{{item.title}} <img src="{{enva}}assets/images/new.gif" *ngIf="i == 0 || i == 1 || i == 2" class="img-fluid"></a></h1>
                                                        <div [innerHTML]="item.description"></div>
                                                        <div class="update-from">{{item.objective_name}}</div>
                                                    </div>
                                                    
                                                    <div class="update-right-side">
                                                        <a href="{{imageurl}}{{item.attachment}}" appExternalUrl>अनुलग्नक देखें</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="movements" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12" *ngFor="let item of UserUpdatesMoveArray; let i = index">
                                                <div class="updates-box">
                                                    <div class="update-head">
                                                        <h1><a [href]="[item.link]" appExternalUrl>{{item.title}} <img src="{{enva}}assets/images/new.gif" *ngIf="i == 0 || i == 1 || i == 2" class="img-fluid"></a></h1>
                                                        <div [innerHTML]="item.description"></div>
                                                        <div class="update-from">Movements</div>
                                                    </div>
                                                    
                                                    <div class="update-right-side">
                                                        <a href="{{imageurl}}{{item.attachment}}" appExternalUrl>अनुलग्नक देखें</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="bfi" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12" *ngFor="let item of UserUpdatesBFIArray; let i = index">
                                                <div class="updates-box">
                                                    <div class="update-head">
                                                        <h1><a [href]="[item.link]" appExternalUrl>{{item.title}} <img src="{{enva}}assets/images/new.gif" *ngIf="i == 0 || i == 1 || i == 2" class="img-fluid"></a></h1>
                                                        <div [innerHTML]="item.description"></div>
                                                        <div class="update-from">Bribery Free India</div>
                                                    </div>
                                                    
                                                    <div class="update-right-side">
                                                        <a href="{{imageurl}}{{item.attachment}}" appExternalUrl>अनुलग्नक देखें</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="busted" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12" *ngFor="let item of UserUpdatesBustedArray; let i = index">
                                                <div class="updates-box">
                                                    <div class="update-head">
                                                        <h1><a [href]="[item.link]" appExternalUrl>{{item.title}} <img src="{{enva}}assets/images/new.gif" *ngIf="i == 0 || i == 1 || i == 2" class="img-fluid"></a></h1>
                                                        <div [innerHTML]="item.description"></div>
                                                        <div class="update-from">Busted Revolution</div>
                                                    </div>
                                                    
                                                    <div class="update-right-side">
                                                        <a href="{{imageurl}}{{item.attachment}}" appExternalUrl>अनुलग्नक देखें</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="expose-campaign" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12" *ngFor="let item of UserUpdatesEArray; let i = index">
                                                <div class="updates-box">
                                                    <div class="update-head">
                                                        <h1><a [href]="[item.link]" appExternalUrl>{{item.title}} <img src="{{enva}}assets/images/new.gif" *ngIf="i == 0 || i == 1 || i == 2" class="img-fluid"></a></h1>
                                                        <div [innerHTML]="item.description"></div>
                                                        <div class="update-from">Expose Campaign</div>
                                                    </div>
                                                    
                                                    <div class="update-right-side">
                                                        <a href="{{imageurl}}{{item.attachment}}" appExternalUrl>अनुलग्नक देखें</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="career" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12" *ngFor="let item of UserUpdatesCCArray; let i = index">
                                                <div class="updates-box">
                                                    <div class="update-head">
                                                        <h1><a [href]="[item.link]" appExternalUrl>{{item.title}} <img src="{{enva}}assets/images/new.gif" *ngIf="i == 0 || i == 1 || i == 2" class="img-fluid"></a></h1>
                                                        <div [innerHTML]="item.description"></div>
                                                        <div class="update-from">Career Counselling</div>
                                                    </div>
                                                    
                                                    <div class="update-right-side">
                                                        <a href="{{imageurl}}{{item.attachment}}" appExternalUrl>अनुलग्नक देखें</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="jobs" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="updates-box-main">
                                        <div class="row">
                                            <div class="col-sm-12"  *ngFor="let item of UserUpdatesJobArray; let i = index">
                                                <div class="job-list-main">
                                                    <div class="job-list-wrap">
                                                        <div class="job-list-thumb">
                                                            <a [routerLink]="['/job-detail',item.slug]"><img src="{{imageurl}}{{item.image}}" class="img-fluid" alt=""></a>
                                                        </div>
                                                        <div class="job-list-caption">
                                                            <h4><a [routerLink]="['/job-detail',item.slug]">{{item.title}}</a><span class="jb-status {{item.job_type}}">{{item.job_type}}</span></h4>
                                                            <ul>
                                                                <li><i class="far fa-building"></i>{{item.category_name}}</li>
                                                                <li><i class="fas fa-map-marker"></i> {{item.city_name}}, {{item.state_name}} </li>
                                                            </ul>
                                                            <p [innerHTML]="item.job_description"></p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="job-list-btn">
                                                        <a [routerLink]="['/job-detail',item.slug]" class="btn btn-shortlist"><i class="fas fa-arrow-right"></i>विवरण देखें</a>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>