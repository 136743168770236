import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {
  enva:any;
  imageurl:any;
  BlogArray:any=[];
  BlogSlug:any;
  BlogData:any=[];
  data:any=[];
  constructor(private route: ActivatedRoute,
    private router:Router,private restAPI: RESTApiService,
    private toast: ToastrService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
       // trick the Router into believing it's last link wasn't previously loaded
       this.router.navigated = false;
      }
    });
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.BlogSlug = params['params'].slug;
        console.log(this.BlogSlug)
      }
    });
    this.fetchBlogs();
    this.fetchblogbySlug();
  }

  fetchBlogs(){
    this.restAPI.get(environment.apiURL + environment.BlogAPI).subscribe(data =>{
      this.data = data;
      this.BlogArray = this.data.data.blog;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  fetchblogbySlug(){
    this.restAPI.get(environment.apiURL + environment.BlogDetailAPI + this.BlogSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.BlogData = this.data.data.blog[0];
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }
}
