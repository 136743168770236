import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { MembershipData } from 'src/app/models/MembershipData';

@Component({
  selector: 'app-user-buy-membership',
  templateUrl: './user-buy-membership.component.html',
  styleUrls: ['./user-buy-membership.component.scss']
})
export class UserBuyMembershipComponent implements OnInit {
  UserMembershipForm:FormGroup;
  data:any=[];
  package_id:any;
  loading: boolean;
  membership_submitted = false;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) { }

  ngOnInit(): void {
    this.UserMembershipForm = this.formBuilder.group({
      id_number: ['', [Validators.required]],
      photo_id_type: ['', [Validators.required]],
      document1: ['', [Validators.required]],
      address_id_number: ['', [Validators.required]],
      address_type: ['', [Validators.required]],
      document2: ['', [Validators.required]],
      image: ['', [Validators.required]],
      volunteer: ['yes', [Validators.required]],
      contribution: ['', [Validators.required]],
      about_yourself: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });

    this.route.paramMap.subscribe(params => {
      this.package_id = params.get('package_id');
    });
  }

  uploadImage(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.UserMembershipForm.patchValue({
      image: file
    });
    this.UserMembershipForm.get('image').setValue(file);
    console.log(file);
  }

  uploadDoc1(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.UserMembershipForm.patchValue({
      document1: file
    });
    this.UserMembershipForm.get('document1').setValue(file);
    console.log(file);
  }

  uploadDoc2(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.UserMembershipForm.patchValue({
      document2: file
    });
    this.UserMembershipForm.get('document2').setValue(file);
    console.log(file);
  }

  get m() { return this.UserMembershipForm.controls; }

  UserMembershipSubmit(){
    this.membership_submitted = true;
    // stop here if form is invalid
    if (this.UserMembershipForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("contribution", this.UserMembershipForm.get('contribution').value);
      formData.append("about_yourself", this.UserMembershipForm.get('about_yourself').value);
      formData.append("image", this.UserMembershipForm.get('image').value);
      formData.append("document1", this.UserMembershipForm.get('document1').value);
      formData.append("document2", this.UserMembershipForm.get('document2').value);
      formData.append("photo_id_type", this.UserMembershipForm.get('photo_id_type').value);
      formData.append("address_type", this.UserMembershipForm.get('address_type').value);
      formData.append("id_number", this.UserMembershipForm.get('id_number').value);
      formData.append("volunteer", this.UserMembershipForm.get('volunteer').value);
      formData.append("address_id_number", this.UserMembershipForm.get('address_id_number').value);
      this.loading = true; // Add this line
      this.RestAPI.post(environment.apiURL + environment.UserBuyNewMembershipAPI + '/' + this.package_id, formData).subscribe(data => {
        this.toast.success('Congratulations Member! Please wait upto 24 hours for the approval.');
        this.router.navigate(["/user-dashboard"]);
        this.UserMembershipForm.reset();
        this.membership_submitted  = false;
        console.log(formData);
        this.loading = false; // Add this line
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }
}
