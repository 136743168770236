import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {
  data: any=[];
  WelcomeLetterArray:any=[];
  UserDetailsArray:any=[];
  constructor(private RestAPI : RESTApiService) { }

  ngOnInit(): void {
    this.fetchWelcomeLetter();
    this.fetchUserDetails();
  }

  fetchWelcomeLetter() {
    this.RestAPI.get(environment.apiURL + environment.UserWelcomeLetter).subscribe(data => {
      this.data = data;
      this.WelcomeLetterArray = this.data.data.welcome_letter;
      console.log(this.WelcomeLetterArray);
    }, err => {
      console.log(err);
    });
  }

  fetchUserDetails() {
    this.RestAPI.get(environment.apiURL + environment.UserfetchDetails).subscribe(data => {
      this.data = data;
      this.UserDetailsArray = this.data.data.user;
      console.log(this.UserDetailsArray);
    }, err => {
      console.log(err);
    });
  }


}
