import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/_helpers/mustmatch.validator';
import { Data } from 'src/app/models/data';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  full_name2: any;
  showMe: boolean= true;
  showMe2: boolean= false;
  showMe3: boolean= false;
  showMe4: boolean= false;
  
  bfi_submitted = false;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  data1:Data;
  name: string;
  mob_no:number;
  mob_no2:number;
  pass: any;
  motp_submitted = false;
  sendotp_submitted = false;
  votp_submitted = false;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  SendOTPForm: FormGroup;
  enva:any;
  imageurl:any;
  contact_submitted = false;
  contactForm:FormGroup;  
  disableBtn = false;
  data:any=[];
  cotp_submitted = false;
  ContactCArray:any=[];
  COTPForm:FormGroup;
  CROTPForm: FormGroup;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";  
  constructor(private restAPI:RESTApiService,
    private formBuilder: FormBuilder,
    private toast: ToastrService, private RestAPI : RESTApiService, private router:Router, private auth: AuthenticationService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: MustMatch('password', 'password_confirmation')
    });
    this.registrationForm = this.formBuilder.group({
      name: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.COTPForm = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required]
    });

    this.CROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.fetchContactContent();
  }
  get c() { return this.contactForm.controls; }
  get co() { return this.COTPForm.controls; }
  get v() { return this.COTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get vrfy() { return this.OTPVForm.controls;}
  get lwp() { return this.LWPForm.controls; }

  COTPSubmit(){
    this.cotp_submitted = true;
    // stop here if form is invalid
    if (this.COTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data = this.COTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data).subscribe(data => {
        // this.contact = this.data.contact;
        // this.otp = this.data2.otp;
        // console.log(this.data2);
        this.toast.success('Proceed to Next Step');
        this.COTPForm.reset();
      //  jQuery('#career-verification').modal('hide');
        this.cotp_submitted  = false;
        // this.router.navigate(["/career-counselling-form", {contact: this.contact, otp: this.otp, email: this.email, name: this.name, state: this.state, city: this.city}]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  CROTPSubmit(){
    // stop here if form is invalid
    if (this.CROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.CROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  contactSubmit(){
    this.contact_submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.contactForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.ContactFormAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('Your Feedback has submitted Successfully. Thank You');
        this.contactForm.reset();
        this.contact_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchContactContent(){
    this.restAPI.get(environment.apiURL + environment.contactContentAPI).subscribe(data =>{
      this.data = data;
      this.ContactCArray = this.data.data.content[0];
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }
  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.email2 = this.data1.email;
        this.full_name2 = this.data1.name;
        console.log(this.mob_no);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        /*  */
        this.registratiopn_submitted = false;
        this.showMe4 = false;
        this.showMe3 = true;
        
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/contact-us"]);
          // jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.showMe2 = true;
        this.showMe3 = false;
        this.showMe4 = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe = false
    /* this.showMe2 = true */
    this.showMe4 = true
  }

}
