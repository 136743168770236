import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Data } from 'src/app/models/data';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-career-counselling-form',
  templateUrl: './career-counselling-form.component.html',
  styleUrls: ['./career-counselling-form.component.scss']
})
export class CareerCounsellingFormComponent implements OnInit {
  enva:any;
  contact:any;name:any;email:any;state:any;city:any;otp:any; //For Param
  CC2Form: FormGroup;
  CC2_submitted = false;
  statesArray:any=[];
  data:any=[];
  data1:Data;
  cityArray:any=[];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  loading: boolean;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) { 
      this.enva = environment.assetsURL;
    }

  ngOnInit(): void {
    this.CC2Form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      otp: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      educational_qualification: ['', [Validators.required]],
      professional_degree: [''],
      counselling_related: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue],
      detail: ['', [Validators.required]],
    });

    this.route.paramMap.subscribe(params => {
      this.contact = params.get('contact');
      this.otp = params.get('otp');
      this.name = params.get('name');
      this.email = params.get('email');
      this.state = params.get('state');
      this.city = params.get('city');
      this.fetchState();
      let e={"target":{"value":params.get('state')}};
      console.log(e);
      this.fetchCity(e);
    });
  }

  get c() { return this.CC2Form.controls; }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.CC2Form.patchValue({
      professional_degree: file
    });
    this.CC2Form.get('professional_degree').setValue(file);
    console.log(file);
  }

  CC2Submit(){
    this.CC2_submitted = true;
    // stop here if form is invalid
    if (this.CC2Form.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("contact", this.CC2Form.get('contact').value);
      formData.append("otp", this.CC2Form.get('otp').value);
      formData.append("gender", this.CC2Form.get('gender').value);
      formData.append("pincode", this.CC2Form.get('pincode').value);
      formData.append("address", this.CC2Form.get('address').value);
      formData.append("dob", this.CC2Form.get('dob').value);
      formData.append("educational_qualification", this.CC2Form.get('educational_qualification').value);
      formData.append("professional_degree", this.CC2Form.get('professional_degree').value);
      formData.append("counselling_related", this.CC2Form.get('counselling_related').value);
      formData.append("detail", this.CC2Form.get('detail').value);
      this.loading = true; // Add this line
      //let data = this.CC2Form.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.CareerC2FormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success('Thank You For Submiting Form');
        this.router.navigate(['/']);
        this.CC2_submitted  = false;
        this.loading = false; // Add this line
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }

  fetchState() {
    this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

}
