import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-view-gallery',
  templateUrl: './view-gallery.component.html',
  styleUrls: ['./view-gallery.component.scss']
})
export class ViewGalleryComponent implements OnInit {
  enva: any;
  imageurl:any;
  ImageGalleryArray:any=[];
  data:any=[];
  LetterCountCityAPI:any;
  recordArray:any=[];
  RecordSlug:any;
  route: any;
  RecordData:any=[];
  

  constructor(private restAPI: RESTApiService, private RestAPI: RESTApiService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.fetchImageGallery();
   }

  ngOnInit(): void {
    this.fetchRecordLetterCount();
    this.fetchRecordLetterbySlug();
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.RecordSlug = params['params'].slug;
        console.log(this.RecordSlug)
      }
    });

  }

  fetchImageGallery(){
    this.RestAPI.get(environment.apiURL + environment.ImageGalAPI).subscribe(data =>{
      this.data = data;
      this.ImageGalleryArray = this.data.data.imagegallery;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  fetchRecordLetterCount(){
    this.RestAPI.get(environment.apiURL + environment.LetterCountCityAPI).subscribe(data => {
      this.data = data;
      this.recordArray = this.data.data.letters;
      console.log(this.recordArray);
    }, err => {
      console.log(err);
    });
  }

  fetchRecordLetterbySlug(){
    this.restAPI.get(environment.apiURL + environment.LetterCountCityAPI).subscribe(data =>{
      this.data = data;
      this.RecordData = this.data.data.letters;
      console.log(this.RecordData);
    }, err => {
      console.log(err);
    });
  }

}
