<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>सहयोग करने के लिए धन्यवाद</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">हमें सहयोग करने के लिए धन्यवाद</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="thank-you-page">
    <div class="jumbotron text-center">
        <h1 class="display-3" style="color: #f58634;">धन्यवाद!</h1>
        <p class="lead">हमारे साथ अपने विवरण की पुष्टि करने के लिए धन्यवाद। आपकी प्रोफ़ाइल प्रक्रियाधीन है हम जल्द ही आपको सूचित करेंगे।</p>
        <hr>
        <!-- <p>
            परेशानी है? <a href="">संपर्क करें</a>
        </p>
        <p class="lead">
          <a class="btn btn-primary btn-sm" role="button">होमपेज जारी रखें</a>
        </p> -->
      </div>
</section>