import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  imageurl:any;
  data:any=[];
  enva:any;
  PPArray:any=[];
  constructor(private restAPI: RESTApiService) {
    this.imageurl = environment.imageURL;
    this.enva = environment.assetsURL;
  }

  ngOnInit(): void {
    this.fetchPP();
  }

  fetchPP(){
    this.restAPI.get(environment.apiURL + environment.PPAPI).subscribe(data =>{
      this.data = data;
      this.PPArray = this.data.data.privacy_policy;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }
}
