import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Data } from 'src/app/models/data';
import { MustMatch } from 'src/app/_helpers/mustmatch.validator';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
declare var jQuery: any;
@Component({
  selector: 'app-career-counselling',
  templateUrl: './career-counselling.component.html',
  styleUrls: ['./career-counselling.component.scss']
})
export class CareerCounsellingComponent implements OnInit {
  full_name2: any;
  showMe: boolean= true;
  showMe2: boolean= false;
  showMe3: boolean= false;
  showMe4: boolean= false;
  
  bfi_submitted = false;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  enva:any;
  pass: any;
  motp_submitted = false;
  sendotp_submitted = false;
  votp_submitted = false;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  SendOTPForm: FormGroup;
  imageurl:any;
  statesArray:any=[];cityArray:any=[];
  data:any=[];
  CareerCatArray:any=[];
  data1:Data;
  data2:Data;
  disableBtn = false;
  CareerCArray:any=[];
  CareerCForm: FormGroup;
  COTPForm:FormGroup;
  CROTPForm: FormGroup;
  CareerC_submitted = false;
  mob_no2:number;
  cotp_submitted = false;
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";   
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  mob_no:number;email: string; name: string;state: string;city: string;otp:any;contact:number; //For Data
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService, private auth: AuthenticationService) {
      this.enva = environment.assetsURL;
      this.imageurl = environment.imageURL;
     }

  ngOnInit(): void {
    this.CareerCForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: MustMatch('password', 'password_confirmation')
    });
    this.registrationForm = this.formBuilder.group({
      name: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.COTPForm = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required]
    });

    this.CROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.fetchCareerC();
    this.fetchState();
    this.fetchCareerCategory();
  }

  get c() { return this.CareerCForm.controls; }
  get co() { return this.COTPForm.controls; }
  get v() { return this.COTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get vrfy() { return this.OTPVForm.controls;}
  get lwp() { return this.LWPForm.controls; }

  clearForm() {
    this.CareerCForm.get('email').reset();
    this.CareerCForm.get('password').reset();
    this.CareerCForm.get('address').reset();
    this.CareerCForm.get('state').reset();
    this.CareerCForm.get('is_indian_citizen').reset();
    this.CareerCForm.get('gender').reset();
    this.CareerCForm.get('password').reset();
    this.CareerCForm.get('whatsapp_number').reset();
    this.CareerCForm.get('alternate_mobile_number').reset();
    this.CareerCForm.get('city').reset();
    this.CareerCForm.get('country').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }

  fetchCareerC() {
    this.RestAPI.get(environment.apiURL + environment.CareerCAPI).subscribe(data => {
      this.data = data;
      this.CareerCArray = this.data.data.career_counselling;
      console.log(this.CareerCArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCareerCategory() {
    this.RestAPI.get(environment.apiURL + environment.CareerCatAPI).subscribe(data => {
      this.data = data;
      this.CareerCatArray = this.data.data[0];
      console.log(this.CareerCatArray);
    }, err => {
      console.log(err);
    });
  }

  CareerCSubmit(){
    this.CareerC_submitted = true;
    // stop here if form is invalid
    if (this.CareerCForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.CareerCForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.CareerCFormAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.email = this.data1.email;
        this.name = this.data1.name;
        this.state = this.data1.state;
        this.city = this.data1.city;
        console.log(this.data1);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.CareerCForm.reset();
        this.CareerC_submitted  = false;
        // jQuery('#career-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000); 
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  COTPSubmit(){
    this.cotp_submitted = true;
    // stop here if form is invalid
    if (this.COTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data2 = this.COTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data2).subscribe(data => {
        this.contact = this.data2.contact;
        this.otp = this.data2.otp;
        console.log(this.data2);
        this.toast.success('Proceed to Next Step');
        this.COTPForm.reset();
       jQuery('#career-verification').modal('hide');
        this.cotp_submitted  = false;
        // this.router.navigate(["/career-counselling-form", {contact: this.contact, otp: this.otp, email: this.email, name: this.name, state: this.state, city: this.city}]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  CROTPSubmit(){
    // stop here if form is invalid
    if (this.CROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.CROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchState() {
    this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  onChange(val) {
    console.log(val);
    this.router.navigate(["/career-counselling-by-filter", val]);
}

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }

  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.full_name2 = this.data1.name;
        this.email2 = this.data1.email;
        console.log(this.mob_no);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        /*  */
        this.registratiopn_submitted = false;
        this.showMe4 = false;
        this.showMe3 = true;
        
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/career-counselling"]);
          // jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.showMe2 = true;
        this.showMe3 = false;
        this.showMe4 = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe = false
    /* this.showMe2 = true */
    this.showMe4 = true
  }
}
