import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Data } from 'src/app/models/data';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { JobData } from 'src/app/models/JobData';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
declare var jQuery: any;
@Component({
  selector: 'app-job-filter',
  templateUrl: './job-filter.component.html',
  styleUrls: ['./job-filter.component.scss']
})
export class JobFilterComponent implements OnInit {
  enva:any;
  data:any = [];
  data1:Data;
  Jobdata:JobData;
  mob_no:any;
  statesArray:any=[];
  cityArray:any=[];
  jobArray: any = [];
  disableBtn = false;
  i = 0;
  filtercategory: any;
  imageurl:any;
  JobCategoryArray:any=[];
  jobalert_submitted = false;
  jaotp_submitted = false;
  JROTPForm:FormGroup;
  JAOTPForm:FormGroup;
  JobSlug:any;
  jobAlertForm: FormGroup;
  Filterbycat:any=[];
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";   
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  constructor(private restAPI: RESTApiService,
    private formBuilder: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private toast: ToastrService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.JobSlug = params['params'].slug;
        console.log(this.JobSlug)
      }
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
  
   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
      }
    });

    this.jobAlertForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      contact: ['', Validators.required],
      job_category: ['', Validators.required],
      job_type: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required]
    });
    this.JAOTPForm = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required]
    });
    this.JROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.fetchJobCategory();
    this.fetchJobPost();
    this.fetchState();
  }

  get j() { return this.jobAlertForm.controls; }
  get jao() { return this.JAOTPForm.controls; }
  
  fetchJobCategory(){
    this.restAPI.get(environment.apiURL + environment.objectivesAPI).subscribe(data =>{
      this.data = data;
      this.JobCategoryArray = this.data.data.objective;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  onChange(val) {
    console.log(val);
    this.router.navigate(["/job-by-filter", val]);
  }
  LoggedIn(){
    return localStorage.getItem('token')!= null;
  }

  clearfilter() {
    this.router.navigate(["/jobs"]);
  }

  fetchJobPost(){
    this.restAPI.get(environment.apiURL + environment.JobFilterByCatAPI + this.JobSlug).subscribe(data =>{
      this.data = data;
      this.jobArray = this.data.data.jobpost;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  jobAlertSubmit(){
    this.jobalert_submitted = true;
    // stop here if form is invalid
    if (this.jobAlertForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.jobAlertForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.JobAlertFormAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        console.log(this.data1);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.jobAlertForm.reset();
        this.jobalert_submitted  = false;
        jQuery('#joba-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000); 
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  JAOTPSubmit(){
    this.jaotp_submitted = true;
    // stop here if form is invalid
    if (this.JAOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.JAOTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.JobAlertOTPFormAPI, data).subscribe(data => {
        console.log(this.data);
        this.toast.success('Thank You For Job Alert Subscription.!');
        this.JAOTPForm.reset();
       jQuery('#joba-verification').modal('hide');
        this.jaotp_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  JROTPSubmit(){
    // stop here if form is invalid
    if (this.JROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.JROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.JobAResendOTP, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }


  fetchState() {
    this.restAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

}
