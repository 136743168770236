<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>उपयोगकर्ता प्रोफ़ाइल</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">उपयोगकर्ता प्रोफ़ाइल</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <app-user-sidebar></app-user-sidebar>
            </div>
            <div class="col-sm-9">
                <div class="main-area-dash">
                    <h3 class="head-tit">पार्श्वचित्र समायोजन</h3>
                    <div class="profile-tabs">
                        <ul class="nav nav-tabs">
                            <li><a class="active" data-toggle="tab" href="#home">प्रोफ़ाइल</a></li>
                            <li><a data-toggle="tab" href="#menu1">लॉगिन &amp; सुरक्षा</a></li>
                        </ul>
                        
                        <div class="tab-content">
                            <div id="home" class="tab-pane fade in active show">
                                <div class="dashboard-area">
                                    <form [formGroup]="UserProfileForm" (ngSubmit)="UserProfileSubmit()" >
                                        <div class="form-group row">
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Profile Picture</label>
                                                <input type="file" class="profile-control" formControlName="image" (change)="uploadFile($event)">
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Name</label>
                                                <input type="text" class="profile-control" placeholder="Enter Name" formControlName="name" [(ngModel)]="UserDetailsArray.name"  [ngClass]="{ 'is-invalid': userprof_submitted && up.name.errors }">
                                                <div *ngIf="userprof_submitted && up.name.errors" class="invalid-profile">
                                                    <div *ngIf="up.name.errors.required">ईमेल की आवश्यकता है</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Email</label>
                                                <input type="text" class="profile-control" disabled placeholder="Enter Email" formControlName="email" [(ngModel)]="UserDetailsArray.email" [ngClass]="{ 'is-invalid': userprof_submitted && up.email.errors }">
                                                <div *ngIf="userprof_submitted && up.email.errors" class="invalid-profile">
                                                    <div *ngIf="up.email.errors.required">ईमेल की आवश्यकता है</div>
                                                    <div *ngIf="up.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Mobile Number</label>
                                                <input type="text" class="profile-control" disabled placeholder="Enter Mobile Number" [pattern]="mobNumberPattern" formControlName="contact" [(ngModel)]="UserDetailsArray.contact"  [ngClass]="{ 'is-invalid': userprof_submitted && up.contact.errors }">
                                                <div *ngIf="userprof_submitted && up.contact.errors" class="invalid-profile">
                                                    <div *ngIf="up.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                                    <div *ngIf="up.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Gender</label>
                                                <select class="profile-control" formControlName="gender" [(ngModel)]="UserDetailsArray.gender" [ngClass]="{ 'is-invalid': userprof_submitted && up.gender.errors }">
                                                    <option [selected]="true">Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                </select>
                                                <div *ngIf="userprof_submitted && up.gender.errors" class="invalid-profile">
                                                    <div *ngIf="up.gender.errors.required">Gender is required</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Date of Birth</label>
                                                <input type="date" class="profile-control" formControlName="dob" [(ngModel)]="UserDetailsArray.dob" [ngClass]="{ 'is-invalid': userprof_submitted && up.dob.errors }">
                                                <div *ngIf="userprof_submitted && up.dob.errors" class="invalid-profile">
                                                    <div *ngIf="up.dob.errors.required">D.O.B is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <div class="col-sm-12">
                                                <label class="label label-profile">Address</label>
                                                <input type="text" class="profile-control" formControlName="address" [(ngModel)]="UserDetailsArray.address" [ngClass]="{ 'is-invalid': userprof_submitted && up.address.errors }">
                                                <div *ngIf="userprof_submitted && up.address.errors" class="invalid-profile">
                                                    <div *ngIf="up.address.errors.required">Address is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-6">
                                                <label class="label label-profile">State</label>
                                                <select class="profile-control" formControlName="state" (change)="fetchCity($event)" [(ngModel)]="UserDetailsArray.state" [ngClass]="{ 'is-invalid': userprof_submitted && up.state.errors }">
                                                    <option value="" [selected]="true">Select State</option>
                                                    <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                                <div *ngIf="userprof_submitted && up.state.errors" class="invalid-profile">
                                                    <div *ngIf="up.state.errors.required">State is required</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="label label-profile">City</label>
                                                <select class="profile-control" formControlName="city" [(ngModel)]="UserDetailsArray.city" [ngClass]="{ 'is-invalid': userprof_submitted && up.city.errors }">
                                                    <option [selected]="true" disabled>Select City</option>
                                                    <option  *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                                <div *ngIf="userprof_submitted && up.city.errors" class="invalid-profile">
                                                    <div *ngIf="up.city.errors.required">City is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-action row">
                                            <div class="col-sm-12 text-center">
                                                <button type="submit" class="btn btn-update-std"><i class="far fa-edit"></i> Update Profile</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div id="menu1" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <form [formGroup]="UserSecurityForm" (ngSubmit)="UserSecuritySubmit()">
                                        <div class="form-group row">
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Old Password</label>
                                                <input type="password" class="profile-control" formControlName="current_password" [ngClass]="{ 'is-invalid': usersecurity_submitted && us.current_password.errors }">
                                                <div *ngIf="usersecurity_submitted && us.current_password.errors" class="invalid-security">
                                                    <div *ngIf="us.current_password.errors.required">Old पासवर्ड की आवश्यकता है</div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="form-group row">
                                            <div class="col-sm-6">
                                                <label class="label label-profile">New Password</label>
                                                <input type="password" class="profile-control" formControlName="new_password" [ngClass]="{ 'is-invalid': usersecurity_submitted && us.new_password.errors }">
                                                <div *ngIf="usersecurity_submitted && us.new_password.errors" class="invalid-security">
                                                    <div *ngIf="us.new_password.errors.required">New पासवर्ड की आवश्यकता है</div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="label label-profile">Re-enter Password</label>
                                                <input type="password" class="profile-control" formControlName="confirm_password" [ngClass]="{ 'is-invalid': usersecurity_submitted && us.confirm_password.errors }">
                                                <div *ngIf="usersecurity_submitted && us.confirm_password.errors" class="invalid-security">
                                                    <div *ngIf="us.confirm_password.errors.required">Confirm पासवर्ड की आवश्यकता है</div>
                                                    <div *ngIf="us.confirm_password.errors.mustMatch">Password doesn't matched</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="form-action row">
                                            <div class="col-sm-12 text-center">
                                                <button type="submit" class="btn btn-update-std"><i class="fas fa-unlock-alt"></i> Change Password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>