<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>पर्दाफाश अभियान</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="expose-campaign-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <!-- <p>भंडाफोड़ क्रांति में हमारे देश के युवा व 135 करोड़ भारतीयों को अपना योगदान व सहयोग देना चाहिए
                    जिससे युवाओं के अधिकारों का शोषण करने वाले चेहरों को बेनकाब किया जा सके व इनको कानूनी
                    प्रक्रिया से कड़ी से कड़ी सजा दिलायी जा सके। अबतक ये सुरक्षित इसलिए थे कि हम भारतीयों ने कभी
                    खुलकर इनका विरोध नहीं किये थे अब समय आ गया है जब हम लोग देशहित, जनहित,युवाहित व समाज हित में
                    अपना फर्ज व कर्त्तव्य निभायें। यदि आपके पास कुछ सूचना, साक्ष्य, सबूत है तो आप हमें ऑनलाइन
                    भेज सकते हैं। </p>
                <br> -->
                <div class="heading-title">
                    <!-- <div class="title text-center">
                        <div class="title">
                            
                            <h3 [innerHTML]="ExposeCArray.title"></h3>
                            <p [innerHTML]="ExposeCArray.content"></p>
                            <div [innerHTML]="ExposeCArray.description"></div>
                        </div>
                    </div> -->
                    <h3 [innerHTML]="ExposeCArray.title_hindi"></h3>
                    <p [innerHTML]="ExposeCArray.content_hindi"></p>
                </div>
                <!-- <div class="row justify-content-center">
                     <div class="col-sm-3" *ngFor="let item of ExposeCArray">
                        <div class="card">
                            <div class="card-body">
                                <h5>{{item.title}}</h5>
                                <p>{{item.content}}</p>
                                <p>{{item.description}}</p>
                                <p>{{item.short_description}}</p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="row">
            <div class="col-sm-8">
                <form [formGroup]="NewUserFrom" style="background: #f2f2f2; padding: 30px 14px" *ngIf="showMe">
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="name">
                            <div *ngIf="newuser_submitted && new.name.errors" class="invalid-login">
                                <div *ngIf="new.name.errors.required">पूरा नाम आवश्यक है</div>
                            </div>
                            <div *ngIf="new.name.touched && new.name.invalid" style="color: red">
                                <div *ngIf="new.name.errors.required">
                                    पूरा नाम आवश्यक है
                                </div>
                            </div>
                            <!-- <span *ngIf="new.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                        </div>
                        <div class="col-sm-6" *ngIf="showMe5">
                            <label class="label-control">मोबाइल नंबर<span style="color: red;">*</span></label>
                            <br>
                            <input type="text" style="width: 344px; height: 40px;" [(ngModel)]="mob_no2"
                                disabled class="text-control" formControlName="contact">
                            <p style="color: green;">{{message}}</p>
                        </div>
                        <div class="col-sm-6">
                            <form [formGroup]="registrationForm">
                                <div class="form-group row">
                                    <div class="col-sm-6" style="display: none;">
                                        <label class="label-control">पूरा नाम <span
                                                style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="name"
                                            [ngClass]="{ 'is-invalid':
                                            registratiopn_submitted && r.name.errors }">
                                        <div *ngIf="registratiopn_submitted &&
                                            r.name.errors" class="invalid-login">
                                            <!-- <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div> -->
                                        </div>
                                        <span *ngIf="r.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span>
                                    </div>
                                    <div *ngIf="showMe4">
                                        <label class="label-control">मोबाइल नंबर
                                            <span style="color: red;">*</span></label>
                                        <input style="width: 360px; height: 40px;" type="number"
                                            class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="contact" [ngClass]="{ 'is-invalid':
                                            registratiopn_submitted && r.contact.errors
                                            }" maxlength="10">
                                        <!-- <span *ngIf="r.contact.invalid" class="errormssg">मोबाइल नंबर आवश्यक
                                            है</span> -->
                                        <div *ngIf="registratiopn_submitted &&
                                            r.contact.errors" class="invalid-login">
                                            <div *ngIf="r.contact.errors.required">मोबाइल
                                                नंबर आवश्यक है</div>
                                            <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                नंबर 10 अंकों का होना चाहिए
                                            </div>
                                        </div>
                                        <div *ngIf="r.contact.touched &&
                                        r.contact.invalid" style="color: red">
                                            <div *ngIf="r.contact.errors.required">मोबाइल
                                                नंबर आवश्यक है</div>
                                                <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                    नंबर 10 अंकों का होना चाहिए
                                                </div>
                                        </div>
                                        <button class="btn btn-dark" (click)="registrationSubmit()"
                                            style="margin-top: 25px;" [hidden]="r.contact.invalid"
                                            *ngIf="showMe2">अपना
                                            नंबर
                                            सत्यापित करें
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <form [formGroup]="OTPVForm" (ngSubmit)="OTPVerifySubmit()"
                        style="padding: 30px 14px; padding-top: 0px;" *ngIf="showMe3">
                        <div class="form-group row" style="display: none;">
                            <div class="col-sm-6">
                                <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                <input [(ngModel)]="full_name2" type="text" class="form-control"
                                    formControlName="name" [ngClass]="{
                                        'is-invalid':
                                        otpverify_submitted && vrfy.name.errors
                                        }">
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">
                                    मोबाइल नंबर <span style="color: red;">*</span></label>
                                <br>
                                <input type="number" [(ngModel)]="mob_no" class="text-control"
                                    formControlName="contact" [ngClass]="{ 'is-invalid':
                                        otpverify_submitted && vrfy.contact.errors
                                        }">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-4">
                                <label class="label-control">6
                                    अंक ओटीपी <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" placeholder="Enter OTP"
                                    [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid':
                                        otpverify_submitted && vrfy.otp.errors }">
                                <div *ngIf="otpverify_submitted &&
                                        vrfy.contact.errors" class="invalid-login">
                                    <div *ngIf="vrfy.otp.errors.required">ओटीपी
                                        आवश्यक है</div>
                                    <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6
                                        अंकों का होना चाहिए</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <button class="btn btn-dark" type="submit" style="margin-top: 28px;">ओटीपी
                                    सत्यापित करें
                                </button>
                            </div>

                        </div>
                    </form>

                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="email"
                                [ngClass]="{ 'is-invalid': newuser_submitted && new.email.errors }">
                            <div *ngIf="newuser_submitted && new.email.errors" class="invalid-login">
                                <div *ngIf="new.email.errors.required">ईमेल आवश्यक है</div>
                                <div *ngIf="new.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                            <div *ngIf="new.email.touched && new.email.invalid" style="color: red">
                                <div *ngIf="new.email.errors.required">
                                    ईमेल आवश्यक है
                                </div>
                                <div *ngIf="new.email.errors.email">
                                    ईमेल एक वैध ईमेल पता होना चाहिए
                                </div>
                            </div>
                            <!-- <span *ngIf="new.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">वैकल्पिक मोबाइल
                                नंबर</label>
                            <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                formControlName="alternate_mobile_number">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">लिंग <span style="color: red;">*</span></label>
                            <br>
                            <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                            <input type="radio" formControlName="gender" value="female"> स्त्री
                            <br>
                            <div *ngIf="new.gender.touched && new.gender.invalid" style="color: red">
                                <div *ngIf="new.gender.errors.required">
                                    लिंग आवश्यक है
                                </div>
                            </div>
                            <!-- <span *ngIf="new.gender.invalid" class="errormssg">लिंग आवश्यक है</span> -->
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">क्या आप भारत के
                                नागरिक हैं ? <span style="color: red;">*</span></label>
                            <br>
                            <input type="radio" formControlName="is_indian_citizen" value="yes">
                            हां&nbsp;&nbsp;
                            <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                            <br>
                            <div *ngIf="new.is_indian_citizen.touched && new.is_indian_citizen.invalid"
                                style="color: red">
                                <div *ngIf="new.is_indian_citizen.errors.required">
                                    क्या आप भारत के नागरिक
                                    हैं ? आवश्यक है
                                </div>
                            </div>
                            <!-- <span *ngIf="new.is_indian_citizen.invalid" class="errormssg">क्या आप भारत के नागरिक
                                हैं ? आवश्यक है</span> -->
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">व्हाट्सएप नं</label>
                            <input type="number" class="form-control" formControlName="whatsapp_number">

                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">आवास का पता
                                <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="address" [ngClass]="{ 'is-invalid':
                                                                  newuser_submitted && new.address.errors
                                                                  }">
                            <div *ngIf="newuser_submitted &&
                                                          new.address.errors" class="invalid-login">
                                <div *ngIf="new.address.errors.required">आवास
                                    का पता आवश्यक है</div>
                            </div>
                            <div *ngIf="new.address.touched && new.address.invalid" style="color: red">
                                <div *ngIf="new.address.errors.required">
                                    आवास
                                    का पता आवश्यक है
                                </div>
                            </div>
                            <!-- <span *ngIf="new.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">वर्तमान निवास का देश <span
                                style="color: red;">*</span></label>
                            <select class="form-control" formControlName="country" (change)="fetchState($event)"
                                [ngClass]="{ 'is-invalid': newuser_submitted && new.country .errors }">
                                <option value="" selected>देश चुनें</option>
                                <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}
                                </option>
                            </select>
                            <div *ngIf="new.country.touched && new.country.invalid" style="color: red">
                                <div *ngIf="new.country.errors.required">
                                    वर्तमान निवास का देश आवश्यक है
                                </div>
                            </div>
                            <!-- <span *ngIf="new.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक
                                है</span> -->
                            <div *ngIf="newuser_submitted && new.country.errors" class="invalid-login">
                                <div *ngIf="new.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">राज्य <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                [ngClass]="{ 'is-invalid':
                                                                  newuser_submitted && new.state.errors }">
                                <option value="" [selected]="true">राज्य
                                    चुनें</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                </option>
                            </select>
                            <!-- <span *ngIf="new.state.invalid" class="errormssg">राज्य
                                आवश्यक है</span> -->
                            <div *ngIf="new.state.touched && new.state.invalid" style="color: red">
                                <div *ngIf="new.state.errors.required">
                                    राज्य
                                    आवश्यक है
                                </div>
                            </div>
                            <div *ngIf="newuser_submitted &&
                                                          new.state.errors" class="invalid-login">
                                <div *ngIf="new.state.errors.required">राज्य
                                    आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">

                        <div class="col-sm-6">
                            <label class="label-control">जिला <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="city" [ngClass]="{ 'is-invalid':
                                                                  newuser_submitted && new.city.errors }">
                                <option value="" [selected]="true">जिला
                                    चुनें</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                </option>
                            </select>
                            <!-- <span *ngIf="new.city.invalid" class="errormssg">जिला
                                आवश्यक है</span> -->
                            <div *ngIf="new.city.touched && new.city.invalid" style="color: red">
                                <div *ngIf="new.city.errors.required">
                                    जिला
                                    आवश्यक है
                                </div>
                            </div>
                            <div *ngIf="newuser_submitted &&
                                                          new.city.errors" class="invalid-login">
                                <div *ngIf="new.city.errors.required">जिला
                                    आवश्यक है</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">तहसील का नाम दर्ज करें
                                <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="tehsil" [ngClass]="{ 'is-invalid':
                                                          newuser_submitted && new.tehsil.errors
                                                                  }">
                            <!-- <span *ngIf="new.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक
                                है</span> -->
                            <div *ngIf="newuser_submitted &&
                                                          new.teshil.errors" class="invalid-login">
                                <div *ngIf="new.tehsil.errors.required">तहसील का नाम दर्ज करें आवश्यक है</div>
                            </div>
                            <div *ngIf="new.tehsil.touched && new.tehsil.invalid" style="color: red">
                                <div *ngIf="new.tehsil.errors.required">
                                    तहसील का नाम दर्ज करें आवश्यक है
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label>पासवर्ड <span style="color: red;">*</span></label>
                            <input type="password" class="form-control" [(ngModel)]="password"
                                formControlName="password">
                            <div *ngIf="new.password.touched && new.password.invalid" style="color: red">
                                <!-- <div *ngIf="new.password.errors.required">
                                      पासवर्ड की आवश्यकता है।
                                    </div> -->
                                <div *ngIf="new.password.errors.required">
                                    पासवर्ड कम से कम 8 वर्णों का होना चाहिए
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>पासवर्ड की पुष्टि कीजिये <span style="color: red;">*</span></label>
                            <input type="password" class="form-control" formControlName="password_confirmation"
                                pattern="{{password}}" [(ngModel)]="password_confirmation">
                            <div *ngIf="new.password_confirmation.touched && new.password_confirmation.invalid"
                                style="color: red">
                                <div *ngIf="new.password_confirmation.invalid">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                </div>
                                <!-- <div *ngIf="new.password_confirmation.errors.pattern">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark" (click)="clearForm()">रीसेट
                                करें</button> &nbsp;&nbsp;
                            <button class="btn btn-dark" [disabled]="NewUserFrom.invalid"
                                (click)="NewuserSubmit()">अभी
                                जमा करे</button>
                        </div>
                    </div>
                </form>
                <!-- <form [formGroup]="registrationForm" (ngSubmit)="registrationSubmit()" *ngIf="!LoggedIn()" style="background-color: #f6f6f6; padding: 30px">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">पूरा नाम <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': registratiopn_submitted && r.name.errors }">
                            <div *ngIf="registratiopn_submitted && r.name.errors" class="invalid-login">
                                <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">ईमेल <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': registratiopn_submitted && r.email.errors }">
                            <div *ngIf="registratiopn_submitted && r.email.errors" class="invalid-login">
                                <div *ngIf="r.email.errors.required">ईमेल आवश्यक है</div>
                                <div *ngIf="r.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">मोबाइल नंबर <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" maxlength="10" [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': registratiopn_submitted && r.contact.errors }">
                            <div *ngIf="registratiopn_submitted && r.contact.errors" class="invalid-login">
                                <div *ngIf="r.contact.errors.required">मोबाइल नंबर आवश्यक है</div>
                                <div *ngIf="r.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark" type="submit">ओटीपी के लिए अनुरोध</button>
                        </div>
                    </div>
                </form> -->
                <!-- <form [formGroup]="ExposeCForm" (ngSubmit)="ExposeCSubmit()" style="background-color: #f6f6f6; padding: 30px" *ngIf="!LoggedIn()">
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">पूरा नाम <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': ExposeC_submitted && c.name.errors }">
                            <div *ngIf="ExposeC_submitted && c.name.errors" class="invalid-login">
                                <div *ngIf="c.name.errors.required">पूरा नाम आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <form [formGroup]="registrationForm" (ngSubmit)="registrationSubmit()">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">मोबाइल नंबर
                                    <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                    formControlName="contact" [ngClass]="{ 'is-invalid':
                                    registratiopn_submitted &&
                                    r.contact.errors }" maxlength="10">
                                
                                <div *ngIf="registratiopn_submitted &&
                                    r.contact.errors" class="invalid-login">
                                    <div *ngIf="r.contact.errors.required">मोबाइल
                                        नंबर आवश्यक है</div>
                                    <div *ngIf="r.contact.errors.pattern">Mobile
                                        Number should be 10 digit</div>
                                </div>
                            </div>
                            <div class="col-sm-6"[hidden]="registrationForm.invalid">
                                <button class="btn btn-dark" type="submit" (click)="toogleTag()" style="margin-top: 28px;">अपना नंबर सत्यापित करें
                                </button>
                            </div>
                        </div>                        
                    </form>
                    <form [formGroup]="ECOTPForm" (ngSubmit)="ECOTPSubmit()" *ngIf="showMe">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">6 
                                    अंक ओटीपी</label>
                                <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                    formControlName="otp" [ngClass]="{ 'is-invalid':
                                    motp_submitted && mo.otp.errors }">
                                <div *ngIf="motp_submitted && mo.contact.errors" class="invalid-login">
                                    <div *ngIf="mo.otp.errors.required">ओटीपी आवश्यक है</div>
                                    <div *ngIf="mo.otp.errors.pattern">OTP should be
                                        6 digit</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <button class="btn btn-dark btn-validate" type="submit" style="margin-top: 31px;" (click)="toogleTag2()" [disabled]="ECOTPForm.invalid">ओटीपी सत्यापित करें
                                </button>
                            </div>
                        </div>
                    </form>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">ईमेल <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': ExposeC_submitted && c.email.errors }">
                            <div *ngIf="ExposeC_submitted && c.email.errors" class="invalid-login">
                                <div *ngIf="c.email.errors.required">ईमेल आवश्यक है</div>
                                <div *ngIf="c.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': ExposeC_submitted && c.contact.errors }">
                            <div *ngIf="ExposeC_submitted && c.contact.errors" class="invalid-login">
                                <div *ngIf="c.contact.errors.required">मोबाइल नंबर आवश्यक है</div>
                                <div *ngIf="c.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                            <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6" formControlName="gender">
                            <label class="label-control">लिंग <span
                                style="color: red;">*</span></label> <br>
                            <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                            <input type="radio" formControlName="gender" value="female"> स्त्री
                        </div>
                        <div class="col-sm-6" formControlName="is_indian_citizen">
                            <label class="label-control">क्या आप भारत के नागरिक हैं ? <span
                                style="color: red;">*</span></label>
                            <br>
                            <input type="radio" formControlName="is_indian_citizen" value="yes"> हां&nbsp;&nbsp;
                            <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">व्हाट्सएप नं <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="whatsapp_number"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.whatsapp_number.errors }">
                            <div *ngIf="ExposeC_submitted && c.whatsapp_number.errors" class="invalid-login">
                                <div *ngIf="c.whatsapp_number.errors.required">व्हाट्सएप नं आवश्यक है</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">आवास का पता <span
                                style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="address"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.address.errors }">
                            <div *ngIf="ExposeC_submitted && c.address.errors" class="invalid-login">
                                <div *ngIf="c.address.errors.required">आवास का पता आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">राज्य <span
                                style="color: red;">*</span></label>
                            <select class="form-control" formControlName="state" (change)="fetchCity($event)" [ngClass]="{ 'is-invalid': ExposeC_submitted && c.state.errors }">
                                <option value="" [selected]="true">राज्य चुनें</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="ExposeC_submitted && c.state.errors" class="invalid-login">
                                <div *ngIf="c.state.errors.required">राज्य आवश्यक है</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">जिला <span
                                style="color: red;">*</span></label>
                            <select class="form-control" formControlName="city" [ngClass]="{ 'is-invalid': ExposeC_submitted && c.city.errors }">
                                <option value="" [selected]="true">जिला चुनें</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="ExposeC_submitted && c.city.errors" class="invalid-login">
                                <div *ngIf="c.city.errors.required">जिला आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center" *ngIf="showMe2">
                        <div class="col-sm-6">
                            <label>पासवर्ड</label>
                            <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password">
                            <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                                'orangered', 'orange']"></password-strength-meter>
                            <small id="passwordHelpBlock" class="form-text
                                text-muted">

                                आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                                चार और कम से कम 8 वर्ण होने चाहिए
                            </small>
                            <div *ngIf="mo.password.touched &&
                                mo.password.invalid" class="alert alert-danger">
                                <div *ngIf="mo.password.errors.required">Password
                                    is required.</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>पासवर्ड की पुष्टि कीजिये</label>
                            <input type="password" class="form-control" formControlName="password_confirmation">
                            <div *ngIf="mo.password_confirmation.touched &&
                                mo.password_confirmation.invalid" class="alert
                                alert-danger">
                                <div *ngIf="mo.password_confirmation.errors.required">Confirm
                                    पासवर्ड की आवश्यकता है.</div>
                                <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark" type="reset">रीसेट करें</button> &nbsp;&nbsp;
                            <button class="btn btn-dark" type="submit">अभी जमा करे</button>
                        </div>
                    </div>
                </form> -->


                <!-- <form [formGroup]="ExposeCForm" *ngIf="showMe4" style="background: #f2f2f2;
                padding: 30px 14px">
                    <div class="form-goroup row">
                        <div class="col-sm-6">
                            <label class="label-control">दिनांक : <span style="color: red;">*</span></label>
                            <input type="date" class="form-control" formControlName="date"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.date.errors }">
                            <div *ngIf="ExposeC_submitted && c.date.errors" class="invalid-login">
                                <div *ngIf="c.date.errors.required">दिनांक आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="full_name" readonly
                                formControlName="name"
                                [ngClass]="{ 'is-in valid': ExposeC_submitted && c.name.errors }">
                            <div *ngIf="ExposeC_submitted && c.name.errors" class="invalid-login">
                                <div *ngIf="c.name.errors.required">पूरा नाम आवश्यक है</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">पिता का नाम <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="father_name"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.father_name.errors }">
                            <div *ngIf="ExposeC_submitted && c.father_name.errors" class="invalid-login">
                                <div *ngIf="c.father_name.errors.required">पिता का नाम आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="email"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.email.errors }">
                            <div *ngIf="ExposeC_submitted && c.email.errors" class="invalid-login">
                                <div *ngIf="c.email.errors.required">ईमेल आवश्यक है</div>
                                <div *ngIf="c.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">लिंग <span style="color: red;">*</span></label> <br>
                            <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                            <input type="radio" formControlName="gender" value="female"> स्त्री
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" [(ngModel)]="mob_no2" readonly
                                [pattern]="mobNumberPattern" formControlName="contact"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.contact.errors }">
                            <div *ngIf="ExposeC_submitted && c.contact.errors" class="invalid-login">
                                <div *ngIf="c.contact.errors.required">मोबाइल आवश्यक है</div>
                                <div *ngIf="c.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">वैकल्पिक मोबाइल नंबर <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                formControlName="alternate_mobile_number"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.alternate_mobile_number.errors }">
                            <div *ngIf="ExposeC_submitted && c.contact.errors" class="invalid-login">
                                <div *ngIf="c.alternate_mobile_number.errors.required">मोबाइल 2 आवश्यक है</div>
                                <div *ngIf="c.alternate_mobile_number.errors.pattern">Alternate Mobile Number should be
                                    10 digit
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">क्या आप भारत के नागरिक हैं ? <span
                                    style="color: red;">*</span></label>
                            <br>
                            <input type="radio" formControlName="is_indian_citizen" value="yes"> हां &nbsp;&nbsp;
                            <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                            <div *ngIf="ExposeC_submitted && c.is_indian_citizen.errors" class="invalid-login">
                                <div *ngIf="c.is_indian_citizen.errors.required">क्या आप भारत के नागरिक हैं आवश्यक है
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <label class="label-control">व्यवसाय <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="business"
                                [ngClass]="{ 'is-in valid': ExposeC_submitted && c.business.errors }">
                            <div *ngIf="ExposeC_submitted && c.business .errors" class="invalid-login">
                                <div *ngIf="c.business.errors.required">व्यवसाय आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">व्हाट्सएप नं <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                formControlName="whatsapp_number"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.whatsapp_number.errors }">
                            <div *ngIf="ExposeC_submitted && c.whatsapp_number.errors" class="invalid-login">
                                <div *ngIf="c.whatsapp_number.errors.required">व्हाट्सएप नं आवश्यक है</div>
                                <div *ngIf="c.whatsapp_number.errors.pattern">Whatsapp Number should be 10 digit</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">फेसबुक युआरएल <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                formControlName="facebook_url"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.contact.errors }">
                            <div *ngIf="ExposeC_submitted && c.facebook_url.errors" class="invalid-login">
                                <div *ngIf="c.facebook_url.errors.required">फेसबुक युआरएल आवश्यक है</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control"> आवास का पता <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="address"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.address.errors }">
                            <div *ngIf="ExposeC_submitted && c.address.errors" class="invalid-login">
                                <div *ngIf="c.address.errors.required">आवास का पता आवश्यक है</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">वर्तमान निवास का देश <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="country" (change)="fetchState($event)"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.country .errors }">
                                <option value="" selected>देश चुनें</option>
                                <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="ExposeC_submitted && c.country.errors" class="invalid-login">
                                <div *ngIf="c.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">राज्य <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.state.errors }">
                                <option value="" [selected]="true">राज्य चुनें</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="ExposeC_submitted && c.state.errors" class="invalid-login">
                                <div *ngIf="c.state.errors.required">राज्य आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">जनपद <span style="color: red;">*</span></label>

                            <select class="form-control" formControlName="city"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.city.errors }">
                                <option value="" [selected]="true">जनपद चुनें</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="ExposeC_submitted && c.city.errors" class="invalid-login">
                                <div *ngIf="c.city.errors.required">जनपद आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label>पासवर्ड</label>
                            <input type="password" class="form-control" [(ngModel)]="pass" minlength="6" formControlName="password">
                            
                            <div *ngIf="c.password.touched &&
                                c.password.invalid" class="alert alert-danger">
                                <div *ngIf="c.password.errors.required">पासवर्ड की आवश्यकता है।</div>
                                <div *ngIf="c.password.errors.minlength">पासवर्ड कम से कम 6 वर्णों का होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>पासवर्ड की पुष्टि कीजिये</label>
                            <input type="password" class="form-control" formControlName="password_confirmation">
                            <div *ngIf="c.password_confirmation.touched &&
                                c.password_confirmation.invalid" class="alert
                                alert-danger">
                                <div *ngIf="c.password_confirmation.errors.required">Confirm
                                    पासवर्ड की आवश्यकता है.</div>
                                <div *ngIf="c.password_confirmation.errors.mustMatch">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-sm-12">
                            <p>: भारतीय रक्षा सेवाओं की भर्ती प्रक्रियाओं व प्रतियोगी परीक्षाओं की चयन प्रक्रियाओं में
                                दलाली, घूसखोरी, धाँधली, हेराफेरी व पेपर लीक करने वालों के बारे में सूचना -</p>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control"> नाम पता मोबाइल नं0 पद किन-किन कार्यों में लिप्त हैं व अन्य
                                जुड़ी सूचना - <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="extra_information"
                                [ngClass]="{ 'is-invalid': ExposeC_submitted && c.extra_information.errors }">
                            
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">
                                आडियो वीडियो रिकार्डिंग अपलोड करें <span style="color: red;">*</span></label>
                            <input type="file" class="form-control"
                                formControlName="recording_file" (change)="uploadRecordingFile($event)">
                            <div *ngIf="ExposeC_submitted && c.recording_file.errors" class="invalid-login">
                                <div *ngIf="c.recording_file.errors.required"> आडियो वीडियो रिकार्डिंग अपलोड आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control" style="font-size: 14px;">: क्या आप चाहते हैं कि आपका नाम गोपनीय
                                रखा जाय? <span style="color: red;">*</span></label> <br>
                            <input type="radio" formControlName="is_name_confidentail" value="yes"> हां &nbsp;&nbsp;
                            <input type="radio" formControlName="is_name_confidentail" value="no"> नहीं
                            
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control" style="font-size: 14px;">: यदि किसी सूचना की जरुरत पड़ी तो क्या
                                आप से सम्पर्क किया जा सकता
                                है? <span style="color: red;">*</span></label>
                            <br>
                            <input type="radio" formControlName="can_contact_for_information" value="yes"> हां &nbsp;&nbsp;
                            <input type="radio" formControlName="can_contact_for_information" value="no"> नहीं
                            
                        </div>
                    </div>
                    <br>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control" style="font-size: 14px;">: क्या आप चाहते हैं कि आपके द्वारा दी
                                गयी सूचना,साक्ष्य व सबूत को
                                भण्डाफोड़ क्रान्ति की मीडिया गैलेरी व आडियो वीडियो गैलेरी में अपलोड किया जा सकता है ?
                                <span style="color: red;">*</span></label>
                            &nbsp;&nbsp;
                            <input type="radio" formControlName="can_upload_evidence_to_busted" value="yes"> हां &nbsp;&nbsp;
                            <input type="radio" formControlName="can_upload_evidence_to_busted" value="no"> नहीं
                           
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control" style="font-size: 14px;">: क्या आप चाहते हैं कि इनके विरुद्ध
                                कानूनी कार्यवाही की जाए इसके
                                लिए आप के द्वारा दी गयी सूचना, साक्ष्य व सबूत को जरुरत के अनुसार उपयोग में लाया जा सकता
                                है ? <span style="color: red;">*</span></label> &nbsp;&nbsp;
                            <input type="radio" formControlName="can_take_legal_action" value="yes"> हां &nbsp;&nbsp;
                            <input type="radio" formControlName="can_take_legal_action" value="no"> नहीं
                            
                        </div>
                    </div>
                    <br>
                    <div class="form-group">
                        <div class="col-sm-6">
                            <label class="label-control" style="font-size: 14px;">
                                समाचार पत्रों में इससे सम्बंधित समाचार प्रकाशित होने पर कटिंग अपलोड करें। <span
                                    style="color: red;">*</span></label>
                            <input type="file" class="form-control"
                                formControlName="newspaper_file" (change)="uploadNewspaperFile($event)">
                            <div *ngIf="ExposeC_submitted && c.newspaper_file.errors" class="invalid-login">
                                <div *ngIf="c.newspaper_file.errors.required">आईडी कार्ड जमा करें आवश्यक है</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="form-group">
                        <div class="col-sm-12" style="font-weight: 500; font-size: 14px;">
                            <input type="checkbox" id="html" name="fav_language" value="">&nbsp; मैं घोषणा करता हूँ कि
                            मैं
                            राष्ट्रधर्म सेवा संगठन के मिशन, आन्दोलन, क्रान्ति, अभियान व इससे जुड़ी सभी जानकारी तथा
                            सूचनाओं से
                            पूर्णरुप से सहमत व वचनबद्ध हूँ। मेरे द्वारा यहाँ प्रस्तुत की गयी सूचनाएँ पूर्णरुप से सत्य
                            है। <span style="color: red;">*</span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark" (click)="clearForm()" >रीसेट
                                करें</button> &nbsp;&nbsp;
                            <button class="btn btn-dark" (click)="ExposeCSubmit()">अभी
                                जमा करे</button>
                        </div>
                    </div>
                </form> -->
            </div>
            <div class="col-sm-4">
                <div class="exposec-sidebar">
                    <!-- <div class="form-exposec" *ngIf="!LoggedIn()">
                        <h3 class="classtitle">के खिलाफ खुलासा करने में हमारी मदद करें<br>रिश्वत प्रैक्टिस, ब्रोकरेज
                            प्रैक्टिस</h3>
                        <form [formGroup]="ExposeCForm" (ngSubmit)="ExposeCSubmit()">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Name"
                                        formControlName="name"
                                        [ngClass]="{ 'is-invalid': ExposeC_submitted && c.name.errors }">
                                    <div *ngIf="ExposeC_submitted && c.name.errors" class="invalid-login">
                                        <div *ngIf="c.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">Email Id</label>
                                    <input type="text" class="form-control" placeholder="Enter Email Id"
                                        formControlName="email"
                                        [ngClass]="{ 'is-invalid': ExposeC_submitted && c.email.errors }">
                                    <div *ngIf="ExposeC_submitted && c.email.errors" class="invalid-login">
                                        <div *ngIf="c.email.errors.required">ईमेल की आवश्यकता है</div>
                                        <div *ngIf="c.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">Mobile No.</label>
                                    <input type="text" class="form-control" placeholder="Enter Mobile No."
                                        [pattern]="mobNumberPattern" formControlName="contact"
                                        [ngClass]="{ 'is-invalid': ExposeC_submitted && c.contact.errors }">
                                    <div *ngIf="ExposeC_submitted && c.contact.errors" class="invalid-login">
                                        <div *ngIf="c.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                        <div *ngIf="c.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">State</label>
                                    <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                        [ngClass]="{ 'is-invalid': ExposeC_submitted && c.state.errors }">
                                        <option value="" [selected]="true">Select State</option>
                                        <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="ExposeC_submitted && c.state.errors" class="invalid-login">
                                        <div *ngIf="c.state.errors.required">State is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">City</label>
                                    <select class="form-control" formControlName="city"
                                        [ngClass]="{ 'is-invalid': ExposeC_submitted && c.city.errors }">
                                        <option value="" [selected]="true">Select City</option>
                                        <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="ExposeC_submitted && c.city.errors" class="invalid-login">
                                        <div *ngIf="c.city.errors.required">City is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-dark" type="submit">Submit Now</button>
                                </div>
                            </div>
                        </form>
                    </div> -->

                    <div class="boxes-section">
                        <div class="row">
                            <div class="col-sm-12" *ngFor="let item of BoxesContentArray | slice:0:3; let i = index">
                                <div class="tab tab-{{i+1}}">
                                    <i class="main-i {{item.icon_text}}"></i>
                                    <h5>{{item.title}}</h5>
                                    <p>{{item.description}}</p>
                                    <a [routerLink]="[item.link]">{{item.button_name}} <i
                                            class="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade otp-modal" id="exposec-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="ECOTPForm" (ngSubmit)="ECOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">मोबाइल नंबर
                            </label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>
                                    कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                <p>
                                    एक ओटीपी भेजा गया है{{mob_no}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">पूरा नाम </label>
                            <input type="text" class="form-control" [(ngModel)]="full_name" disabled
                                formControlName="name">
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">ईमेल</label>
                            <input type="text" class="form-control" formControlName="email" disabled
                                [(ngModel)]="email2">
                        </div>
                    </div>
                    <div class="form-group row">

                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label>पासवर्ड</label>
                            <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password">
                            <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                                'orangered', 'orange']"></password-strength-meter>
                            <small id="passwordHelpBlock" class="form-text
                                text-muted">

                                आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                                चार और कम से कम 8 वर्ण होने चाहिए
                            </small>
                            <div *ngIf="mo.password.touched &&
                                mo.password.invalid" class="alert alert-danger">
                                <div *ngIf="mo.password.errors.required">Password
                                    is required.</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>पासवर्ड की पुष्टि कीजिये</label>
                            <input type="password" class="form-control" formControlName="password_confirmation">
                            <div *ngIf="mo.password_confirmation.touched &&
                                mo.password_confirmation.invalid" class="alert
                                alert-danger">
                                <div *ngIf="mo.password_confirmation.errors.required">Confirm
                                    पासवर्ड की आवश्यकता है.</div>
                                <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6
                                अंक ओटीपी</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                formControlName="otp" [ngClass]="{ 'is-invalid':
                                motp_submitted && mo.otp.errors }">
                            <div *ngIf="motp_submitted && mo.contact.errors" class="invalid-login">
                                <div *ngIf="mo.otp.errors.required">ओटीपी आवश्यक है</div>
                                <div *ngIf="mo.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <form [formGroup]="EROTPForm" (ngSubmit)="EROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">यदि आपको ओटीपी प्राप्त नहीं हुआ है, आप 30 . के बाद एक नए ओटीपी का
                                अनुरोध कर सकते हैं सेकंड</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">पुन: भेजें
                                ओटीपी</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade otp-modal" id="exposec-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="ECOTPForm" (ngSubmit)="ECOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>Please enter the OTP to verify your mobile number</h3>
                                <p>A OTP has sent to {{mob_no}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 Digit OTP</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                formControlName="otp" [ngClass]="{ 'is-invalid': ecotp_submitted && ec.otp.errors }">
                            <div *ngIf="ecotp_submitted && ec.contact.errors" class="invalid-login">
                                <div *ngIf="ec.otp.errors.required">OTP is required</div>
                                <div *ngIf="ec.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Validate OTP</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <form [formGroup]="EROTPForm" (ngSubmit)="EROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">If you did not received OTP, you can request a new OTP after 30
                                seconds</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend
                                OTP</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->