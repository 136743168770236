<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <h3>संगठन संरचना</h3>
      <div class="breadcrumb-sec">
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
              </ol>
            </nav>
      </div>
  </div>
  </div>
</div>
</section>



<section class="strength-page-section">
  <div class="container">
    <div class="heading-title">
      <!-- <div class="title">
        <h3>Welcome to <span>RDSS INDIA</span><img src="{{enva}}assets/images/profile/india.gif" class="img-flag"></h3>
        <div [innerHTML]="OrgStructureData.description"></div>
      </div> -->
    </div>

    <div class="row mt-4">
      <div class="col-sm-12">
        <div class="heading-title">
          <div class="title">
            <h3></h3>
          </div>
        </div>
        <div class="info-2" [innerHTML]="OrgStructureData.page2_content">
        <!--Content here-->
        </div>
      </div>

      <div class="col-sm-12">
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item disabled">
              <a class="page-link" [routerLink]="['/organization-structure']" tabindex="-1">पिछला</a>
            </li>
            <li class="page-item"><a class="page-link" [routerLink]="['/organization-structure']">1</a></li>
            <li class="page-item"><a class="page-link">2</a></li>
            <li class="page-item">
              <a class="page-link">आगे</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</section>
