import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
@Component({
  selector: 'app-letters-maharashtra',
  templateUrl: './letters-maharashtra.component.html',
  styleUrls: ['./letters-maharashtra.component.scss']
})
export class LettersMaharashtraComponent implements OnInit {

  galleryAndLetterMetaData:any=[];
  RecordLetterCount:any;
  recordArray:any=[];
  data: any=[];
  constructor(private titleService: Title, private meta: Meta, private canonical: CanonicalService, private RestAPI: RESTApiService) {
    this.titleService.setTitle(this.galleryAndLetterMetaData.meta_title);
    this.meta.addTags([
      { name: 'description', content: this.galleryAndLetterMetaData.meta_description },
      { name: 'keywords', content: this.galleryAndLetterMetaData.meta_keywords },
      { property: 'og:title', content: this.galleryAndLetterMetaData.og_title },
      { proprety: 'og:description', content: this.galleryAndLetterMetaData.og_description },
      { property: 'og:type', content: this.galleryAndLetterMetaData.og_type },
      { property: 'article:publisher', content: this.galleryAndLetterMetaData.publisher },
      { property: 'og:locale', content: this.galleryAndLetterMetaData.og_locale },
      { name: 'twitter:card', content: this.galleryAndLetterMetaData.twitter_card },
      { name: 'twitter:creator', content: this.galleryAndLetterMetaData.twitter_creator },
      { name: 'twitter:description', content: this.galleryAndLetterMetaData.twitter_description },
      { name: 'twitter:image', content: this.galleryAndLetterMetaData.twitter_img_url },
      { name: 'twitter:site', content: this.galleryAndLetterMetaData.twitter_site },
      { name: 'twitter:title', content: this.galleryAndLetterMetaData.twitter_title },
      { name: 'twitter:domain', content: this.galleryAndLetterMetaData.twitter_domain },
      { name: 'robots', content: this.galleryAndLetterMetaData.meta_robots },
      { property: 'og:image', content: this.galleryAndLetterMetaData.og_image },
      { property: 'og:url', content: this.galleryAndLetterMetaData.og_url },
      { property: 'og:site_name', content: this.galleryAndLetterMetaData.og_site_name },
      { property: 'artical:publisher', content: this.galleryAndLetterMetaData.articale_publisher },
      { name: 'twitter:type', content: this.galleryAndLetterMetaData.twitter_type }
    ]); 
   }
  ngOnInit(): void {
    this.canonical.createCanonicalLink();
    this.fetchRecordLetterCount();
  }
  fetchRecordLetterCount(){
    this.RestAPI.get(environment.apiURL + environment.RecordLetterCountAPI).subscribe(data => {
      this.data = data;
      this.recordArray = this.data.data.records;
      console.log(this.recordArray);
    }, err => {
      console.log(err);
    });
  }

}
