import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Data } from 'src/app/models/data';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-expose-campaign-form',
  templateUrl: './expose-campaign-form.component.html',
  styleUrls: ['./expose-campaign-form.component.scss']
})
export class ExposeCampaignFormComponent implements OnInit {
  ExposeC3Form:FormGroup;
  ExposeC3_submitted = false;
  contact:any;name:any;email:any;state:any;city:any;otp:any; //For Param
  enva:any;
  statesArray:any=[];
  data:any=[];
  data1:Data;
  cityArray:any=[];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  loading: boolean;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) { 
    this.enva = environment.assetsURL;
  }

  ngOnInit(): void {
    this.ExposeC3Form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      otp: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      citizen_india: ['yes', [Validators.required]],
      other_information: ['', [Validators.required]],
      video: ['', [Validators.required]],
      image: ['', [Validators.required]],
      confidential_name: ['no', [Validators.required]],
      information_connected: ['no', [Validators.required]],
      media_gallery: ['no', [Validators.required]],
      legal_action: ['no', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });

    this.route.paramMap.subscribe(params => {
      this.contact = params.get('contact');
      this.otp = params.get('otp');
      this.name = params.get('name');
      this.email = params.get('email');
      this.state = params.get('state');
      this.city = params.get('city');
      this.fetchState();
      let e={"target":{"value":params.get('state')}};
      console.log(e);
      this.fetchCity(e);
    });
  }

  get ec() { return this.ExposeC3Form.controls; }


  ExposeC3Submit(){
    this.ExposeC3_submitted = true;
    // stop here if form is invalid
    if (this.ExposeC3Form.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("contact", this.ExposeC3Form.get('contact').value);
      formData.append("otp", this.ExposeC3Form.get('otp').value);
      formData.append("gender", this.ExposeC3Form.get('gender').value);
      formData.append("citizen_india", this.ExposeC3Form.get('citizen_india').value);
      formData.append("other_information", this.ExposeC3Form.get('other_information').value);
      formData.append("image", this.ExposeC3Form.get('image').value);
      formData.append("video", this.ExposeC3Form.get('video').value);
      formData.append("confidential_name", this.ExposeC3Form.get('confidential_name').value);
      formData.append("information_connected", this.ExposeC3Form.get('information_connected').value);
      formData.append("media_gallery", this.ExposeC3Form.get('media_gallery').value);
      formData.append("legal_action", this.ExposeC3Form.get('legal_action').value);
      formData.append("pincode", this.ExposeC3Form.get('pincode').value);
      formData.append("address", this.ExposeC3Form.get('address').value);
      this.loading = true; // Add this line
      //let data = this.BR2Form.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.ExposeCStep3FormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success('Thank You For Submiting Form');
        this.router.navigate(['/']);
        this.ExposeC3_submitted  = false;
        this.loading = false; // Add this line
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }

  uploadImage(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.ExposeC3Form.patchValue({
      image: file
    });
    this.ExposeC3Form.get('image').setValue(file);
    console.log(file);
  }

  uploadVideo(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.ExposeC3Form.patchValue({
      video: file
    });
    this.ExposeC3Form.get('video').setValue(file);
    console.log(file);
  }

  fetchState() {
    this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

}
