import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-become-volunteer',
  templateUrl: './become-volunteer.component.html',
  styleUrls: ['./become-volunteer.component.scss']
})
export class BecomeVolunteerComponent implements OnInit {
  VolunteerForm:FormGroup;
  data:any=[];
  loading: boolean;
  cityArray:any=[];
  statesArray:any=[];
  volunteer_submitted = false;
  contact:any;otp:any;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) { }

  ngOnInit(): void {
    this.VolunteerForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      otp: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      father_name: ['', [Validators.required]],
      adhaar_number: ['', [Validators.required]],
      adhaar_doc: ['', [Validators.required]],
      pan_number: ['', [Validators.required]],
      pan_doc: ['', [Validators.required]],
      other_doc_title:  ['', [Validators.required]],
      other_doc: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });

    this.route.paramMap.subscribe(params => {
      this.contact = params.get('contact');
      this.otp = params.get('otp');
    });
    this.fetchState();
  }

  upload_adhaar(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.VolunteerForm.patchValue({
      adhaar_doc: file
    });
    this.VolunteerForm.get('adhaar_doc').setValue(file);
    console.log(file);
  }

  upload_pan(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.VolunteerForm.patchValue({
      pan_doc: file
    });
    this.VolunteerForm.get('pan_doc').setValue(file);
    console.log(file);
  }

  upload_other(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.VolunteerForm.patchValue({
      other_doc: file
    });
    this.VolunteerForm.get('other_doc').setValue(file);
    console.log(file);
  }

  get v() { return this.VolunteerForm.controls; }

  VolunteerSubmit(){
    this.volunteer_submitted = true;
    // stop here if form is invalid
    if (this.VolunteerForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("first_name", this.VolunteerForm.get('first_name').value);
      formData.append("last_name", this.VolunteerForm.get('last_name').value);
      formData.append("gender", this.VolunteerForm.get('gender').value);
      formData.append("email", this.VolunteerForm.get('email').value);
      formData.append("contact", this.VolunteerForm.get('contact').value);
      formData.append("otp", this.VolunteerForm.get('otp').value);
      formData.append("address", this.VolunteerForm.get('address').value);
      formData.append("state", this.VolunteerForm.get('state').value);
      formData.append("city", this.VolunteerForm.get('city').value);
      formData.append("pincode", this.VolunteerForm.get('pincode').value);
      formData.append("dob", this.VolunteerForm.get('dob').value);
      formData.append("father_name", this.VolunteerForm.get('father_name').value);
      formData.append("adhaar_number", this.VolunteerForm.get('adhaar_number').value);
      formData.append("adhaar_doc", this.VolunteerForm.get('adhaar_doc').value);
      formData.append("father_name", this.VolunteerForm.get('father_name').value);
      formData.append("pan_number", this.VolunteerForm.get('pan_number').value);
      formData.append("pan_doc", this.VolunteerForm.get('pan_doc').value);
      formData.append("other_doc_title", this.VolunteerForm.get('other_doc_title').value);
      formData.append("other_doc", this.VolunteerForm.get('other_doc').value);
      this.loading = true; // Add this line
      this.RestAPI.post(environment.apiURL + environment.VolunteerFormStep3API, formData).subscribe(data => {
        console.log(formData);
        this.toast.success('Congratulations! You Volunteer Form has been submitted Successfully');
        this.router.navigate(["/"]);
        this.VolunteerForm.reset();
        this.volunteer_submitted  = false;
        this.loading = false; // Add this line
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }

  fetchState() {
    this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

}
