<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>ब्लॉग डिटेल्स </h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/blog']">ब्लॉग</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">ब्लॉग डिटेल्स </li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-detail-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="blog-detail-con">
                    <h3>{{BlogData.heading}}</h3>
                    <ul>
                        <li><i class="fas fa-clock"></i> {{BlogData.created_at}}</li>
                        <li><i class="fas fa-eye"></i> 899</li>
                    </ul>
                    <img src="{{imageurl}}{{BlogData.image}}" class="img-fluid">
                    <div class="blog-detail-p" [innerHTML]="BlogData.content"></div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="blog-sidebar">
                    <div class="blog-sidebar-widgets">
                        <div class="blog-detail-wrap">
                            <div class="blog-detail-wrap-header">
                                <h4>रिलेटेड पोस्ट्स</h4>
                            </div>
                            <div class="blog-detail-wrap-body">
                                <ul class="blog-post">
                                    <li *ngFor="let item of BlogArray">
                                        <div class="blog-li">
                                            <div class="blog-img">
                                                <a [routerLink]="['/blog-detail', item.slug]"><img src="{{imageurl}}{{item.image}}" alt=""></a>
                                            </div>
                                            <div class="blog-cont">
                                                <h6><a [routerLink]="['/blog-detail', item.slug]">{{item.heading}}</a></h6>
                                                <p>{{item.content}}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
