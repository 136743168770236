import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentModel } from '../../models/payment.model';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PayumoneyPaymentService } from 'src/app/services/payumoney-payment.service';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {

  paymentModel: PaymentModel = new PaymentModel();
  paymentForm = new FormGroup({
    firstname : new FormControl(''),
    lastname : new FormControl(''),
    email : new FormControl(''),
    phone : new FormControl(''),
    amount : new FormControl(''),
    productinfo : new FormControl('')
  });
  donation_amount3: any;
  public payuform: any = {};
  disablePaymentButton: boolean = true;
  OTPVForm: FormGroup;
  otpverify_submitted = false;

  constructor(private http: HttpClient, private restAPI: RESTApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toast: ToastrService,  private RestAPI: RESTApiService, private paymentService: PayumoneyPaymentService) { }

  confirmPayment() {
    const paymentPayload = {
      email: this.payuform.email,
      name: this.payuform.firstname,
      phone: this.payuform.phone,
      productInfo: this.payuform.productinfo,
      amount: this.payuform.amount
    }
    return this.http.post<any>('https://test.payu.in/_payment -H', paymentPayload).subscribe(
      data => {
      console.log(data);
      this.payuform.txnid = data.txnId;
      this.payuform.surl = data.sUrl;
      this.payuform.furl = data.fUrl;
      this.payuform.key = data.key;
      this.payuform.hash = data.hash;
      this.payuform.txnid = data.txnId;
        this.disablePaymentButton = false;
    }, error1 => {
        console.log(error1);
      })
  }

  ngOnInit(): void {
    this.OTPVForm = this.formBuilder.group({
      amount: [''],
      name: [''],
      cardno: ['']
    });
  }
  
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.OTPVForm.reset();
        this.otpverify_submitted = false;

      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  onSubmit() {

    this.paymentModel.firstname = this.paymentForm.value.firstname;
    this.paymentModel.lastname = this.paymentForm.value.lastname;
    this.paymentModel.email = this.paymentForm.value.email;
    this.paymentModel.phone = this.paymentForm.value.phone;
    this.paymentModel.amount = this.paymentForm.value.amount;
    this.paymentModel.productinfo = this.paymentForm.value.productinfo;

    console.log('Payment Model : ' + JSON.stringify(this.paymentModel));
    this.paymentService.createPayment(this.paymentModel).subscribe(
      res => {
        this.onSuccessPayment(res);
      },
      err => {
        this.onFailurePayment(err);
      }
    );
  }

  onSuccessPayment(response) {
    console.log('Success Payment : ' + response);
    if (response.url) {
      // Render PayUmoney payment gateway page
      window.location.href = response.url;
    }
  }

  onFailurePayment(error) {
    console.log('Failure Payment : ' + error);
  }
}
