import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  enva:any;
  imageurl:any;
  BlogArray:any=[];
  data:any=[];
  constructor(private restAPI: RESTApiService,
    private toast: ToastrService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
   }

  ngOnInit(): void {
    this.fetchBlogs();
  }

  fetchBlogs(){
    this.restAPI.get(environment.apiURL + environment.BlogAPI).subscribe(data =>{
      this.data = data;
      this.BlogArray = this.data.data.blog;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }
}
