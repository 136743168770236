<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>उपयोगकर्ता सदस्यता</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">उपयोगकर्ता सदस्यता</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <app-user-sidebar></app-user-sidebar>
            </div>
            <div class="col-sm-9">
                <div class="main-area-dash">
                    <h3 class="head-tit">सदस्यता</h3>
                    <section class="dashboard-area">
                        <div class="subscription-tabs">
                            <ul class="nav nav-tabs">
                                <li><a class="active" data-toggle="tab" href="#mission-sub">मिशन</a></li>
                                <li><a data-toggle="tab" href="#objectives-sub">उद्देश्य</a></li>
                                <li><a data-toggle="tab" href="#other-programs">अन्य कार्यक्रम</a></li>
                                <li><a data-toggle="tab" href="#job-alerts">नौकरी अलर्ट</a></li>
                            </ul>
                            <div class="tab-content">
                                <div id="mission-sub" class="tab-pane fade in active show">
                                    <div class="dashboard-area">
                                        <div class="subscription-box-main">
                                            <div class="row">
                                                <div class="col-sm-12" *ngFor="let item of UserSubscriptionMission">
                                                    <div class="subscription-box">
                                                        <div class="subs-head">
                                                            <i class="{{item.icon}}"></i>
                                                            <h3>{{item.mission_name}}</h3>
                                                        </div>
                                                        <div class="subscription-button">
                                                            <div class="already-subscribed" *ngIf="item.subscribed == 'yes'">
                                                                <i class="fas fa-check"></i> पहले से है
                                                                <button type="button" class="btn btn-unsubscribe" (click)="MissionSubscribe($event, item)"><i class="fas fa-times"></i></button>
                                                            </div>
                                                            <div class="not-subscribed" *ngIf="item.subscribed == 'no'">
                                                                <button type="button" class="btn btn-subscribe" (click)="MissionSubscribe($event, item)">अभी ग्राहक बनें</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="objectives-sub" class="tab-pane fade">
                                    <div class="dashboard-area">
                                        <div class="subscription-box-main">
                                            <div class="row">
                                                <div class="col-sm-12" *ngFor="let item of UserSubscriptionObjective">
                                                    <div class="subscription-box">
                                                        <div class="subs-head">
                                                            <i class="{{item.icon}}"></i>
                                                            <h3>{{item.objective_name}}</h3>
                                                        </div>
                                                        <div class="subscription-button">
                                                            <div class="already-subscribed" *ngIf="item.subscribed == 'yes'">
                                                                <i class="fas fa-check"></i> पहले से है
                                                                <button type="button" class="btn btn-unsubscribe" (click)="ObjectiveSubscribe($event, item)"><i class="fas fa-times"></i></button>
                                                            </div>
                                                            <div class="not-subscribed" *ngIf="item.subscribed == 'no'">
                                                                <button type="button" class="btn btn-subscribe" (click)="ObjectiveSubscribe($event, item)">अभी ग्राहक बनें</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="other-programs" class="tab-pane fade">
                                    <div class="dashboard-area">
                                        <div class="subscription-box-main">
                                            <div class="row">
                                                <div class="col-sm-12" *ngFor="let item of UserSubscriptionOther">
                                                    <div class="subscription-box">
                                                        <div class="subs-head">
                                                            <i class="fas fa-chevron-right"></i>
                                                            <h3>{{item.name}}</h3>
                                                        </div>
                                                        <div class="subscription-button">
                                                            <div class="already-subscribed" *ngIf="item.subscribed == 'yes'">
                                                                <i class="fas fa-check"></i> पहले से है
                                                                <button type="button" class="btn btn-unsubscribe" (click)="OtherSubscribe($event, item)"><i class="fas fa-times"></i></button>
                                                            </div>
                                                            <div class="not-subscribed" *ngIf="item.subscribed == 'no'">
                                                                <button type="button" class="btn btn-subscribe" (click)="OtherSubscribe($event, item)">अभी ग्राहक बनें</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="job-alerts" class="tab-pane fade">
                                    <div class="dashboard-area">
                                        <div class="subscription-box-main">
                                            <div class="row">
                                                <div class="col-sm-12 mb-3 text-right">
                                                    <button type="button" data-target="#add-jobalert" data-toggle="modal" class="btn-addjobalert"><i class="fas fa-plus"></i> नया अलर्ट सेट करें</button>
                                                    <hr />
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-sm-12" *ngFor="let item of UserSubscriptionJobAlerts">
                                                    <div class="subscription-box-job-alerts">
                                                        <div class="subs-head">
                                                            <h3>{{item.category}}</h3>
                                                            <ul>
                                                                <li><i class="fas fa-align-center"></i> {{item.type}}</li>
                                                                <li><i class="fas fa-map-marker"></i> {{item.state}}, {{item.city}}</li>
                                                            </ul>
                                                        </div>
                                                        <div class="subscription-button">
                                                            <ul>
                                                                <li><button class="btn-delete" (click)="JobAlertDelete($event, item)" type="button"><i class="fas fa-trash"></i> हटाएं</button></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal fade show" [style.display]="MissionSubscribeModal ? 'block' : 'none'" id="mission-subscribe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="MSForm" (ngSubmit)="MSSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Mission ID</label>
                            <input type="text" class="form-control" formControlName="mission_id" readonly [(ngModel)]="MissionFormData.mission_id" >
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <h3 class="h4-modalhead">Are You Sure you want to 
                                <span *ngIf="MissionFormData.subscribed == 'no'">subscribe</span>
                                <span *ngIf="MissionFormData.subscribed == 'yes'">unsubscribe</span> 
                                {{MissionFormData.heading}} ?</h3>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">
                                <span *ngIf="MissionFormData.subscribed == 'no'">Yes, अभी ग्राहक बनें</span>
                                <span *ngIf="MissionFormData.subscribed == 'yes'">Yes, Unअभी ग्राहक बनें</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade show" [style.display]="ObjectiveSubscribeModal ? 'block' : 'none'" id="objective-subscribe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="hideobjective()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="OForm" (ngSubmit)="OSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Objective ID</label>
                            <input type="text" class="form-control" formControlName="objective_id" readonly [(ngModel)]="ObjectiveFormData.objective_id" >
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <h3 class="h4-modalhead">Are You Sure you want to 
                                <span *ngIf="ObjectiveFormData.subscribed == 'no'">subscribe</span>
                                <span *ngIf="ObjectiveFormData.subscribed == 'yes'">unsubscribe</span> 
                                {{ObjectiveFormData.objective_name}} ?</h3>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">
                                <span *ngIf="ObjectiveFormData.subscribed == 'no'">Yes, अभी ग्राहक बनें</span>
                                <span *ngIf="ObjectiveFormData.subscribed == 'yes'">Yes, Unअभी ग्राहक बनें</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade show" [style.display]="OtherSubscribeModal ? 'block' : 'none'" id="other-subscribe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="hideother()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="OPForm" (ngSubmit)="OPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Program Name</label>
                            <input type="text" class="form-control" formControlName="type" [(ngModel)]="OtherFormData.form_name" readonly>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <h3 class="h4-modalhead">Are You Sure you want to 
                                <span *ngIf="OtherFormData.subscribed == 'no'">subscribe</span>
                                <span *ngIf="OtherFormData.subscribed == 'yes'">unsubscribe</span> 
                                {{OtherFormData.name}} ?</h3>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">
                                <span *ngIf="OtherFormData.subscribed == 'no'">Yes, अभी ग्राहक बनें</span>
                                <span *ngIf="OtherFormData.subscribed == 'yes'">Yes, Unअभी ग्राहक बनें</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="add-jobalert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-userlogin">
        <div class="modal-content">
            <div class="top-design"><img class="img-fluid" src="{{enva}}assets/images/top-login.png"></div>
            <div class="modal-close">
                <button class="close" data-dismiss="modal" type="button">×</button>
            </div>
            <div class="modal-body">
                <div class="row login-heads">
                    <div class="col-sm-12">
                        <h3 class="heads-login">Add Job Alerts</h3>
                        <span class="allrequired">All field are required</span></div>
                    </div>
                    <form [formGroup]="jobAlertForm" (ngSubmit)="jobAlertSubmit()">
                        <div class="form-group">
                            <label>Category</label>
                            <select class="text-material" formControlName="job_category" [ngClass]="{ 'is-invalid': jobalert_submitted && j.job_category.errors }">
                                <option value="" selected="true">Choose Category</option>
                                <option value="{{item.id}}" *ngFor="let item of JobCategoryArray">{{item.category_name}}</option>
                            </select>
                            <div *ngIf="jobalert_submitted && j.job_category.errors" class="invalid-login">
                                <div *ngIf="j.job_category.errors.required">Category is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Job Type</label>
                            <select class="text-material" formControlName="job_type" [ngClass]="{ 'is-invalid': jobalert_submitted && j.job_type.errors }">
                                <option value="" selected="true">Choose Type</option>
                                <option value="part-time">Part Time</option>
                                <option value="full-time">Full Time</option>
                                <option value="all">Both</option>
                            </select>
                            <div *ngIf="jobalert_submitted && j.job_type.errors" class="invalid-login">
                                <div *ngIf="j.job_type.errors.required">Job Type is required</div>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label>State</label>
                            <select class="text-material" formControlName="state" (change)="fetchCity($event)" [ngClass]="{ 'is-invalid': jobalert_submitted && j.state.errors }">
                                <option value="" selected="true">Select State</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="jobalert_submitted && j.state.errors" class="invalid-login">
                                <div *ngIf="j.state.errors.required">State is required</div>
                            </div>
                        </div>
                        
                        <div class="form-group">
                            <label>City</label>
                            <select class="text-material" formControlName="city" [ngClass]="{ 'is-invalid': jobalert_submitted && j.city.errors }">
                                rs }">
                                <option value="" [selected]="true">Select City</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="jobalert_submitted && j.city.errors" class="invalid-login">
                                <div *ngIf="j.city.errors.required">City is required</div>
                            </div>
                        </div>

                        <div class="row form-group mt-lg-10">
                            <div class="col-sm-12">
                                <button type="submit" class="btn-login">Add New Alert</button>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade show" [style.display]="JobAlertDeletemodal ? 'block' : 'none'" id="jobalert-delete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="hidejobalert()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="JADForm" (ngSubmit)="JADSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Alert Id</label>
                            <input type="text" class="form-control" formControlName="alert_id" [(ngModel)]="JobAlertFormData.alert_id" readonly>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <h3 class="h4-modalhead">Are You Sure you want to Delete {{JobAlertFormData.category}} Job Alert ?</h3>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Yes, Delete Now</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>