<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>जॉब</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">जॉब</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="jobs-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-7">
                <div class="job-list-filters">
                    <h3 class="showing-result-h">ताज़ी ओपनिंग
                        <div class="sortby-listing" style="display: none;">
                            <label class="label-sort">Sort By:</label>
                            <select class="form-sorting">
                                <option>Sort By</option>
                                <option>Latest</option>
                                <option>Oldest</option>
                            </select>
                        </div>
                        <div class="sortby-listing">
                            <label class="label-sort">केटेगरी:</label>
                            <select class="form-sorting" (change)="onChange($event.target.value)">
                                <option value="" selected="true">सेलेक्ट केटेगरी</option>
                                <option *ngFor="let item of JobCategoryArray" value="{{item.slug}}">
                                    {{item.category_name}}</option>
                            </select>
                        </div>
                    </h3>
                </div>
                <div class="job-listing">
                    <div class="row">
                        <div class="col-sm-12" *ngFor="let item of jobArray">
                            <div class="job-list-main">
                                <div class="job-list-wrap">
                                    <div class="job-list-thumb">
                                        <a [routerLink]="['/job-detail',item.slug]"><img
                                                src="{{imageurl}}{{item.image}}" class="img-fluid" alt=""></a>
                                    </div>
                                    <div class="job-list-caption">
                                        <h4><a [routerLink]="['/job-detail',item.slug]">{{item.title}}</a><span
                                                class="jb-status {{item.job_type}}">{{item.job_type}}</span></h4>
                                        <ul>
                                            <li><i class="far fa-building"></i>{{item.category_name}}</li>
                                            <li><i class="fas fa-map-marker"></i> {{item.city_name}},
                                                {{item.state_name}} </li>
                                        </ul>
                                        <p [innerHTML]="item.job_description"></p>
                                    </div>
                                </div>

                                <div class="job-list-btn">
                                    <a [routerLink]="['/job-detail',item.slug]" class="btn btn-shortlist"><i
                                            class="fas fa-arrow-right"></i>और देखे</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="job-listing-paginate">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li class="page-item"><a class="active page-link">1</a></li>
                            <li class="page-item"><a class="page-link">2</a></li>
                            <li class="page-item"><a class="page-link">3</a></li>
                        </ul>
                    </nav>
                </div>-->
            </div>
            <div class="col-sm-6 col-md-6 col-lg-5">
                <div class="job-list-sidebar-widgets">
                    <div class="job-list-detail-wrap">
                        <div class="job-list-detail-wrap-header">
                            <h4>सुचना पाने के लिए</h4>
                        </div>
                        <div class="job-list-detail-wrap-body">
                            <!-- <form [formGroup]="LWPForm" (ngSubmit)="LWPSubmit()" *ngIf="showMe" class="text-center"
                                style="background: #f2f2f2; padding: 30px 14px">
                                <div class="row form-group">
                                    <div class="col-sm-12 ">
                                        <label>पंजीकृत ईमेल / मोबाइल नंबर</label>
                                        <br>
                                        <input class="text-material" formControlName="email" type="text"
                                            [ngClass]="{ 'is-invalid': lwp_submitted && lwp.email.errors }">
                                        <div *ngIf="lwp_submitted && lwp.email.errors" class="invalid-login">
                                            <div *ngIf="lwp.email.errors.required">ईमेल की आवश्यकता है</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col-sm-12">
                                        <label>पास वर्ड</label>
                                        <br>
                                        <input class="text-material" formControlName="password" type="password"
                                            [ngClass]="{ 'is-invalid': lwp_submitted && lwp.password.errors }">
                                        <div *ngIf="lwp_submitted && lwp.password.errors" class="invalid-login">
                                            <div *ngIf="lwp.password.errors.required">पासवर्ड की आवश्यकता है</div>
                                        </div>
                                        <br>
                                        <a href="#" class="forgotatag" data-dismiss="modal"
                                            data-target="#forgot-password" data-toggle="modal">पासवर्ड भूल गए ?</a>
                                    </div>
                                </div>


                                <div class="row form-group mt-lg-10">
                                    <div class="col-sm-12">
                                        <button type="submit" class="btn-login">लॉग इन करें</button>
                                    </div>
                                </div>
                                <div class="modal-foo">
                                    <a type="button" (click)="toogleTag()" class="forgotatag">नया उपयोगकर्ता ? अभी
                                        पंजीकरण करें</a>
                                </div>
                            </form> -->
                            <!-- <form [formGroup]="registrationForm" style="background: #f2f2f2; padding: 30px 14px"
                                (ngSubmit)="registrationSubmit()" *ngIf="showMe4" class="text-center">
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                      registratiopn_submitted && r.name.errors
                              }">
                                        <div *ngIf="registratiopn_submitted &&
                          r.name.errors" class="invalid-login">
                                            <div *ngIf="r.name.errors.required">पूरा
                                                नाम आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">मोबाइल नंबर
                                            <span style="color: red;">*</span></label>
                                        <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="contact" [ngClass]="{ 'is-invalid':
                              registratiopn_submitted &&
                              r.contact.errors }" maxlength="10">
                                        <div *ngIf="registratiopn_submitted &&
                              r.contact.errors" class="invalid-login">
                                            <div *ngIf="r.contact.errors.required">मोबाइल
                                                नंबर आवश्यक है</div>
                                            <div *ngIf="r.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <button class="btn btn-dark" type="submit" style="margin-top: 25px;">अपना नंबर
                                            सत्यापित करें
                                        </button>
                                    </div>
                                </div>
                            </form> -->
                            <!-- <form [formGroup]="OTPVForm" style="background: #f2f2f2; padding: 30px 14px" (ngSubmit)="OTPVerifySubmit()" *ngIf="showMe3" class="text-center">
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                        <input [(ngModel)]="full_name2"  type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                                        otpverify_submitted && vrfy.name.errors
                                                }">
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control" >
                                            मोबाइल नंबर <span style="color: red;">*</span></label>
                                            <br>
                                        <input type="number" [(ngModel)]="mob_no" class="text-control" formControlName="contact" [ngClass]="{ 'is-invalid': otpverify_submitted && vrfy.contact.errors }">            
                                    </div>      
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">6
                                            अंक ओटीपी <span style="color: red;">*</span></label>
                                        <input type="number" class="form-control"
                                            placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp"
                                            [ngClass]="{ 'is-invalid':
                                            otpverify_submitted && vrfy.otp.errors }">
                                        <div *ngIf="otpverify_submitted && vrfy.contact.errors" class="invalid-login">
                                            <div *ngIf="vrfy.otp.errors.required">ओटीपी आवश्यक है</div>
                                            <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <button class="btn btn-dark" type="submit"
                                            style="margin-top: 28px;">ओटीपी सत्यापित करें
                                        </button>
                                    </div>
                                </div>
                            </form> -->
                            <form [formGroup]="jobAlertForm" (ngSubmit)="jobAlertSubmit()">
                                <div class="form-group">
                                    <label class="label-control">पूरा नाम<span style="color: red;">*</span></label>
                                    <input type="text" class="form-control"
                                        formControlName="name"
                                        [ngClass]="{ 'is-invalid': jobalert_submitted && j.name.errors }">
                                    <div *ngIf="jobalert_submitted && j.name.errors" class="invalid-login">
                                        <div *ngIf="j.name.errors.required">पूरा नाम की आवश्यकता है</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="label-control">ईमेल<span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="email"
                                        [ngClass]="{ 'is-invalid': jobalert_submitted && j.email.errors }">
                                    <div *ngIf="jobalert_submitted && j.email.errors" class="invalid-login">
                                        <div *ngIf="j.email.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>मोबाइल नंबर<span style="color: red;">*</span></label>
                                    <input type="text" class="form-control"
                                        formControlName="contact"
                                        [ngClass]="{ 'is-invalid': jobalert_submitted && j.contact.errors }">
                                    <div *ngIf="jobalert_submitted && j.contact.errors" class="invalid-login">
                                        <div *ngIf="j.contact.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="alternate_mobile_number">
                                    </div>

                                </div> -->
                                <!-- <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">व्हाट्सएप नंबर<span
                                                style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="whatsapp_number"
                                            [ngClass]="{ 'is-invalid': jobalert_submitted && j.whatsapp_number.errors }">
                                        <div *ngIf="jobalert_submitted && j.whatsapp_number.errors"
                                            class="invalid-login">
                                            <div *ngIf="j.whatsapp_number.errors.required">WhatsApp No की आवश्यकता है</div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="form-group row">
                                    <div class="col-sm-6">
                                        <p>लिंग <span style="color: red;">*</span></p>
                                        <label><input type="radio" value="male" formControlName="gender"> पुरुष</label>
                                        &nbsp;&nbsp;
                                        <label><input type="radio" value="female" formControlName="gender">
                                            स्त्री</label>
                                    </div>
                                    <div class="col-sm-6">
                                        <p>क्या आप भारत के नागरिक हैं ? <span style="color: red;">*</span></p>
                                        <label><input type="radio" value="yes" formControlName="is_indian_citizen">
                                            हां</label>
                                        &nbsp;&nbsp;
                                        <label><input type="radio" value="no" formControlName="is_indian_citizen">
                                            नहीं</label>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label>श्रेणी</label>
                                    <select class="form-control" formControlName="job_category"
                                        [ngClass]="{ 'is-invalid': jobalert_submitted && j.job_category.errors }">
                                        <option value="" selected="true">वर्ग चुने</option>
                                        <option value="{{item.id}}" *ngFor="let item of JobCategoryArray">
                                            {{item.category_name}}</option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.job_category.errors" class="invalid-login">
                                        <div *ngIf="j.job_category.errors.required">वर्ग की आवश्यकता है</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>कार्य का प्रकार</label>
                                    <select class="form-control" formControlName="job_type"
                                        [ngClass]="{ 'is-invalid': jobalert_submitted && j.job_type.errors }">
                                        <option value="" selected="true">नौकरी का प्रकार चुनें</option>
                                        <option value="part-time">Part Time</option>
                                        <option value="full-time">Full Time</option>
                                        <option value="all">Both</option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.job_type.errors" class="invalid-login">
                                        <div *ngIf="j.job_type.errors.required">नौकरी का प्रकार की आवश्यकता है</div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">आवास का पता <span
                                                style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="address"
                                            [ngClass]="{ 'is-invalid': jobalert_submitted && j.address.errors }">
                                        <div *ngIf="jobalert_submitted && j.address.errors" class="invalid-login">
                                            <div *ngIf="j.address.errors.required">Home Address की आवश्यकता है</div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="form-group">
                                    <label>राज्य</label>
                                    <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                        [ngClass]="{ 'is-invalid': jobalert_submitted && j.state.errors }">
                                        <option value="" selected="true">राज्य चुनें</option>
                                        <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.state.errors" class="invalid-login">
                                        <div *ngIf="j.state.errors.required">राज्य की आवश्यकता है</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>जिला</label>
                                    <select class="form-control" formControlName="city"
                                        [ngClass]="{ 'is-invalid': jobalert_submitted && j.city.errors }">
                                        rs }">
                                        <option value="" [selected]="true">जिला चुनें</option>
                                        <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.city.errors" class="invalid-login">
                                        <div *ngIf="j.city.errors.required">जिला की आवश्यकता है</div>
                                    </div>
                                </div>
                                <!-- <button type="reset" class="btn btn-primary">Reset</button> -->
                                <button type="submit" class="btn btn-primary">ओटीपी पर आगे बढ़ें</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade otp-modal" id="joba-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="JAOTPForm" (ngSubmit)="JAOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>
                                    कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                <p>
                                    एक ओटीपी भेजा गया है {{mob_no}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 अंकों का ओटीपी</label>
                            <input type="number" class="form-control" [pattern]="OTPPattern" formControlName="otp"
                                [ngClass]="{ 'is-invalid': jaotp_submitted && jao.otp.errors }">
                            <div *ngIf="jaotp_submitted && jao.otp.errors" class="invalid-login">
                                <div *ngIf="jao.otp.errors.required">OTP की आवश्यकता है</div>
                                <div *ngIf="jao.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <form [formGroup]="JROTPForm" (ngSubmit)="JROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">
                                यदि आपको ओटीपी प्राप्त नहीं हुआ है, तो आप 30 सेकंड के बाद एक नए ओटीपी का अनुरोध कर सकते
                                हैं</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">ओटीपी पुनः
                                भेजें</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>