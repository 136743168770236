<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>हमारा लक्ष्य</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['']">मुख्य पृष्ठ</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="mission-page-section">
    <section class="main">
        <div class="container">
            <div class="row justify-space-between">
                <div class="col-sm-4">
                    <div class="info-image">
                        <img src="{{imageurl}}{{MissionArray.image}}" alt="img" class="img-fluid">
                    </div>
                </div>
                <div class="col-sm-7 align-self-center">
                    <div class="info">
                        <div [innerHTML]="MissionArray.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="mission">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="title">
                        <h2>हम समस्याओं को हल करने के लिए मिशन पर हैं</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-md-4" *ngFor="let item of MissionProblemArray">
                    <div class="outer">
                        <div class="inner-box">
                            <div class="icon">
                                <i class="{{item.text}}"></i>
                            </div>
                            <div class="text">
                                <h4>{{item.heading}}</h4>
                                <p [innerHTML]="item.description"></p>
                                <a [routerLink]="" id="{{item.id}}" *ngIf="!LoggedIn()" (click)="onClick($event, item)">आगे पढ़े</a>
                                <a [routerLink]="['/objective-detail', item.slug]" *ngIf="LoggedIn()">आगे पढ़े</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

<div class="modal mission-modal fade show" [style.display]="showModal ? 'block' : 'none'" id="mission-detail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close" (click)="hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-7">
                        <div class="missiondetail-left">
                            <img src="{{imageurl}}{{modaldata.image}}" class="img-fluid">
                            <h3>{{modaldata.heading}}</h3>
                            <p [innerHTML]="modaldata.description"></p>
                            <a [routerLink]="['/objective-detail', modaldata.slug]" class="view-detail-modal">View Detail</a>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="missiondetail-right">
                            <h3>{{modaldata.enquiry_form_title}}</h3>
                            <form [formGroup]="MissionForm" (ngSubmit)="MissionSubmit()">
                                <div class="form-group row" style="display: none;">
                                    <div class="col-sm-12">
                                        <input type="text" class="text-control" [(ngModel)]="modaldata.id" formControlName="mission_id">
                                    </div>
                                </div>            
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम</label>
                                        <input type="text" class="text-control" formControlName="name" [ngClass]="{ 'is-invalid': mission_submitted && m.name.errors }">
                                        <div *ngIf="mission_submitted && m.name.errors" class="invalid-login">
                                            <div *ngIf="m.name.errors.required">Name is required</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">मोबाइल</label>
                                        <input type="number" class="text-control" [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': mission_submitted && m.contact.errors }">
                                        <div *ngIf="mission_submitted && m.contact.errors" class="invalid-login">
                                            <div *ngIf="m.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                            <div *ngIf="m.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">ईमेल</label>
                                        <input type="text" class="text-control" formControlName="email" [ngClass]="{ 'is-invalid': mission_submitted && m.email.errors }">
                                        <div *ngIf="mission_submitted && m.email.errors" class="invalid-login">
                                            <div *ngIf="m.email.errors.required">ईमेल की आवश्यकता है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">पिन कोड</label>
                                        <input type="number" class="text-control" formControlName="pincode" [ngClass]="{ 'is-invalid': mission_submitted && m.pincode.errors }">
                                        <div *ngIf="mission_submitted && m.pincode.errors" class="invalid-login">
                                            <div *ngIf="m.pincode.errors.required">Pincode is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">आवास का पता</label>
                                        <input type="text" class="text-control" formControlName="address" [ngClass]="{ 'is-invalid': mission_submitted && m.address.errors }">
                                        <div *ngIf="mission_submitted && m.address.errors" class="invalid-login">
                                            <div *ngIf="m.address.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">राज्य</label>
                                        <select class="text-control" formControlName="state" (change)="fetchCity($event)" [ngClass]="{ 'is-invalid': mission_submitted && m.state.errors }">
                                            <option value="" [selected]="true">राज्य चुनें</option>
                                            <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                                        </select>
                                        <div *ngIf="mission_submitted && m.state.errors" class="invalid-login">
                                            <div *ngIf="m.state.errors.required">State is required</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">जिला</label>
                                        <select class="text-control" formControlName="city" [ngClass]="{ 'is-invalid': mission_submitted && m.city.errors }">
                                            <option value="" [selected]="true">जिला चुनें</option>
                                            <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                                        </select>
                                        <div *ngIf="mission_submitted && m.city.errors" class="invalid-login">
                                            <div *ngIf="m.city.errors.required">City is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" type="submit">ओटीपी पर आगे बढ़ें</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade otp-modal" id="mission-verification" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="MOTPForm" (ngSubmit)="MOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">ID</label>
                            <input type="number" class="form-control" placeholder="Enter ID" [(ngModel)]="mission_id" formControlName="mission_id">
                        </div>
                        <div class="col-sm-12">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>Please enter the OTP to verify your mobile number</h3>
                                <p>A OTP has sent to {{mob_no}}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 Digit OTP</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid': motp_submitted && mo.otp.errors }">
                            <div *ngIf="motp_submitted && m.contact.errors" class="invalid-login">
                                <div *ngIf="mo.otp.errors.required">OTP is required</div>
                                <div *ngIf="mo.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Validate OTP</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <form [formGroup]="MROTPForm" (ngSubmit)="MROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span  class="wish-otp">If you did not received OTP, you can request a new OTP after 30 seconds</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend OTP</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
