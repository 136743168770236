import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { DownloadFileService } from 'src/app/services/download-flie.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-our-vision-andolan',
  templateUrl: './our-vision-andolan.component.html',
  styleUrls: ['./our-vision-andolan.component.scss']
})
export class OurVisionAndolanComponent implements OnInit {

  data:any=[];
  OurVisionArray:any=[];
  enva:any;
  BoxesContentArray:any=[]; 
  imageurl:any;
  videoArray:any=[];

  constructor(private downloadFileService: DownloadFileService, private restAPI: RESTApiService, private RestAPI: RESTApiService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
   }

  ngOnInit(): void {
    this.fetchOurVision();
  }

  fetchOurVision(){
    this.restAPI.get(environment.apiURL + environment.OurVisionAndolan).subscribe(data => {
      this.data = data;
      this.OurVisionArray = this.data.data.our_vision;
      console.log(this.OurVisionArray);
    }, err => {
      console.log(err);
    });
  }
  public downloadFile():void{
    this.downloadFileService.downloadFile()
    .subscribe(response=>
      {
        let fileName=response.headers.get('letter_format')
        ?.split(';')[1].split('=')[1];
        // let blob:Blob=response.body as Blob;
        let blob = new Blob([response.body], { type: 'application/pdf' });
        let a = document.createElement('a');
        a.download="Letter.pdf";
        a.href = window.URL.createObjectURL(blob);
        a.click();
        
      }

    )
  }
  fetchAndolanVideos(){
    this.RestAPI.get(environment.apiURL + environment.VideosAndolan).subscribe(data => {
      this.data = data;
      this.videoArray = this.data.data.video;
      console.log(this.videoArray);

    }, err => {
      console.log(err);
    }
    );
  }
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
}
