<section class="page-header-section" style="
    background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  ">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>सहयोग करें</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/']">मुख्य पृष्ठ</a>
                            </li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">सहयोग करें</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="movement-page-section">
    <div class="container">
        <div class="row">
            <div class="row" *ngIf="showMeDonation">
                <div class="col-sm-12 text-center">
                    <h1 style="color: #171a80; font-weight: bold;">प्रिव्यू पृष्ठ</h1>
                    <br>
                </div>
                <div class="row">
                    <div class="col-sm-7" *ngIf="showMeDonation">
                        <div>
                            <form [formGroup]="UploadSSForm">
                                <div class="form-group row" style="display: none;">
                                    <div class="col-sm-12">
                                        <input type="text" class="text-control" *ngFor="let item of userId" value="{{ item.id }}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red">*</span></label>
                                        <input type="text" [(ngModel)]="donation_name" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="name" />
                                        <!-- <div *ngIf="newuser_submitted && new.name.errors" class="invalid-login">
                                            <div *ngIf="new.name.errors.required">पूरा नाम आवश्यक है</div>
                                        </div>
                                        <span *ngIf="new.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">मोबाइल नंबर<span style="color: red">*</span></label>
                                        <br />
                                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" [(ngModel)]="mob_no3" disabled class="text-control" formControlName="contact" />
                                        <p style="color: green">{{ message }}</p>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">लिंग <span style="color: red">*</span></label>
                                        <br/>
                                        <input type="radio" formControlName="gender" [(ngModel)]="donation_gender" value="male"/> पुरुष&nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" [(ngModel)]="donation_gender" value="female"/> स्त्री
                                        <br/>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">क्या आप भारत के नागरिक हैं ?
                                            <span style="color: red">*</span></label>
                                        <br/>
                                        <input type="radio" formControlName="is_indian_citizen" [(ngModel)]="donation_is_indian_citizen" value="yes" /> हां&nbsp;&nbsp;
                                        <input type="radio" formControlName="is_indian_citizen" [(ngModel)]="donation_is_indian_citizen" value="no" /> नहीं
                                        <br/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">व्हाट्सएप नं</label>
                                        <input type="number" [(ngModel)]="donation_whatsapp" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="whatsapp_number" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                        <input type="number" [(ngModel)]="donation_alternate" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="alternate_mobile_number" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">ईमेल <span style="color: red">*</span></label>
                                        <input type="text" [(ngModel)]="donation_email" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="email" [ngClass]="{'is-invalid': newuser_submitted && new.email.errors}" />
                                        <!-- <div *ngIf="newuser_submitted && new.email.errors" class="invalid-login">
                                            <div *ngIf="new.email.errors.required">ईमेल आवश्यक है</div>
                                            <div *ngIf="new.email.errors.email">
                                                ईमेल एक वैध ईमेल पता होना चाहिए
                                            </div>
                                        </div>
                                        <span *ngIf="new.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">आवास का पता <span style="color: red">*</span></label>
                                        <input type="text" [(ngModel)]="donation_address" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="address" [ngClass]="{'is-invalid': newuser_submitted && new.address.errors}" />
                                        <!-- <div *ngIf="newuser_submitted && new.address.errors" class="invalid-login">
                                            <div *ngIf="new.address.errors.required">
                                                आवास का पता आवश्यक है
                                            </div>
                                        </div>
                                        <span *ngIf="new.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">वर्तमान निवास का देश
                                            <span class="required-span">*</span></label>
                                        <select class="form-control" [(ngModel)]="donation_country" style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="country" (change)="fetchState($event)"
                                            [ngClass]="{'is-invalid': newuser_submitted && new.country.errors}">
                                            <option value="" selected>देश चुनें</option>
                                            <option *ngFor="let item of countryArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <br/>
                                        <!-- <span *ngIf="new.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक
                                            है</span>
                                        <div *ngIf="newuser_submitted && new.country.errors" class="invalid-login">
                                            <div *ngIf="new.country.errors.required">
                                                वर्तमान निवास का देश आवश्यक है
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">राज्य <span style="color: red">*</span></label>
                                        <select class="form-control" [(ngModel)]="donation_state" style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="state" (change)="fetchCity($event)"
                                            [ngClass]="{'is-invalid': newuser_submitted && new.state.errors}">
                                            <option value="" [selected]="true">राज्य चुनें</option>
                                            <option *ngFor="let item of statesArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <br/>
                                        <!-- <span *ngIf="new.state.invalid" class="errormssg">राज्य आवश्यक है</span>
                                        <div *ngIf="newuser_submitted && new.state.errors" class="invalid-login">
                                            <div *ngIf="new.state.errors.required">राज्य आवश्यक है</div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">जिला <span style="color: red">*</span></label>
                                        <select class="form-control" [(ngModel)]="donation_city" style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="city" [ngClass]="{'is-invalid': newuser_submitted && new.city.errors}">
                                            <option value="" [selected]="true">जिला चुनें</option>
                                            <option *ngFor="let item of cityArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <br/>
                                        <!-- <span *ngIf="new.city.invalid" class="errormssg">जिला आवश्यक है</span>
                                        <div *ngIf="newuser_submitted && new.city.errors" class="invalid-login">
                                            <div *ngIf="new.city.errors.required">जिला आवश्यक है</div>
                                        </div> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">तहसील का नाम दर्ज करें
                                            <span style="color: red">*</span></label>
                                        <input type="text" [(ngModel)]="donation_tehsil" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="tehsil" [ngClass]="{'is-invalid': newuser_submitted && new.tehsil.errors}" />
                                        <!-- <span *ngIf="new.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक है</span>
                                        <div *ngIf="newuser_submitted && new.teshil.errors" class="invalid-login">
                                            <div *ngIf="new.tehsil.errors.required">
                                                तहसील का नाम दर्ज करें आवश्यक है
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पेशा <span style="color: red">*</span></label>
                                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" [(ngModel)]="donation_profession" formControlName="profession" [ngClass]="{ 'is-invalid': newuser_submitted && new.profession.errors}" />
                                        <!-- <span *ngIf="new.profession.invalid" class="errormssg">पेशा आवश्यक है</span>
                                        <div *ngIf="newuser_submitted && new.profession.errors" class="invalid-login">
                                            <div *ngIf="new.profession.errors.required">
                                                पेशा आवश्यक है
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">कृपया दान राशि चुनें <span style="color: red">*</span></label><br />
                                        <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="100" (click)="toggle2()" />
                                        ₹100&nbsp;&nbsp;
                                        <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="500" (click)="toggle2()" />
                                        ₹500&nbsp;&nbsp;
                                        <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="1000" (click)="toggle2()" />
                                        ₹1000&nbsp;&nbsp;
                                        <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="2000" (click)="toggle2()" />
                                        ₹2000&nbsp;&nbsp;<br/>
                                        <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="5000" (click)="toggle2()" />
                                        ₹5000 &nbsp;&nbsp;
                                        <input type="radio" formControlName="amount" [(ngModel)]="donation_amount" value="" (click)="toggle()" /> अन्य 
                                        <input formControlName="amount" *ngIf="showMe6" type="text" />
                                        <br />
                                        <!-- <span *ngIf="new.amount.invalid" class="errormssg">कृपया दान राशि चुनें</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पैन कार्ड नं<span style="color: red">*</span></label>
                                        <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" [(ngModel)]="donation_pancard" formControlName="pancard" [ngClass]="{ 'is-invalid': newuser_submitted && new.profession.errors}" />
                                        <!-- <span *ngIf="new.profession.invalid" class="errormssg">पेशा आवश्यक है</span>
                                        <div *ngIf="newuser_submitted && new.profession.errors" class="invalid-login">
                                            <div *ngIf="new.profession.errors.required">
                                                पेशा आवश्यक है
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-sm-5" *ngIf="showMeDonation">
                        <table style="background-color: #EEEEEE; width: 500px; height: 500px; left: 940px; top: 790px;">
                            <br>
                            <tr>
                                <td style="font-weight: bold">खाता का नाम</td>
                                <td>राष्ट्रधर्म सेवा संगठन</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">खाता संख्या</td>
                                <td>33852355461</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">बैंक</td>
                                <td>स्टेट बैंक आफ इंडिया</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">शाखा</td>
                                <td>मुख्य शाखा,गाजीपुर</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">जिला</td>
                                <td>गाजीपुर</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">राज्य</td>
                                <td>उत्तर प्रदेश</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">ब्रान्च कोड</td>
                                <td>000080</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">आर्ई एफ एस सी कोड</td>
                                <td>SBIN0000080 (5 वर्ण शून्य है)</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold">खाता का प्रकार</td>
                                <td>चालू खाता</td>
                            </tr>
                            <br>
                        </table>
                        <br>
                        <form [formGroup]="UploadSSForm">
                            <div class="form-group">
                                <!-- <div class="">
                                    <label class="label-control" style="color: #f58634;">यहाँ पर जमापर्ची अपलोड करे </label> <br>
                                    <input type="file" class="text-control" formControlName="image"
                                      (change)="uploadImage($event)">
                                    <div *ngIf="uploadss_submitted && ss.image.errors" class="invalid-login">
                                      <div *ngIf="ss.image.errors.required">यहाँ पर जमापर्ची अपलोड करे</div>
                                    </div>
                                    <button style="    margin-left: 358px;
                                    margin-top: -60px;background-color: #f58634;
                                    border-color: #f58634" class="btn btn-dark"(click)="UploadssSubmit()">अपलोड करे</button>
                                </div> -->
                                <div class="col-sm-6" style="font-weight: bold; font-size: 14px;">
                                    <br/>
                                    <input type="radio" formControlName="payment_mode" value="bank_transfer" (click)="toggle3()" /> यहाँ पर जमापर्ची अपलोड करे&nbsp;&nbsp;
                                    <br/>
                                </div>
                                <div class="col-sm-6" style="font-weight: bold; font-size: 14px;" *ngIf="showMe7">
                                    <br/>
                                    <input type="file" class="text-control" formControlName="payment_receipt" (change)="uploadImage($event)">
                                    <br/>
                                </div>
                                <div class="col-sm-6" style="font-weight: bold; font-size: 14px;">
                                    <br/>
                                    <input type="radio" formControlName="payment_mode" value="online" (click)="toggle4()" /> ऑनलाइन भुगतान करें
                                    <br/>
                                </div>
                            </div>
                        </form>
                        <div style="margin-left: 130px;">
                            <br>
                            <button class="btn btn-dark" (click)="toggle5()" style="background-color: #555555; border-color: #555555; width: 120px; height: 48px; left: 1060px; top: 1373px;"> कैंसिल </button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button class="btn btn-dark" *ngIf="showMe9" (click)="UploadssSubmit()" style="background-color: #f58634; border-color: #f58634; width: 120px;height: 48px;left: 1060px;top: 1373px;">सबमिट </button>
                            <button class="btn btn-dark" *ngIf="showMe8" (click)="UploadssSubmit2()" style="background-color: #f58634; border-color: #f58634; width: 120px;height: 48px;left: 1060px;top: 1373px;">सबमिट. </button>
                            <form [formGroup]="payuform" style="display: none" ngNoForm action="https://test.payu.in/_payment -H" name="payuform" method="POST" target="payment_popup">
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">name </label><br />
                                        <input type="text" class="text-control" formControlName="firstname" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">दान राशि </label><br />
                                        <input type="text" [(ngModel)]="donation_amount2" disabled class="text-control" formControlName="amount" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">key </label><br />
                                        <input type="text" class="text-control" formControlName="key" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">productinfo </label><br />
                                        <input type="text" class="text-control" formControlName="productinfo" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">email </label><br />
                                        <input type="text" class="text-control" formControlName="email" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">surl </label><br />
                                        <input type="text" class="text-control" formControlName="surl" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">furl </label><br />
                                        <input type="text" class="text-control" formControlName="furl" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">phone</label><br />
                                        <input type="text" class="text-control" formControlName="phone" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">hash </label><br />
                                        <input type="text" class="text-control" formControlName="hash" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <form [formGroup]="UploadSSForm">
                <div class="row">
                    <div class="col">
                        <h3 class="title">billing address</h3>
                        <div class="inputBox">
                            <span>full name :</span>
                            <input type="text" placeholder="john deo">
                        </div>
                        <div class="inputBox">
                            <span>email :</span>
                            <input type="email" placeholder="example@example.com">
                        </div>
                        <div class="inputBox">
                            <span>address :</span>
                            <input type="text" placeholder="room - street - locality">
                        </div>
                        <div class="inputBox">
                            <span>city :</span>
                            <input type="text" placeholder="mumbai">
                        </div>
                        <div class="flex">
                            <div class="inputBox">
                                <span>state :</span>
                                <input type="text" placeholder="india">
                            </div>
                            <div class="inputBox">
                                <span>zip code :</span>
                                <input type="text" placeholder="123 456">
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <h3 class="title">payment</h3>
                        <div class="inputBox">
                            <span>cards accepted :</span>
                            <img src="images/card_img.png" alt="">
                        </div>
                        <div class="inputBox">
                            <span>name on card :</span>
                            <input type="text" placeholder="mr. john deo">
                        </div>
                        <div class="inputBox">
                            <span>credit card number :</span>
                            <input type="number" placeholder="1111-2222-3333-4444">
                        </div>
                        <div class="inputBox">
                            <span>exp month :</span>
                            <input type="text" placeholder="january">
                        </div>
                        <div class="flex">
                            <div class="inputBox">
                                <span>exp year :</span>
                                <input type="number" placeholder="2022">
                            </div>
                            <div class="inputBox">
                                <span>CVV :</span>
                                <input type="text" placeholder="1234">
                            </div>
                        </div>
                    </div>
                </div>
                <input type="submit" value="proceed to checkout" class="submit-btn">
            </form> -->

            <div class="col-sm-8" *ngIf="showMeDonationHide">
                <div *ngIf="!LoggedIn()">
                    <form [formGroup]="NewUserFrom">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">पूरा नाम <span style="color: red">*</span></label>
                                <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="name" />
                                <div *ngIf="newuser_submitted && new.name.errors" class="invalid-login">
                                    <div *ngIf="new.name.errors.required">पूरा नाम आवश्यक है</div>
                                </div>
                                <div *ngIf="new.name.touched && new.name.invalid" style="color: red">
                                    <div *ngIf="new.name.errors.required">
                                        पूरा नाम आवश्यक है
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                            </div>
                            <div class="col-sm-6" *ngIf="showMe5">
                                <label class="label-control">मोबाइल नंबर<span style="color: red">*</span></label>
                                <br />
                                <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" [(ngModel)]="mob_no2" disabled class="text-control" formControlName="contact" />
                                <p style="color: green">{{ message }}</p>
                            </div>
                            <div class="col-sm-6">
                                <form [formGroup]="registrationForm">
                                    <div class="form-group row">
                                        <div class="col-sm-6" style="display: none">
                                            <label class="label-control">पूरा नाम <span style="color: red">*</span></label>
                                            <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="name" [ngClass]="{'is-invalid': registratiopn_submitted && r.name.errors}" />
                                            <div *ngIf="registratiopn_submitted && r.name.errors" class="invalid-login">
                                                <!-- <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div> -->
                                            </div>

                                            <span *ngIf="r.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span>
                                        </div>
                                        <div *ngIf="showMe4">
                                            <label class="label-control">मोबाइल नंबर <span
                                                    style="color: red">*</span></label>
                                            <input type="number" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control"
                                                formControlName="contact" [ngClass]="{'is-invalid': registratiopn_submitted && r.contact.errors}" />
                                            <!-- <span *ngIf="r.contact.invalid" class="errormssg">मोबाइल नंबर आवश्यक है</span> -->
                                            <div *ngIf="registratiopn_submitted && r.contact.errors"class="invalid-login">
                                                <div *ngIf="r.contact.errors.required">
                                                    मोबाइल नंबर आवश्यक है
                                                </div>
                                                <div *ngIf="r.contact.errors.maxlength">
                                                    मोबाइल नंबर 10 अंकों का होना चाहिए
                                                </div>
                                            </div>
                                            <div *ngIf="r.contact.touched &&
                                                    r.contact.invalid" style="color: red">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                            <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                                नंबर 10 अंकों का होना चाहिए
                                                            </div>
                                                    </div>
                                            <button class="btn btn-dark" (click)="registrationSubmit()" style="background-color: #f58634; border-color: #f58634; height: 48px;left: 1060px;top: 1373px; margin-top: 25px" [hidden]="r.contact.invalid" *ngIf="showMe2"> 
                                                अपना नंबर सत्यापित करें
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <form [formGroup]="OTPVForm" (ngSubmit)="OTPVerifySubmit()" style="padding: 30px 14px; padding-top: 0px;"
                            *ngIf="showMe3">
                            <div class="form-group row" style="display: none">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red">*</span></label>
                                    <input [(ngModel)]="full_name2" type="text" class="form-control" formControlName="name" [ngClass]="{'is-invalid': otpverify_submitted && vrfy.name.errors}" />
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control"> मोबाइल नंबर <span style="color: red">*</span></label>
                                    <br />
                                    <input type="number"  [(ngModel)]="mob_no" class="text-control" formControlName="contact" [ngClass]="{'is-invalid': otpverify_submitted && vrfy.contact.errors}" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-4">
                                    <label class="label-control">6 अंक ओटीपी <span style="color: red">*</span></label>
                                    <input type="number" style="width: 234px; height: 46px; left: 530px; top: 677px;" class="form-control" placeholder="Enter OTP"
                                        [pattern]="OTPPattern" formControlName="otp" [ngClass]="{'is-invalid': otpverify_submitted && vrfy.otp.errors}" />
                                    <div *ngIf="otpverify_submitted && vrfy.contact.errors" class="invalid-login">
                                        <div *ngIf="vrfy.otp.errors.required">ओटीपी आवश्यक है</div>
                                        <div *ngIf="vrfy.otp.errors.pattern">
                                            ओटीपी 6 अंकों का होना चाहिए
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <button class="btn btn-dark" type="submit" style="background-color: #f58634; border-color: #f58634; height: 48px;left: 1060px;top: 1373px; margin-top: 31px">
                                        ओटीपी सत्यापित करें
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">ईमेल <span style="color: red">*</span></label>
                                <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="email" [ngClass]="{'is-invalid': newuser_submitted && new.email.errors}" />
                                <div *ngIf="newuser_submitted && new.email.errors" class="invalid-login">
                                    <div *ngIf="new.email.errors.required">ईमेल आवश्यक है</div>
                                    <div *ngIf="new.email.errors.email">
                                        ईमेल एक वैध ईमेल पता होना चाहिए
                                    </div>
                                </div>
                                <div *ngIf="new.email.touched && new.email.invalid" style="color: red">
                                    <div *ngIf="new.email.errors.required">
                                        ईमेल आवश्यक है
                                    </div>
                                    <div *ngIf="new.email.errors.email">
                                        ईमेल एक वैध ईमेल पता होना चाहिए
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                <input type="number" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" [pattern]="mobNumberPattern" formControlName="alternate_mobile_number" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">लिंग <span style="color: red">*</span></label>
                                <br />
                                <input type="radio" formControlName="gender" value="male"/> पुरुष&nbsp;&nbsp;
                                <input type="radio" formControlName="gender" value="female"/> स्त्री
                                <br />
                                <div *ngIf="new.gender.touched && new.gender.invalid" style="color: red">
                                    <div *ngIf="new.gender.errors.required">
                                        लिंग आवश्यक है
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.gender.invalid" class="errormssg">लिंग आवश्यक है</span> -->
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">क्या आप भारत के नागरिक हैं ?
                                    <span style="color: red">*</span></label>
                                <br />
                                <input type="radio" formControlName="is_indian_citizen" value="yes" /> हां&nbsp;&nbsp;
                                <input type="radio" formControlName="is_indian_citizen" value="no" /> नहीं
                                <br />
                                <div *ngIf="new.is_indian_citizen.touched && new.is_indian_citizen.invalid" style="color: red">
                                    <div *ngIf="new.is_indian_citizen.errors.required">
                                        क्या आप भारत के नागरिक हैं ? आवश्यक है
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.is_indian_citizen.invalid" class="errormssg">क्या आप भारत के नागरिक हैं ? आवश्यक है</span> -->
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">व्हाट्सएप नं</label>
                                <input type="number"style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="whatsapp_number" />
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">आवास का पता <span style="color: red">*</span></label>
                                <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="address" [ngClass]="{'is-invalid': newuser_submitted && new.address.errors}" />
                                <div *ngIf="newuser_submitted && new.address.errors" class="invalid-login">
                                    <div *ngIf="new.address.errors.required">
                                        आवास का पता आवश्यक है
                                    </div>
                                </div>
                                <div *ngIf="new.address.touched && new.address.invalid" style="color: red">
                                    <div *ngIf="new.address.errors.required">
                                        आवास का पता आवश्यक है
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">वर्तमान निवास का देश
                                    <span class="required-span" style="color: red">*</span></label>
                                <select class="form-control" style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="country" (change)="fetchState($event)"
                                    [ngClass]="{'is-invalid': newuser_submitted && new.country.errors}">
                                    <option value="" selected>देश चुनें</option>
                                    <option *ngFor="let item of countryArray" value="{{ item.id }}">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <!-- <span *ngIf="new.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक
                                    है</span> -->
                                <div *ngIf="newuser_submitted && new.country.errors" class="invalid-login">
                                    <div *ngIf="new.country.errors.required">
                                        वर्तमान निवास का देश आवश्यक है
                                    </div>
                                </div>
                                <div *ngIf="new.country.touched && new.country.invalid" style="color: red">
                                    <div *ngIf="new.country.errors.required">
                                        वर्तमान निवास का देश आवश्यक है
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">राज्य <span style="color: red">*</span></label>
                                <select class="form-control" style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="state" (change)="fetchCity($event)"
                                    [ngClass]="{'is-invalid': newuser_submitted && new.state.errors}">
                                    <option value="" [selected]="true">राज्य चुनें</option>
                                    <option *ngFor="let item of statesArray" value="{{ item.id }}">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="new.state.touched && new.state.invalid" style="color: red">
                                    <div *ngIf="new.state.errors.required">
                                        राज्य आवश्यक है
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.state.invalid" class="errormssg">राज्य आवश्यक है</span> -->
                                <div *ngIf="newuser_submitted && new.state.errors" class="invalid-login">
                                    <div *ngIf="new.state.errors.required">राज्य आवश्यक है</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">जिला <span style="color: red">*</span></label>
                                <select class="form-control" style="width: 335px; height: 46px; left: 530px; top: 677px;" formControlName="city" [ngClass]="{'is-invalid': newuser_submitted && new.city.errors}">
                                    <option value="" [selected]="true">जिला चुनें</option>
                                    <option *ngFor="let item of cityArray" value="{{ item.id }}">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="new.city.touched && new.city.invalid" style="color: red">
                                    <div *ngIf="new.city.errors.required">
                                        जिला आवश्यक है
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.city.invalid" class="errormssg">जिला आवश्यक है</span> -->
                                <div *ngIf="newuser_submitted && new.city.errors" class="invalid-login">
                                    <div *ngIf="new.city.errors.required">जिला आवश्यक है</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">तहसील का नाम दर्ज करें
                                    <span style="color: red">*</span></label>
                                <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="tehsil" [ngClass]="{'is-invalid': newuser_submitted && new.tehsil.errors}" />
                                <!-- <span *ngIf="new.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक है</span> -->
                                <div *ngIf="newuser_submitted && new.teshil.errors" class="invalid-login">
                                    <div *ngIf="new.tehsil.errors.required">
                                        तहसील का नाम दर्ज करें आवश्यक है
                                    </div>
                                </div>
                                <div *ngIf="new.tehsil.touched && new.tehsil.invalid" style="color: red">
                                    <div *ngIf="new.tehsil.errors.required">
                                        तहसील का नाम दर्ज करें आवश्यक है
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">पेशा <span style="color: red">*</span></label>
                                <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="profession" [ngClass]="{ 'is-invalid': newuser_submitted && new.profession.errors}" />
                                <!-- <span *ngIf="new.profession.invalid" class="errormssg">पेशा आवश्यक है</span> -->
                                <div *ngIf="newuser_submitted && new.profession.errors" class="invalid-login">
                                    <div *ngIf="new.profession.errors.required">
                                        पेशा आवश्यक है
                                    </div>
                                </div>
                                <div *ngIf="new.profession.touched && new.profession.invalid" style="color: red">
                                    <div *ngIf="new.profession.errors.required">
                                        पेशा आवश्यक है
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">पैन कार्ड नं</label>
                                <input type="text" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="pancard" [ngClass]="{ 'is-invalid': newuser_submitted && new.profession.errors}" />
                                <!-- <span *ngIf="new.profession.invalid" class="errormssg">पेशा आवश्यक है</span>
                                <div *ngIf="newuser_submitted && new.profession.errors" class="invalid-login">
                                    <div *ngIf="new.profession.errors.required">
                                        पेशा आवश्यक है
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">कृपया दान राशि चुनें <span style="color: red">*</span></label><br />
                                <input type="radio" formControlName="amount" value="100" (click)="toggle2()" />
                                ₹100&nbsp;&nbsp;
                                <input type="radio" formControlName="amount" value="500" (click)="toggle2()" />
                                ₹500&nbsp;&nbsp;
                                <input type="radio" formControlName="amount" value="1000" (click)="toggle2()" />
                                ₹1000&nbsp;&nbsp;
                                <input type="radio" formControlName="amount" value="2000" (click)="toggle2()" />
                                ₹2000&nbsp;&nbsp;
                                <input type="radio" formControlName="amount" value="5000" (click)="toggle2()" />
                                ₹5000 <br />
                                <input type="radio" formControlName="amount" value="" (click)="toggle()" /> अन्य 
                                <input formControlName="amount" *ngIf="showMe6" type="text" />
                                <br />
                                <div *ngIf="new.amount.touched && new.amount.invalid" style="color: red">
                                    <div *ngIf="new.amount.errors.required">
                                        कृपया दान राशि चुनें
                                    </div>
                                </div>
                                <!-- <span *ngIf="new.amount.invalid" class="errormssg">कृपया दान राशि चुनें</span> -->
                            </div>
                        </div>
                        <div class="form-group row justify-content-center">
                            <div class="col-sm-6">
                                <label class="label-control">पासवर्ड</label>
                                <input type="password" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" [(ngModel)]="password" formControlName="password" />
                                <!-- <span *ngIf="new.password.invalid" class="errormssg">पासवर्ड कम से कम 8 वर्णों का होना चाहिए</span> -->
                                <div *ngIf="new.password.touched && new.password.invalid" style="color: red">
                                    <div *ngIf="new.password.errors.required">
                                        पासवर्ड कम से कम 8 वर्णों का होना चाहिए
                                    </div>
                                    <div *ngIf="new.password.errors.minLength">
                                        पासवर्ड कम से कम 8 वर्णों का होना चाहिए
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">पासवर्ड की पुष्टि कीजिये</label>
                                <input type="password" style="width: 335px; height: 46px; left: 530px; top: 677px;" class="form-control" formControlName="password_confirmation"
                                    pattern="{{ password }}" [(ngModel)]="password_confirmation" />
                                <div *ngIf="new.password_confirmation.touched && new.password_confirmation.invalid" style="color: red">
                                    <div *ngIf="new.password_confirmation.invalid">
                                        पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                    </div>
                                    <!-- <div *ngIf="new.password_confirmation.errors.pattern">
                                        पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                    </div> -->

                                </div>
                                <!-- <span *ngIf="new.password_confirmation.invalid" class="errormssg">पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</span> -->
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12 text-center">
                                <button class="btn btn-dark" (click)="clearForm()" style="background-color: #555555; border-color: #555555; width: 120px;height: 48px;left: 1060px;top: 1373px;">
                                    रीसेट करें
                                </button>
                                &nbsp;&nbsp;
                                <button class="btn btn-dark" [disabled]="NewUserFrom.invalid" (click)="NewuserSubmit()"  style="background-color: #f58634; border-color: #f58634; width: 120px;height: 48px;left: 1060px;top: 1373px;" >
                                    अभी जमा करे
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- <div *ngIf="LoggedIn()">
                    <form [formGroup]="donateForm" (ngSubmit)="donateSubmit()"
                        style="background-color: #f6f6f6; padding: 30px">
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">पूरा नाम</label>
                                <input type="text" [(ngModel)]="full_name" readonly class="form-control"
                                    formControlName="name" [ngClass]="{
                    'is-invalid': donate_submitted && d.name.errors
                  }" />
                                <div *ngIf="donate_submitted && d.name.errors" class="invalid-login">
                                    <div *ngIf="d.name.errors.required">पूरा नाम आवश्यक है</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">ईमेल</label>
                                <input type="text" class="form-control" formControlName="email" [ngClass]="{
                    'is-invalid': donate_submitted && d.email.errors
                  }" />
                                <div *ngIf="donate_submitted && d.email.errors" class="invalid-login">
                                    <div *ngIf="d.email.errors.required">ईमेल आवश्यक है</div>
                                    <div *ngIf="d.email.errors.email">
                                        ईमेल एक वैध ईमेल पता होना चाहिए
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">मोबाइल नंबर</label>
                                <input type="text" [(ngModel)]="mob_no2" readonly class="form-control"
                                    [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{
                    'is-invalid': donate_submitted && d.contact.errors
                  }" />
                                <div *ngIf="donate_submitted && d.contact.errors" class="invalid-login">
                                    <div *ngIf="d.contact.errors.required">
                                        मोबाइल नंबर आवश्यक है
                                    </div>
                                    <div *ngIf="d.contact.errors.pattern">
                                        मोबाइल नंबर 10 अंकों का होना चाहिए
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                    formControlName="alternate_mobile_number" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6" formControlName="gender">
                                <label class="label-control">लिंग </label> <br />
                                <input type="radio" formControlName="gender" value="male" />
                                पुरुष&nbsp;&nbsp;
                                <input type="radio" formControlName="gender" value="female" />
                                स्त्री
                            </div>
                            <div class="col-sm-6" formControlName="is_indian_citizen">
                                <label class="label-control">क्या आप भारत के नागरिक हैं ?
                                </label>
                                <br />
                                <input type="radio" formControlName="is_indian_citizen" value="yes" />
                                हां&nbsp;&nbsp;
                                <input type="radio" formControlName="is_indian_citizen" value="no" />
                                नहीं
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">व्हाट्सएप नं </label>
                                <input type="text" class="form-control" formControlName="whatsapp_number" [ngClass]="{
                    'is-invalid': donate_submitted && d.whatsapp_number.errors
                  }" />
                                <div *ngIf="donate_submitted && d.whatsapp_number.errors" class="invalid-login">
                                    <div *ngIf="d.whatsapp_number.errors.required">
                                        व्हाट्सएप नं आवश्यक है
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">आवास का पता </label>
                                <input type="text" class="form-control" formControlName="address" [ngClass]="{
                    'is-invalid': donate_submitted && d.address.errors
                  }" />
                                <div *ngIf="donate_submitted && d.address.errors" class="invalid-login">
                                    <div *ngIf="d.address.errors.required">
                                        आवास का पता आवश्यक है
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">राज्य</label>
                                <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                    [ngClass]="{
                    'is-invalid': donate_submitted && d.state.errors
                  }">
                                    <option value="" [selected]="true">राज्य चुनें</option>
                                    <option *ngFor="let item of statesArray" value="{{ item.id }}">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="donate_submitted && d.state.errors" class="invalid-login">
                                    <div *ngIf="d.state.errors.required">राज्य आवश्यक है</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">जिला</label>
                                <select class="form-control" formControlName="city" [ngClass]="{
                    'is-invalid': donate_submitted && d.city.errors
                  }">
                                    <option value="" [selected]="true">जिला चुनें</option>
                                    <option *ngFor="let item of cityArray" value="{{ item.id }}">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div *ngIf="donate_submitted && d.city.errors" class="invalid-login">
                                    <div *ngIf="d.city.errors.required">जिला आवश्यक है</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row justify-content-center">
                            <div class="col-sm-6">
                                <label>पासवर्ड</label>
                                <input type="password" class="form-control" [(ngModel)]="pass"
                                    formControlName="password" />

                                <div *ngIf="mo.password.touched && mo.password.invalid" class="alert alert-danger">
                                    <div *ngIf="mo.password.errors.required">
                                        Password is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>पासवर्ड की पुष्टि कीजिये</label>
                                <input type="password" class="form-control" formControlName="password_confirmation" />
                                <div *ngIf="
                    mo.password_confirmation.touched &&
                    mo.password_confirmation.invalid
                  " class="alert alert-danger">
                                    <div *ngIf="mo.password_confirmation.errors.required">
                                        Confirm पासवर्ड की आवश्यकता है.
                                    </div>
                                    <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                        पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="form-group row">
                            <div class="col-sm-12 text-center">
                                <button class="btn btn-dark" type="reset">रीसेट करें</button>
                                &nbsp;&nbsp;
                                <button class="btn btn-dark" type="submit">अभी जमा करे</button>
                            </div>
                        </div>
                    </form>
                </div> -->
            </div>
            <!-- <div class="col-sm-8" *ngIf="LoggedIn()">
                <div class="sec-title">
                    <h2 style="
              color: #116821;
              text-decoration: none;
              font-family: 'Lohit Devanagari', serif;
              font-size: 17px;
              font-weight: 600;
              letter-spacing: 0.3px;
              padding-top: 10px;
            ">
                        दान करें
                    </h2>
                    <p>
                        <span style="
                font-weight: 400;
                font-size: 14px;
                color: #d93b03;
                text-decoration: none;
              ">‘‘राष्ट्रधर्म सेवा संगठन’’</span>
                        को सहयोग करने हेतु इच्छुक दानदाता स्टेट बैंक आफ इंडिया का चालू खाता
                        में अपनी योगदान व सहयोग राशि जमा कर देशहित, जनहित,युवाहित,व समाजहित
                        में भागीदारी, उतरत्दायित्व व कर्त्तव्य निभा सकते हैं-
                    </p>
                </div>
                <table>
                    <tr>
                        <td>खाता का नाम</td>
                        <td>राष्ट्रधर्म सेवा संगठन</td>
                    </tr>
                    <tr>
                        <td>खाता संख्या</td>
                        <td>33852355461</td>
                    </tr>
                    <tr>
                        <td>बैंक</td>
                        <td>स्टेट बैंक आफ इंडिया</td>
                    </tr>
                    <tr>
                        <td>शाखा</td>
                        <td>मुख्य शाखा,गाजीपुर</td>
                    </tr>
                    <tr>
                        <td>जिला</td>
                        <td>गाजीपुर</td>
                    </tr>
                    <tr>
                        <td>राज्य</td>
                        <td>उत्तर प्रदेश</td>
                    </tr>
                    <tr>
                        <td>ब्रान्च कोड</td>
                        <td>000080</td>
                    </tr>
                    <tr>
                        <td>आर्ई एफ एस सी कोड</td>
                        <td>SBIN0000080 (5 वर्ण शून्य है)</td>
                    </tr>
                    <tr>
                        <td>खाता का प्रकार</td>
                        <td>चालू खाता</td>
                    </tr>
                </table>
                <br />
                <p>
                    <span style="font-size: 14px; color: #363636; font-weight: 600">निर्देश :</span>
                    बैंक अकाउन्ट में योगदान व सहयोग राशि जमा करने के बाद ऑनलाइन डोनेशन
                    फॉर्म को भरना अति आवश्यक है।
                </p>

                <br />
                <div class="donation-form">
                    <div class="card">
                        <div class="card-body" *ngIf="!LoggedIn()">
                            <h2 style="
                  color: #116821;
                  text-decoration: none;
                  font-family: 'Lohit Devanagari', serif;
                  font-size: 17px;
                  font-weight: 600;
                  letter-spacing: 0.3px;
                  padding-top: 10px;
                ">
                                सहयोग करें
                            </h2>
                            <br />
                            <form [formGroup]="donateForm" (ngSubmit)="donateSubmit()">
                                <div class="form-goroup row">
                                    <div class="col-sm-6">
                                        <label class="label-control">दिनांक : <span style="color: red">*</span></label>
                                        <input type="date" class="form-control" formControlName="date" [ngClass]="{
                        'is-invalid': donate_submitted && d.date.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.date.errors" class="invalid-login">
                                            <div *ngIf="d.date.errors.required">दिनांक आवश्यक है</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="name" [ngClass]="{
                        'is-invalid': donate_submitted && d.name.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.name.errors" class="invalid-login">
                                            <label class="label-control">पूरा नाम <span
                                                    style="color: red">*</span></label>
                                            <div *ngIf="d.name.errors.required">
                                                पूरा नाम आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">पिता का नाम <span
                                                style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="father_name" [ngClass]="{
                        'is-invalid': donate_submitted && d.father_name.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.father_name.errors" class="invalid-login">
                                            <div *ngIf="d.father_name.errors.required">
                                                पिता का नाम आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">ईमेल <span style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="email" [ngClass]="{
                        'is-invalid': donate_submitted && d.email.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.email.errors" class="invalid-login">
                                            <div *ngIf="d.email.errors.required">ईमेल आवश्यक है</div>
                                            <div *ngIf="d.email.errors.email">
                                                ईमेल एक वैध ईमेल पता होना चाहिए
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">मोबाइल 1 <span style="color: red">*</span></label>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="mobile_number_1" [ngClass]="{
                        'is-invalid':
                          donate_submitted && d.mobile_number_1.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.mobile_number_1.errors" class="invalid-login">
                                            <div *ngIf="d.mobile_number_1.errors.required">
                                                मोबाइल 1 आवश्यक है
                                            </div>
                                            <div *ngIf="d.mobile_number_1.errors.pattern">
                                                Mobile Number should be 10 digit
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">मोबाइल 2</label>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="contact" />
                                    </div>
                                    <div class="col-sm-6" formControlName="gender">
                                        <label class="label-control">लिंग <span style="color: red">*</span></label>
                                        <br />
                                        <input type="radio" formControlName="gender" value="male" />
                                        पुरुष&nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" value="female" />
                                        स्त्री
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">व्यवसाय <span style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="business" [ngClass]="{
                        'is-invalid': donate_submitted && d.business.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.business.errors" class="invalid-login">
                                            <div *ngIf="d.business.errors.required">
                                                व्यवसाय आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">व्हाट्सएप नं <span
                                                style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="whatsapp_number"
                                            [ngClass]="{
                        'is-invalid':
                          donate_submitted && d.whatsapp_number.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.whatsapp_number.errors" class="invalid-login">
                                            <div *ngIf="d.whatsapp_number.errors.required">
                                                व्हाट्सएप नं आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">फेसबुक युआरएल <span
                                                style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="facebook_url"
                                            [ngClass]="{
                        'is-invalid': donate_submitted && d.facebook_url.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.facebook_url.errors" class="invalid-login">
                                            <div *ngIf="d.facebook_url.errors.required">
                                                फेसबुक युआरएल आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" formControlName="is_indian_citizen">
                                        <label class="label-control">क्या आप भारत के नागरिक हैं ?
                                            <span style="color: red">*</span></label>
                                        <br />
                                        <input type="radio" formControlName="is_indian_citizen" value="yes" />
                                        हां&nbsp;&nbsp;
                                        <input type="radio" formControlName="is_indian_citizen" value="no" />
                                        नहीं
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">आवास का पता <span
                                                style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="address" [ngClass]="{
                        'is-invalid': donate_submitted && d.address.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.address.errors" class="invalid-login">
                                            <div *ngIf="d.address.errors.required">
                                                आवास का पता आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">वर्तमान निवास का देश
                                            <span style="color: red">*</span></label>
                                        <select class="form-control" formControlName="country"
                                            (change)="fetchState($event)" [ngClass]="{
                        'is-invalid': donate_submitted && d.country.errors
                      }">
                                            <option value="" selected>देश चुनें</option>
                                            <option *ngFor="let item of countryArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="donate_submitted && d.country.errors" class="invalid-login">
                                            <div *ngIf="d.country.errors.required">
                                                वर्तमान निवास का देश आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">राज्य <span style="color: red">*</span></label>
                                        <select class="form-control" formControlName="state"
                                            (change)="fetchCity($event)" [ngClass]="{
                        'is-invalid': donate_submitted && d.state.errors
                      }">
                                            <option value="" selected>राज्य चुनें</option>
                                            <option *ngFor="let item of statesArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="donate_submitted && d.state.errors" class="invalid-login">
                                            <div *ngIf="d.state.errors.required">राज्य आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">जिला <span style="color: red">*</span></label>
                                        <select class="form-control" formControlName="city" [ngClass]="{
                        'is-invalid': donate_submitted && d.city.errors
                      }">
                                            <option value="" [selected]="true">जिला चुनें</option>
                                            <option *ngFor="let item of cityArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="donate_submitted && d.city.errors" class="invalid-login">
                                            <div *ngIf="d.city.errors.required">जिला आवश्यक है</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">सहयोग राशि <span
                                                style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="subject" [ngClass]="{
                        'is-invalid': donate_submitted && d.subject.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.subject.errors" class="invalid-login">
                                            <div *ngIf="d.subject.errors.required">
                                                सहयोग राशि आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6" formControlName="gender">
                                        <label class="label-control">भुगतान विधि <span
                                                style="color: red">*</span></label>
                                        <br />
                                        <input type="radio" formControlName="gender" value="a" />
                                        ऑनलाइन &nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" value="c" />
                                        चेक/डीडी नं0 &nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" value="n" />
                                        नकदी
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">यहाँ पर चेक/डीडी नं0 दर्ज करे
                                            <span style="color: red">*</span></label>
                                        <input type="text" class="form-control" formControlName="subject" [ngClass]="{
                        'is-invalid': donate_submitted && d.subject.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.subject.errors" class="invalid-login">
                                            <div *ngIf="d.subject.errors.required">
                                                यहाँ पर चेक/डीडी नं0 दर्ज करे आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">यहाँ पर जमापर्ची अपलोड करे
                                            <span style="color: red">*</span></label>
                                        <input type="file" class="form-control" formControlName="feedback" [ngClass]="{
                        'is-invalid': donate_submitted && d.feedback.errors
                      }" (change)="uploadIdentityCard($event)" />
                                        <div *ngIf="donate_submitted && d.feedback.errors" class="invalid-login">
                                            <div *ngIf="d.feedback.errors.required">
                                                समस्या आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-goroup row">
                                    <div class="col-sm-6">
                                        <label class="label-control">दिनांक : <span style="color: red">*</span></label>
                                        <input type="date" class="form-control" formControlName="date" [ngClass]="{
                        'is-invalid': donate_submitted && d.date.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.date.errors" class="invalid-login">
                                            <div *ngIf="d.date.errors.required">दिनांक आवश्यक है</div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="form-group">
                                    <div class="col-sm-12" style="font-weight: 500">
                                        <input type="checkbox" id="html" name="fav_language" value="" />&nbsp; मैं घोषणा
                                        करता हूँ कि मैं राष्ट्रधर्म सेवा संगठन के
                                        मिशन, आन्दोलन, क्रान्ति, अभियान व इससे जुड़ी सभी जानकारी तथा
                                        सूचनाओं से पूर्णरुप से सहमत व वचनबद्ध हूँ। मेरे द्वारा यहाँ
                                        प्रस्तुत की गयी सूचनाएँ पूर्णरुप से सत्य है।
                                        <span style="color: red">*</span>
                                    </div>
                                </div>
                                <br />
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-submit" type="reset" value="Reset">
                                            अभी रीसेट करें
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button class="btn btn-submit" type="submit">
                                            अभी जमा करे
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <div class="d-flex">
                                            <label class="label-control nowrap-center">Donation Amount (<i
                                                    class="fas fa-rupee-sign"></i>)</label>
                                            &nbsp;&nbsp;&nbsp;
                                            <input type="number" id="donation-amount" class="text-control"
                                                formControlName="amount" [ngClass]="{
                          'is-invalid': donate_submitted && d.amount.errors
                        }" />
                                        </div>
                                        <div *ngIf="donate_submitted && d.amount.errors" class="invalid-login">
                                            <div *ngIf="d.amount.errors.required">
                                                Amount is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" style="display: none">
                                    <div class="col-sm-12">
                                        <ul class="btn-main-donation">
                                            <li>
                                                <button type="button" (click)="changeAmount($event)"
                                                    class="btn-amount btn-default" value="5000">
                                                    <i class="fas fa-rupee-sign"></i>5000
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="changeAmount($event)"
                                                    class="btn-amount btn-default" value="10000">
                                                    <i class="fas fa-rupee-sign"></i>10000
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="changeAmount($event)"
                                                    class="btn-amount btn-default" value="25000">
                                                    <i class="fas fa-rupee-sign"></i>25000
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="changeAmount($event)"
                                                    class="btn-amount btn-default" value="50000">
                                                    <i class="fas fa-rupee-sign"></i>50000
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" (click)="changeAmount($event)"
                                                    class="btn-amount btn-default" value="75000">
                                                    <i class="fas fa-rupee-sign"></i>75000
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control text-center">Please donate every month
                                            <span>You can cancel any time you want </span></label>
                                        <ul class="btn-main-donation">
                                            <li>
                                                <label><input type="radio" formControlName="donation_type"
                                                        value="monthly" />
                                                    Monthly</label>
                                            </li>
                                            <li>
                                                <label><input type="radio" formControlName="donation_type"
                                                        value="onetime" />
                                                    Onetime</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <h3>Personal Information</h3>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">Name</label>
                                        <input type="text" class="text-control" placeholder="Enter Name"
                                            formControlName="name" [ngClass]="{
                        'is-invalid': donate_submitted && d.name.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.name.errors" class="invalid-login">
                                            <div *ngIf="d.name.errors.required">Name is required</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">Email</label>
                                        <input type="text" class="text-control" placeholder="Enter Email"
                                            formControlName="email" [ngClass]="{
                        'is-invalid': donate_submitted && d.email.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.email.errors" class="invalid-login">
                                            <div *ngIf="d.email.errors.required">
                                                ईमेल की आवश्यकता है
                                            </div>
                                            <div *ngIf="d.email.errors.email">
                                                ईमेल एक वैध ईमेल पता होना चाहिए
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">Mobile No.</label>
                                        <input type="text" class="text-control" [pattern]="mobNumberPattern"
                                            placeholder="Enter Mobile No" formControlName="mobile" [ngClass]="{
                        'is-invalid': donate_submitted && d.mobile.errors
                      }" />
                                        <div *ngIf="donate_submitted && d.mobile.errors" class="invalid-login">
                                            <div *ngIf="d.mobile.errors.required">
                                                मोबाइल नंबर चाहिए
                                            </div>
                                            <div *ngIf="d.mobile.errors.pattern">
                                                मोबाइल नंबर 10 अंकों का होना चाहिए
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">State</label>
                                        <select class="text-control" formControlName="state"
                                            (change)="fetchCity($event)" [ngClass]="{
                        'is-invalid': donate_submitted && d.state.errors
                      }">
                                            <option value="" selected="true">Select State</option>
                                            <option *ngFor="let item of statesArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="donate_submitted && d.state.errors" class="invalid-login">
                                            <div *ngIf="d.state.errors.required">
                                                State is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">City</label>
                                        <select class="text-control" formControlName="city" [ngClass]="{
                        'is-invalid': donate_submitted && d.city.errors
                      }">
                                            <option value="" selected="true">Select City</option>
                                            <option *ngFor="let item of cityArray" value="{{ item.id }}">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                        <div *ngIf="donate_submitted && d.city.errors" class="invalid-login">
                                            <div *ngIf="d.city.errors.required">City is required</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">Send me updates of work done by RDSS</label>
                                        <select class="text-control" formControlName="send_update" [ngClass]="{
                        'is-invalid': donate_submitted && d.send_update.errors
                      }">
                                            <option value="" selected="true">Select Yes/No</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        <div *ngIf="donate_submitted && d.send_update.errors" class="invalid-login">
                                            <div *ngIf="d.send_update.errors.required">
                                                Select Yes/No is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" type="submit">
                                            Proceed to Next Step
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-body" *ngIf="LoggedIn()">
                            <form [formGroup]="UserdonateForm" (ngSubmit)="UserdonateSubmit()">
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <div class="d-flex">
                                            <label class="label-control nowrap-center">Donation Amount (<i
                                                    class="fas fa-rupee-sign"></i>)</label>
                                            &nbsp;&nbsp;&nbsp;
                                            <input type="number" id="donation-amount" class="text-control"
                                                formControlName="amount" [ngClass]="{
                          'is-invalid': userdonate_submitted && ud.amount.errors
                        }" />
                                        </div>
                                        <div *ngIf="userdonate_submitted && ud.amount.errors" class="invalid-login">
                                            <div *ngIf="ud.amount.errors.required">
                                                Amount is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control text-center">Please donate every month
                                            <span>You can cancel any time you want </span></label>
                                        <ul class="btn-main-donation">
                                            <li>
                                                <label><input type="radio" formControlName="donation_type"
                                                        value="monthly" />
                                                    Monthly</label>
                                            </li>
                                            <li>
                                                <label><input type="radio" formControlName="donation_type"
                                                        value="onetime" />
                                                    Onetime</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">Automatic Payment</label>
                                        <select class="text-control" formControlName="automatic_payment" [ngClass]="{
                        'is-invalid':
                          userdonate_submitted && ud.automatic_payment.errors
                      }">
                                            <option selected value="">Select</option>
                                            <option value="once">I will donate once</option>
                                            <option value="monthly_fromcc">
                                                Monthly from Credit Card
                                            </option>
                                        </select>
                                        <div *ngIf="
                        userdonate_submitted && ud.automatic_payment.errors
                      " class="invalid-login">
                                            <div *ngIf="ud.automatic_payment.errors.required">
                                                Automatic Payment is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">Number of Months</label>
                                        <select class="text-control" formControlName="no_of_month" [ngClass]="{
                        'is-invalid':
                          userdonate_submitted && ud.no_of_month.errors
                      }">
                                            <option selected value="">Select Months</option>
                                            <option value="until cancelled">Until Cancelled</option>
                                            <option value="3">3</option>
                                            <option value="6">6</option>
                                            <option value="12">12</option>
                                        </select>
                                        <div *ngIf="userdonate_submitted && ud.no_of_month.errors"
                                            class="invalid-login">
                                            <div *ngIf="ud.no_of_month.errors.required">
                                                No. of months is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">Your Address</label>
                                        <textarea cols="4" rows="2" formControlName="address" class="text-control"
                                            placeholder="Enter your address
                                            here" [ngClass]="{
                        'is-invalid': userdonate_submitted && ud.address.errors
                      }"></textarea>
                                        <div *ngIf="userdonate_submitted && ud.address.errors" class="invalid-login">
                                            <div *ngIf="ud.address.errors.required">
                                                Address is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">Donate to</label>
                                        <select class="text-control" formControlName="donate_to" [ngClass]="{
                        'is-invalid':
                          userdonate_submitted && ud.donate_to.errors
                      }">
                                            <option value="" selected>Select</option>
                                            <option value="national">National</option>
                                        </select>
                                        <div *ngIf="userdonate_submitted && ud.donate_to.errors" class="invalid-login">
                                            <div *ngIf="ud.donate_to.errors.required">
                                                Donate to is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">PAN Number</label>
                                        <input type="text" class="text-control" [pattern]="PanPattern"
                                            formControlName="pan_number" placeholder="Enter 10 digit PAN
                                            number" [ngClass]="{
                        'is-invalid':
                          userdonate_submitted && ud.pan_number.errors
                      }" />
                                        <div *ngIf="userdonate_submitted && ud.pan_number.errors" class="invalid-login">
                                            <div *ngIf="ud.pan_number.errors.required">
                                                PAN Number to is required
                                            </div>
                                            <div *ngIf="ud.pan_number.errors.pattern">
                                                PAN Number must be 10 digit
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <label class="label-control text-center"><input type="checkbox"
                                                formControlName="acceptTerms" [ngClass]="{
                          'is-invalid':
                            userdonate_submitted && ud.acceptTerms.errors
                        }" />
                                            I declare that I am an Indian citizen, above 18 years of
                                            age.</label>
                                        <div *ngIf="userdonate_submitted && ud.acceptTerms.errors"
                                            class="invalid-login">
                                            <div *ngIf="ud.acceptTerms.errors.required">
                                                Acceptance is required
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" type="submit">
                                            Submit Now
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-sm-4" *ngIf="cardShow">
                <div class="movement-sidebar">
                    <div class="boxes-section">
                        <div class="row">
                            <div class="col-sm-12" *ngFor=" let item of BoxesContentArray | slice: 1:4; let i = index">
                                <div class="tab tab-{{ i + 1 }}">
                                    <i class="main-i {{ item.icon_text }}"></i>
                                    <h5>{{ item.title }}</h5>
                                    <p>{{ item.description }}</p>
                                    <a [routerLink]="[item.link]">{{ item.button_name }} <i class="fas fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/><br/>

        <!-- <div class="swiffy-slider slider-item-show3 slider-item-reveal slider-nav-dark slider-nav-autoplay slider-nav-outside-expand">
            <ul class="slider-container py-4" id="slider2">
                <li>
                    <div class="card shadow">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="card shadow h-100">
                        <div class="ratio ratio-1x1">
                            <img src="assets/images/user.png"  class="card-img-top img-thumbnail" loading="lazy" alt="...">
                        </div>
                        <div class="card-body d-flex flex-column flex-md-row">
                            <div class="flex-grow-1">
                                <strong>Name</strong>
                                <p class="card-text">Address</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        
            <button type="button" class="slider-nav" aria-label="Go to previous"></button>
            <button type="button" class="slider-nav slider-nav-next" aria-label="Go to next"></button>
        </div> -->
    </div>
</section>
<div class="modal fade otp-modal" id="movement-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="MOTPForm" (ngSubmit)="MOTPSubmit()">
                    <div class="form-group row" style="display: none">
                        <div class="col-sm-12">
                            <label class="label-control">मोबाइल नंबर </label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>
                                    कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें
                                </h3>
                                <p>एक ओटीपी भेजा गया है{{ mob_no }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">पूरा नाम </label>
                            <input type="text" class="form-control" [(ngModel)]="full_name" disabled
                                formControlName="name" />
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">ईमेल</label>
                            <input type="text" class="form-control" formControlName="email" disabled
                                [(ngModel)]="email2" />
                        </div>
                    </div>
                    <div class="form-group row"></div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label>पासवर्ड</label>
                            <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password" />
                            <password-strength-meter [password]="pass" [minPasswordLength]="8"
                                [colors]="['darkred', 'orangered', 'orange']"></password-strength-meter>
                            <small id="passwordHelpBlock" class="form-text text-muted">
                                आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष चार और कम से कम 8
                                वर्ण होने चाहिए
                            </small>
                            <div *ngIf="mo.password.touched && mo.password.invalid" class="alert alert-danger">
                                <div *ngIf="mo.password.errors.required">
                                    Password is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>पासवर्ड की पुष्टि कीजिये</label>
                            <input type="password" class="form-control" formControlName="password_confirmation" />
                            <div *ngIf="
                  mo.password_confirmation.touched &&
                  mo.password_confirmation.invalid
                " class="alert alert-danger">
                                <div *ngIf="mo.password_confirmation.errors.required">
                                    Confirm पासवर्ड की आवश्यकता है.
                                </div>
                                <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 अंक ओटीपी</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                formControlName="otp" [ngClass]="{ 'is-invalid': motp_submitted && mo.otp.errors }" />
                            <div *ngIf="motp_submitted && mo.contact.errors" class="invalid-login">
                                <div *ngIf="mo.otp.errors.required">ओटीपी आवश्यक है</div>
                                <div *ngIf="mo.otp.errors.pattern">
                                    ओटीपी 6 अंकों का होना चाहिए
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">
                                ओटीपी सत्यापित करें
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <form [formGroup]="MROTPForm" (ngSubmit)="MROTPSubmit()">
                    <div class="form-group row" style="display: none">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">यदि आपको ओटीपी प्राप्त नहीं हुआ है, आप 30 . के बाद एक नए ओटीपी
                                का अनुरोध कर सकते हैं सेकंड</span><br />
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">
                                पुन: भेजें ओटीपी
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade otp-modal" id="movement-verification" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="MOTPForm" (ngSubmit)="MOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>Please enter the OTP to verify your mobile number</h3>
                                <p>A OTP has sent to {{mob_no}}</p>
                            </div>
                        </div>
                    </div>                    
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 Digit OTP</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid': motp_submitted && mo.otp.errors }">
                            <div *ngIf="motp_submitted && d.contact.errors" class="invalid-login">
                                <div *ngIf="mo.otp.errors.required">OTP is required</div>
                                <div *ngIf="mo.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Validate OTP</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <form [formGroup]="MROTPForm" (ngSubmit)="MROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>                
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span  class="wish-otp">If you did not received OTP, you can request a new OTP after 30 seconds</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend OTP</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->