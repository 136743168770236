import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-home-testimonials',
  templateUrl: './home-testimonials.component.html',
  styleUrls: ['./home-testimonials.component.scss']
})

export class HomeTestimonialsComponent implements OnInit {
  enva:any;
  data:any=[];
  testiArray:any = [];
  imageURL:any;
  feedbackForm:FormGroup;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
  feedback_submitted = false;
  constructor(private restAPI:RESTApiService,
    private formBuilder: FormBuilder,
    private toast: ToastrService) { 
    this.enva = environment.assetsURL;
    this.imageURL = environment.imageURL;
  }

  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({
      image:[null], 
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      feedback: ['', [Validators.required]]
    });
    this.fetchTestimonials();
  }


  get f() { return this.feedbackForm.controls; }

  fetchTestimonials(){
    this.restAPI.get(environment.apiURL + environment.testimonialsAPI).subscribe(data =>{
      this.data = data;
      this.testiArray = this.data.data.testimonial;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.feedbackForm.patchValue({
      image: file
    });
    this.feedbackForm.get('image').setValue(file);
    console.log(file);
  }
  
  feedbackSubmit(){
    this.feedback_submitted = true;
    // stop here if form is invalid
    if (this.feedbackForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("image", this.feedbackForm.get('image').value);
      formData.append("name", this.feedbackForm.get('name').value);
      formData.append("email", this.feedbackForm.get('email').value);
      formData.append("contact", this.feedbackForm.get('contact').value);
      formData.append("designation", this.feedbackForm.get('designation').value);
      formData.append("feedback", this.feedbackForm.get('feedback').value);

      let data = this.feedbackForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.testimonialPostAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success('Your Feedback has submitted Successfully. Thank You');
        this.feedbackForm.reset();
        this.feedback_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    margin:20,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: false
  }

}
