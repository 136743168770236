<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumec.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>विस्तार शिविर चैट</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">विस्तार शिविर चैट</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <app-user-sidebar></app-user-sidebar>
            </div>
            <div class="col-sm-9">
                <div class="main-area-dash">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table-bordered table table-filuser">
                                    <thead>
                                        <tr>
                                            <th>उपयोगकर्ता</th>
                                            <th>आईडी</th>
                                            <th>अन्य सूचना</th>
                                            <th>गुप्त</th>
                                            <th>अंतिम बार</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        <tr>
                                            <td>{{ECSummarydata.user_name}}</td>
                                            <td>{{ECSummarydata.expose_id}}</td>
                                            <td>{{ECSummarydata.other_information}}</td>
                                            <td>{{ECSummarydata.confidential_name}}</td>
                                            <td>{{ECSummarydata.last_reply}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-block">
                                        <div class="mesgs">
                                            <div class="msg_history">
                                                <div [ngClass]="{'incoming_msg': item.added_by == 'admin', 'outgoing_msg':item.added_by === 'user' }" *ngFor="let item of ECdata">
                                                    <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" *ngIf="item.added_by == 'admin'" class="img-fluid" alt="sunil"> </div>
                                                    <div [ngClass]="{'received_msg': item.added_by == 'admin', '':item.added_by === 'user' }">
                                                        <div [ngClass]="{'received_withd_msg': item.added_by == 'admin', 'sent_msg':item.added_by === 'user' }">
                                                            <p>{{item.message}}
                                                                <a *ngIf="item.document != null" href="{{imageurl}}{{item.document}}">अनुलग्नक देखें</a>
                                                            </p>
                                                            <span class="time_date"> {{item.created_at}} | By <span *ngIf="item.added_by == 'user'">Me</span><span *ngIf="item.added_by == 'admin'">Admin</span></span>
                                                        </div>
                                                    </div>
                                                </div>                                           
                                            </div>
                                            <form [formGroup]="ECReplyForm" (ngSubmit)="ECReplySubmit()">
                                                <div class="msg_send">
                                                    <div class="form-group row">
                                                        <div class="col-sm-12 d-none">
                                                            <input class="form-control" type="text" [(ngModel)]="ECID" placeholder="Type your message" formControlName="expose_form_id">
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-sm-12">
                                                            <textarea cols="6" class="form-control" rows="3" placeholder="Type your message" formControlName="message" [ngClass]="{ 'is-invalid': ECReply_submitted && ecr.message.errors }"></textarea>
                                                            <div *ngIf="ECReply_submitted && ecr.message.errors"  class="invalid-login">
                                                                <div *ngIf="ecr.message.errors.required">Message is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-sm-6">
                                                            <input type="file" class="form-control" formControlName="document" (change)="uploadDoc($event)" [ngClass]="{ 'is-invalid': ECReply_submitted && ecr.document.errors }">
                                                            <div *ngIf="ECReply_submitted && ecr.document.errors"  class="invalid-login">
                                                                <div *ngIf="ecr.document.errors.required">Document is required</div>
                                                            </div>
                                                            <span style="color:red;">Attachment Allowed: PDF/Video/Image</span>
                                                        </div>
                                                        <div class="col-sm-6 text-right">
                                                            <button class="btn btn-dark" type="submit">Reply Now <i class="fas fa-paper-plane"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div *ngIf="loading" class="loading-preloader">
                                                <div class="loadin-inner">
                                                    <i class="fas fa-spinner fa-spin"></i>
                                                    <h3>Please Wait... While We uploading your files.</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>