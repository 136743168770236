import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {
  enva: any;
  constructor() {
    this.enva = environment.assetsURL;
   }

  ngOnInit(): void {
  }

  LoggedIn(){
    return localStorage.getItem('token')!= null;
  }

}
