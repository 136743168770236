<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>ब्लॉग</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">ब्लॉग</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="blog-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-3" *ngFor="let item of BlogArray">
                <div class="single-featured">
                    <a [routerLink]="['/blog-detail', item.slug]" class="blog-img">
                        <img src="{{imageurl}}{{item.image}}" class="img-fluid" alt="" title="">
                        <span>पोस्ट किआ {{item.created_at}}</span>
                    </a>
                    
                    <div class="featured-content">
                        <a [routerLink]="['/blog-detail', item.slug]">
                            <h3>{{item.heading}}</h3>
                        </a>
                        
                        <a [routerLink]="['/blog-detail', item.slug]" class="read-more">आगे पढ़े</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>