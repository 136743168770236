import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { Data } from 'src/app/models/data';
import { MustMatch } from 'src/app/_helpers/mustmatch.validator';
import { ConfirmedValidator } from 'src/app/_helpers/confirmed.validators';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
import { Router } from '@angular/router';
declare var jQuery: any;
@Component({
  selector: 'app-our-objective',
  templateUrl: './our-objective.component.html',
  styles:[
    `.modal{background: rgba(0,0,0, .5);`
  ],
  styleUrls: ['./our-objective.component.scss']
})
export class OurObjectiveComponent implements OnInit {
  showMe: boolean= true;
  showMe2: boolean= true;
  showMe3: boolean= false;
  showMe4: boolean= true;
  showMe5: boolean= false
  password: any;
  password_confirmation: any;
  full_name2: any;
  bfi_submitted = false;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  countryArray: any = [];
  pass: any;
  motp_submitted = false;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  //Modal data details
  modaldata:any = [];
  disableBtn = false;
  enva:any;
  imageurl:any;
  mob_no: any;
  mob_no2: any;
  showModal : boolean;
  data1:Data;
  objective_id:any;
  OOTPForm:FormGroup;
  OROTPForm:FormGroup;
  ObjectiveForm:FormGroup;  
  ootp_submitted = false;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";  
  objective_submitted = false;
  ObjectivesArray:any = [];
  data:any = [];
  statesArray:any=[];
  cityArray:any=[];
  message: string;
  constructor(private restAPI:RESTApiService,
    private formBuilder: FormBuilder,
    private toast: ToastrService, private RestAPI: RESTApiService, private router: Router, private auth:AuthenticationService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
   }

  ngOnInit(): void {
    this.ObjectiveForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      pancard: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });
    this.registrationForm = this.formBuilder.group({
      name: [''],
      /* email: ['', [Validators.required, Validators.email]], */
      contact: ['', [Validators.required]],
    })
    this.OROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.OOTPForm = this.formBuilder.group({
      contact: [''],
      name: ['' ],
      email: ['', []],
      otp: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    }
    );
    this.fetchCountry();
    this.fetchObjectives();
  }

  clearForm() {
    this.ObjectiveForm.get('email').reset();
    this.ObjectiveForm.get('password').reset();
    this.ObjectiveForm.get('address').reset();
    this.ObjectiveForm.get('state').reset();
    this.ObjectiveForm.get('is_indian_citizen').reset();
    this.ObjectiveForm.get('gender').reset();
    this.ObjectiveForm.get('password').reset();
    this.ObjectiveForm.get('password').reset();
    this.ObjectiveForm.get('whatsapp_number').reset();
    this.ObjectiveForm.get('password_confirmation').reset();
    this.ObjectiveForm.get('city').reset();
    this.ObjectiveForm.get('country').reset();
    this.ObjectiveForm.get('tehsil').reset();
    this.ObjectiveForm.get('pancard').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }

  get o() { return this.ObjectiveForm.controls; }
  get oo() { return this.OOTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get vrfy() { return this.OTPVForm.controls;}
  get lwp() { return this.LWPForm.controls; }

  fetchObjectives(){
    this.restAPI.get(environment.apiURL + environment.objectivesAPI).subscribe(data =>{
      this.data = data;
      this.ObjectivesArray = this.data.data.objective;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  ObjectiveSubmit(){
    this.objective_submitted = true;
    // stop here if form is invalid
    if (this.ObjectiveForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      //let data = this.MissionForm.getRawValue();
      this.data1 = this.ObjectiveForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        console.log(this.data1);
        /* this.mob_no = this.data1.contact; */
        this.objective_id = this.data1.objective_id;
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.ObjectiveForm.reset();
        this.objective_submitted  = false;
        /* jQuery('#objective-verification').modal('show'); */
       this.showModal = false; 
       this.showMe = false;
       setTimeout(()=>{  
        this.disableBtn = true;
      }, 30000); 
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  OOTPSubmit(){
    this.ootp_submitted = true;
    // stop here if form is invalid
    if (this.OOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.OOTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('फ़ॉर्म सफलतापूर्वक सबमिट किया गया');
        this.OOTPForm.reset();
       /* jQuery('#objective-verification').modal('hide'); */
        this.ootp_submitted  = false;
        /* this.showMe3 = true; */
        this.showMe3 = true;
    this.showMe2 = false;
    this.showMe4 = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  OROTPSubmit(){
    // stop here if form is invalid
    if (this.OROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.OROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  /* fetchState() {
    this.restAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  } */
  fetchCountry() {
    this.RestAPI.get(environment.apiURL + environment.countryAPI).subscribe(data => {
      this.data = data;
      this.countryArray = this.data.data.countries;
      console.log(this.countryArray);
    }, err => {
      console.log(err);
    });
  }
  fetchState(e) {
    this.RestAPI.get(environment.apiURL + environment.stateAPI1 + e.target.value).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }
  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  onClick(event, item)
  {
    this.showModal = true;
    this.modaldata = item;
    console.log(this.modaldata);
  }

  hide()
  {
    this.showModal = false; 
  }

  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }

  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.full_name = this.data1.name;
        this.email2 = this.data1.email;
        this.mob_no2 = this.data1.contact;
        this.full_name2 = this.data1.name;
        console.log(this.mob_no);
        /* this.toast.success('ओटीपी सफलतापूर्वक भेजा गया'); */
        /*  */
        this.registratiopn_submitted = false;
        this.showMe2 = false;
        this.showMe3 = true;
        this.showMe4 = false;
        this.showMe5 = true;
        this.message = "ओटीपी सफलतापूर्वक भेजा गया"
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/our-objective"]);
          // jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें ओटीपी 6 अंकों का होना चाहिए');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        /* this.toast.success('नंबर सत्यापित हो गया है'); */
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        /* this.showMe2 = true; */
        this.showMe3 = false;
        /* this.router.navigate(["/thank-you"]); */
        this.message = "नंबर सत्यापित हो गया है"
      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe = false
    /* this.showMe2 = true */
    this.showMe4 = true
  }
}
