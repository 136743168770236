import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { Data } from 'src/app/models/data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/services/canonical.service';
import { MustMatch } from '../../_helpers/mustmatch.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
declare var jQuery: any;




@Component({
  selector: 'app-upload-your-letter',
  templateUrl: './upload-your-letter.component.html',
  styles: [
    `.modal{background: rgba(0,0,0, .5);z-index:999999999;}`
  ],
  styleUrls: ['./upload-your-letter.component.scss']
})
export class UploadYourLetterComponent implements OnInit {
  projectcount: number = 0;
  projectcountstop:any = setInterval(() => {
    this.projectcount++;
    if(this.projectcount == 125)
    {
      clearInterval(this.projectcountstop);
    }
  },10)
  showMe: boolean= true;
  showMe2: boolean= false;
  showMe3: boolean= false;
  showMe4: boolean= false;
  full_name2: any;
  bfi_submitted = false;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  pass: any;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  enva: any;
  letter_submitted = false;
  data1: Data;
  data: any=[];
  statesArray: any = [];
  lettercategoriesArray: any = [];
  lettercategories2Array: any = [];
  ExposeCArray: any = [];
  cityArray: any = [];
  LetterForm:FormGroup;
  uploadLetterMetaData: any = [];
  recordArray:any=[];
  motp_submitted = false;
  disableBtn = false;
  mrotp_submitted = false;
  mob_no:any;
  mob_no2:any;
  MOTPForm: FormGroup;
  MROTPForm: FormGroup;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  PanPattern = "^[A-Z0-9]{10}$"; 
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,  private router:Router, private toast: ToastrService, private canonical: CanonicalService, private restAPI:RESTApiService, private RestAPI: RESTApiService, private titleService: Title, private meta: Meta, private auth: AuthenticationService) {
    this.enva = environment.assetsURL;
    this.titleService.setTitle(this.uploadLetterMetaData.meta_title);
    this.meta.addTags([
      { name: 'description', content: this.uploadLetterMetaData.meta_description },
      { name: 'keywords', content: this.uploadLetterMetaData.meta_keywords },
      { property: 'og:title', content: this.uploadLetterMetaData.og_title },
      { proprety: 'og:description', content: this.uploadLetterMetaData.og_description },
      { property: 'og:type', content: this.uploadLetterMetaData.og_type },
      { property: 'article:publisher', content: this.uploadLetterMetaData.publisher },
      { property: 'og:locale', content: this.uploadLetterMetaData.og_locale },
      { name: 'twitter:card', content: this.uploadLetterMetaData.twitter_card },
      { name: 'twitter:creator', content: this.uploadLetterMetaData.twitter_creator },
      { name: 'twitter:description', content: this.uploadLetterMetaData.twitter_description },
      { name: 'twitter:image', content: this.uploadLetterMetaData.twitter_img_url },
      { name: 'twitter:site', content: this.uploadLetterMetaData.twitter_site },
      { name: 'twitter:title', content: this.uploadLetterMetaData.twitter_title },
      { name: 'twitter:domain', content: this.uploadLetterMetaData.twitter_domain },
      { name: 'robots', content: this.uploadLetterMetaData.meta_robots },
      { property: 'og:image', content: this.uploadLetterMetaData.og_image },
      { property: 'og:url', content: this.uploadLetterMetaData.og_url },
      { property: 'og:site_name', content: this.uploadLetterMetaData.og_site_name },
      { property: 'artical:publisher', content: this.uploadLetterMetaData.articale_publisher },
      { name: 'twitter:type', content: this.uploadLetterMetaData.twitter_type }
    ]);
  }

  ngOnInit(): void {
    this.fetchRecordLetterCount();
    this.canonical.createCanonicalLink();
    this.LetterForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      father_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile_number: ['', [Validators.required]],
      whatsapp_number: ['', [Validators.required]],
      image: ['', [Validators.required]],
      letter: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      letter_categories: ['', []],
      letter_types: ['', []],
      date_of_birth: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      address: ['', [Validators.required]],
      acceptTerms: ['', [Validators.required]],
    }, {
      validator: MustMatch('password', 'password_confirmation')
    });
    this.registrationForm = this.formBuilder.group({
      name: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.MOTPForm = this.formBuilder.group({
      contact: [''],
      name: ['' ],
      email: ['', []],
      otp: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    }
    );

    this.MROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.fetchState();
    this.fetchCategories();
  }
  get l() { return this.LetterForm.controls; }
  

  uploadImage(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.LetterForm.patchValue({
      image: file
    });
    this.LetterForm.get('image').setValue(file);
    console.log(file);
  }
  uploadLetter(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.LetterForm.patchValue({
      letter: file
    });
    this.LetterForm.get('letter').setValue(file);
    console.log(file);
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }
  fetchState() {
    this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }
  fetchCategories(){
    this.RestAPI.get(environment.apiURL + environment.lettercategoriesAPI).subscribe(data => {
      this.data = data;
      this.lettercategoriesArray = this.data.data.letter_categories;
    }, err => {
      console.log(err);
    }
    );
  }
  fetchCategories2(e){
    this.RestAPI.get(environment.apiURL + environment.lettercategories2API + e.target.value).subscribe(data => {
      this.data = data;
      this.lettercategories2Array = this.data.data.letter_types;
    }, err => {
      console.log(err);
    }
    );
  }
  LetterSubmit() {
    this.letter_submitted = true;
    // stop here if form is invalid
    if (this.LetterForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("image", this.LetterForm.get('image').value);
      formData.append('letter', this.LetterForm.get('letter').value);
      formData.append("first_name", this.LetterForm.get('first_name').value);
      formData.append("email", this.LetterForm.get('email').value);
      formData.append("mobile_number", this.LetterForm.get('mobile_number').value);
      formData.append("date_of_birth", this.LetterForm.get('date_of_birth').value);
      formData.append("gender", this.LetterForm.get('gender').value);
      formData.append("address", this.LetterForm.get('address').value);
      formData.append("state", this.LetterForm.get('state').value);
      formData.append("city", this.LetterForm.get('city').value);
      formData.append("last_name", this.LetterForm.get('last_name').value);
      formData.append("father_name", this.LetterForm.get('father_name').value);
      formData.append("whatsapp_number", this.LetterForm.get('whatsapp_number').value);
      formData.append("letter_categories", this.LetterForm.get('letter_categories').value);
      // let data = this.LetterForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.LetterFormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success('Your Letter has submitted Successfully. Thank You');
        this.LetterForm.reset();
        this.letter_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }

  fetchRecordLetterCount(){
    this.RestAPI.get(environment.apiURL + environment.RecordLetterCountAPI).subscribe(data => {
      this.data = data;
      this.recordArray = this.data.data.records;
      console.log(this.recordArray);
    }, err => {
      console.log(err);
    });
  }

  MOTPSubmit() {
    this.motp_submitted = true;
    // stop here if form is invalid
    if (this.MOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MOTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.MOTPForm.reset();
        // this.router.navigate(["/thank-you"]);
        this.motp_submitted = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  MROTPSubmit() {
    this.mrotp_submitted = true;
    // stop here if form is invalid
    if (this.MROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        this.mrotp_submitted = false;
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  get mo() { return this.MOTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get vrfy() { return this.OTPVForm.controls;}
  get lwp() { return this.LWPForm.controls; }

  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.email2 = this.data1.email;
        this.full_name2 = this.data1.name;
        console.log(this.mob_no);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        /*  */
        this.registratiopn_submitted = false;
        this.showMe4 = false;
        this.showMe3 = true;
        
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/upload-your-letter"]);
          // jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.showMe2 = true;
        this.showMe3 = false;
        this.showMe4 = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe = false
    /* this.showMe2 = true */
    this.showMe4 = true
  }


}
function letter(arg0: string, letter: any) {
  throw new Error('Function not implemented.');
}

