<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>संपर्क करें</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">संपर्क करें</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-us-page">
    <div class="container">
        <div class="row clearfix">
            <div class="left-col col-lg-6 col-md-12 col-sm-12">
                <div class="inner-box">
                    <div class="images clearfix">
                        <figure class="image"><img src="{{imageurl}}{{ContactCArray.image1}}" alt=""></figure>
                        <figure class="image"><img src="{{imageurl}}{{ContactCArray.image2}}" alt=""></figure>
                    </div>
                    
                    <div class="contact-info-box">
                        <div class="inner">
                            <ul class="info">
                                <li class="clearfix">
                                    <h4>शीघ्र संपर्क</h4>
                                    <div class="content">
                                        <span class="icon fas fa-phone-alt"></span>
                                        <span>फ़ोन करे</span><br>
                                        <a class="txt" [href]="'tel:' + ContactCArray.contact">{{ContactCArray.contact}}</a>
                                    </div>
                                </li>
                                
                                <li class="clearfix">
                                    <h4>मेल एड्रेस</h4>
                                    <div class="content">
                                        <span class="icon fas fa-envelope"></span>
                                        <span>मेल करे </span><br>
                                        <a [href]="'mailto:' + ContactCArray.email" class="txt">{{ContactCArray.email}}</a>
                                    </div>
                                </li>
                                
                                <li class="clearfix">
                                    <h4>ऑफिस का पता </h4>
                                    <div class="content">
                                        <span class="icon fas fa-map-marker"></span>
                                        <span>हमें पता लगाएँ</span><br>
                                        <a >{{ContactCArray.address}}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            <div class="right-col col-lg-6 col-md-12 col-sm-12">
                <div class="inner">
                    <div class="sec-title">
                        <h2>हम यहां आपकी सहायता करने के लिए हैं</h2>
                        <div class="lower-text">यदि आपके कोई प्रश्न हैं, तो हमें एक संदेश दें</div>
                    </div>
                    <div class="form-contact">
                        <!-- <form [formGroup]="LWPForm" (ngSubmit)="LWPSubmit()" *ngIf="showMe" class="text-center"
                            style="background: #f2f2f2; padding: 30px 14px">
                            <div class="row form-group">
                                <div class="col-sm-12 ">
                                    <label>पंजीकृत ईमेल / मोबाइल नंबर</label>
                                    <br>
                                    <input class="text-material" formControlName="email" type="text"
                                        [ngClass]="{ 'is-invalid': lwp_submitted && lwp.email.errors }">
                                    <div *ngIf="lwp_submitted && lwp.email.errors" class="invalid-login">
                                        <div *ngIf="lwp.email.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-sm-12">
                                    <label>पास वर्ड</label>
                                    <br>
                                    <input class="text-material" formControlName="password" type="password"
                                        [ngClass]="{ 'is-invalid': lwp_submitted && lwp.password.errors }">
                                    <div *ngIf="lwp_submitted && lwp.password.errors" class="invalid-login">
                                        <div *ngIf="lwp.password.errors.required">पासवर्ड की आवश्यकता है</div>
                                    </div>
                                    <br>
                                    <a href="#" class="forgotatag" data-dismiss="modal" data-target="#forgot-password"
                                        data-toggle="modal">पासवर्ड भूल गए ?</a>
                                </div>
                            </div>


                            <div class="row form-group mt-lg-10">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn-login">लॉग इन करें</button>
                                </div>
                            </div>
                            <div class="modal-foo">
                                <a type="button" (click)="toogleTag()" class="forgotatag">नया उपयोगकर्ता ? अभी
                                    पंजीकरण करें</a>
                            </div>
                        </form>
                        <form [formGroup]="registrationForm" style="background: #f2f2f2; padding: 30px 14px"
                            (ngSubmit)="registrationSubmit()" *ngIf="showMe4" class="text-center">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                  registratiopn_submitted && r.name.errors
                          }">
                                    <div *ngIf="registratiopn_submitted &&
                      r.name.errors" class="invalid-login">
                                        <div *ngIf="r.name.errors.required">पूरा
                                            नाम आवश्यक है</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">मोबाइल नंबर
                                        <span style="color: red;">*</span></label>
                                    <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                        formControlName="contact" [ngClass]="{ 'is-invalid':
                          registratiopn_submitted &&
                          r.contact.errors }" maxlength="10">
                                    <div *ngIf="registratiopn_submitted &&
                          r.contact.errors" class="invalid-login">
                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                            नंबर आवश्यक है</div>
                                        <div *ngIf="r.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-dark" type="submit" style="margin-top: 25px;">अपना नंबर
                                        सत्यापित करें
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form [formGroup]="OTPVForm" style="background: #f2f2f2; padding: 30px 14px" (ngSubmit)="OTPVerifySubmit()" *ngIf="showMe3" class="text-center">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input [(ngModel)]="full_name2"  type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                                    otpverify_submitted && vrfy.name.errors
                                            }">
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control" >
                                        मोबाइल नंबर <span style="color: red;">*</span></label>
                                        <br>
                                    <input type="number" [(ngModel)]="mob_no" class="text-control" formControlName="contact" [ngClass]="{ 'is-invalid': otpverify_submitted && vrfy.contact.errors }">            
                                </div>      
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">6
                                        अंक ओटीपी <span style="color: red;">*</span></label>
                                    <input type="number" class="form-control"
                                        placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp"
                                        [ngClass]="{ 'is-invalid':
                                        otpverify_submitted && vrfy.otp.errors }">
                                    <div *ngIf="otpverify_submitted && vrfy.contact.errors" class="invalid-login">
                                        <div *ngIf="vrfy.otp.errors.required">ओटीपी आवश्यक है</div>
                                        <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-dark" type="submit"
                                        style="margin-top: 28px;">ओटीपी सत्यापित करें
                                    </button>
                                </div>
                            </div>
                        </form> -->
                        <form [formGroup]="contactForm" (ngSubmit)="contactSubmit()">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम  <span
                                        style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': contact_submitted && c.name.errors }">
                                    <div *ngIf="contact_submitted && c.name.errors" class="invalid-login">
                                        <div *ngIf="c.name.errors.required">पूरा नाम की आवश्यकता है</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">ईमेल <span
                                        style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': contact_submitted && c.email.errors }">
                                    <div *ngIf="contact_submitted && c.email.errors" class="invalid-login">
                                        <div *ngIf="c.email.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                
                                <div class="col-sm-6">
                                    <label class="label-control">मोबाइल नंबर <span
                                        style="color: red;">*</span></label>
                                    <input type="text" class="form-control"  [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': contact_submitted && c.mobile.errors }"> 
                                    <div *ngIf="contact_submitted && c.mobile.errors" class="invalid-login">
                                        <div *ngIf="c.mobile.errors.required">मोबाइल नंबर चाहिए</div>
                                        <div *ngIf="c.mobile.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">विषय <span
                                        style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="subject" [ngClass]="{ 'is-invalid': contact_submitted && c.subject.errors }">
                                    <div *ngIf="contact_submitted && c.subject.errors" class="invalid-login">
                                        <div *ngIf="c.subject.errors.required">सवाल की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                    <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                        formControlName="alternate_mobile_number">
                                </div>
                                
                            </div> -->
                            <!-- <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">व्हाट्सएप नंबर <span
                                            style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="whatsapp_number"
                                        [ngClass]="{ 'is-invalid': contact_submitted && c.whatsapp_number.errors }">
                                    <div *ngIf="contact_submitted && c.whatsapp_number.errors" class="invalid-login">
                                        <div *ngIf="c.whatsapp_number.errors.required">WhatsApp No is required</div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="form-group row">
                                <div class="col-sm-6">
                                    <p>लिंग <span style="color: red;">*</span></p>
                                    <label><input type="radio" value="male" formControlName="gender"> पुरुष   </label>
                                    &nbsp;&nbsp;
                                    <label><input type="radio" value="female" formControlName="gender"> स्त्री</label>
                                </div>
                                <div class="col-sm-6">
                                    <p>क्या आप भारत के नागरिक हैं ? <span
                                            style="color: red;">*</span></p>                 
                                    <label><input type="radio" value="yes" formControlName="is_indian_citizen"> हां  </label>
                                            &nbsp;&nbsp;
                                    <label><input type="radio" value="no" formControlName="is_indian_citizen"> नहीं</label>
                                </div>
                            </div> -->
                            <!-- <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">आवास का पता  <span
                                            style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="address"
                                        [ngClass]="{ 'is-invalid': contact_submitted && c.address.errors }">
                                    <div *ngIf="contact_submitted && c.address.errors" class="invalid-login">
                                        <div *ngIf="c.address.errors.required">Home Address is required</div>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">सवाल</label>
                                    <textarea class="form-control" rows="4" cols="8"  formControlName="message" [ngClass]="{ 'is-invalid': contact_submitted && c.message.errors }"></textarea>
                                    <div *ngIf="contact_submitted && c.message.errors" class="invalid-login">
                                        <div *ngIf="c.message.errors.required">सवाल की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-submit" type="submit">अभी जमा करे</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>