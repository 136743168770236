import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-movement-detail',
  templateUrl: './movement-detail.component.html',
  styleUrls: ['./movement-detail.component.scss']
})

export class MovementDetailComponent implements OnInit {
  enva:any;
  imageurl:any;
  MovementSlug:any;
  MovementArray:any=[];
  MovementImagesArray:any=[];
  MovementVideosArray:any=[];
  data: any=[];
  MovementDetailArray:any=[];
  constructor(private route: ActivatedRoute,
    private router:Router,
    private RestAPI: RESTApiService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;

    this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
     }
    
     this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
           // trick the Router into believing it's last link wasn't previously loaded
           this.router.navigated = false;
        }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.MovementSlug = params['params'].slug;
        console.log(this.MovementSlug)
      }
    });
    this.fetchMovementbySlug();
    this.fetchMovement();
  }

  
  fetchMovementbySlug() {
    this.RestAPI.get(environment.apiURL + environment.MovementDetailAPI + this.MovementSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.MovementDetailArray = this.data.data.movement[0];
      this.MovementImagesArray = this.data.data.image;
      this.MovementVideosArray = this.data.data.video;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchMovement() {
    this.RestAPI.get(environment.apiURL + environment.MovementAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.MovementArray = this.data.data.movement;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }

}
