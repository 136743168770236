import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { DomSanitizer  } from '@angular/platform-browser';

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss']
})

export class VideoGalleryComponent implements OnInit {
  VideoGalleryArray:any=[];
  enva:any;
  url:any;
  videoURL:any;
  baseUrl:string = 'https://www.youtube.com/embed/';
  data:any=[];
  video: any = {id: 'wzrnuUOoFNM'};

  constructor(private restAPI: RESTApiService,
    private sanitizer: DomSanitizer) { 
     
    }

  ngOnInit(): void {
    this.enva = environment.assetsURL;
    this.fetchVideos();
  }


  fetchVideos(){
    this.restAPI.get(environment.apiURL + environment.VideoGalleryAPI).subscribe(data =>{
      this.data = data;
      this.VideoGalleryArray = this.data.data.video;
    }, err => {
      console.log(err);
    });
  }

}
