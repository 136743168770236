import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  enva:any;
  data:any=[];
  NewsSlider:any=[];
  CatNewsOneArray:any=[];
  CatNewsTwoArray:any=[];
  CatNewsThreeArray:any=[];
  LatestNewsArray:any=[];
  imageurl:any;
  constructor(private restAPI: RESTApiService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.fetchNewsSlider();
  }

  fetchNewsSlider(){
    this.restAPI.get(environment.apiURL + environment.NewsHomepageAPI).subscribe(data =>{
      this.data = data;
      this.NewsSlider = this.data.data.featured;
      this.LatestNewsArray = this.data.data.latest;
      this.CatNewsOneArray = this.data.data.category1;
      this.CatNewsTwoArray = this.data.data.category2;
      this.CatNewsThreeArray = this.data.data.category3;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
