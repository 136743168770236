<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>सहयोग करें</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">सहयोग करें</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="donation-page-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-8 col-lg-6 col-md-8">
                <div class="donation-form">
                    <div class="card">
                        <div class="card-body">
                            <form [formGroup]="donateStep2Form" (ngSubmit)="donateSubmitStep2()">
                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <div class="d-flex">
                                            <label class="label-control nowrap-center">Donation Amount (<i class="fas fa-rupee-sign"></i>)</label>
                                            &nbsp;&nbsp;&nbsp;
                                            <input type="number" id="donation-amount" readonly class="text-control" [(ngModel)]="amount" formControlName="amount" [ngClass]="{ 'is-invalid': donatestep2_submitted && d.amount.errors }">
                                        </div>
                                        <div *ngIf="donatestep2_submitted && d.amount.errors" class="invalid-login">
                                            <div *ngIf="d.amount.errors.required">Amount is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">Email</label>
                                        <input type="text" class="text-control" readonly [(ngModel)]="email" formControlName="email" [ngClass]="{ 'is-invalid': donatestep2_submitted && d.email.errors }">
                                        <div *ngIf="donatestep2_submitted && d.email.errors" class="invalid-login">
                                            <div *ngIf="d.email.errors.required">ईमेल की आवश्यकता है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">Mobile No.</label>
                                        <input type="text" class="text-control" readonly [(ngModel)]="mobile" formControlName="mobile" [ngClass]="{ 'is-invalid': donatestep2_submitted && d.mobile.errors }">
                                        <div *ngIf="donatestep2_submitted && d.mobile.errors" class="invalid-login">
                                            <div *ngIf="d.mobile.errors.required">मोबाइल नंबर चाहिए</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">Automatic Payment</label>
                                        <select class="text-control" formControlName="automatic_payment" [ngClass]="{ 'is-invalid': donatestep2_submitted && d.automatic_payment.errors }">
                                            <option selected value="">Select</option>
                                            <option value="once">I will donate once</option>
                                            <option value="monthly_fromcc">Monthly from Credit Card</option>
                                        </select>
                                        <div *ngIf="donatestep2_submitted && d.automatic_payment.errors" class="invalid-login">
                                            <div *ngIf="d.automatic_payment.errors.required">Automatic Payment is required</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">Number of Months</label>
                                        <select class="text-control" formControlName="no_of_month" [ngClass]="{ 'is-invalid': donatestep2_submitted && d.no_of_month.errors }">
                                            <option selected value="">Select Months</option>
                                            <option value="until cancelled">Until Cancelled</option>
                                            <option value="3">3</option>
                                            <option value="6">6</option>
                                            <option value="12">12</option>
                                        </select>
                                        <div *ngIf="donatestep2_submitted && d.no_of_month.errors" class="invalid-login">
                                            <div *ngIf="d.no_of_month.errors.required">No. of months is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">Your Address</label>
                                        <textarea cols="4" rows="2" formControlName="address" class="text-control" placeholder="Enter your address here" [ngClass]="{ 'is-invalid': donatestep2_submitted && d.address.errors }"></textarea>
                                        <div *ngIf="donatestep2_submitted && d.address.errors" class="invalid-login">
                                            <div *ngIf="d.address.errors.required">Address is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">Donate to</label>
                                        <select class="text-control" formControlName="donate_to"  [ngClass]="{ 'is-invalid': donatestep2_submitted && d.donate_to.errors }">
                                            <option value="" selected>Select</option>
                                            <option value="national">National</option>
                                        </select>
                                        <div *ngIf="donatestep2_submitted && d.donate_to.errors" class="invalid-login">
                                            <div *ngIf="d.donate_to.errors.required">Donate to is required</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">PAN Number</label>
                                        <input type="text" class="text-control" [pattern]="mobNumberPattern" formControlName="pan_number" placeholder="Enter 10 digit PAN number"  [ngClass]="{ 'is-invalid': donatestep2_submitted && d.pan_number.errors }">
                                        <div *ngIf="donatestep2_submitted && d.pan_number.errors" class="invalid-login">
                                            <div *ngIf="d.pan_number.errors.required">PAN Number to is required</div>
                                            <div *ngIf="d.pan_number.errors.pattern">PAN Number must be 10 digit</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <label class="label-control text-center"><input type="checkbox" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': donatestep2_submitted && d.acceptTerms.errors }"> I declare that I am an Indian citizen, above 18 years of age.</label>
                                        <div *ngIf="donatestep2_submitted && d.acceptTerms.errors" class="invalid-login">
                                            <div *ngIf="d.acceptTerms.errors.required">Acceptance is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" type="submit">Submit Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>