<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>{{MovementDetailArray.title}}</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['']">मुख्य पृष्ठ</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/our-movement']">हमारा आंदोलन</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{{MovementDetailArray.title}}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="movement-detail-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="movement-div">
                    <div class="movement-img">
                        <img src="{{imageurl}}{{MovementDetailArray.image}}" (error) ="onImgError($event)" class="img-fluid">
                    </div>
                    
                    <div class="movement-content">
                        <h3>{{MovementDetailArray.title}}</h3>
                        <hr />
                        <div [innerHTML]="MovementDetailArray.description">
                            <p>Due to not being fair and transparent in the recruitment processes of Indian forces, brokerage and bribery have reached every youth of India in a large network. This brokerage, bribery and juggling has not started in Indian forces today but only about 30 to 40 years ago, this practice started slowly, it has completely caught the entire army and all the defense services and today it is in full swing Is on</p>
                            <p>First of all, on getting duty in the recruitment board of an officer, the candidates related to the people who knew them, got them recruited in the army on the strength of jugaad. For this work, these officers received some money in the form of gifts and sympathy by the candidates in exchange for this work and from here, bribery was born in the Indian forces. After this, these officials saw that there is a lot of money in this work, it can earn millions and crores of rupees, so these people think why not do it on a large scale and earn well. Then what was it that these people added other people with them, then they also saw that in this work millions of crores of rupees can be earned without hard work, thus all the people kept adding their trusted people. And this practice spread in the form of brokerage and these brokers started doing it on a large scale, considering it as their business and business.</p>
                        </div>
                    </div>

                    <div class="movement-img-gal">
                        <h4><span>इमेज गैलरी</span></h4>
                        <div class="row">
                            <div class="col-sm-3 col-6 col-md-3" *ngFor="let item of MovementImagesArray">
                                <a [routerLink]="['']" class="thumbnail">
                                    <img src="{{imageurl}}{{item.movement_image}}" title="{{item.image_title}}" alt="{{item.image_title}}" class="img-fluid">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="movement-vdo-gal">
                        <h4><span>वीडियो गैलरी</span></h4>
                        <div class="row">
                            <div class="col-sm-4 col-6 col-md-4" *ngFor="let item of MovementVideosArray">
                                 <youtube-player class="ytply" videoId="{{item.video_url}}" suggestedQuality="highres" [height]="200" [width]="250"></youtube-player>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-4">
                <div class="movement-sidebar">
                    <div class="movement-sidebar-widgets">
                        <div class="movement-detail-wrap">
                            <div class="movement-detail-wrap-header">
                                <h4>रिलेटेड पोस्ट</h4>
                            </div>
                            <div class="movement-detail-wrap-body">
                                <ul class="movement-post">
                                    <li *ngFor="let item of MovementArray | slice:0:3">
                                        <div class="movement-li">
                                            <div class="movement-img">
                                                <a [routerLink]="['/movement-detail', item.slug]"><img src="{{imageurl}}{{item.image}}" (error) ="onImgError($event)" alt="{{item.title}}"></a>
                                            </div>
                                            <div class="movement-cont">
                                                <h6><a [routerLink]="['/movement-detail', item.slug]">{{item.title}}</a></h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>