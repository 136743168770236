import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-busted-revolution-detail',
  templateUrl: './busted-revolution-detail.component.html',
  styleUrls: ['./busted-revolution-detail.component.scss']
})
export class BustedRevolutionDetailComponent implements OnInit {
  enva:any;
  imageurl:any;
  BustedRSlug:any;
  BustedRArray:any=[];
  BustedRImagesArray:any=[];
  BustedRVideosArray:any=[];
  data: any=[];
  BustedRDetailArray:any=[];
  constructor(private route: ActivatedRoute,
    private router:Router,
    private RestAPI: RESTApiService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
  
   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
      }
  });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.BustedRSlug = params['params'].slug;
        console.log(this.BustedRSlug)
      }
    });
    this.fetchBustedRbySlug();
    this.fetchBustedR();
  }

  fetchBustedRbySlug() {
    this.RestAPI.get(environment.apiURL + environment.BustedRDetailAPI + this.BustedRSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.BustedRDetailArray = this.data.data.busted_revolution[0];
      this.BustedRImagesArray = this.data.data.image;
      this.BustedRVideosArray = this.data.data.video;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchBustedR() {
    this.RestAPI.get(environment.apiURL + environment.BustedRAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.BustedRArray = this.data.data.busted_revolution;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }

}
