import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { Data } from 'src/app/models/data';
import { MustMatch } from 'src/app/_helpers/mustmatch.validator';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ConfirmedValidator } from 'src/app/_helpers/confirmed.validators';
import { AuthenticationService } from "../../services/Authentication/authentication.service";

@Component({
  selector: 'app-suggest-india',
  templateUrl: './suggest-india.component.html',
  styleUrls: ['./suggest-india.component.scss']
})
export class SuggestIndiaComponent implements OnInit {
  message: any;
  password: any;
  password_confirmation: any;
  NewUserFrom: FormGroup;
  newuser_submitted = false;
  showSubject: boolean = true;
  showFeedback: boolean = false;
  showMe: boolean = true;
  showMe2: boolean = true;
  showMe3: boolean = false;
  showMe4: boolean = true;
  showMe5: boolean = false;
  full_name2: any;
  bfi_submitted = false;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  pass: any;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  data1: Data;
  TodayDate = "30-05-2022";
  date1 = new Date();
  currentYear = this.date1.getUTCFullYear();
  currentMonth = this.date1.getUTCMonth() + 1;
  currentDay = this.date1.getUTCDay();
  FinalMonth: any;
  FinalDay: any;
  suggest_submitted = false;
  SuggestForm: FormGroup;
  statesArray: any = [];
  countryArray: any = [];
  cityArray: any = [];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";
  data: any = [];
  motp_submitted = false;
  disableBtn = false;
  mrotp_submitted = false;
  mob_no:any;
  mob_no2:any;
  MOTPForm: FormGroup;
  MROTPForm: FormGroup;
  constructor(private restAPI: RESTApiService,
    private formBuilder: FormBuilder,
    private toast: ToastrService, private RestAPI: RESTApiService, private router:Router, private auth: AuthenticationService) { }

  ngOnInit(): void {
    if (this.currentMonth < 10) {
      this.FinalMonth = "0" + this.currentMonth;
    } else {
      this.FinalMonth = this.currentMonth
    }
    if (this.currentDay < 10) {
      this.FinalDay = "0" + this.currentDay;
    } else {
      this.FinalDay = this.currentDay;
    }

    this.TodayDate = this.FinalDay + "-" + this.FinalMonth + "-" + this.currentYear;
    this.SuggestForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      father_name: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      tehsil: [''],
      gender: ['', [Validators.required]],
      date: ['', [Validators.required]],
      business: ['', [Validators.required]],
      facebook_url: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      feedback: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });
    this.NewUserFrom = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      pancard: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });
    this.registrationForm = this.formBuilder.group({
      name: [''],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
    })
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.MOTPForm = this.formBuilder.group({
      contact: [''],
      name: ['' ],
      email: ['', []],
      otp: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    }
    );

    this.MROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    // this.fetchState();
    this.fetchCountry();
  }
  get s() { return this.SuggestForm.controls; }
  SuggestSubmit() {
    this.suggest_submitted = true;
    // stop here if form is invalid
    if ('') {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.SuggestForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.SuggestFormAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('Your Suggestion/Feedback has submitted Successfully. Thank You');
        this.SuggestForm.reset();
        this.suggest_submitted = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  fetchCountry() {
    this.restAPI.get(environment.apiURL + environment.countryAPI).subscribe(data => {
      this.data = data;
      this.countryArray = this.data.data.countries;
      console.log(this.countryArray);
    }, err => {
      console.log(err);
    });
  }

  fetchState(e) {
    this.restAPI.get(environment.apiURL + environment.stateAPI1 + e.target.value).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  MOTPSubmit() {
    this.motp_submitted = true;
    // stop here if form is invalid
    if (this.MOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MOTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.MOTPForm.reset();
        // this.router.navigate(["/thank-you"]);
        this.motp_submitted = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  MROTPSubmit() {
    this.mrotp_submitted = true;
    // stop here if form is invalid
    if (this.MROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        this.mrotp_submitted = false;
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  get mo() { return this.MOTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get vrfy() { return this.OTPVForm.controls;}
  get lwp() { return this.LWPForm.controls; }
  get new() { return this.NewUserFrom.controls; }

  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.email2 = this.data1.contact;
        this.full_name2 = this.data1.name;
        console.log(this.mob_no);
        // this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.message = "ओटीपी सफलतापूर्वक भेजा गया"
        /*  */
        this.registratiopn_submitted = false;
        // this.showMe4 = false;
        this.showMe4 = false;
        this.showMe3 = true;
        this.showMe5 = true;
        
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/suggest-india"]);
          // jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        // this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.message = "नंबर सत्यापित हो गया है"
        this.showMe2 = false;
        this.showMe3 = false;
        this.showMe4 = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe = false
    this.showMe2 = true
    // this.showMe4 = true
  }
  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }
  NewuserSubmit(){
    this.newuser_submitted = true;
    // stop here if form is invalid
    if (this.NewUserFrom.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.NewUserFrom.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        // this.mob_no = this.data1.contact;
        // console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.NewUserFrom.reset();
        this.newuser_submitted  = false;
        this.showMe = false;
        // jQuery('#bfi-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  clearForm() {
    this.NewUserFrom.get('email').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('address').reset();
    this.NewUserFrom.get('state').reset();
    this.NewUserFrom.get('is_indian_citizen').reset();
    this.NewUserFrom.get('gender').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('whatsapp_number').reset();
    this.NewUserFrom.get('alternate_mobile_number').reset();
    this.NewUserFrom.get('city').reset();
    this.NewUserFrom.get('country').reset();
    this.NewUserFrom.get('tehsil').reset();
    this.NewUserFrom.get('pancard').reset();
    this.NewUserFrom.get('password_confirmation').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }
}
