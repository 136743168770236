<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>हमें पता लगाएँ</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">हमें पता लगाएँ</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="locate-us-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-4 col-md-4 col-12">
                <!-- Single Contact -->
                <div class="single-contact">
                    <i class="fas fa-map-marker"></i>
                    <!-- <h4>हमें पता लगाएँ</h4> -->
                    <p>{{LocateDArray.address}}</p>
                </div>
                <!--/ End Single Contact -->
            </div>
            <div class="col-lg-4 col-sm-4 col-md-4 col-12">
                <!-- Single Contact -->
                <div class="single-contact">
                    <i class="fas fa-phone"></i>
                    <h4>कॉल करे</h4>
                    <p>मोबाइल: {{LocateDArray.contact}}</p>
                </div>
            </div>
            <!--/ End Single Contact -->
            <!-- Single Contact -->
            <div class="col-lg-4 col-sm-4 col-md-4 col-12">
                <div class="single-contact no-border">
                    <i class="fas fa-envelope"></i>
                    <h4>मेल करे</h4>
                    <p>{{LocateDArray.email}}</p>
                </div>
            </div>
            <!--/ End Single Contact -->
        </div>
        <hr />
        <div class="row">
            <div class="col-sm-4" *ngFor="let item of LocationArray">
                <div class="box-location enquiry-box">
                    <div class="row">
                        <div class="col-sm-12">
                            <h3 class="h3-box">{{item.office_name}}</h3> <!-- title here -->
                        </div>
                        <div class="col-sm-12">
                            <google-map height="200px" width="100%" [center]="center"></google-map>
                        </div>
                        <div class="col-sm-12">
                            <div class="box-height">
                                <strong><i class="fas fa-map-marker"></i> </strong> {{item.address}}, {{item.city_name}} - {{item.pincode}} , {{item.state_name}}, India<br> 
                                <strong><i class="fas fa-tty"></i> </strong> {{item.contact}}<br>
                                <strong><i class="fas fa-envelope"></i> </strong> {{item.email}}           						            						</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>