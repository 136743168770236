<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>मिशन का विवरण</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">मिशन का विवरण</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="mission-detail-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="mission-div">
                    <div class="mission-img">
                        <img src="{{imageurl}}{{MissionData.image}}" class="img-fluid">
                    </div>
                    
                    <div class="mission-content">
                        <h3>{{MissionData.heading}}</h3>
                        <hr />
                        <div [innerHTML]="MissionData.description"></div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="mission-sidebar">
                    <div class="mission-sidebar-widgets">
                        <div class="mission-detail-wrap">
                            <div class="mission-detail-wrap-header">
                                <h4>रिलेटेड पोस्ट</h4>
                            </div>
                            <div class="mission-detail-wrap-body">
                                <ul class="mission-post">
                                    <li *ngFor="let item of MissionProblemArray | slice:0:5">
                                        <div class="mission-li">
                                            <div class="mission-img">
                                                <a [routerLink]="['/mission-detail', item.slug]"><img class="img-fluid rounded-circle" src="{{imageurl}}{{item.image}}" (error) ="onImgError($event)" alt="{{item.heading}}"></a>
                                            </div>
                                            <div class="mission-cont">
                                                <h6><a [routerLink]="['/mission-detail', item.slug]">{{item.heading}}</a></h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>