<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url();background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>View Gallery and Letter</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">View Gallery and Letter</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid">
    <div class="logo">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/left logo.png" alt="..." class="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/right logo.png" alt="..." class="float-right">
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</div>

<div class="view-gallery-and-letter-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <h3  style="color: #f58634; margin-top: -45px;">View Gallery and Letter &nbsp; <img src="assets/images/right logo.png" class="img-thumbnail"
                            style="max-width: 7%; border: 0px solid;">
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <table  class="center" border="1" cellpadding="5" cellspacing="5">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>State </th>
                            <th>District</th>
                            <th>City</th>
                            <th>No of Letters</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of recordArray ;let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.state}}</td>
                            <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td>
                            <td> <a [routerLink]="['/view-gallery']">{{item.letters_count}}</a></td>
                        </tr>
                    </tbody> 
                </table>
            </div>
        </div>
    </div>
</div>
<br>