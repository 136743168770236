// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  assetsURL: 'http://localhost:4100/',
  apiURL: 'https://www.rdssindia.org/master/',
  imageURL: 'https://www.rdssindia.org/master/public/storage/',
  HomepageAPI:'api/homepage_hindi',
  countryAPI: 'api/countries',
  stateAPI1: 'api/states-by-country/',
  stateAPI: 'api/state',
  VerifyNumberAPI: 'api/verify-contact',

  sendRegistrationOtpAPI: 'api/send-registration-otp',
  verifyOtpAPI: 'api/verify-registration-otp',
  newotpResendOtpAPI: 'api/resend-register-otp',
  newregisterAPI: 'api/register',
  existNumberAPI: 'api/check-contact-exist',
  previewFromAPI: 'api/donation-form/1',

  cityAPI: 'api/fetchcitybystate?state_id=',
  lettercategoriesAPI: 'api/letter-category',
  lettercategories2API: 'api/letter-types-by-category/',
  testimonialsAPI: 'api/testimonial',
  sliderAPI: 'api/slider_hindi',
  identityAPI: 'api/identity_hindi',
  founderAPI: 'api/founder_hindi',
  missionAPI: 'api/managemission_hindi',
  missionProblemAPI: 'api/mission_hindi',
  missionProblemDetailAPI: 'api/missiondetail?slug=',
  MissionPFormAPI: 'api/mission_form',
  MPOTPFormAPI: 'api/verify_mission_form',
  MissionResendOTP:'api/resend_otp',
  strengthAPI: 'api/strength_hindi',
  orgStuctureAPI: 'api/org_structure_hindi',
  orgNationCommAPI: 'api/organization_commit_hindi',
  generalSettingAPI: 'api/general_setting',
  visionAPI: 'api/vision_hindi',
  objectivesAPI: 'api/objective_category_hindi',
  MovementAPI:'api/movement_hindi',
  MovementDetailAPI:'api/movement_slug_hindi?slug=',
  BFIAPI:'api/bbi_hindi',
  BFIDetailAPI:'api/bbi_slug_hindi?slug=',
  BFIForm:'api/bbi_form',
  BFIOTPForm: 'api/verify_bbi_form',
  BFIResendOTP: 'api/resend_otp_bbi',
  BustedRAPI:'api/busted_hindi',
  BustedRDetailAPI: 'api/busted_slug_hindi?slug=',
  BustedRFormAPI: 'api/busted_form',
  BustedROTPFormAPI:'api/verify_busted_form',
  BustedResendOTP: 'api/resend_otp_busted',
  BustedRStep3FormAPI: 'api/busted_update',
  BustedContentAPI: 'api/br-content',
  ExposeContentAPI: 'api/expose-content',
  ExposeCAPI:'api/expose_hindi',
  ExposeCDetailAPI: 'api/expose_slug_hindi?slug=',
  ExposeCFormAPI: 'api/expose_form',
  ExposeCOTPFormAPI: 'api/verify_expose_form',
  ExposeCStep3FormAPI: 'api/expose_update',
  ExposeResendOTP:'api/resend_otp_expose',
  ObjectiveDetailAPI: 'api/objective_slug?slug=',
  ObjectiveJobAPI: 'api/objective_jobs?slug=',
  ObjectiveFormAPI: 'api/objective_form',
  OOTPFORMAPI: 'api/verify_objective_form',
  ObjectiveResendOTP: 'api/resend_otp_objective',
  TeamAPI: 'api/our_team_hindi',
  testimonialPostAPI: 'api/testimonialdetail',
  ContactFormAPI: 'api/contact_form',
  contactContentAPI:'api/contact_content',
  MovementFormAPI: 'api/movement_form',
  MovementOTPFormAPI:'api/verify_movement_form',
  MovementResendOTP: 'api/resend_otp_movement',
  ImageGalAPI: 'api/image_gallery_hindi',
  VideoGalleryAPI: 'api/video_gallery',
  JobAPI: 'api/jobpost_hindi',
  JobPostDetailAPI: 'api/jobs_hindi?slug=',
  JobFilterByCatAPI: 'api/jobbycat_hindi?slug=',
  JobApplyFormAPI: 'api/apply_job',
  CareerCAPI:'api/career_counselling_hindi',
  CareerCFormAPI:'api/counselling_form',
  CareerCOTPFormAPI:'api/verify_counselling_form',
  CareerCROTPFORMAPI:'api/resend_otp_counselling',
  CareerC2FormAPI:'api/counselling_update',
  CareerCFilterByCatAPI: 'api/ccbycat_hindi?slug=',
  CareerCatAPI: 'api/career_category_hindi',
  JobAlertFormAPI: 'api/alert_job',
  JobAlertOTPFormAPI: 'api/verify_job_alert',
  JobAResendOTP: 'api/resend_otp_job_alert',
  NewsHomepageAPI:'api/news_homepage_hindi',
  NewsAPI: 'api/newspost_hindi',
  NewsDetailAPI: 'api/news_hindi?slug=',
  NewsViewsDetailAPI: 'api/newsview?slug=',
  DonationStep1FormAPi: 'api/donation_form',
  DonationStep2FormAPi: 'api/donation_update',
  BlogAPI:'api/blog_hindi',
  BlogDetailAPI: 'api/blogdetails_hindi?slug=',
  MembershipPackageApi:'api/membership',
  MembershipFormStep1API: 'api/membership_form',
  MembershipFormStep2API: 'api/verify_membership_form',
  MembershipFormStep3API: 'api/membership_update',
  MembershipROTPFOrm:'api/resend_otp_membership',
  MembershipContentAPI: 'api/membership_content_hindi',
  VolunteerFormStep1API:'api/volunteer_step1',
  VolunteerFormStep2API:'api/verify_volunteer_form',
  VolunteerFormStep3API:'api/volunteer_form',
  VolunteerFormResendAPI:'api/volunteer_resend_otp',
  SuggestFormAPI: 'api/suggest_india',
  VisitorAPI: 'api/increment-visitor',
  TNCAPI: 'api/terms_and_condition_hindi',
  RPAPI: 'api/refund_policy_hindi',
  PPAPI:'api/privacy_policy_hindi',
  LocateUsAPI: 'api/locate_us',
  // My Account API
  UserRegisterAPI: 'api/user_register',
  UserOTPRegisterAPI: 'api/verify_user_form',
  UserRegisterResendOTP: 'api/resend_otp_user',
  UserLWPAPI: 'api/user_login',
  UserForgotStep1Form: 'api/forgot_password',
  UserForgotStep2Form: 'api/verify_forgot_password',
  UserForgotROTPForm: 'api/forgot_resend_otp',
  UserfetchDetails: 'api/userprofile',
  UserEmailVerification: 'api/email_user',
  UserEmailVerificationS2: 'api/email_verify',
  UserWelcomeLetter: 'api/welcome_letter',
  UserProfileUpdateAPI: 'api/userupdate',
  UserSecurityUpdateAPI: 'api/usersecurity',
  UserSubscriptionAPI: 'api/user_subscriptions',
  UserMissionSubscriptionAPi: 'api/mission_update',
  UserObjectiveSubscriptionAPi: 'api/objective_update',
  UserOtherSubscriptionAPi: 'api/other_program_update',
  UserAddJobAlertAPI: 'api/alert_add',
  UserDeleteJobAlertAPI: 'api/alert_delete',
  UserLatestUpdatesAPI: 'api/latest-updates',
  UserBuyNewMembershipAPI : 'api/add_membership_user',
  UserRenewalMembershipAPI: 'api/renew-membership',
  UserDonationAPI:'api/donation_form1',
  UserBustedFormAPI: 'api/busted_form1',
  UserExposeFormAPI:'api/expose_form1',
  UserMovementSummaryAPI: 'api/movement_user_form',
  UserFetchECChatsAPI: 'api/expose_campaign_chats?expose_form_id=',
  UserECReplyFormAPI: 'api/expose_campaign_reply',
  UserECChatSummaryAPI: 'api/expose_campaign_chat_summary?expose_form_id=',
  UserFetchBRChatsAPI: 'api/busted_revolution_chats?busted_form_id=',
  UserBRReplyFormAPI: 'api/busted_revolution_reply',
  UserBRChatSummaryAPI: 'api/busted_revolution_chat_summary?busted_form_id=',
  AboutUsAndolan: 'api/sena-bharti-andolan/about-us',
  OurIdentityAndolan: 'api/sena-bharti-andolan/our-identity',
  OurVisionAndolan: 'api/sena-bharti-andolan/our-vision',
  OurAimAndolan: 'api/sena-bharti-andolan/our-aim',
  OurStrengthAndolan: 'api/sena-bharti-andolan/our-strength',
  VideosAndolan: 'api/sena-bharti-andolan/videos',
  CommitteeStructure: 'api/sena-bharti-andolan/committee-structure',
  DownloadLetterAPI: 'api/andolan-letter-format',
  CommitteeFounder: 'api/sena-bharti-andolan/committee-founder',
  LetterFormAPI: 'api/andolan-letter',
  RecordLetterCountAPI: 'api/andolan-records',
  LetterCountCityAPI: 'api/andolan-record/lucknow',
  AndolanContantAPI: 'api/andolan-content',
  AndolanVideoAPI: 'api/andolan-video'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
