import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-busted-revolution-chats',
  templateUrl: './user-busted-revolution-chats.component.html',
  styleUrls: ['./user-busted-revolution-chats.component.scss']
})
export class UserBustedRevolutionChatsComponent implements OnInit {
  BRID:any;
  data:any=[];
  BRReplyForm:FormGroup;
  BRReply_submitted = false;
  BRdata:any=[];
  BRSummarydata:any=[];
  enva:any;
  imageurl : any;
  loading: boolean;
  constructor(private route: ActivatedRoute,
    private router:Router,private restAPI: RESTApiService,
    private toast: ToastrService,
    private formBuilder: FormBuilder) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
   this.router.events.subscribe((evt) => {
    if (evt instanceof NavigationEnd) {
     // trick the Router into believing it's last link wasn't previously loaded
     this.router.navigated = false;
    }
  });
 }

  ngOnInit(): void {
    this.BRReplyForm = this.formBuilder.group({
      busted_form_id : ['', [Validators.required]],
      message : ['', [Validators.required]],
      document : ['']
    });
    this.route.paramMap.subscribe(params => {
      if (params['params'].id) {
        this.BRID = params['params'].id;
        console.log(this.BRID)
      }
    });
    this.fetchBRbyID();
    this.fetchBRSummarybyID();
  }

  get brr() { return this.BRReplyForm.controls; }

  uploadDoc(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.BRReplyForm.patchValue({
      document: file
    });
    this.BRReplyForm.get('document').setValue(file);
    console.log(file);
  }

  fetchBRbyID(){
    this.restAPI.get(environment.apiURL + environment.UserFetchBRChatsAPI + this.BRID).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.BRdata = this.data.data.busted_reply;
      console.log(this.BRdata);
      }, err =>{
        console.log(err);
      });
  }

  fetchBRSummarybyID(){
    this.restAPI.get(environment.apiURL + environment.UserBRChatSummaryAPI + this.BRID).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.BRSummarydata = this.data.data.busted_reply[0];
      console.log(this.BRSummarydata);
      }, err =>{
        console.log(err);
      });
  }

  BRReplySubmit(){
    this.BRReply_submitted = true;
    // stop here if form is invalid
    if (this.BRReplyForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("busted_form_id", this.BRReplyForm.get('busted_form_id').value);
      formData.append("document", this.BRReplyForm.get('document').value);
      formData.append("message", this.BRReplyForm.get('message').value);
      this.loading = true; // Add this line
      //let data = this.BR2Form.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserBRReplyFormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success("Thanks for your reply!");
        this.BRReply_submitted  = false;
        this.loading = false; // Add this line
        this.BRReplyForm.reset();
        this.fetchBRbyID();
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }

}
