import { Component, OnInit } from '@angular/core';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organization-structure-page-two',
  templateUrl: './organization-structure-page-two.component.html',
  styleUrls: ['./organization-structure-page-two.component.scss']
})
export class OrganizationStructurePageTwoComponent implements OnInit {
  OrgStructureData:any=[];
  data:any=[];
  imageurl:any;
  enva:any;
  constructor(private restAPI: RESTApiService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;}

  ngOnInit(): void {
    this.fetchOrgStructure();
  }

  fetchOrgStructure(){
    this.restAPI.get(environment.apiURL + environment.orgStuctureAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.OrgStructureData = this.data.data.manageorganization;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

}
