<!-- Testimonial section -->
<section class="testimonial-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-12">
                <div class="section-title">
                    <h4 style="color: #f58634;">प्रशंसापत्र</h4>
                    <p>हमारे बारे में लोग क्या कहते हैं</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <owl-carousel-o [options]="customOptions" class="card-deck">
                    <ng-template carouselSlide *ngFor="let item of testiArray">
                        <div class="clientSayWrapper">
                            <div class="clientSay">
                                <i class="fas fa-quote-left"></i>
                                <div class="simple-article normall">
                                    <p>{{item.feedback}}</p>
                                </div>
                            </div>
                            <div class="sayPersone">
                                <img src="{{imageURL}}{{item.image}}" class="img-fluid" (error) ="onImgError($event)" alt="{{item.name}}">
                                <div class="personeInfo">
                                    <p>{{item.name}}</p>
                                    <span>{{item.designation}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>  
                </owl-carousel-o>
            </div>

            <div class="col-sm-12 mt-4 text-center">
                <button type="button" data-target="#send-feedback" data-toggle="modal" class="btn btn-feedback">प्रतिक्रिया भेजें</button>
            </div>
        </div>
    </div>
</section>

<div class="modal applyjobmodal fade" id="send-feedback" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Send Feedback</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="feedbackForm" (ngSubmit)="feedbackSubmit()">
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">Profile Picture</label>
                            <input type="file" class="apply-control" name="image" formControlName="image" (change)="uploadFile($event)">
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">Name</label>
                            <input type="text" class="apply-control" placeholder="Enter Name" name="name" formControlName="name" [ngClass]="{ 'is-invalid': feedback_submitted && f.name.errors }">
                            <div *ngIf="feedback_submitted && f.name.errors" class="invalid-login">
                                <div *ngIf="f.name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">Email</label>
                            <input type="text" class="apply-control" placeholder="Enter Email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': feedback_submitted && f.email.errors }">
                            <div *ngIf="feedback_submitted && f.name.errors" class="invalid-login">
                                <div *ngIf="f.email.errors.required">ईमेल की आवश्यकता है</div>
                                <div *ngIf="f.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">Mobile No.</label>
                            <input type="text" class="apply-control" placeholder="Enter Mobile No." [pattern]="mobNumberPattern" name="contact" formControlName="contact" [ngClass]="{ 'is-invalid': feedback_submitted && f.contact.errors }">
                            <div *ngIf="feedback_submitted && f.contact.errors" class="invalid-login">
                                <div *ngIf="f.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                <div *ngIf="f.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">Designation</label>
                            <input type="text" class="apply-control" placeholder="Enter Designation" name="designation" formControlName="designation" [ngClass]="{ 'is-invalid': feedback_submitted && f.designation.errors }">
                            <div *ngIf="feedback_submitted && f.designation.errors" class="invalid-login">
                                <div *ngIf="f.designation.errors.required">Designation is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">Feedback</label>
                            <textarea cols="4" rows="2" class="apply-control" placeholder="Feedback here" name="feedback" formControlName="feedback" [ngClass]="{ 'is-invalid': feedback_submitted && f.feedback.errors }"></textarea>
                            <div *ngIf="feedback_submitted && f.feedback.errors" class="invalid-login">
                                <div *ngIf="f.feedback.errors.required">Feedback is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-proceed" type="submit">Send Feedback</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>