<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>उपयोगकर्ता सदस्यता</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">उपयोगकर्ता सदस्यता</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <app-user-sidebar></app-user-sidebar>
            </div>
            <div class="col-sm-9">
                <div class="main-area-dash">
                    <div class="profile-tabs">
                        <ul class="nav nav-tabs">
                            <li><a class="active" data-toggle="tab" href="#membership">सदस्यता</a></li>
                            <li><a data-toggle="tab" href="#idcard">पहचान पत्र</a></li>
                            <li><a data-toggle="tab" href="#certificate">प्रमाणपत्र</a></li>
                            <li><a data-toggle="tab" href="#membership-history">सदस्यता इतिहास</a></li>
                        </ul>
                        
                        <div class="tab-content">
                            <div id="membership" class="tab-pane fade in active show">
                                <div class="dashboard-area">
                                    <div class="active-membership">
                                        <div class="row">
                                            <div class="col-sm-6 col-md-7">
                                                <div class="membership-current">
                                                    <div class="membership-plan">
                                                        <span>सक्रिय योजना</span>
                                                    </div>
                                                    <div class="membership-cur1">
                                                        <div class="membership-head">
                                                            <h2>{{UserDetailsMArray.name}}</h2>
                                                            <h3><i class="fas fa-rupee-sign"></i> {{UserDetailsMArray.discount}}<span>/- {{UserDetailsMArray.validity}}days</span></h3>
                                                        </div>
                                
                                                        <div class="membership-date">
                                                            <ul>
                                                                <li>खरीद की तारीख: {{UserDetailsMArray.purchasedate}}</li>
                                                                <li>समाप्ति तिथि: {{UserDetailsMArray.expirydate}}</li>
                                                            </ul>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="membership-cur2">
                                                        <div class="expiry-date-mem">
                                                            <h3>{{UserDetailsMArray.daysremaining}} <span>दिन शेष</span></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="idcard" class="tab-pane">
                                <div class="dashboard-area">
                                    <div class="row">
                                        <div class="col-lg-5 col-md-7 col-sm-6">
                                            <div class="id-card-main" id="print-idcard">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <div class="card-logoside">
                                                            <img src="{{imageurl}}{{generalData.image}}" class="img-fluid">
                                                            <h5 class="cmp-name">राश्ट्रधर्म सेवा सनातन</h5>
                                                        </div>
                                                        <div class="pic">
                                                            <img src="{{imageurl}}{{UserDetailsArray.image}}" alt="{{UserDetailsArray.name}}" class="img-fluid">
                                                        </div>
                                                    </div>
                                                    <div class="identity-card-head">
                                                        <h3>पहचान पत्र</h3>
                                                    </div>
                                                    <div class="card-body">
                                                        
                                                        <h4 class="name">{{UserDetailsArray.name}}</h4>
                                                        <p class="deg">सदस्यता आईडी: <span>{{UserDetailsMArray.membership_id}}</span></p>
                                                        <div class="row">
                                                            <div class="col-5">
                                                                <div class="info">ईमेल: </div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="det">{{UserDetailsArray.email}}</div>
                                                            </div>
                                                            <div class="col-5">
                                                                <div class="info">मोबाइल न:</div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="det">{{UserDetailsArray.contact}}</div>
                                                            </div>
                                                            <div class="col-5">
                                                                <div class="info">पता: </div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="det">{{UserDetailsArray.address}}</div>
                                                            </div>
                                                            <div class="col-5">
                                                                <div class="info">शामिल: </div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="det">{{UserDetailsMArray.purchasedate}}</div>
                                                            </div>
                                                            <div class="col-5">
                                                                <div class="info">समाप्त: </div>
                                                            </div>
                                                            <div class="col-7">
                                                                <div class="det">{{UserDetailsMArray.expirydate}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-lg-7">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="certificate" class="tab-pane">
                                <div class="dashboard-area">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="certificate-main" id="print-certificate">
                                                <div class="certify">
                                                    <div class="certify-inner">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="head">
                                                                    <img src="{{imageurl}}{{generalData.image}}" alt="" class="img-fluid">
                                                                    <h2 class="title">राश्ट्रधर्म सेवा सनातन</h2>
                                                                    <h3 class="sub-title">Head Office: {{generalData.address}}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="detail">
                                                            <h4 class="title">सदस्यता का प्रमाण पत्र</h4>
                                                            <p class="text">यह प्रमाणित किया जाता है कि <strong class="name">{{UserDetailsArray.name}}</strong></p>
                                                            <p class="text">Of<span class="add"> {{UserDetailsArray.address}}</span></p>
                                                            <p class="text">सदस्यता आईडी के लिए राष्ट्रधर्म सेवा संगठन की सदस्यता प्राप्त है <strong class="num">{{UserDetailsMArray.membership_id}}</strong> &amp; सदस्यता तक मान्य  <span class="add">{{UserDetailsMArray.expirydate}}</span></p>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="sign">
                                                                    <p class="sub">श्री योगेश सिंह यादव<br />संस्थापक और प्रबंध निदेशक
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="membership-history" class="tab-pane">
                                <div class="dashboard-area">
                                    <div class="table-responsive">
										<table class="table rl-table">
											<thead>
												<tr>
													<th>खरीदने की तारीख</th>
                                                    <th>रकम</th>
                                                    <th>पैकेज</th>
                                                    <th>वैधता</th>
                                                    <th>समाप्ति </th>
													<th>उद्देश्य</th>
													<th>सदस्यता की स्थिति</th>
													<!--<th>Invoice</th>-->
												</tr>
											</thead>
											<tbody>
                                                <tr>
													<td>{{UserDetailsMArray.purchasedate}}</td>
													<td><i class="fas fa-rupee-sign"></i> {{UserDetailsMArray.discount}}</td>
                                                    <td>{{UserDetailsMArray.name}}</td>
                                                    <td>{{UserDetailsMArray.validity}} दिन</td>
                                                    <td>{{UserDetailsMArray.expirydate}}</td>
                                                    <td>वर्तमान में सक्रिय</td>
													<td>{{UserDetailsMArray.membership_status}}</td>
													<!--<td><a href="#" class="btn-view"><i class="fas fa-eye"></i></a></td>-->
												</tr>
												<tr *ngFor="let item of UserDetailsMHArray">
													<td>{{item.purchasedate}}</td>
													<td><i class="fas fa-rupee-sign"></i> {{item.discount}}</td>
                                                    <td>{{item.name}}</td>
                                                    <td>{{item.validity}} दिन</td>
                                                    <td>{{item.expirydate}}</td>
                                                    <td><span *ngIf="item.type == 'new'">पहली सदस्यता</span><span *ngIf="item.type == 'renewal'">सदस्यता नवीकरण</span></td>
													<td>{{item.membership_status}}</td>
													<!--<td><a href="#" class="btn-view"><i class="fas fa-eye"></i></a></td>-->
												</tr>
											</tbody>
										</table>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>