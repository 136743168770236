import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-our-vision',
  templateUrl: './our-vision.component.html',
  styleUrls: ['./our-vision.component.scss']
})
export class OurVisionComponent implements OnInit {
  imageurl:any;
  data:any=[];
  enva:any;
  VisionArray:any=[];
  constructor(private restAPI: RESTApiService) {
    this.imageurl = environment.imageURL;
    this.enva = environment.assetsURL;
   }

  ngOnInit(): void {
    this.fetchVision();
  }

  fetchVision(){
    this.restAPI.get(environment.apiURL + environment.visionAPI).subscribe(data =>{
      this.data = data;
      this.VisionArray = this.data.data.managevision;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
