<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumc.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>जॉब काउंसलिंग फॉर्म</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">जॉब काउंसलिंग फॉर्म</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="career-counselling-form">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <!-- <h3>जॉब काउंसलिंग</h3> -->
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-12">
                <form [formGroup]="CC2Form" (ngSubmit)="CC2Submit()">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Name</label>
                            <input type="text" class="text-control" placeholder="Enter Name" formControlName="name" readonly [(ngModel)]="name" [ngClass]="{ 'is-invalid': CC2_submitted && c.name.errors }">
                            <div *ngIf="CC2_submitted && c.name.errors" class="invalid-login">
                                <div *ngIf="c.name.errors.required">Name is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Email</label>
                            <input type="text" class="text-control" placeholder="Enter Email" readonly [(ngModel)]="email" formControlName="email" [ngClass]="{ 'is-invalid': CC2_submitted && c.email.errors }">
                            <div *ngIf="CC2_submitted && c.email.errors" class="invalid-login">
                                <div *ngIf="c.email.errors.required">ईमेल की आवश्यकता है</div>
                                <div *ngIf="c.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Mobile No.</label>
                            <input type="text" class="text-control" placeholder="Enter Mobile No." readonly [(ngModel)]="contact" [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': CC2_submitted && c.contact.errors }">
                            <div *ngIf="CC2_submitted && c.contact.errors" class="invalid-login">
                                <div *ngIf="c.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                <div *ngIf="c.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>

                        <div class="col-sm-4" style="display: none;">
                            <label class="label-control">OTP</label>
                            <input type="text" class="text-control" placeholder="Enter OTP" readonly [(ngModel)]="otp" formControlName="otp" [ngClass]="{ 'is-invalid': CC2_submitted && c.otp.errors }">
                            <div *ngIf="CC2_submitted && c.otp.errors" class="invalid-login">
                                <div *ngIf="c.otp.errors.required">OTP is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Gender</label>
                            <select class="text-control" formControlName="gender" [ngClass]="{ 'is-invalid': CC2_submitted && c.gender.errors }">
                                <option value="" selected="true">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            <div *ngIf="CC2_submitted && c.gender.errors" class="invalid-login">
                                <div *ngIf="c.gender.errors.required">Gender is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">D.O.B.</label>
                            <input type="date" class="text-control" formControlName="dob" [ngClass]="{ 'is-invalid': CC2_submitted && c.dob.errors }">
                            <div *ngIf="CC2_submitted && c.dob.errors" class="invalid-login">
                                <div *ngIf="c.dob.errors.required">D.O.B is required</div>
                            </div>
                        </div>
                       
                        <div class="col-sm-4">
                            <label class="label-control">Address</label>
                            <input type="text" class="text-control" formControlName="address" placeholder="Enter Address" [ngClass]="{ 'is-invalid': CC2_submitted && c.address.errors }">
                            <div *ngIf="CC2_submitted && c.address.errors" class="invalid-login">
                                <div *ngIf="c.address.errors.required">Address is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">State</label>
                            <select class="text-control" formControlName="state" (change)="fetchCity($event)" readonly [(ngModel)]="state" [ngClass]="{ 'is-invalid': CC2_submitted && c.state.errors }">
                                <option value="" [selected]="true">Select State</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="CC2_submitted && c.state.errors" class="invalid-login">
                                <div *ngIf="c.state.errors.required">State is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">City</label>
                            <select class="text-control" formControlName="city" [(ngModel)]="city" readonly [ngClass]="{ 'is-invalid': CC2_submitted && c.city.errors }">
                                <option value="" [selected]="true">Select City</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="CC2_submitted && c.city.errors" class="invalid-login">
                                <div *ngIf="c.city.errors.required">City is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Pincode</label>
                            <input type="text" class="text-control" placeholder="Enter Pincode"  formControlName="pincode" [ngClass]="{ 'is-invalid': CC2_submitted && c.pincode.errors }">
                            <div *ngIf="CC2_submitted && c.pincode.errors" class="invalid-login">
                                <div *ngIf="c.pincode.errors.required">Pincode is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Educational Qualification</label>
                            <select class="text-control" formControlName="educational_qualification" [ngClass]="{ 'is-invalid': CC2_submitted && c.educational_qualification.errors }">
                                <option value="" [selected]="true">Select Qualification</option>
                                <option value="Diploma">Diploma</option>
                                <option value="Upto 9th">Upto 9th</option>
                                <option value="10th Pass">10th Pass</option>
                                <option value="12th Pass">12th Pass</option>
                                <option value="Graduation">Graduation</option>
                                <option value="Post Graduation">Post Graduation</option>
                            </select>
                            <div *ngIf="CC2_submitted && c.educational_qualification.errors" class="invalid-login">
                                <div *ngIf="c.educational_qualification.errors.required">Educational Qualification is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">Professional Degree (If Any)</label>
                            <input type="file" class="text-control" formControlName="professional_degree" (change)="uploadFile($event)">
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">Counselling Related to</label>
                            <select class="text-control" formControlName="counselling_related" [ngClass]="{ 'is-invalid': CC2_submitted && c.counselling_related.errors }">
                                <option value="" [selected]="true">Select Counselling</option>
                                <option value="Education">Education</option>
                                <option value="Career">Career</option>
                                <option value="Jobs">Jobs</option>
                            </select>
                            <div *ngIf="CC2_submitted && c.counselling_related.errors" class="invalid-login">
                                <div *ngIf="c.counselling_related.errors.required">Counselling is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">Full Detail</label>
                            <textarea rows="2" cols="4" class="text-control" formControlName="detail" [ngClass]="{ 'is-invalid': CC2_submitted && c.detail.errors }">
                            </textarea>
                            <div *ngIf="CC2_submitted && c.detail.errors" class="invalid-login">
                                <div *ngIf="c.detail.errors.required">Full Detail is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <label class="label-control"><input type="checkbox" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': CC2_submitted && c.acceptTerms.errors }"> &nbsp;&nbsp; I agree to the Terms and Conditions</label>
                            <div *ngIf="CC2_submitted && c.acceptTerms.errors" class="invalid-login">Acceptance is required</div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button type="submit" class="btn btn-dark">Submit Now</button>
                        </div>
                    </div>
                </form>

                <div *ngIf="loading" class="loading-preloader">
                    <div class="loadin-inner">
                        <i class="fas fa-spinner fa-spin"></i>
                        <h3>Please Wait... While We uploading your files.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>