<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>हमारी दृष्टि</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a [routerLink]="['']">मुख्य पृष्ठ</a></li>
                        </ol>
                      </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="vission-page-section">
    <div class="container">
        <!-- <div class="row">
            <div class="col-sm-12">
                <h3 class="vision-h3">{{VisionArray.heading}}</h3>
                <h2 class="vision-h2">{{VisionArray.subheading}}</h2>
            </div>
        </div> -->
        <div class="row">
            <div class="col-sm-8">
                <div class="info" [innerHTML]="VisionArray.content1">
                </div>
            </div>
            <div class="col-sm-4 align-self-center">
                <div class="vision-img">
                    <img src="{{imageurl}}{{VisionArray.image1}}" class="img-fluid">
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-4 align-self-center">
                <div class="vision-img">
                    <img src="{{imageurl}}{{VisionArray.image2}}" class="img-fluid">
                </div>
            </div>
            <div class="col-sm-8">
                <div class="info" [innerHTML]="VisionArray.content2">
                    
                </div>
            </div>
        </div>

        <hr />

        <div class="row justify-content-center">
            <div class="col-sm-4">
                <div class="content-v-box">
                    <i class="{{VisionArray.box1}}"></i>
                    <h3 [innerHTML]="VisionArray.box1_text"></h3>
                    <p [innerHTML]="VisionArray.box1_content"></p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="content-v-box">
                    <i class="{{VisionArray.box2}}"></i>
                    <h3 [innerHTML]="VisionArray.box2_text"></h3>
                    <p [innerHTML]="VisionArray.box2_content"></p>    
                </div>
            </div>
            <div class="col-sm-4">
                <div class="content-v-box">
                    <i class="{{VisionArray.box3}}"></i>
                    <h3 [innerHTML]="VisionArray.box3_text"></h3>
                    <p [innerHTML]="VisionArray.box3_content"></p>    
                </div>
            </div>
        </div>
    </div>
    <br>
</section>