<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumec.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>विस्तार शिविर</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">विस्तार शिविर</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <app-user-sidebar></app-user-sidebar>
            </div>
            <div class="col-sm-9">
                <div class="main-area-dash">
                    <!-- <h3 class="head-tit">विस्तार शिविर</h3> -->
                    <div class="profile-tabs">
                        <ul class="nav nav-tabs">
                            <li><a class="active" data-toggle="tab" href="#new-submission">नई सबमिशन</a></li>
                            <li><a data-toggle="tab" href="#submission-history">प्रस्तुत इतिहास</a></li>
                        </ul>

                        <div class="tab-content">
                            <div id="new-submission" class="tab-pane fade in active show">
                                <form [formGroup]="ECForm" (ngSubmit)="ECSubmit()">
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label class="label-control">Are you a citizen of India?</label><br>
                                            <label><input type="radio" value="yes" formControlName="citizen_india"> Yes</label> &nbsp;&nbsp;
                                            <label><input type="radio" value="no" formControlName="citizen_india"> No</label> &nbsp;&nbsp;
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <p><b>Information about the recruitment processes of Indian Defense Services and the selection procedures of competitive examinations, brokerage, bribery, rigging, rigging and paper leaking -</b></p>
                                        </div>
                                        <div class="col-sm-4">
                                            <label class="label-control">Other Information</label>
                                            <textarea cols="6" rows="3" formControlName="other_information" class="text-control" [ngClass]="{ 'is-invalid': EC_submitted && ec.other_information.errors }" placeholder="Name Address Mobile Number Posts are involved in which work and other related information"></textarea>
                                            <div *ngIf="EC_submitted && ec.other_information.errors" class="invalid-login">
                                                <div *ngIf="ec.other_information.errors.required">Other Information is required</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label class="label-control">Upload audio video recording</label>
                                            <input type="file" class="text-control" formControlName="video" (change)="uploadVideo($event)" [ngClass]="{ 'is-invalid': EC_submitted && ec.video.errors }">
                                            <div *ngIf="EC_submitted && ec.video.errors" class="invalid-login">
                                                <div *ngIf="ec.video.errors.required">Recording is required</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <label class="label-control">Cutting of News related published in newspapers</label>
                                            <input type="file" class="text-control"  formControlName="image" (change)="uploadImage($event)" [ngClass]="{ 'is-invalid': EC_submitted && ec.image.errors }">
                                            <div *ngIf="EC_submitted && ec.image.errors" class="invalid-login">
                                                <div *ngIf="ec.image.errors.required">News Cutting is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <div class="table-responsive">
                                                <table class="table tab-questn table-bordered">
                                                    <tr>
                                                        <td>Do you want your name to be kept confidential? </td>
                                                        <td><label><input type="radio" formControlName="confidential_name" value="yes"> Yes</label> &nbsp;&nbsp;<label><input type="radio" formControlName="confidential_name" value="no"> No</label> &nbsp;&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>If any information is required, can you be contacted?</td>
                                                        <td><label><input type="radio" formControlName="information_connected" value="yes"> Yes </label> &nbsp;&nbsp;<label><input type="radio" formControlName="information_connected" value="no"> No </label> &nbsp;&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Do you want that the information, evidence and evidence given by you can be uploaded in the media gallery and audio video gallery of the busted revolution?</td>
                                                        <td><label><input type="radio" formControlName="media_gallery" value="yes"> Yes </label> &nbsp;&nbsp;<label><input type="radio" formControlName="media_gallery" value="no"> No </label> &nbsp;&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Do you want to take legal action against them, for this information, evidence and evidence given by you can be used as per the requirement?</td>
                                                        <td><label><input type="radio" formControlName="legal_action" value="yes"> Yes </label> &nbsp;&nbsp;<label><input type="radio" formControlName="legal_action" value="no"> No </label> &nbsp;&nbsp;</td>
                                                    </tr>
                                                </table>
                                            </div>   
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <div class="col-sm-12">
                                            <label class="label-control"><input type="checkbox" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': EC_submitted && ec.acceptTerms.errors }"> &nbsp;&nbsp; I declare that I am a citizen of India and I am voluntarily giving my contribution and cooperation in the bustling revolution and busting campaign. The information presented by me here is absolutely true.</label>
                                            <div *ngIf="EC_submitted && ec.acceptTerms.errors" class="invalid-login">Acceptance is required</div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <div class="col-sm-12 text-center">
                                            <button type="submit" class="btn btn-dark">Submit Now</button>
                                        </div>
                                    </div>
                                </form>
                
                                <div *ngIf="loading" class="loading-preloader">
                                    <div class="loadin-inner">
                                        <i class="fas fa-spinner fa-spin"></i>
                                        <h3>Please Wait... While We uploading your files.</h3>
                                    </div>
                                </div>
                            </div>
                            <div id="submission-history" class="tab-pane fade">
                                <div class="dashboard-area">
                                    <div class="table-responsive">
										<table class="table rl-table">
											<thead>
												<tr>
													<th>ज़मा करने की तिथि</th>
                                                    <th>आईडी</th>
                                                    <th>अन्य सूचना</th>
                                                    <th>गुप्त</th>
                                                    <th>कार्य</th>
												</tr>
											</thead>
											<tbody>
                                                <tr *ngFor="let item of ExposeSummaryArray">
                                                    <td>{{item.created_at}}</td>
                                                    <td>{{item.id}}</td>
                                                    <td>{{item.other_information}}</td>
                                                    <td>{{item.confidential_name}}</td>
                                                    <td><a [routerLink]="[ '/user-expose-campaign-chats', item.id]" class="btn-view"><i class="fas fa-comment"></i></a></td>
                                                </tr>
                                            </tbody>
										</table>
									</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>