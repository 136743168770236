import { Component, OnInit } from '@angular/core';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-membership',
  templateUrl: './user-membership.component.html',
  styleUrls: ['./user-membership.component.scss']
})
export class UserMembershipComponent implements OnInit {
  UserDetailsMArray:any=[];
  data:any=[];
  UserDetailsMHArray:any=[];
  imageurl:any;
  generalData:any=[];
  UserDetailsArray:any=[];
  constructor(private restAPI:RESTApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastrService) {
      this.imageurl = environment.imageURL;
     }

  ngOnInit(): void {
    this.fetchUserDetails();
    this.fetchGeneralSetting();
  }

  
  fetchUserDetails() {
    this.restAPI.get(environment.apiURL + environment.UserfetchDetails).subscribe(data => {
      this.data = data;
      this.UserDetailsArray = this.data.data.user;
      this.UserDetailsMArray = this.data.data.membership_detail;
      this.UserDetailsMHArray = this.data.data.membership_history;
      console.log(this.UserDetailsMHArray);
    }, err => {
      console.log(err);
    });
  }

  fetchGeneralSetting(){
    this.restAPI.get(environment.apiURL + environment.generalSettingAPI).subscribe(data =>{
      this.data = data;
      this.generalData = this.data.data.generalsetting;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }


  
}
