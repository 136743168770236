<section class="middle-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 align-self-center">
                <div class="middle-l">
                    <div class="media">
                        <i class="fas fa-phone-alt align-self-center mr-3"></i>
                        <div class="media-body">
                            <h5>संपर्क करें</h5>
                            <h3>{{generalData.helpline_number}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="row justify-content-center">
                    <div class="logo">
                        <a class="" [routerLink]="['/']">
                            <img src="{{imageurl}}{{generalData.image}}" alt="logo" class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 align-self-center">
                <div class="middle-r">
                    <ul *ngIf="LoggedIn()">
                        <li class="nav-item">
                            <div class="dropdown">
                                <button class="btn-userpro dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="{{imageurl}}{{UserDetailsArray.image}}" alt="Profile Picture"
                                        class="img-fluid">
                                    <span>{{UserDetailsArray.name}}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" [routerLink]="['/user-dashboard']"><i
                                            class="far fa-user-circle"></i> मेरा डैशबोर्ड</a>
                                    <a class="dropdown-item" [routerLink]="['/user-profile']"><i
                                            class="far fa-edit"></i> प्रोफाइल सेटिंग्स</a>
                                    <a class="dropdown-item" (click)="Logout()"><i class="fas fa-sign-out-alt"></i>
                                        लॉगआउट</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul *ngIf="!LoggedIn()">
                        <li class="active"><a href="#" data-target="#register" data-toggle="modal" (click)="toogleTag()" ><i
                                    class="fas fa-sign-in-alt"></i> लॉग इन करें</a></li>
                        <li><a href="#" data-target="#register" data-toggle="modal" (click)="toogleTag2()" ><i class="fas fa-users"></i> रजिस्टर
                                करें</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade otp-modal" id="register" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-userlogin">
        <div class="modal-content">
            <div class="top-design"><img class="img-fluid" src="assets/images/top-login.png"></div>
            <div class="modal-close">
                <button class="close" data-dismiss="modal" type="button">×</button>
            </div>
            <div class="modal-body" *ngIf="showMe">
                <div class="row login-heads">
                    <div class="col-sm-12">
                        <h3 class="heads-login">
                            लॉग इन करें</h3>
                        <span class="allrequired"> सभी फ़ील्ड आवश्यक हैं</span>
                    </div>
                </div>
                <form [formGroup]="LWPForm" (ngSubmit)="LWPSubmit()">
                    <div class="row form-group">
                        <div class="col-sm-12">
                            <label>पंजीकृत ईमेल दर्ज करें</label>
                            <input class="text-material" formControlName="email" type="text"
                                [ngClass]="{ 'is-invalid': lwp_submitted && lwp.email.errors }">
                            <div *ngIf="lwp_submitted && lwp.email.errors" class="invalid-login">
                                <div *ngIf="lwp.email.errors.required">ईमेल की आवश्यकता है</div>
                            </div>
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-sm-12">
                            <label>पास वर्ड दर्ज करें</label>
                            <input class="text-material" formControlName="password" type="password"
                                [ngClass]="{ 'is-invalid': lwp_submitted && lwp.password.errors }">
                            <div *ngIf="lwp_submitted && lwp.password.errors" class="invalid-login">
                                <div *ngIf="lwp.password.errors.required">पासवर्ड की आवश्यकता है</div>
                            </div>
                            <a type="button" (click)="toogleTag3()" class="forgotatag">पासवर्ड भूल गए ?</a>
                        </div>
                    </div>


                    <div class="row form-group mt-lg-10">
                        <div class="col-sm-12">
                            <button type="submit" class="btn-login">लॉग इन करें</button>
                        </div>
                    </div>
                </form>
                <div class="modal-foo">
                    <button class="btn-joinnow" (click)="toogleTag2()" >नया
                        उपयोगकर्ता ? अभी पंजीकरण करें</button>
                </div>
            </div>
            <div class="modal-content" *ngIf="showMe3">
                <div class="top-design"><img class="img-fluid" src="assets/images/top-login.png"></div>
                <div class="modal-close">
                    <button class="close" data-dismiss="modal" type="button">×</button>
                </div>
                <div class="modal-body">
                    <div class="row login-heads">
                        <div class="col-sm-12">
                            <h3 class="heads-login">पासवर्ड भूल गए</h3>
                        </div>
                    </div>
                    <form [formGroup]="ForgotForm" (ngSubmit)="ForgotSubmit()">
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <input class="text-material" formControlName="contact" [pattern]="mobNumberPattern"
                                    placeholder="मोबाइल नंबर दर्ज करें।" type="number"
                                    [ngClass]="{ 'is-invalid': forgot_submitted && r.contact.errors }">
                                <div *ngIf="forgot_submitted && f.contact.errors" class="invalid-login">
                                    <div *ngIf="f.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                    <div *ngIf="f.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group mt-lg-10">
                            <div class="col-sm-12">
                                <button type="submit" class="btn-login">ओटीपी पर आगे बढ़ें
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-foo">
                    <button class="btn-joinnow" data-dismiss="modal" data-target="#userlogin" data-toggle="modal">पहले
                        से ही
                        पंजीकृत ?
                        लॉग इन करें</button>
                </div>
            </div>

            <div class="modal-body" *ngIf="showMe2">
                <div class="row login-heads">
                    <div class="col-sm-12">
                        <h3 class="heads-login">रजिस्टर करें</h3>
                    </div>
                    <form [formGroup]="RegisterForm" (ngSubmit)="RegisterSubmit()">
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <label class="label-control">पूरा नाम<span style="color: red;">*</span></label>
                                <input class="text-material" formControlName="name" type="text"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.name.errors }">
                                <div *ngIf="register_submitted && r.name.errors" class="invalid-login">
                                    <div *ngIf="r.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <label class="label-control">ईमेल<span style="color: red;">*</span></label>
                                <input class="text-material" formControlName="email" type="text"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.email.errors }">
                                <div *ngIf="register_submitted && r.email.errors" class="invalid-login">
                                    <div *ngIf="r.email.errors.required">ईमेल की आवश्यकता है</div>
                                    <div *ngIf="r.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <label class="label-control">मोबाइल नंबर<span style="color: red;">*</span></label>
                                <input class="text-material" formControlName="contact" [pattern]="mobNumberPattern"
                                    type="number" [ngClass]="{ 'is-invalid': register_submitted && r.contact.errors }">
                                <div *ngIf="register_submitted && r.contact.errors" class="invalid-login">
                                    <div *ngIf="r.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                    <div *ngIf="r.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <p>लिंग <span style="color: red;">*</span></p>
                                <label><input type="radio" value="male" formControlName="gender"> पुरुष </label>
                                &nbsp;&nbsp;
                                <label><input type="radio" value="female" formControlName="gender"> स्त्री</label>
                            </div>
                            <div class="col-sm-6">
                                <p>क्या आप भारत के नागरिक हैं ? <span style="color: red;">*</span></p>
                                <label><input type="radio" value="yes" formControlName="is_indian_citizen"> हां </label>
                                &nbsp;&nbsp;
                                <label><input type="radio" value="no" formControlName="is_indian_citizen"> नहीं</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">व्हाट्सएप नंबर <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="whatsapp_number"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.whatsapp_number.errors }">
                                <div *ngIf="register_submitted && r.whatsapp_number.errors" class="invalid-login">
                                    <div *ngIf="r.whatsapp_number.errors.required">WhatsApp No is required</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">आवास का पता <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="address"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.address.errors }">
                                <div *ngIf="register_submitted && r.address.errors" class="invalid-login">
                                    <div *ngIf="r.address.errors.required">Home Address is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <input class="text-material" formControlName="password" [(ngModel)]="pass"
                                    placeholder="Password" type="password"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.password.errors }">
                                <password-strength-meter [password]="pass" [minPasswordLength]="8"
                                    [colors]="['darkred', 'orangered', 'orange']"></password-strength-meter>
                                <small id="passwordHelpBlock" class="form-text text-muted">
                                    Your password must contain at least one uppercase, one special char and atleast 8
                                    characters
                                </small>
                                <div *ngIf="register_submitted && r.password.errors" class="invalid-login">
                                    <div *ngIf="r.password.errors.required">पासवर्ड की आवश्यकता है</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <select class="select-material" formControlName="state" (change)="fetchCity($event)"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.state.errors }">
                                    <option value="" [selected]="true">राज्य चुनें</option>
                                    <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                                </select>
                                <div *ngIf="register_submitted && r.state.errors" class="invalid-login">
                                    <div *ngIf="r.state.errors.required">State is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <select class="select-material" formControlName="city"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.city.errors }">
                                    <option value="" [selected]="true">जिला चुनें</option>
                                    <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                                </select>
                                <div *ngIf="register_submitted && r.city.errors" class="invalid-login">
                                    <div *ngIf="r.city.errors.required">City is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row form-check">
                            <div class="col-sm-12">
                                <input class="form-check-input" formControlName="acceptTerms" id="acceptTerms"
                                    type="checkbox"
                                    [ngClass]="{ 'is-invalid': register_submitted && r.acceptTerms.errors }">
                                <label class="form-check-label" for="acceptTerms"> I Accept <a>Terms &amp;
                                        Conditions</a></label>
                                <div *ngIf="register_submitted && r.acceptTerms.errors" class="invalid-login">
                                    <div *ngIf="r.acceptTerms.errors.required">Acceptance is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group mt-lg-10">
                            <div class="col-sm-12">
                                <button type="submit" class="btn-login">रजिस्टर करें</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-foo">
                    <button class="btn-joinnow" (click)="toogleTag()">
                        पहले से ही खाता है ? लॉग इन करें</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade otp-modal" id="user-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="ROTPForm" (ngSubmit)="ROTPSubmit()">
                        <div class="form-group row" style="display: none;">
                            <div class="col-sm-12">
                                <label class="label-control">
                                    मोबाइल नंबर</label>
                                <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                    formControlName="contact">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <div class="head-main-otp">
                                    <h3>
                                        कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                    <p>
                                        एक ओटीपी भेजा गया है{{mob_no}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row justify-content-center">
                            <div class="col-sm-6">
                                <label class="label-control">6
                                    अंक ओटीपी</label>
                                <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                    formControlName="otp" [ngClass]="{ 'is-invalid': rotp_submitted && ro.otp.errors }">
                                <div *ngIf="rotp_submitted && ro.contact.errors" class="invalid-login">
                                    <div *ngIf="ro.otp.errors.required">OTP is required</div>
                                    <div *ngIf="ro.otp.errors.pattern">OTP should be 6 digit</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12 text-center">
                                <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <form [formGroup]="RROTPForm" (ngSubmit)="RROTPSubmit()">
                        <div class="form-group row" style="display: none;">
                            <div class="col-sm-6">
                                <label class="label-control">मोबाइल नंबर</label>
                                <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                    formControlName="contact">
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-8 text-center">
                                <span class="wish-otp">
                                    यदि आपको ओटीपी प्राप्त नहीं हुआ है, तो आप 30 सेकंड के बाद एक नए ओटीपी का अनुरोध कर
                                    सकते
                                    हैं</span><br>
                            </div>
                            <div class="col-sm-4 text-center align-self-center">
                                <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">
                                    ओटीपी पुनः भेजें</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="userlogin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-userlogin">
            <div class="modal-content">
                <div class="top-design"><img class="img-fluid" src="assets/images/top-login.png"></div>
                <div class="modal-close">
                    <button class="close" data-dismiss="modal" type="button">×</button>
                </div>
                <div class="modal-body">
                    <div class="row login-heads">
                        <div class="col-sm-12">
                            <h3 class="heads-login">
                                लॉग इन करें</h3>
                            <span class="allrequired">
                                सभी फ़ील्ड आवश्यक हैं</span>
                        </div>
                    </div>
                    <form [formGroup]="LWPForm" (ngSubmit)="LWPSubmit()">
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <input class="text-material" formControlName="email"
                                    placeholder="पंजीकृत ईमेल दर्ज करें" type="text"
                                    [ngClass]="{ 'is-invalid': lwp_submitted && lwp.email.errors }">
                                <div *ngIf="lwp_submitted && lwp.email.errors" class="invalid-login">
                                    <div *ngIf="lwp.email.errors.required">ईमेल की आवश्यकता है</div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group">
                            <div class="col-sm-12">
                                <input class="text-material" formControlName="password" placeholder="
                                पास वर्ड दर्ज करें" type="password"
                                    [ngClass]="{ 'is-invalid': lwp_submitted && lwp.password.errors }">
                                <div *ngIf="lwp_submitted && lwp.password.errors" class="invalid-login">
                                    <div *ngIf="lwp.password.errors.required">पासवर्ड की आवश्यकता है</div>
                                </div>
                                <a href="#" class="forgotatag" data-dismiss="modal" data-target="#forgot-password"
                                    data-toggle="modal">पासवर्ड भूल गए ?</a>
                            </div>
                        </div>


                        <div class="row form-group mt-lg-10">
                            <div class="col-sm-12">
                                <button type="submit" class="btn-login">लॉग इन करें</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-foo">
                    <button class="btn-joinnow" data-dismiss="modal" data-target="#register" data-toggle="modal">नया
                        उपयोगकर्ता ? अभी पंजीकरण करें</button>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="modal fade" id="register" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-userlogin">
            <div class="modal-content">
                <div class="top-design"><img class="img-fluid" src="assets/images/top-login.png"></div>
                <div class="modal-close">
                    <button class="close" data-dismiss="modal" type="button">×</button>
                </div>
                <div class="modal-body">
                    <div class="row login-heads">
                        <div class="col-sm-12">
                            <h3 class="heads-login">पासवर्ड भूल गए</h3>
                        </div>
                    </div>
                    <form [formGroup]="ForgotForm" (ngSubmit)="ForgotSubmit()">
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <input class="text-material" formControlName="contact" [pattern]="mobNumberPattern"
                                    placeholder="मोबाइल नंबर दर्ज करें।" type="number"
                                    [ngClass]="{ 'is-invalid': forgot_submitted && r.contact.errors }">
                                <div *ngIf="forgot_submitted && f.contact.errors" class="invalid-login">
                                    <div *ngIf="f.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                    <div *ngIf="f.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                </div>
                            </div>
                        </div>

                        <div class="row form-group mt-lg-10">
                            <div class="col-sm-12">
                                <button type="submit" class="btn-login">ओटीपी पर आगे बढ़ें
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-foo">
                    <button class="btn-joinnow" data-dismiss="modal" data-target="#userlogin" data-toggle="modal">पहले
                        से ही
                        पंजीकृत ?
                        लॉग इन करें</button>
                </div>
            </div>
        </div>
    </div> -->


    <div class="modal fade otp-modal" id="fuser-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form [formGroup]="ForgotOTPForm" (ngSubmit)="ForgotOTPSubmit()">
                        <div class="form-group row" style="display: none;">
                            <div class="col-sm-12">
                                <label class="label-control">मोबाइल नंबर
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                    formControlName="contact">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <div class="head-main-otp">
                                    <h3>
                                        कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                    <p>एक ओटीपी भेजा गया है {{mob_no}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row justify-content-center">
                            <div class="col-sm-6">
                                <label class="label-control">6
                                    अंक ओटीपी</label>
                                <input type="number" class="form-control" [pattern]="OTPPattern" formControlName="otp"
                                    [ngClass]="{ 'is-invalid': fotp_submitted && ro.otp.errors }">
                                <div *ngIf="fotp_submitted && fo.contact.errors" class="invalid-login">
                                    <div *ngIf="fo.otp.errors.required">OTP is required</div>
                                    <div *ngIf="fo.otp.errors.pattern">OTP should be 6 digit</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-6">
                                <label class="label-control">
                                    नया पासवर्ड</label>
                                <input type="password" class="form-control" formControlName="password"
                                    [ngClass]="{ 'is-invalid': fotp_submitted && fo.password.errors }">
                                <div *ngIf="fotp_submitted && fo.password.errors" class="invalid-login">
                                    <div *ngIf="fo.password.errors.required">पासवर्ड की आवश्यकता है</div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="label-control">
                                    पासवर्ड फिर से दर्ज करें</label>
                                <input type="password" class="form-control" formControlName="confirm_pass"
                                    [ngClass]="{ 'is-invalid': fotp_submitted && fo.confirm_pass.errors }">
                                <div *ngIf="fotp_submitted && fo.confirm_pass.errors" class="invalid-login">
                                    <div *ngIf="fo.confirm_pass.errors.required">Re-enter पासवर्ड की आवश्यकता है</div>
                                    <div *ngIf="fo.confirm_pass.errors.mustMatch">Password doesn't matched</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-12 text-center">
                                <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <form [formGroup]="ForgotROTPForm" (ngSubmit)="ForgotROTPSubmit()">
                        <div class="form-group row" style="display: none;">
                            <div class="col-sm-6">
                                <label class="label-control">मोबाइल नंबर
                                </label>
                                <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                    formControlName="contact">
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-8 text-center">
                                <span class="wish-otp">
                                    यदि आपको ओटीपी प्राप्त नहीं हुआ है, तो आप 30 सेकंड के बाद एक नए ओटीपी का अनुरोध कर
                                    सकते</span><br>
                            </div>
                            <div class="col-sm-4 text-center align-self-center">
                                <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">
                                    ओटीपी पुनः भेजें</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>