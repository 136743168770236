<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>संगठन में शामिल हों फॉर्म</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">संगठन में शामिल हों फॉर्म</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="membership-form">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <h3>संगठन में शामिल हों</h3>
                        <p>अर दी इस इस भारत की सबसे तेजी से बढ़ती राजनीतिक पार्टी है। आज एक सदस्य के रूप में शामिल हों और भारत में राजनीति के भविष्य के पाठ्यक्रम को आकार देने में मदद करें।</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <form [formGroup]="Membership3Form" (ngSubmit)="Membership3Submit()">
              <div class="form-group row">
                <div class="col-sm-12">
                  <h3 class="title-doc">Documentation</h3>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4" style="display: none;">
                  <label class="label-control">Mobile No.</label>
                  <input type="text" class="text-control" placeholder="Enter Mobile No." disabled formControlName="contact" [(ngModel)]="contact">
                  <div *ngIf="membership_submitted && m.contact.errors" class="invalid-login">
                    <div *ngIf="m.contact.errors.required">मोबाइल नंबर चाहिए</div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="label-control">Profile Photo</label>
                  <input type="file" class="text-control" formControlName="image" (change)="uploadImage($event)" [ngClass]="{ 'is-invalid': membership_submitted && m.image.errors }">
                  <div *ngIf="membership_submitted && m.image.errors" class="invalid-login">
                    <div *ngIf="m.image.errors.required">Profile Photo is required</div>
                  </div>
                </div>
                <div class="col-sm-4" style="display: none;">
                  <label class="label-control">OTP</label>
                  <input type="text" class="text-control" placeholder="Enter otp" readonly formControlName="otp" [(ngModel)]="otp">
                  <div *ngIf="membership_submitted && m.otp.errors" class="invalid-login">
                    <div *ngIf="m.otp.errors.required">OTP is required</div>
                  </div>
                </div>
              </div>


              <div class="form-group row">
                <div class="col-sm-4">
                  <label class="label-control">Photo ID Type</label>
                  <select class="text-control" formControlName="photo_id_type"  [ngClass]="{ 'is-invalid': membership_submitted && m.photo_id_type.errors }">
                    <option value="" selected>Select Type</option>
                    <option value="1">Aadhar Card</option>
                    <option value="2">Voter ID</option>
                    <option value="3">PAN Card</option>
                  </select>
                  <div *ngIf="membership_submitted && m.photo_id_type.errors" class="invalid-login">
                    <div *ngIf="m.photo_id_type.errors.required">Photo ID Type is required</div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <label class="label-control">ID Number</label>
                  <input type="text" class="text-control" placeholder="Enter ID Number" formControlName="id_number" [ngClass]="{ 'is-invalid': membership_submitted && m.id_number.errors }">
                  <div *ngIf="membership_submitted && m.id_number.errors" class="invalid-login">
                    <div *ngIf="m.id_number.errors.required">ID Number is required</div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <label class="label-control">ID Document</label>
                  <input type="file" class="text-control" formControlName="document1" (change)="uploadDoc1($event)" [ngClass]="{ 'is-invalid': membership_submitted && m.document1.errors }">
                  <div *ngIf="membership_submitted && m.document1.errors" class="invalid-login">
                    <div *ngIf="m.document1.errors.required">ID Document is required</div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-4">
                  <label class="label-control">Address Proof Type</label>
                  <select class="text-control" formControlName="address_type"  [ngClass]="{ 'is-invalid': membership_submitted && m.address_type.errors }">
                    <option value="" selected>Select Type</option>
                    <option value="1">Aadhar Card</option>
                    <option value="2">Electricity Bill</option>
                    <option value="3">Voter ID</option>
                  </select>
                  <div *ngIf="membership_submitted && m.address_type.errors" class="invalid-login">
                    <div *ngIf="m.address_type.errors.required">Address Proof Type is required</div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <label class="label-control">Address ID Number</label>
                  <input type="text" class="text-control" placeholder="Enter ID Number" formControlName="address_id_number" [ngClass]="{ 'is-invalid': membership_submitted && m.address_id_number.errors }">
                  <div *ngIf="membership_submitted && m.address_id_number.errors" class="invalid-login">
                    <div *ngIf="m.address_id_number.errors.required">Address ID Number is required</div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <label class="label-control">Address ID Document</label>
                  <input type="file" class="text-control" formControlName="document2" (change)="uploadDoc2($event)" [ngClass]="{ 'is-invalid': membership_submitted && m.document2.errors }">
                  <div *ngIf="membership_submitted && m.document2.errors" class="invalid-login">
                    <div *ngIf="m.document2.errors.required">Address Document is required</div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label class="label-control">Would you like to tell us more about yourself?</label>
                  <textarea class="text-control" cols="6" rows="3" formControlName="about_yourself" placeholder="Write about yourself"></textarea>
                </div>
                <div *ngIf="membership_submitted && m.about_yourself.errors" class="invalid-login">
                  <div *ngIf="m.about_yourself.errors.required">About Yourself is required</div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <h3 class="title-doc">Choose Membership</h3>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <div class="package-list">
                    <ul class="pack-ul">
                      <li *ngFor="let item of MemPackagesArray">
                        <label>
                            <h3><input type="radio" [ngClass]="{ 'is-invalid': membership_submitted && m.package_id.errors }" formControlName="package_id" value="{{item.package_id}}"> <span>{{item.name}} - {{item.validity}} Days at <i class="fas fa-rupee-sign"></i>{{item.price}} /-</span></h3>
                            <p [innerHTML]="item.features"></p>
                        </label>
                      </li>
                    </ul>
                    <div *ngIf="membership_submitted && m.package_id.errors" class="invalid-login">
                      <div *ngIf="m.package_id.errors.required">Choose Membership is required</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 text-center">
                  <label class="label-control"><input type="checkbox" formControlName="acceptTerms"> &nbsp;&nbsp; I am above 18 years , and not enrolled as a government employee. I am not a member of any other political party registered with the Election Commission of India. I am not a member with any organization whose views, policies or actions are in conflict with the objective of the party. I have not been convicted of any offense involving moral turpitude. I hereby consent to receiving any communication from the party either in writing, electronically and/or in any audio-visual format via phone (including SMS/MMS), email and/or at my address.</label>
                  <div *ngIf="membership_submitted && m.acceptTerms.errors" class="invalid-login">
                    <div *ngIf="m.acceptTerms.errors.required">Acceptance is required</div>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 text-center">
                  <button type="submit" class="btn btn-dark">Submit Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
</section>

<div *ngIf="loading" class="loading-preloader">
    <div class="loadin-inner">
        <i class="fas fa-spinner fa-spin"></i>
        <h3>Please Wait... While We uploading your files.</h3>
    </div>
</div>
