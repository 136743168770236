import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Data } from 'src/app/models/data';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { MustMatch } from '../../_helpers/mustmatch.validator';
import { ConfirmedValidator } from 'src/app/_helpers/confirmed.validators';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
declare var jQuery: any;
@Component({
  selector: 'app-busted-revolution',
  templateUrl: './busted-revolution.component.html',
  styleUrls: ['./busted-revolution.component.scss']
})
export class BustedRevolutionComponent implements OnInit {
  message: any;
  password: any;
  password_confirmation: any;
  NewUserFrom: FormGroup;
  newuser_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup; 
  showMe: boolean= true;
  showMe2: boolean= true;
  showMe3: boolean= false;
  showMe4: boolean= true;
  showMe5: boolean= false;
  countryArray: any = [];
  enva:any;
  pass: any;
  motp_submitted = false;
  email2: any; 
  full_name: any;
  full_name2: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  imageurl:any;
  BustedRForm: FormGroup;
  BOTPForm:FormGroup;
  BRROTPForm:FormGroup;
  BustedR_submitted = false;
  botp_submitted = false;
  disableBtn = false;
  statesArray:any=[];
  OTPVForm: FormGroup;
  otpverify_submitted = false;
  data:any=[];
  BoxesContentArray:any=[];
  data1:Data;
  data2:Data;
  mob_no:any;
  mob_no2:any;
  BustedRArray:any=[];
  cityArray:any=[];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";   
  email: string; name: string;state: string;city: string;otp:any;contact:number;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService, private auth:AuthenticationService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.BustedRForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: MustMatch('password', 'password_confirmation')
    });

    this.NewUserFrom = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      pancard: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: [''],
      otp: ['', Validators.required]
    });
    this.registrationForm = this.formBuilder.group({
      name: [''],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
    })

    this.BOTPForm = this.formBuilder.group({
      contact: [''],
      name: ['' ],
      email: ['', []],
      otp: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    }
    );
    this.BRROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.fetchCountry();
    this.fetchBustedR();
    this.fetchboxes();
  }
  
  get b() { return this.BustedRForm.controls; }
  get mo() { return this.BOTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get lwp() { return this.LWPForm.controls; }
  get vrfy() { return this.OTPVForm.controls;}
  get new() { return this.NewUserFrom.controls; }

  BustedRSubmit(){
    this.BustedR_submitted = true;
    // stop here if form is invalid
    if (this.BustedRForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.BustedRForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.BustedRFormAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.email = this.data1.email;
        this.name = this.data1.name;
        this.state = this.data1.state;
        this.city = this.data1.city;
        console.log(this.data1);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.BustedRForm.reset();
        this.BustedR_submitted  = false;
        this.showMe = true;
        this.showMe4 = false;
        // jQuery('#bustedr-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe2 = true
    this.showMe = false
  }

  BOTPSubmit(){
    this.botp_submitted = true;
    // stop here if form is invalid
    if (this.BOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data2 = this.BOTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, this.data2).subscribe(data => {
        this.contact = this.data2.contact;
        this.otp = this.data2.otp;
        console.log(this.data2);
        this.toast.success('नंबर सत्यापित हो गया है');
        this.BOTPForm.reset();
      //  jQuery('#bustedr-verification').modal('hide');
        this.botp_submitted  = false;
        this.showMe2 = false;
        this.showMe3 = false;
        this.showMe4 = true;
        // this.router.navigate(["/thank-you"]);
        // this.router.navigate(["/busted-revolution-form", {contact: this.contact, otp: this.otp, email: this.email, name: this.name, state: this.state, city: this.city}]);
      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }

  BRROTPSubmit(){
    // stop here if form is invalid
    if (this.BRROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.BRROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  // fetchState() {
  //   this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
  //     this.data = data;
  //     this.statesArray = this.data.data.state;
  //     console.log(this.statesArray);
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  fetchCountry() {
    this.RestAPI.get(environment.apiURL + environment.countryAPI).subscribe(data => {
      this.data = data;
      this.countryArray = this.data.data.countries;
      console.log(this.countryArray);
    }, err => { 
      console.log(err);
    });
  }
  fetchState(e) {
    this.RestAPI.get(environment.apiURL + environment.stateAPI1 + e.target.value).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  fetchBustedR() {
    this.RestAPI.get(environment.apiURL + environment.BustedContentAPI).subscribe(data => {
      this.data = data;
      this.BustedRArray = this.data.data.br_content;
      console.log(this.BustedRArray);
    }, err => {
      console.log(err);
    });
  }

  fetchboxes(){
    this.RestAPI.get(environment.apiURL + environment.HomepageAPI).subscribe(data =>{
      this.data = data;
      this.BoxesContentArray = this.data.data.homepage_box;
      console.log(this.BoxesContentArray);
    }, err => {
      console.log(err);
    });
  }

  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }

  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.full_name2 = this.data1.name;
        // this.email2 = this.data1.email;
        console.log(this.mob_no);
        // this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        // 
        this.registratiopn_submitted = false;
        // this.showMe2 = false;
        this.message = "ओटीपी सफलतापूर्वक भेजा गया"
        this.showMe4 = false;
        this.showMe5 = true;
        this.showMe3 = true;
        // jQuery('#bustedr-verification').modal('show');
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit(){
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
        return;
    } else{
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data:any) => {
        if(data.success==false){
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token',data.data.token);
          this.router.navigate(["/busted-revolution"]);
          /* jQuery('#userlogin').modal('hide'); */
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें ओटीपी 6 अंकों का होना चाहिए');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        // this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.showMe2 = false;
        this.showMe3 = false;
        this.showMe4 = false;
        this.message = "नंबर सत्यापित हो गया है"
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }

  NewuserSubmit(){
    this.newuser_submitted = true;
    // stop here if form is invalid
    if (this.NewUserFrom.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.NewUserFrom.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        // this.mob_no = this.data1.contact;
        // console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.NewUserFrom.reset();
        this.newuser_submitted  = false;
        this.showMe = false;
        this.showMe4 = false;
        // jQuery('#bfi-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  clearForm() {
    this.NewUserFrom.get('email').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('address').reset();
    this.NewUserFrom.get('state').reset();
    this.NewUserFrom.get('is_indian_citizen').reset();
    this.NewUserFrom.get('gender').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('whatsapp_number').reset();
    this.NewUserFrom.get('alternate_mobile_number').reset();
    this.NewUserFrom.get('city').reset();
    this.NewUserFrom.get('country').reset();
    this.NewUserFrom.get('tehsil').reset();
    this.NewUserFrom.get('password_confirmation').reset();
    this.NewUserFrom.get('pancard').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }

}
