import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-expose-campaign-chats',
  templateUrl: './user-expose-campaign-chats.component.html',
  styleUrls: ['./user-expose-campaign-chats.component.scss']
})
export class UserExposeCampaignChatsComponent implements OnInit {
  ECID:any;
  data:any=[];
  ECReplyForm:FormGroup;
  ECReply_submitted = false;
  ECdata:any=[];
  ECSummarydata:any=[];
  enva:any;
  imageurl : any;
  loading: boolean;
  constructor(private route: ActivatedRoute,
    private router:Router,private restAPI: RESTApiService,
    private toast: ToastrService,
    private formBuilder: FormBuilder) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
   this.router.events.subscribe((evt) => {
    if (evt instanceof NavigationEnd) {
     // trick the Router into believing it's last link wasn't previously loaded
     this.router.navigated = false;
    }
  });
 }

  ngOnInit(): void {
    this.ECReplyForm = this.formBuilder.group({
      expose_form_id : ['', [Validators.required]],
      message : ['', [Validators.required]],
      document : ['', [Validators.required]]
    });
    this.route.paramMap.subscribe(params => {
      if (params['params'].id) {
        this.ECID = params['params'].id;
        console.log(this.ECID)
      }
    });
    this.fetchECbyID();
    this.fetchECSummarybyID();
  }

  get ecr() { return this.ECReplyForm.controls; }

  uploadDoc(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.ECReplyForm.patchValue({
      document: file
    });
    this.ECReplyForm.get('document').setValue(file);
    console.log(file);
  }

  fetchECbyID(){
    this.restAPI.get(environment.apiURL + environment.UserFetchECChatsAPI + this.ECID).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.ECdata = this.data.data.expose_reply;
      console.log(this.ECdata);
      }, err =>{
        console.log(err);
      });
  }

  fetchECSummarybyID(){
    this.restAPI.get(environment.apiURL + environment.UserECChatSummaryAPI + this.ECID).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.ECSummarydata = this.data.data.expose_reply[0];
      console.log(this.ECSummarydata);
      }, err =>{
        console.log(err);
      });
  }

  ECReplySubmit(){
    this.ECReply_submitted = true;
    // stop here if form is invalid
    if (this.ECReplyForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("expose_form_id", this.ECReplyForm.get('expose_form_id').value);
      formData.append("document", this.ECReplyForm.get('document').value);
      formData.append("message", this.ECReplyForm.get('message').value);
      this.loading = true; // Add this line
      //let data = this.BR2Form.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserECReplyFormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success("Thanks for your reply!");
        this.ECReply_submitted  = false;
        this.loading = false; // Add this line
        this.fetchECbyID();
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }

}
