import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  imageurl:any;
  data:any=[];
  enva:any;
  TNCArray:any=[];
  constructor(private restAPI: RESTApiService) {
    this.imageurl = environment.imageURL;
    this.enva = environment.assetsURL;
  }

  ngOnInit(): void {
    this.fetchTNC();
  }

  fetchTNC(){
    this.restAPI.get(environment.apiURL + environment.TNCAPI).subscribe(data =>{
      this.data = data;
      this.TNCArray = this.data.data.terms_and_condition;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }
}
