<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>सदस्यता फॉर्म</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">सदस्यता फॉर्म</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="membership-form">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <!-- <h3>सदस्यता बने</h3> -->
                        <p>आर दी इस इस भारत की सबसे तेजी से बढ़ती राजनीतिक पार्टी है। आज एक सदस्य के रूप में शामिल हों और भारत में राजनीति के भविष्य के पाठ्यक्रम को आकार देने में मदद करें</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <form [formGroup]="UserMembershipForm" (ngSubmit)="UserMembershipSubmit()">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Photo ID Type</label>
                            <select class="text-control" formControlName="photo_id_type"  [ngClass]="{ 'is-invalid': membership_submitted && m.photo_id_type.errors }">
                                <option value="" selected>Select Type</option>
                                <option value="1">Aadhar Card</option>
                                <option value="2">Voter ID</option>
                                <option value="3">PAN Card</option>
                            </select>
                            <div *ngIf="membership_submitted && m.photo_id_type.errors" class="invalid-login">
                                <div *ngIf="m.photo_id_type.errors.required">Photo ID Type is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">ID Number</label>
                            <input type="text" class="text-control" placeholder="Enter ID Number" formControlName="id_number" [ngClass]="{ 'is-invalid': membership_submitted && m.id_number.errors }">
                            <div *ngIf="membership_submitted && m.id_number.errors" class="invalid-login">
                                <div *ngIf="m.id_number.errors.required">ID Number is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">ID Document</label>
                            <input type="file" class="text-control" formControlName="document1" (change)="uploadDoc1($event)" [ngClass]="{ 'is-invalid': membership_submitted && m.document1.errors }">
                            <div *ngIf="membership_submitted && m.document1.errors" class="invalid-login">
                                <div *ngIf="m.document1.errors.required">ID Document is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Address Proof Type</label>
                            <select class="text-control" formControlName="address_type"  [ngClass]="{ 'is-invalid': membership_submitted && m.address_type.errors }">
                                <option value="" selected>Select Type</option>
                                <option value="1">Aadhar Card</option>
                                <option value="2">Electricity Bill</option>
                                <option value="3">Voter ID</option>
                            </select>
                            <div *ngIf="membership_submitted && m.address_type.errors" class="invalid-login">
                                <div *ngIf="m.address_type.errors.required">Address Proof Type is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">Address ID Number</label>
                            <input type="text" class="text-control" placeholder="Enter ID Number" formControlName="address_id_number" [ngClass]="{ 'is-invalid': membership_submitted && m.address_id_number.errors }">
                            <div *ngIf="membership_submitted && m.address_id_number.errors" class="invalid-login">
                                <div *ngIf="m.address_id_number.errors.required">Address ID Number is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">Address ID Document</label>
                            <input type="file" class="text-control" formControlName="document2" (change)="uploadDoc2($event)" [ngClass]="{ 'is-invalid': membership_submitted && m.document2.errors }">
                            <div *ngIf="membership_submitted && m.document2.errors" class="invalid-login">
                                <div *ngIf="m.document2.errors.required">Address Document is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Profile Photo</label>
                            <input type="file" class="text-control" formControlName="image" (change)="uploadImage($event)" [ngClass]="{ 'is-invalid': membership_submitted && m.image.errors }">
                            <div *ngIf="membership_submitted && m.image.errors" class="invalid-login">
                                <div *ngIf="m.image.errors.required">Profile Photo is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Would you like to volunteer?</label><br>
                            <label><input type="radio" formControlName="volunteer" value="yes"> Yes &nbsp;&nbsp;</label>
                            <label><input type="radio" formControlName="volunteer" value="no"> No &nbsp;&nbsp;</label>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">How would you like to contribute?</label>
                            <br>
                            <div class="contribution-checboxes">
                                <ul>
                                    <li><label><input type="checkbox" formControlName="contribution" value="1"> Work in your community/mohalla</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="2"> Join calling campaigns</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="3"> IT support (coding, web design etc.)</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="4"> Provide housing to volunteers</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="5"> Policy/Research support</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="6"> Become a digital activist</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="7"> Attend party events</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="8"> Become a photo/video volunteer</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="9"> Content writing (mention preferred language below)</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="10"> Just a Membership</label></li>
                                    <li><label><input type="checkbox" formControlName="contribution" value="11"> Other</label></li>
                                </ul>
                                <div *ngIf="membership_submitted && m.contribution.errors" class="invalid-login">
                                    <div *ngIf="m.contribution.errors.required">Atleast 1 Contribuion is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">Would you like to tell us more about yourself?</label>
                            <textarea class="text-control" cols="6" rows="3" formControlName="about_yourself" placeholder="Write about yourself"></textarea>
                        </div> 
                        <div *ngIf="membership_submitted && m.about_yourself.errors" class="invalid-login">
                            <div *ngIf="m.about_yourself.errors.required">About Yourself is required</div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <label class="label-control"><input type="checkbox" formControlName="acceptTerms"> &nbsp;&nbsp; I am above 18 years , and not enrolled as a government employee. I am not a member of any other political party registered with the Election Commission of India. I am not a member with any organization whose views, policies or actions are in conflict with the objective of the party. I have not been convicted of any offense involving moral turpitude. I hereby consent to receiving any communication from the party either in writing, electronically and/or in any audio-visual format via phone (including SMS/MMS), email and/or at my address.</label>
                            <div *ngIf="membership_submitted && m.acceptTerms.errors" class="invalid-login">
                                <div *ngIf="m.acceptTerms.errors.required">Acceptance is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button type="submit" class="btn btn-primary">Submit Now</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div *ngIf="loading" class="loading-preloader">
    <div class="loadin-inner">
        <i class="fas fa-spinner fa-spin"></i>
        <h3>Please Wait... While We uploading your files.</h3>
    </div>
</div>
