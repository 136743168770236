import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Data } from 'src/app/models/data';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
declare var jQuery: any;
@Component({
  selector: 'app-careercounselling-filter',
  templateUrl: './careercounselling-filter.component.html',
  styleUrls: ['./careercounselling-filter.component.scss']
})
export class CareercounsellingFilterComponent implements OnInit {
  enva:any;
  imageurl:any;
  CCSlug:any;
  statesArray:any=[];cityArray:any=[];
  data:any=[];
  CareerCatArray:any=[];
  data1:Data;
  data2:Data;
  disableBtn = false;
  CareerCArray:any=[];
  CareerCForm: FormGroup;
  COTPForm:FormGroup;
  CROTPForm: FormGroup;
  CareerC_submitted = false;
  cotp_submitted = false;
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";   
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  mob_no:number;email: string; name: string;state: string;city: string;otp:any;contact:number; //For Data
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) {
      this.enva = environment.assetsURL;
      this.imageurl = environment.imageURL;
     }


     ngOnInit(): void {
      this.route.paramMap.subscribe(params => {
        if (params['params'].slug) {
          this.CCSlug = params['params'].slug;
          console.log(this.CCSlug)
        }
      });
  
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
     }
    
     this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
           // trick the Router into believing it's last link wasn't previously loaded
           this.router.navigated = false;
        }
      });

      this.CareerCForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        contact: ['', [Validators.required]],
        state: ['', [Validators.required]],
        city: ['', [Validators.required]]
      });
  
      this.COTPForm = this.formBuilder.group({
        contact: ['', Validators.required],
        otp: ['', Validators.required]
      });
  
      this.CROTPForm = this.formBuilder.group({
        contact: ['', Validators.required]
      });
      this.fetchCareerC();
      this.fetchState();
      this.fetchCareerCategory();
    }
  
    get c() { return this.CareerCForm.controls; }
    get co() { return this.COTPForm.controls; }
  
    fetchCareerC() {
      this.RestAPI.get(environment.apiURL + environment.CareerCFilterByCatAPI + this.CCSlug).subscribe(data => {
        this.data = data;
        this.CareerCArray = this.data.data.career_counselling;
        console.log(this.CareerCArray);
      }, err => {
        console.log(err);
      });
    }
  
    fetchCareerCategory() {
      this.RestAPI.get(environment.apiURL + environment.CareerCatAPI).subscribe(data => {
        this.data = data;
        this.CareerCatArray = this.data.data[0];
        console.log(this.CareerCatArray);
      }, err => {
        console.log(err);
      });
    }
  
    CareerCSubmit(){
      this.CareerC_submitted = true;
      // stop here if form is invalid
      if (this.CareerCForm.invalid) {
        this.toast.error('कृपया सभी फ़ील्ड जांचें');
          return;
      }else{
        this.data1 = this.CareerCForm.getRawValue();
        this.RestAPI.post(environment.apiURL + environment.CareerCFormAPI, this.data1).subscribe(data => {
          this.mob_no = this.data1.contact;
          this.email = this.data1.email;
          this.name = this.data1.name;
          this.state = this.data1.state;
          this.city = this.data1.city;
          console.log(this.data1);
          this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
          this.CareerCForm.reset();
          this.CareerC_submitted  = false;
          jQuery('#career-verification').modal('show');
          setTimeout(()=>{  
            this.disableBtn = true;
          }, 30000); 
        }, err => {
          this.toast.error(err.error.error);
          console.log(err);
        });
      }
    }
  
    COTPSubmit(){
      this.cotp_submitted = true;
      // stop here if form is invalid
      if (this.COTPForm.invalid) {
        this.toast.error('कृपया सभी फ़ील्ड जांचें');
          return;
      }else{
        this.data2 = this.COTPForm.getRawValue();
        this.RestAPI.post(environment.apiURL + environment.CareerCOTPFormAPI, this.data2).subscribe(data => {
          this.contact = this.data2.contact;
          this.otp = this.data2.otp;
          console.log(this.data2);
          this.toast.success('Proceed to Next Step');
          this.COTPForm.reset();
         jQuery('#career-verification').modal('hide');
          this.cotp_submitted  = false;
          this.router.navigate(["/career-counselling-form", {contact: this.contact, otp: this.otp, email: this.email, name: this.name, state: this.state, city: this.city}]);
        }, err => {
          this.toast.error(err.error.error);
          console.log(err);
        });
      }
    }
  
    CROTPSubmit(){
      // stop here if form is invalid
      if (this.CROTPForm.invalid) {
        this.toast.error('कृपया सभी फ़ील्ड जांचें');
          return;
      }else{
        let data = this.CROTPForm.getRawValue();
        this.RestAPI.post(environment.apiURL + environment.CareerCROTPFORMAPI, data).subscribe(data => {
          console.log(data);
          this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
          this.disableBtn = false;
          setTimeout(()=>{  
            this.disableBtn = true;
          }, 30000);
        }, err => {
          this.toast.error(err.error.error);
          console.log(err);
        });
      }
    }
  
    fetchState() {
      this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
        this.data = data;
        this.statesArray = this.data.data.state;
        console.log(this.statesArray);
      }, err => {
        console.log(err);
      });
    }
  
    onChange(val) {
      console.log(val);
      this.router.navigate(["/career-counselling-by-filter", val]);
    }

    clearfilter() {
      this.router.navigate(["/career-counselling"]);
    }
  
    fetchCity(e) {
      console.log(e);
      this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
        this.data = data;
        this.cityArray = this.data.data.city;
        console.log(this.cityArray);
      }, err => {
        console.log(err);
      });
    }

    LoggedIn() {
      return localStorage.getItem('token')!= null;
    }
}
