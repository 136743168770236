<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>संगठन संस्थापक</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="founder-page-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="media">
                    <div class="profile">
                        <img src="{{imageurl}}{{FounderArray.image}}" alt="{{FounderArray.title}}" class="img-fluid">
                        <h6 style="font-weight: bold;">{{FounderArray.title}}</h6>
                        <small style="font-weight: bold;" >{{FounderArray.designation}}</small>
                    </div>
                    <div class="media-body">
                        <h4>{{FounderArray.content_title}}</h4>
                        <div [innerHTML]="FounderArray.content"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

