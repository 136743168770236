<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>पर्दाफाश अभियान</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">पर्दाफाश अभियान</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="exposec-detail-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="exposec-div">
                    <div class="exposec-img">
                        <img src="{{imageurl}}{{ExposeCDetailArray.image}}" (error) ="onImgError($event)" class="img-fluid">
                    </div>
                    
                    <div class="exposec-content">
                        <h3>{{ExposeCDetailArray.title}}</h3>
                        <hr />
                        <div [innerHTML]="ExposeCDetailArray.description">
                            
                        </div>
                    </div>

                    <div class="exposec-img-gal">
                        <h4><span>इमेज गैलरी</span></h4>
                        <div class="row">
                            <div class="col-sm-3 col-4 col-md-3" *ngFor="let item of ExposeCImagesArray">
                                <a [routerLink]="['']" class="thumbnail">
                                    <img src="{{imageurl}}{{item.busted_image}}" title="{{item.image_title}}" alt="{{item.image_title}}" class="img-fluid">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="exposec-vdo-gal">
                        <h4><span>वीडियो गैलरी</span></h4>
                        <div class="row">
                            <div class="col-sm-4 col-4 col-md-4" *ngFor="let item of ExposeCImagesArray">
                                <youtube-player class="ytply" videoId="{{item.video_url}}" suggestedQuality="highres" [height]="200" [width]="250"></youtube-player>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-4">
                <div class="exposec-sidebar">
                    <div class="exposec-sidebar-widgets">
                        <div class="exposec-detail-wrap">
                            <div class="exposec-detail-wrap-header">
                                <h4>रिलेटेड पोस्ट्स</h4>
                            </div>
                            <div class="exposec-detail-wrap-body">
                                <ul class="exposec-post">
                                    <li *ngFor="let item of ExposeCArray | slice:0:3">
                                        <div class="exposec-li">
                                            <div class="exposec-img">
                                                <a [routerLink]="['/expose-campaign-detail', item.slug]"><img src="{{imageurl}}{{item.image}}" class="img-fluid" (error) ="onImgError($event)" alt="{{item.title}}"></a>
                                            </div>
                                            <div class="exposec-cont">
                                                <h6><a [routerLink]="['/expose-campaign-detail', item.slug]">{{item.title}}</a></h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>