import { Component, OnInit } from '@angular/core';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-updates',
  templateUrl: './user-updates.component.html',
  styleUrls: ['./user-updates.component.scss']
})
export class UserUpdatesComponent implements OnInit {
  UserUpdatesMArray:any=[];
  data:any=[];
  imageurl:any;
  UserUpdatesBFIArray:any=[];
  UserUpdatesJobArray:any=[];
  UserUpdatesBustedArray:any=[];
  UserUpdatesUSArray:any=[];
  UserUpdatesOArray:any=[];
  UserUpdatesEArray:any=[];
  UserUpdatesCCArray:any=[];
  enva:any;
  UserUpdatesMoveArray:any=[];
  constructor(private restAPI:RESTApiService,
    private router: Router) {
      this.imageurl = environment.imageURL;
      this.enva = environment.assetsURL;
     }

  ngOnInit(): void {
    this.fetchUserUpdates();
  }

  fetchUserUpdates() {
    this.restAPI.get(environment.apiURL + environment.UserLatestUpdatesAPI).subscribe(data => {
      this.data = data;
      this.UserUpdatesMArray = this.data.data.mission;
      this.UserUpdatesOArray = this.data.data.objective_updates;
      this.UserUpdatesMoveArray = this.data.data.movement_updates;      
      this.UserUpdatesBFIArray = this.data.data.bfi_updates;
      this.UserUpdatesBustedArray = this.data.data.busted_updates;
      this.UserUpdatesEArray = this.data.data.expose_updates;
      this.UserUpdatesCCArray = this.data.data.career_updates;
      this.UserUpdatesJobArray = this.data.data.job_updates;
      this.UserUpdatesUSArray = this.data.data.user_sub;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
