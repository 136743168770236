<section class="top-section">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-sm-3">
        <div class="top-nav-left">
          <ul class="list-unstyled">
            <li><a href="https://www.rdssindia.org/english/" appExternalUrl><img src="/assets/images/usa-flag.jpg"
                  class="img-fluid"> English</a></li>
            <li><a href="https://www.rdssindia.org/" class="active" appExternalUrl><img
                  src="/assets/images/india-flag.jpg" class="img-fluid"> हिन्दी</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-9">
        <div class="top-nav">
          <ul class="list-unstyled">
            <li><a [routerLink]="['/jobs']">जॉब्स</a></li>
            <li><a [routerLink]="['/news']">समाचार</a></li>
            <li><a [routerLink]="['/blog']">ब्लॉग</a></li>
            <li><a href="#" data-target="#join-volunteer" data-toggle="modal">वालंटियर बने</a></li>
            <li><a [routerLink]="['/membership']" *ngIf="!LoggedIn()">संगठन में शामिल हों</a></li>
            <li><a [routerLink]="['/suggest-india']">सजेस्ट इंडिया</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown-about" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                जॉब करियर
              </a>

              <div class="dropdown-menu" aria-labelledby="navbarDropdown-about">
                <a class="dropdown-item" [routerLink]="['/jobs']">जॉब ढूंढे</a>
                <a class="dropdown-item" [routerLink]="['/career-counselling']">जॉब काउंसलिंग</a>
                <a class="dropdown-item" [routerLink]="['/jobs']">जॉब की सुचना</a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/']">हमारे उत्पाद</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdown-contact" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">संपर्क करें
              </a>

              <div class="dropdown-menu" aria-labelledby="navbarDropdown-contact">
                <a class="dropdown-item" [routerLink]="['/contact-us']">संपर्क करें</a>
                <a class="dropdown-item" [routerLink]="['/locate-us']">हमारी शाखाएँ</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>