<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumc.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>जॉब काउंसलिंग</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">जॉब काउंसलिंग</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="career-counselling-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <!-- <h3>जॉब काउंसलिंग</h3> -->
                        <p>आरडीएसएस इंडिया भारत के हर युवा को आत्म निर्भर बनाने के लिए अत्यधिक प्रेरित है। हम यहां
                            उन्हें उचित कैरियर मार्गदर्शन के साथ-साथ नौकरी शिकार में मदद करने के लिए हैं। हमारे
                            प्रशिक्षण कार्यक्रम निश्चित रूप से युवाओं को उनकी इच्छा के किसी भी अवसर के लिए प्रेरित
                            करेंगे।
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-7">
                <div class="cc-list-filters">
                    <h3 class="showing-result-h">सभी सामान्य प्रश्न
                        <div class="sortby-listing">
                            <label class="label-sort">केटेगरी :</label>
                            <select class="form-sorting" (change)="onChange($event.target.value)">
                                <option value="" selected="true">सेलेक्ट केटेगरी </option>
                                <option *ngFor="let item of CareerCatArray" value="{{item.slug}}">{{item.name}}</option>
                            </select>
                        </div>
                    </h3>
                </div>
                <div class="accordion" id="faqExample">
                    <div class="card" *ngFor="let item of CareerCArray let i = index">
                        <div class="card-header p-2" id="heading{{i}}">
                            <h5 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse"
                                    [attr.data-target]="'#collapse'+ i" aria-expanded="true"
                                    [attr.aria-controls]="'#collapse'+ i">
                                    {{item.question}} - <span class="category-name">{{item.category_name}}</span>
                                </button>
                            </h5>
                        </div>

                        <div id="collapse{{i}}" class="collapse" [ngClass]="{'show' : i == 0}"
                            [attr.aria-labelledby]="'heading'+ i" data-parent="#faqExample">
                            <div class="card-body">
                                {{item.answer}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-5">
                <div class="careerc-sidebar">
                    <div class="form-careerc" *ngIf="!LoggedIn()">
                        <h3 class="classtitle">एक्सपर्ट से फ्री काउंसलिंग करवाएं</h3>
                        <!-- <form [formGroup]="LWPForm" (ngSubmit)="LWPSubmit()" *ngIf="showMe" class="text-center"
                            style="background: #f2f2f2; padding: 30px 14px">
                            <div class="row form-group">
                                <div class="col-sm-12 ">
                                    <label>पंजीकृत ईमेल / मोबाइल नंबर</label>
                                    <br>
                                    <input class="text-material" formControlName="email" type="text"
                                        [ngClass]="{ 'is-invalid': lwp_submitted && lwp.email.errors }">
                                    <div *ngIf="lwp_submitted && lwp.email.errors" class="invalid-login">
                                        <div *ngIf="lwp.email.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-sm-12">
                                    <label>पास वर्ड</label>
                                    <br>
                                    <input class="text-material" formControlName="password" type="password"
                                        [ngClass]="{ 'is-invalid': lwp_submitted && lwp.password.errors }">
                                    <div *ngIf="lwp_submitted && lwp.password.errors" class="invalid-login">
                                        <div *ngIf="lwp.password.errors.required">पासवर्ड की आवश्यकता है</div>
                                    </div>
                                    <br>
                                    <a href="#" class="forgotatag" data-dismiss="modal" data-target="#forgot-password"
                                        data-toggle="modal">पासवर्ड भूल गए ?</a>
                                </div>
                            </div>


                            <div class="row form-group mt-lg-10">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn-login">लॉग इन करें</button>
                                </div>
                            </div>
                            <div class="modal-foo">
                                <a type="button" (click)="toogleTag()" class="forgotatag">नया उपयोगकर्ता ? अभी
                                    पंजीकरण करें</a>
                            </div>
                        </form>
                        <form [formGroup]="registrationForm" style="background: #f2f2f2; padding: 30px 14px"
                            (ngSubmit)="registrationSubmit()" *ngIf="showMe4" class="text-center">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                  registratiopn_submitted && r.name.errors
                          }">
                                    <div *ngIf="registratiopn_submitted &&
                      r.name.errors" class="invalid-login">
                                        <div *ngIf="r.name.errors.required">पूरा
                                            नाम आवश्यक है</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">मोबाइल नंबर
                                        <span style="color: red;">*</span></label>
                                    <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                        formControlName="contact" [ngClass]="{ 'is-invalid':
                          registratiopn_submitted &&
                          r.contact.errors }" maxlength="10">
                                    <div *ngIf="registratiopn_submitted &&
                          r.contact.errors" class="invalid-login">
                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                            नंबर आवश्यक है</div>
                                        <div *ngIf="r.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-dark" type="submit" style="margin-top: 25px;">अपना नंबर
                                        सत्यापित करें
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form [formGroup]="OTPVForm" style="background: #f2f2f2; padding: 30px 14px" (ngSubmit)="OTPVerifySubmit()" *ngIf="showMe3" class="text-center">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input [(ngModel)]="full_name2"  type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                                    otpverify_submitted && vrfy.name.errors
                                            }">
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control" >
                                        मोबाइल नंबर <span style="color: red;">*</span></label>
                                        <br>
                                    <input type="number" [(ngModel)]="mob_no" class="text-control" formControlName="contact" [ngClass]="{ 'is-invalid': otpverify_submitted && vrfy.contact.errors }">            
                                </div>      
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">6
                                        अंक ओटीपी <span style="color: red;">*</span></label>
                                    <input type="number" class="form-control"
                                        placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp"
                                        [ngClass]="{ 'is-invalid':
                                        otpverify_submitted && vrfy.otp.errors }">
                                    <div *ngIf="otpverify_submitted && vrfy.contact.errors" class="invalid-login">
                                        <div *ngIf="vrfy.otp.errors.required">ओटीपी आवश्यक है</div>
                                        <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-dark" type="submit"
                                        style="margin-top: 28px;">ओटीपी सत्यापित करें
                                    </button>
                                </div>
                            </div>
                        </form> -->
                        <form [formGroup]="CareerCForm" (ngSubmit)="CareerCSubmit()">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control"
                                        formControlName="name"
                                        [ngClass]="{ 'is-invalid': CareerC_submitted && c.name.errors }">
                                    <div *ngIf="CareerC_submitted && c.name.errors" class="invalid-login">
                                        <div *ngIf="c.name.errors.required">पूरा नाम की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">ईमेल<span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="email"
                                        [ngClass]="{ 'is-invalid': CareerC_submitted && c.email.errors }">
                                    <div *ngIf="CareerC_submitted && c.email.errors" class="invalid-login">
                                        <div *ngIf="c.email.errors.required">ईमेल की आवश्यकता है</div>
                                        <div *ngIf="c.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">मोबाइल नंबर<span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" 
                                        [pattern]="mobNumberPattern" formControlName="contact"
                                        [ngClass]="{ 'is-invalid': CareerC_submitted && c.contact.errors }">
                                    <div *ngIf="CareerC_submitted && c.contact.errors" class="invalid-login">
                                        <div *ngIf="c.contact.errors.required">मोबाइल नंबर की आवश्यकता है</div>
                                        <div *ngIf="c.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">राज्य</label>
                                    <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                        [ngClass]="{ 'is-invalid': CareerC_submitted && c.state.errors }">
                                        <option value="" [selected]="true">राज्य चुनें</option>
                                        <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="CareerC_submitted && c.state.errors" class="invalid-login">
                                        <div *ngIf="c.state.errors.required">जिला की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">जिला</label>
                                    <select class="form-control" formControlName="city"
                                        [ngClass]="{ 'is-invalid': CareerC_submitted && c.city.errors }">
                                        <option value="" [selected]="true">जिला चुनें</option>
                                        <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="CareerC_submitted && c.city.errors" class="invalid-login">
                                        <div *ngIf="c.city.errors.required">जिला की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-dark" type="submit">ओटीपी पर आगे बढ़ें
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal fade otp-modal" id="career-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="COTPForm" (ngSubmit)="COTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                <p>एक ओटीपी भेजा गया है {{mob_no}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 अंकों का ओटीपी</label>
                            <input type="number" class="form-control" [pattern]="OTPPattern" formControlName="otp"
                                [ngClass]="{ 'is-invalid': cotp_submitted && co.otp.errors }">
                            <div *ngIf="cotp_submitted && co.otp.errors" class="invalid-login">
                                <div *ngIf="co.otp.errors.required">OTP is required</div>
                                <div *ngIf="co.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <form [formGroup]="CROTPForm" (ngSubmit)="CROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" [(ngModel)]="mob_no" readonly
                                formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">यदि आपको ओटीपी प्राप्त नहीं हुआ है, तो आप 30 सेकंड के बाद एक नए ओटीपी
                                का अनुरोध कर सकते हैं</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">ओटीपी पुनः
                                भेजें</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>