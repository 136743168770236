import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-gallery-andolan',
  templateUrl: './gallery-andolan.component.html',
  styleUrls: ['./gallery-andolan.component.scss']
})
export class GalleryAndolanComponent implements OnInit {
  imageurl:any;
  data:any=[];
  ImageGalleryArray:any=[];

  constructor(private restAPI: RESTApiService) { }

  ngOnInit(): void {
    this.imageurl = environment.imageURL;
    this.fetchVideos();
  }
  fetchVideos(){
    this.restAPI.get(environment.apiURL + environment.VideosAndolan).subscribe(data =>{
      this.data = data;
      this.ImageGalleryArray = this.data.data.video;
    }, err => {
      console.log(err);
    });
  }
}
