<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url();background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>View Gallery</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/view-gallery-and-letter']">Back</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">View Gallery</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid">
    <div class="logo">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/left logo.png" alt="..." class="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/right logo.png" alt="..." class="float-right">
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</div>

<div class="view-gallery-and-letter-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <h3  style="color: #f58634;">View Gallery <img src="assets/images/right logo.png" class="img-thumbnail"
                            style="max-width: 7%; border: 0px solid;">
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <table class="center">
                    <thead>
                        <tr>
                            <!-- <th>S.No</th>
                            <th>State </th>
                            <th>District</th>
                            <th>City</th>
                            <th>No of Letters</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="row img" *ngFor="let item of RecordData">
                            <td>
                                 <img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid">
                                <div class="text-center">
                                    <small>{{item.first_name}} {{item.last_name}}</small>
                                    <!-- <p>{{item.image}}</p> -->
                                    <p>{{item.city}}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row img">
            <!-- <div class="col-sm-3 col-6 col-md-3 col-lg-3" *ngFor="let item of ImageGalleryArray">
                <div class="gallery-main">
                    <a class="thumbnail">
                        <img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid">
                        <div class="text-right">
                            <small class="text-muted">{{item.title}}</small>
                        </div>
                    </a>
                </div>
            </div> -->
            <!-- <div class="col-sm-1"></div>
           <div class="col-sm-2">
            <img  src="https://th.bing.com/th/id/OIP.Vd4kaVvCkO9E6Ez898hnHwHaFO?pid=ImgDet&rs=1" class="img-fluid" alt="Responsive image">
            <p style="text-align: center;">Name <br> City <br> State </p>
           </div>
           <div class="col-sm-2 center">
            <img  src="https://th.bing.com/th/id/OIP.Vd4kaVvCkO9E6Ez898hnHwHaFO?pid=ImgDet&rs=1" class="img-fluid" alt="Responsive image">
            <p style="text-align: center;">Name <br> City <br> State </p>
           </div>
           <div class="col-sm-2 center">
            <img  src="https://th.bing.com/th/id/OIP.Vd4kaVvCkO9E6Ez898hnHwHaFO?pid=ImgDet&rs=1" class="img-fluid" alt="Responsive image">
            <p style="text-align: center;">Name <br> City <br> State </p>
           </div>
           <div class="col-sm-2 center">
            <img  src="https://th.bing.com/th/id/OIP.Vd4kaVvCkO9E6Ez898hnHwHaFO?pid=ImgDet&rs=1" class="img-fluid" alt="Responsive image">
            <p style="text-align: center;">Name <br> City <br> State </p>
           </div>
           <div class="col-sm-2 center">
            <img src="https://visualwilderness.com/wp-content/uploads/2016/06/research-patagonia.jpg" class="img-fluid" alt="Responsive image">
            <p style="text-align: center;">Name <br> City <br> State </p>
           </div>
           <div class="col-sm-1 center">
           </div> -->
        </div>
    </div>
</div>