<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>भण्डाफोड़ क्रांति फॉर्म</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">भण्डाफोड़ क्रांति फॉर्म</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="busted-revolution-form">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <!-- <h3>भण्डाफोड़ क्रांति <img src="{{enva}}assets/images/profile/india.gif" class="img-flag"></h3> -->
                        <p>हमारे देश के युवाओं और 135 करोड़ भारतीयों को बस्टेड क्रांतियों में अपना योगदान और समर्थन देना चाहिए</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-12">
                <form [formGroup]="BR2Form" (ngSubmit)="BR2Submit()">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Name</label>
                            <input type="text" class="text-control" placeholder="Enter Name" formControlName="name" readonly [(ngModel)]="name" [ngClass]="{ 'is-invalid': BR2_submitted && b.name.errors }">
                            <div *ngIf="BR2_submitted && b.name.errors" class="invalid-login">
                                <div *ngIf="b.name.errors.required">Name is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Email</label>
                            <input type="text" class="text-control" placeholder="Enter Email" readonly [(ngModel)]="email" formControlName="email" [ngClass]="{ 'is-invalid': BR2_submitted && b.email.errors }">
                            <div *ngIf="BR2_submitted && b.email.errors" class="invalid-login">
                                <div *ngIf="b.email.errors.required">ईमेल की आवश्यकता है</div>
                                <div *ngIf="b.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Mobile No.</label>
                            <input type="text" class="text-control" placeholder="Enter Mobile No." readonly [(ngModel)]="contact" [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': BR2_submitted && b.contact.errors }">
                            <div *ngIf="BR2_submitted && b.contact.errors" class="invalid-login">
                                <div *ngIf="b.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                <div *ngIf="b.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>

                        <div class="col-sm-4" style="display: none;">
                            <label class="label-control">OTP</label>
                            <input type="text" class="text-control" placeholder="Enter OTP" readonly [(ngModel)]="otp" formControlName="otp" [ngClass]="{ 'is-invalid': BR2_submitted && b.otp.errors }">
                            <div *ngIf="BR2_submitted && b.otp.errors" class="invalid-login">
                                <div *ngIf="b.otp.errors.required">OTP is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Gender</label>
                            <select class="text-control" formControlName="gender" [ngClass]="{ 'is-invalid': BR2_submitted && b.gender.errors }">
                                <option value="" selected="true">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            <div *ngIf="BR2_submitted && b.gender.errors" class="invalid-login">
                                <div *ngIf="b.gender.errors.required">Gender is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Are you a citizen of India?</label><br>
                            <label><input type="radio" value="yes" formControlName="citizen_india"> Yes</label> &nbsp;&nbsp;
                            <label><input type="radio" value="no" formControlName="citizen_india"> No</label> &nbsp;&nbsp;
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Address</label>
                            <input type="text" class="text-control" formControlName="address" placeholder="Enter Address">
                            <div *ngIf="BR2_submitted && b.address.errors" class="invalid-login">
                                <div *ngIf="b.address.errors.required">Address is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">State</label>
                            <select class="text-control" formControlName="state" (change)="fetchCity($event)" readonly [(ngModel)]="state" [ngClass]="{ 'is-invalid': BR2_submitted && b.state.errors }">
                                <option value="" [selected]="true">Select State</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="BR2_submitted && b.state.errors" class="invalid-login">
                                <div *ngIf="b.state.errors.required">State is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">City</label>
                            <select class="text-control" formControlName="city" [(ngModel)]="city" readonly [ngClass]="{ 'is-invalid': BR2_submitted && b.city.errors }">
                                <option value="" [selected]="true">Select City</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="BR2_submitted && b.city.errors" class="invalid-login">
                                <div *ngIf="b.city.errors.required">City is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Pincode</label>
                            <input type="text" class="text-control" placeholder="Enter Pincode"  formControlName="pincode" [ngClass]="{ 'is-invalid': BR2_submitted && b.pincode.errors }">
                            <div *ngIf="BR2_submitted && b.pincode.errors" class="invalid-login">
                                <div *ngIf="b.pincode.errors.required">Pincode is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <p><b>Information about the recruitment processes of Indian Defense Services and the selection procedures of competitive examinations, brokerage, bribery, rigging, rigging and paper leaking -</b></p>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Other Information</label>
                            <textarea cols="6" rows="3" formControlName="other_information" class="text-control" [ngClass]="{ 'is-invalid': BR2_submitted && b.other_information.errors }" placeholder="Name Address Mobile Number Posts are involved in which work and other related information"></textarea>
                            <div *ngIf="BR2_submitted && b.other_information.errors" class="invalid-login">
                                <div *ngIf="b.other_information.errors.required">Other Information is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Upload audio video recording</label>
                            <input type="file" class="text-control" formControlName="video" (change)="uploadVideo($event)" [ngClass]="{ 'is-invalid': BR2_submitted && b.video.errors }">
                            <div *ngIf="BR2_submitted && b.video.errors" class="invalid-login">
                                <div *ngIf="b.video.errors.required">Recording is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Cutting of News related published in newspapers</label>
                            <input type="file" class="text-control"  formControlName="image" (change)="uploadImage($event)" [ngClass]="{ 'is-invalid': BR2_submitted && b.image.errors }">
                            <div *ngIf="BR2_submitted && b.image.errors" class="invalid-login">
                                <div *ngIf="b.image.errors.required">News Cutting is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="table-responsive">
                                <table class="table tab-questn table-bordered">
                                    <tr>
                                        <td>Do you want your name to be kept confidential? </td>
                                        <td><label><input type="radio" formControlName="confidential_name" value="yes"> Yes</label> &nbsp;&nbsp;<label><input type="radio" formControlName="confidential_name" value="no"> No</label> &nbsp;&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>If any information is required, can you be contacted?</td>
                                        <td><label><input type="radio" formControlName="information_connected" value="yes"> Yes </label> &nbsp;&nbsp;<label><input type="radio" formControlName="information_connected" value="no"> No </label> &nbsp;&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>Do you want that the information, evidence and evidence given by you can be uploaded in the media gallery and audio video gallery of the busted revolution?</td>
                                        <td><label><input type="radio" formControlName="media_gallery" value="yes"> Yes </label> &nbsp;&nbsp;<label><input type="radio" formControlName="media_gallery" value="no"> No </label> &nbsp;&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>Do you want to take legal action against them, for this information, evidence and evidence given by you can be used as per the requirement?</td>
                                        <td><label><input type="radio" formControlName="legal_action" value="yes"> Yes </label> &nbsp;&nbsp;<label><input type="radio" formControlName="legal_action" value="no"> No </label> &nbsp;&nbsp;</td>
                                    </tr>
                                </table>
                            </div>   
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control"><input type="checkbox" formControlName="acceptTerms" [ngClass]="{ 'is-invalid': BR2_submitted && b.acceptTerms.errors }"> &nbsp;&nbsp; I declare that I am a citizen of India and I am voluntarily giving my contribution and cooperation in the bustling revolution and busting campaign. The information presented by me here is absolutely true.</label>
                            <div *ngIf="BR2_submitted && b.acceptTerms.errors" class="invalid-login">Acceptance is required</div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button type="submit" class="btn btn-dark">Submit Now</button>
                        </div>
                    </div>
                </form>

                <div *ngIf="loading" class="loading-preloader">
                    <div class="loadin-inner">
                        <i class="fas fa-spinner fa-spin"></i>
                        <h3>Please Wait... While We uploading your files.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>