import { Component, OnInit } from '@angular/core';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { DownloadFileService } from 'src/app/services/download-flie.service';

@Component({
  selector: 'app-committee-structure',
  templateUrl: './committee-structure.component.html',
  styleUrls: ['./committee-structure.component.scss']
})
export class CommitteeStructureComponent implements OnInit {

  OrgStructureData:any=[];
  data:any=[];
  CommitteeArrayLv1:any=[];
  CommitteeArrayLv2:any=[];
  imageurl:any;
  enva:any;
  constructor(private restAPI: RESTApiService,  private downloadFileService:DownloadFileService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
   }

  ngOnInit(): void {
    this.fetchOrgStructure();
    this.fetchOrgCLv1();
    this.fetchOrgCLv2();
  }

  fetchOrgStructure(){
    this.restAPI.get(environment.apiURL + environment.CommitteeStructure).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.OrgStructureData = this.data.data.committe_structure;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchOrgCLv1(){
    this.restAPI.get(environment.apiURL + environment.orgNationCommAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.CommitteeArrayLv1 = this.data.data.level1;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }
  fetchOrgCLv2(){
    this.restAPI.get(environment.apiURL + environment.orgNationCommAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.CommitteeArrayLv2 = this.data.data.level2;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }
  public downloadFile():void{
    this.downloadFileService.downloadFile()
    .subscribe(response=>
      {
        let fileName=response.headers.get('letter_format')
        ?.split(';')[1].split('=')[1];
        // let blob:Blob=response.body as Blob;
        let blob = new Blob([response.body], { type: 'application/pdf' });
        let a = document.createElement('a');
        a.download="Letter.pdf";
        a.href = window.URL.createObjectURL(blob);
        a.click();
        
      }

    )
  }
}
