import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-locate-us',
  templateUrl: './locate-us.component.html',
  styleUrls: ['./locate-us.component.scss']
})
export class LocateUsComponent implements OnInit {
  LocateDArray:any=[];
  data:any=[];
  LocationArray:any=[];
  center = {lat: 26.853470, lng: 80.912758};
  constructor(private restAPI:RESTApiService) { }

  ngOnInit(): void {
    this.fetchLocateContent();
    this.fetchLocations();
  }

  fetchLocateContent(){
    this.restAPI.get(environment.apiURL + environment.contactContentAPI).subscribe(data =>{
      this.data = data;
      this.LocateDArray = this.data.data.content[0];
      console.log(this.LocateDArray);
    }, err => {
      console.log(err);
    });
  }

  fetchLocations(){
    this.restAPI.get(environment.apiURL + environment.LocateUsAPI).subscribe(data =>{
      this.data = data;
      this.LocationArray = this.data.data.locate_us;
      console.log(this.LocationArray);
    }, err => {
      console.log(err);
    });
  }

}
