<section class="sidebar-section">
    <div class="row">
        <div class="col-sm-12">
            <div class="pro-user">
                <img src="{{imageurl}}{{UserDetailsArray.image}}" alt="Profile Picture" class="img-fluid">
            </div>
            <div class="p-details">
                <h4>{{UserDetailsArray.name}}</h4>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="sidebar-menu">
                <nav class="navbar navbar-expand-md navbar-sd-sidebar">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSidebar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-mob"><i class="fas fa-bars"></i></span>
                    </button>
                    
                    <div class="collapse navbar-collapse nav-side" id="navbarSidebar">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a [routerLink]="[ '/user-dashboard' ]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" class="nav-link">डैशबोर्ड <span class="sr-only">(current)</span></a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="[ '/user-updates' ]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" class="nav-link">नवीनतम अपडेट</a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="[ '/user-profile' ]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" class="nav-link">पार्श्वचित्र समायोजन</a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="[ '/user-subscription' ]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" class="nav-link">अंशदान</a>
                            </li>
                            <li class="nav-item" *ngIf="UserDetailsArray.membership == 'yes'">
                                <a [routerLink]="[ '/user-membership' ]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" class="nav-link">सदस्यता</a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="[ '/user-busted-revolution' ]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" class="nav-link">फटी हुई क्रांति</a>
                            </li>
                            <li class="nav-item">
                                <a [routerLink]="[ '/user-expose-campaign' ]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" class="nav-link">अभियान का खुलासा</a>
                            </li>
                            <li class="nav-item">
                                <a (click)="Logout()" class="nav-link">लॉग आउट</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>        
        </div>
        <div class="col-sm-12">
            <div class="other-st-detail">
                <ul>
                    <li *ngIf="UserDetailsArray.membership_status == 'not yet'">
                        <label>आपके पास सदस्यता नहीं है</label>
                        <span><button type="button" class="btn-purchasem" data-target="#purchase-membership" data-toggle="modal">अभी खरीदो</button></span>
                    </li>
                    <li *ngIf="UserDetailsArray.membership_status == 'pending'">
                        <label>आपकी सदस्यता आवेदन प्रक्रियाधीन है, हम आपको एक पंजीकृत सदस्य बनने पर सूचित करेंगे</label>
                    </li>
                    <li *ngIf="UserDetailsArray.membership_status == 'expired'">
                        <label>आपकी सदस्यता समाप्त हो गई है</label>
                        <span><button type="button" class="btn-purchasem" data-target="#renewal-membership" data-toggle="modal">नवीनीकरण अब</button></span>
                    </li>
                    <li *ngIf="UserDetailsArray.membership == 'yes'">
                        <label>सदस्यता आईडी</label>
                        <span>{{UserDetailsMArray.membership_id}}</span>
                    </li>
                    <li>
                        <label>ईमेल</label>
                        <span>{{UserDetailsArray.email}} 
                            <i *ngIf="UserDetailsArray.email_verified == 'yes'"><i class="fas fa-check-circle" style="color: green;"></i></i>
                            <button *ngIf="UserDetailsArray.email_verified == 'no'" type="button" (click)="verifyemail()" class="btn-sm btn-emailverify btn">सत्यापित करें</button>
                        </span>
                    </li>
                    
                    <li>
                        <label>मोबाइल</label>
                        <span>{{UserDetailsArray.contact}}
                            <i *ngIf="UserDetailsArray.contact_verified == 'yes'"><i class="fas fa-check-circle" style="color: green;"></i></i>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>



<div class="modal fade show otp-modal" [style.display]="EmailVerificationModal ? 'block' : 'none'" id="emailverify" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="EVForm" (ngSubmit)="EVSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Email</label>
                            <input type="text" readonly class="form-control" formControlName="email" [(ngModel)]="UserDetailsArray.email">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>Please enter the OTP to verify your registered email</h3>
                                <p>A OTP has sent to {{UserDetailsArray.email}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label class="label-control">6 Digit OTP</label>
                            <input type="number" class="form-control" formControlName="otp" placeholder="Enter 6 digit OTP" [ngClass]="{ 'is-invalid': ev_submitted && ev.otp.errors }">
                            <div *ngIf="ev_submitted && ev.otp.errors" class="invalid-security">
                                <div *ngIf="ev.otp.errors.required">OTP is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Verify Now</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="purchase-membership" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Purchase New Membership</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4" *ngFor="let item of MemPackagesArray">
                        <div class="package-main">
                            <div class="pack-top">
                                <h3>{{item.name}}</h3>
                                <div class="price-pack">
                                    <h4><i class="fas fa-rupee-sign"></i>{{item.discount}}<span>/- {{item.validity}} Days</span></h4>
                                </div>
                                <hr />
                            </div>
                            <div class="pack-features" [innerHTML]="item.features">
                                
                            </div>
                            <div class="pack-btn">
                                <a class="btn btn-access" (click)="membershipbuy($event, item)">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="renewal-membership" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Renewal Membership</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4" *ngFor="let item of MemPackagesArray">
                        <div class="package-main">
                            <div class="pack-top">
                                <h3>{{item.name}}</h3>
                                <div class="price-pack">
                                    <h4><i class="fas fa-rupee-sign"></i>{{item.discount}}<span>/- {{item.validity}} Days</span></h4>
                                </div>
                                <hr />
                            </div>
                            <div class="pack-features" [innerHTML]="item.features">
                                
                            </div>
                            <div class="pack-btn">
                                <a class="btn btn-access" (click)="membershiprenewal($event, item)">Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

