import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from 'src/app/models/data';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { MustMatch } from '../../_helpers/mustmatch.validator';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
import { ConfirmedValidator } from 'src/app/_helpers/confirmed.validators';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { PaymentModel } from '../../models/payment.model';
// import { userInfo } from 'os';

declare var jQuery: any;

@Component({
  selector: 'app-donate-us',
  templateUrl: './donate-us.component.html',
  styles: [
    `.modal{background: rgba(0,0,0, .5)};`
  ],
  styleUrls: ['./donate-us.component.scss']
})
export class DonateUsComponent implements OnInit {
  modaldata:any = [];
  user_id:any;
  paymentModel: PaymentModel = new PaymentModel();
  public payuform: any = {};
  disablePaymentButton: boolean = true;
  cardShow: boolean = true;
  UploadSSForm: FormGroup;
  uploadss_submitted = false;
  donation_name: any;
  donation_number: any;
  donation_pancard: any;
  mob_no3: any;
  donation_email: any;
  donation_gender: any;
  donation_is_indian_citizen: any;
  donation_country: any;
  donation_state: any;
  donation_city: any;
  donation_tehsil: any;
  donation_amount: any;
  donation_amount2: any;
  donation_amount3: any;
  donation_profession: any;
  donation_address: any;
  donation_whatsapp: any;
  donation_alternate: any;
  full_name2: any;
  password: any;
  password_confirmation: any;
  NewUserFrom: FormGroup;
  newuser_submitted = false;
  showMeDonation: boolean = false;
  showMeDonationHide: boolean = true;
  ECID:any;
  // showMe: boolean= true;
  showMe2: boolean = true;
  showMe3: boolean = false;
  showMe4: boolean = true;
  showMe5: boolean = false;
  showMe6: boolean = false;
  showMe7: boolean = false;
  showMe8: boolean = false;
  showMe9: boolean = true;
  bfi_submitted = false;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  pass: any;
  email2: any;
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  enva: any;
  donate_submitted = false;
  donateForm: FormGroup;
  data1: Data;
  UserdonateForm: FormGroup;
  userdonate_submitted = false;
  BoxesContentArray: any = [];
  data: any = [];
  statesArray: any = [];
  countryArray: any = [];
  motp_submitted = false;
  disableBtn = false;
  mrotp_submitted = false;
  mob_no: any;
  mob_no2: any;
  MOTPForm: FormGroup;
  MROTPForm: FormGroup;
  cityArray: any = [];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  PanPattern = "^[A-Z0-9]{10}$";
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";
  email: string; amount: number; mobile: number; //For Data
  message: any;
  userId: string;
  strikeCheckout: any;
  constructor(private restAPI: RESTApiService,
    private formBuilder: FormBuilder, private route: ActivatedRoute,
    private router: Router,
    private toast: ToastrService, private RestAPI: RESTApiService, private auth: AuthenticationService, private http: HttpClient) {
    this.enva = environment.assetsURL;
    // this.route.params.subscribe((params) => {
    //   this.userId =params.user_id;
    // })
  }

  ngOnInit(): void {
    // this.stripePaymentGateway();
    this.fetchboxes();
    this.donateForm = this.formBuilder.group({
      // amount: ['', Validators.required],
      // donation_type: ['monthly', Validators.required],
      // name: ['', [Validators.required]],
      // email: ['', [Validators.required, Validators.email]],
      // mobile: ['', [Validators.required]],
      // state: ['', [Validators.required]],
      // city: ['', [Validators.required]],
      // send_update: ['', [Validators.required]]
      name: ['', Validators.required],
      // father_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // mobile_number_1: ['', [Validators.required]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      // business : ['', [Validators.required]],
      whatsapp_number: ['', [Validators.required]],
      // facebook_url : ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      // subject: ['', [Validators.required]],
      address: ['', [Validators.required]],
      // country: ['', [Validators.required]],
      // date : ['', [Validators.required]],
      // feedback: ['', [Validators.required]]
    });
    this.UserdonateForm = this.formBuilder.group({
      amount: ['', Validators.required],
      donation_type: ['monthly', Validators.required],
      automatic_payment: ['', Validators.required],
      no_of_month: ['', [Validators.required]],
      address: ['', [Validators.required]],
      donate_to: ['', [Validators.required]],
      pan_number: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });
    this.registrationForm = this.formBuilder.group({
      name: [''],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.MOTPForm = this.formBuilder.group({
      contact: [''],
      name: [''],
      email: ['', []],
      otp: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    }
    );

    this.MROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.NewUserFrom = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      pancard: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]],
      profession: ['', [Validators.required]],
      amount: ['0', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });

    this.UploadSSForm = this.formBuilder.group({
      payment_mode: ['0', [Validators.required]],
      payment_receipt: [''],
      user_id: [''],
      online: [''],
      name: ['', [Validators.required]],
      email: [''],
      contact: [''],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      profession: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      whatsapp_number: [''],
      alternate_mobile_number: [''],
      pancard: [''],
      amount: ['0', [Validators.required]]
    });
    this.payuform = this.formBuilder.group({
      key: ['7aylJHb4', [Validators.required]],
      amount: ['', [Validators.required]],
      txnid: ['t6svtqtjRdl4ws', [Validators.required]],
      productinfo: ['', [Validators.required]],
      email: ['', [Validators.required]],
      firstname: ['', [Validators.required]],
      surl: ['https://apiplayground-response.herokuapp.com/', [Validators.required]],
      furl: ['https://apiplayground-response.herokuapp.com/', [Validators.required]],
      hash: ['eabec285da28fd0e3054d41a4d24fe9f7599c9d0b66646f7a9984303fd6124044b6206daf831e9a8bda28a6200d318293a13d6c193109b60bd4b4f8b09c90972', [Validators.required]]
    });
    this.route.paramMap.subscribe(params => {
      if (params['params'].id) {
        this.ECID = params['params'].id;
        console.log(this.ECID)
      }
    });
    this.fetchECbyID();
    // this.fetchState();
    this.fetchCountry();
    var paymentData = {
      productinfo: "",
      txnid: "",
      amount: "",
      email: "",
      phone: "",
      lastname: "",
      firstname: "",
      surl: "", //"http://localhost:3000/payu/success"
      furl: "", //"http://localhost:3000/payu/fail"
  };
  }

  uploadIdentityCard(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.donateForm.patchValue({
      identity_card: file
    });
    this.donateForm.get('identity_card').setValue(file);
    console.log(file);
  }
  donateSubmit() {
    this.donate_submitted = true;
    // stop here if form is invalid
    if (this.donateForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.movementForm.getRawValue();
      this.data1 = this.donateForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.donateForm.reset();
        this.donate_submitted = false;
        // jQuery('#movement-verification').modal('show');
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  MOTPSubmit() {
    this.motp_submitted = true;
    // stop here if form is invalid
    if (this.MOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data1 = this.MOTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        console.log(data);
        this.toast.success('नंबर सत्यापित हो गया है');
        this.MOTPForm.reset();
        // this.router.navigate(["/thank-you"]);
        this.motp_submitted = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  MROTPSubmit() {
    this.mrotp_submitted = true;
    // stop here if form is invalid
    if (this.MROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        this.mrotp_submitted = false;
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  // donateSubmit(){
  //   this.donate_submitted = true;
  //   if (this.donateForm.invalid) {
  //     this.toast.error('कृपया सभी फ़ील्ड जांचें');
  //       return;
  //   }else{
  //     this.data1 = this.donateForm.getRawValue();
  //     this.restAPI.post(environment.apiURL + environment.DonationStep1FormAPi, this.data1).subscribe(data => {
  //       this.mobile = this.data1.mobile;
  //       this.email = this.data1.email;
  //       this.amount = this.data1.amount;
  //       console.log(this.data1);
  //       this.toast.success('Proceed to Next Step Successfully.');
  //       this.donateForm.reset();
  //       this.donate_submitted  = false;
  //       this.router.navigate(["/donate-us-step2", {mobile: this.mobile, email: this.email, amount: this.amount}]);
  //     }, err => {
  //       this.toast.error(err.error.error);
  //       console.log(err);
  //     });
  //   }
  // }

  fetchboxes() {
    this.RestAPI.get(environment.apiURL + environment.HomepageAPI).subscribe(data => {
      this.data = data;
      this.BoxesContentArray = this.data.data.homepage_box;
      console.log(this.BoxesContentArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCountry() {
    this.restAPI.get(environment.apiURL + environment.countryAPI).subscribe(data => {
      this.data = data;
      this.countryArray = this.data.data.countries;
      console.log(this.countryArray);
    }, err => {
      console.log(err);
    });
  }

  fetchState(e) {
    this.restAPI.get(environment.apiURL + environment.stateAPI1 + e.target.value).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  // fetchState() {
  //   this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
  //     this.data = data;
  //     this.statesArray = this.data.data.state;
  //     console.log(this.statesArray);
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  get d() { return this.donateForm.controls; }
  get ud() { return this.UserdonateForm.controls; }
  get mo() { return this.MOTPForm.controls; }
  get r() { return this.registrationForm.controls; }
  get vrfy() { return this.OTPVForm.controls; }
  get lwp() { return this.LWPForm.controls; }
  get new() { return this.NewUserFrom.controls; }
  get ss() { return this.UploadSSForm.controls; }

  changeAmount($event: any) {
    console.log();
  }

  LoggedIn() {
    return localStorage.getItem('token') != null;
  }

  UserdonateSubmit() {
    this.userdonate_submitted = true;
    // stop here if form is invalid
    if (this.UserdonateForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      this.data1 = this.UserdonateForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserDonationAPI, this.data1).subscribe(data => {
        console.log(this.data1);
        this.toast.success('Donation Subscription Successfully');
        this.UserdonateForm.reset();
        this.userdonate_submitted = false;
        this.router.navigate(["/donate-us"]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.full_name2 = this.data1.name;
        this.mob_no3 = this.data1.contact;
        // this.email2 = this.data1.email;
        console.log(this.mob_no);
        // this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        // 
        this.registratiopn_submitted = false;
        this.showMe2 = false;
        this.showMe3 = true;
        this.showMe4 = false;
        this.showMe5 = true;
        // jQuery('#bustedr-verification').modal('show');
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
        this.message = "ओटीपी सफलतापूर्वक भेजा गया"
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/busted-revolution"]);
          /* jQuery('#userlogin').modal('hide'); */
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें ओटीपी 6 अंकों का होना चाहिए');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        // this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.showMe3 = false;
        /* this.router.navigate(["/thank-you"]); */
        this.message = "नंबर सत्यापित हो गया है"

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }

  NewuserSubmit() {
    this.newuser_submitted = true;
    // stop here if form is invalid
    if (this.NewUserFrom.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      this.data1 = this.NewUserFrom.getRawValue();
      const userInfo = this.NewUserFrom.value;
      userInfo.id = this.userId;
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        this.donation_name = this.data1.name;
        this.donation_email = this.data1.email;
        this.donation_gender = this.data1.gender;
        this.donation_is_indian_citizen = this.data1.is_indian_citizen;
        this.donation_country = this.data1.country;
        this.donation_state = this.data1.state;
        this.donation_city = this.data1.city;
        this.donation_tehsil = this.data1.tehsil;
        this.donation_amount = this.data1.amount;
        this.donation_amount2 = this.data1.amount;
        this.donation_amount3 = this.data1.amount;
        this.donation_profession = this.data1.profession;
        this.donation_address = this.data1.address;
        this.donation_number = this.data1.contact;
        this.donation_pancard = this.data1.pancard;
        this.donation_alternate = this.data1.alternate_mobile_number;
        this.donation_whatsapp = this.data1.whatsapp_number;
        this.fetchECbyID();
        // console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.NewUserFrom.reset();
        this.newuser_submitted = false;
        this.cardShow = false;

        // jQuery('#bfi-verification').modal('show');
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
        this.showMeDonationHide = false;
        this.showMeDonation = true;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  clearForm() {
    this.NewUserFrom.get('email').reset();
    this.NewUserFrom.get('name').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('address').reset();
    this.NewUserFrom.get('state').reset();
    this.NewUserFrom.get('is_indian_citizen').reset();
    this.NewUserFrom.get('gender').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('whatsapp_number').reset();
    this.NewUserFrom.get('alternate_mobile_number').reset();
    this.NewUserFrom.get('city').reset();
    this.NewUserFrom.get('country').reset();
    this.NewUserFrom.get('tehsil').reset();
    this.NewUserFrom.get('amount').reset();
    this.NewUserFrom.get('password_confirmation').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }

  // restrictZero(event:any) {
  //   if(event.target.value.length === 0 && event.key === "0") {
  //     event.preventDefault();
  //   } 
  // }

  toggle() {
    this.showMe6 = true
  }
  toggle2() {
    this.showMe6 = false
  }
  toggle3() {
    this.showMe7 = true
    this.showMe8 = false
    this.showMe9 = true
  }
  toggle4() {
    this.showMe7 = false
    this.showMe8 = true
    this.showMe9 = false
  }

  toggle5() {
    this.showMeDonationHide = true
    this.showMeDonation = false
    this.cardShow = true
  }
  uploadImage(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.UploadSSForm.patchValue({
      payment_receipt: file
    });
    this.UploadSSForm.get('payment_receipt').setValue(file);
    console.log(file);
  }
  uploadImage2(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.UploadSSForm.patchValue({
      online: file
    });
    this.UploadSSForm.get('online').setValue(file);
    console.log(file);
  }

  UploadssSubmit() {
    this.uploadss_submitted = true;
    // if (this.UploadSSForm.invalid) {
    //   this.toast.error('कृपया फ़ील्ड जांचें');
    //   return;
    // } else {
    //   let data = this.UploadSSForm.getRawValue();
    //   this.RestAPI.post(environment.apiURL + environment.previewFromAPI, data).subscribe(data => {
    //     // var formData: any = new FormData();
    //     // const headers = new HttpHeaders();
    //     // headers.append('Content-Type', 'multipart/form-data');
    //     // headers.append('Accept', 'application/json');
    //     // formData.append("payment_mode", this.UploadSSForm.get('payment_receipt').value);
    //     // console.log(data);
    //     this.objective_id = this.data1.objective_id;
    //     this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
    //     this.UploadSSForm.reset();
    //     this.uploadss_submitted = false;

    //   }, err => {
    //     this.toast.error(err.error.error);
    //     console.log(err);
    //   });
    // }

    if (this.UploadSSForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें');
      return;
    } else {
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("payment_mode", this.UploadSSForm.get('payment_mode').value);
      formData.append("payment_receipt", this.UploadSSForm.get('payment_receipt').value);
      formData.append("online", this.UploadSSForm.get('online').value);
      formData.append("name", this.UploadSSForm.get('name').value);
      formData.append("address", this.UploadSSForm.get('address').value);
      formData.append("country", this.UploadSSForm.get('country').value);
      formData.append("state", this.UploadSSForm.get('state').value);
      formData.append("city", this.UploadSSForm.get('city').value);
      formData.append("tehsil", this.UploadSSForm.get('tehsil').value);
      formData.append("profession", this.UploadSSForm.get('profession').value);
      formData.append("amount", this.UploadSSForm.get('amount').value);
      formData.append("pancard", this.UploadSSForm.get('pancard').value);
      formData.append("user_id", this.UploadSSForm.get('user_id').value);
      this.restAPI.post(environment.apiURL + environment.previewFromAPI, formData).subscribe(data => {
        console.log(data);
        // this.user_id = this.data1.user_id;
        this.toast.success('सफलतापूर्वक जमा। धन्यवाद');
        this.UploadSSForm.reset();
        this.uploadss_submitted = false;
        this.showMeDonationHide = true
        this.showMeDonation = false
        this.cardShow = true
        this.fetchECbyID();
        this.router.navigate(["/thank-you-donation"]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  UploadssSubmit2() {
    this.uploadss_submitted = true;
    // if (this.UploadSSForm.invalid) {
    //   this.toast.error('कृपया फ़ील्ड जांचें');
    //   return;
    // } else {
    //   let data = this.UploadSSForm.getRawValue();
    //   this.RestAPI.post(environment.apiURL + environment.previewFromAPI, data).subscribe(data => {
    //     // var formData: any = new FormData();
    //     // const headers = new HttpHeaders();
    //     // headers.append('Content-Type', 'multipart/form-data');
    //     // headers.append('Accept', 'application/json');
    //     // formData.append("payment_mode", this.UploadSSForm.get('payment_receipt').value);
    //     // console.log(data);
    //     this.objective_id = this.data1.objective_id;
    //     this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
    //     this.UploadSSForm.reset();
    //     this.uploadss_submitted = false;

    //   }, err => {
    //     this.toast.error(err.error.error);
    //     console.log(err);
    //   });
    // }

    if (this.UploadSSForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें');
      return;
    } else {
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("payment_mode", this.UploadSSForm.get('payment_mode').value);
      formData.append("payment_receipt", this.UploadSSForm.get('payment_receipt').value);
      formData.append("online", this.UploadSSForm.get('online').value);
      formData.append("name", this.UploadSSForm.get('name').value);
      formData.append("address", this.UploadSSForm.get('address').value);
      formData.append("country", this.UploadSSForm.get('country').value);
      formData.append("state", this.UploadSSForm.get('state').value);
      formData.append("city", this.UploadSSForm.get('city').value);
      formData.append("tehsil", this.UploadSSForm.get('tehsil').value);
      formData.append("profession", this.UploadSSForm.get('profession').value);
      formData.append("amount", this.UploadSSForm.get('amount').value);
      formData.append("pancard", this.UploadSSForm.get('pancard').value);
      formData.append("user_id", this.UploadSSForm.get('user_id').value);
      this.restAPI.post(environment.apiURL + environment.previewFromAPI, formData).subscribe(data => {
        console.log(data);
        // this.user_id = this.data1.user_id;
        this.toast.success('सफलतापूर्वक जमा। धन्यवाद');
        this.UploadSSForm.reset();
        // this.router.navigate(["https://pmny.in/JIvnCRJBcXqs"]);
        window.open('https://pmny.in/JIvnCRJBcXqs', '_blank');
        this.uploadss_submitted = false;
        this.showMeDonationHide = true
        this.showMeDonation = false
        this.donation_amount3 = this.data1.amount;
        this.cardShow = true
        this.fetchECbyID();
        
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  // confirmPayment() {
  //   const paymentPayload = {
  //     amount: this.payuform.amount,
  //     key: this.payuform.key,
  //     txnid: this.payuform.txnid,
  //     productinfo:this.payuform.productinfo,
  //     email:this.payuform.email,
  //     firstname: this.payuform.firstname,
  //     surl: this.payuform.surl,
  //     furl: this.payuform.furl,
  //     hash:this.payuform.hash
  //   }
  //   this.data = this.payuform.getRawValue();
  //   return this.http.post<any>('http://localhost:4200/payment/payment-details', paymentPayload).subscribe(
  //     data => {
  //     console.log(data);
  //     this.payuform.txnid = data.txnId;
  //     this.payuform.surl = data.sUrl;
  //     this.payuform.furl = data.fUrl;
  //     this.payuform.key = data.key;
  //     this.payuform.hash = data.hash;
  //     this.payuform.txnid = data.txnId;
  //       this.disablePaymentButton = false;
  //   }, error1 => {
  //       console.log(error1);
  //     })
  // }

  fetchECbyID(){
    this.restAPI.get(environment.apiURL + environment.previewFromAPI + this.ECID).subscribe(data =>{
      this.data = data;
      this.userId = this.data.data.user;
      console.log(this.userId);
      }, err =>{
        console.log(err);
      });
  }

  // checkout(amount) {
  //   const strikeCheckout = (<any>window).StripeCheckout.configure({
  //     key: 'pk_test_12239293949ksdfksdjkfj1232q3jkjssdfjk',
  //     locale: 'auto',
  //     token: function (stripeToken: any) {
  //       console.log(stripeToken)
  //       alert('Stripe token generated!');
  //     }
  //   });
  // }
  // stripePaymentGateway() {
  //   if(!window.document.getElementById('stripe-script')) {
  //     const scr = window.document.createElement("script");
  //     scr.id = "stripe-script";
  //     scr.type = "text/javascript";
  //     scr.src = "https://checkout.stripe.com/checkout.js";

  //     scr.onload = () => {
  //       this.strikeCheckout = (<any>window).StripeCheckout.configure({
  //         key: 'pk_test_12239293949ksdfksdjkfj1232q3jkjssdfjk',
  //         locale: 'auto',
  //         token: function (token: any) {
  //           console.log(token)
  //           alert('Payment via stripe successfull!');
  //         }
  //       });
  //     }
        
  //     window.document.body.appendChild(scr);
  //   }
  // }

}
