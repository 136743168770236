<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumc.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>जॉब काउंसलिंग</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">जॉब काउंसलिंग</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="career-counselling-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <!-- <h3>जॉब काउंसलिंग</h3> -->
                        <p>आरडीएसएस इंडिया भारत के हर युवा को आत्म निर्भर बनाने के लिए अत्यधिक प्रेरित है। हम यहां उन्हें उचित कैरियर मार्गदर्शन के साथ-साथ नौकरी शिकार में मदद करने के लिए हैं। हमारे प्रशिक्षण कार्यक्रम निश्चित रूप से युवाओं को उनकी इच्छा के किसी भी अवसर के लिए प्रेरित करेंगे।
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-7 col-md-7 col-lg-8">
                <div class="cc-list-filters">
                    <h3 class="showing-result-h">सामान्य प्रश्न - {{CCSlug}}
                        <div class="sortby-listing">
                            <button class="btn btn-dark btn-sm" (click)="clearfilter()" type="button">Clear Filter</button>
                        </div>
                        <div class="sortby-listing">
                            <label class="label-sort">केटेगरी:</label>
                            <select class="form-sorting" (change)="onChange($event.target.value)">
                                <option value="" selected="true">सेलेक्ट केटेगरी</option>
                                <option *ngFor="let item of CareerCatArray" value="{{item.slug}}">{{item.name}}</option>
                            </select>
                        </div>
                    </h3>
                </div>
                <div class="accordion" id="faqExample">
                    <div class="card" *ngFor="let item of CareerCArray let i = index">
                        <div class="card-header p-2" id="heading{{i}}">
                            <h5 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" [attr.data-target]="'#collapse'+ i"  aria-expanded="true" [attr.aria-controls]="'#collapse'+ i">
                                    {{item.question}} - <span class="category-name">{{item.category_name}}</span>
                                </button>
                            </h5>
                        </div>
                        
                        <div id="collapse{{i}}" class="collapse" [ngClass]="{'show' : i == 0}" [attr.aria-labelledby]="'heading'+ i" data-parent="#faqExample">
                            <div class="card-body">
                                {{item.answer}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-5 col-md-5 col-lg-4">
                <div class="careerc-sidebar">
                    <div class="form-careerc" *ngIf="!LoggedIn()">
                        <h3 class="classtitle">एक्सपर्ट से फ्री काउंसलिंग करवाएं</h3>
                        <form [formGroup]="CareerCForm" (ngSubmit)="CareerCSubmit()">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" [ngClass]="{ 'is-invalid': CareerC_submitted && c.name.errors }">
                                    <div *ngIf="CareerC_submitted && c.name.errors" class="invalid-login">
                                        <div *ngIf="c.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">Email Id</label>
                                    <input type="text" class="form-control" placeholder="Enter Email Id" formControlName="email" [ngClass]="{ 'is-invalid': CareerC_submitted && c.email.errors }">
                                    <div *ngIf="CareerC_submitted && c.email.errors" class="invalid-login">
                                        <div *ngIf="c.email.errors.required">ईमेल की आवश्यकता है</div>
                                        <div *ngIf="c.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">Mobile No.</label>
                                    <input type="text" class="form-control" placeholder="Enter Mobile No." [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': CareerC_submitted && c.contact.errors }">
                                    <div *ngIf="CareerC_submitted && c.contact.errors" class="invalid-login">
                                        <div *ngIf="c.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                        <div *ngIf="c.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">State</label>
                                    <select class="form-control" formControlName="state" (change)="fetchCity($event)" [ngClass]="{ 'is-invalid': CareerC_submitted && c.state.errors }">
                                        <option value="" [selected]="true">Select State</option>
                                        <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                                    </select>
                                    <div *ngIf="CareerC_submitted && c.state.errors" class="invalid-login">
                                        <div *ngIf="c.state.errors.required">State is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">City</label>
                                    <select class="form-control" formControlName="city" [ngClass]="{ 'is-invalid': CareerC_submitted && c.city.errors }">
                                        <option value="" [selected]="true">Select City</option>
                                        <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                                    </select>
                                    <div *ngIf="CareerC_submitted && c.city.errors" class="invalid-login">
                                        <div *ngIf="c.city.errors.required">City is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-dark" type="submit">Submit Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal fade otp-modal" id="career-verification" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="COTPForm" (ngSubmit)="COTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>Please enter the OTP to verify your mobile number</h3>
                                <p>A OTP has sent to {{mob_no}}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 Digit OTP</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid': cotp_submitted && co.otp.errors }">
                            <div *ngIf="cotp_submitted && co.otp.errors" class="invalid-login">
                                <div *ngIf="co.otp.errors.required">OTP is required</div>
                                <div *ngIf="co.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Validate OTP</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <form [formGroup]="CROTPForm" (ngSubmit)="CROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span  class="wish-otp">If you did not received OTP, you can request a new OTP after 30 seconds</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend OTP</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>