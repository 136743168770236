import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss']
})
export class MissionDetailComponent implements OnInit {
  data: any=[];
  MissionData: any=[];
  MissionSlug:any;
  enva:any;
  imageurl:any;
  MissionProblemArray: any=[];
  constructor(private route: ActivatedRoute,
    private router:Router,
    private RestAPI: RESTApiService) { 
      this.enva = environment.assetsURL;
      this.imageurl = environment.imageURL;
      
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
     }
    
     this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
           // trick the Router into believing it's last link wasn't previously loaded
           this.router.navigated = false;
        }
    });
    }
  ngOnInit(): void {
    $(".modal-backdrop").remove();
    $('body').removeClass('modal-open');
    $("body").css("padding-right","0");
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.MissionSlug = params['params'].slug;
        console.log(this.MissionSlug)
      }
    });
    this.fetchMissionProblembySlug();
    this.fetchMissionPoblems();
  }

  fetchMissionProblembySlug() {
    this.RestAPI.get(environment.apiURL + environment.missionProblemDetailAPI + this.MissionSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.MissionData = this.data.data.missiondetail[0];
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchMissionPoblems(){
    this.RestAPI.get(environment.apiURL + environment.missionProblemAPI).subscribe(data =>{
      this.data = data;
      this.MissionProblemArray = this.data.data[0];
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }

}
