<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>हमारा उद्देश्य</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="objective-page-section">
    <div class="container">
        <div class="row mt-5">
            <div class="col-sm-12">
                <p>इन लक्ष्यों को प्राप्त करने के लिए, हमें एक संवेदनशील और केंद्रित संगठन की आवश्यकता होती है जहां
                    संसाधनों को संरेखित किया जाता है और जब और जहां सबसे अधिक आवश्यकता होती है, प्रयासों को बढ़ाया जा
                    सकता है। चार सामान्य लक्ष्यों की दिशा में एक साथ काम कर रहे एक केंद्रित वैश्विक संगठन के साथ,
                    इंटरनेशनल फेडरेशन आरडीएसएस लक्ष्यों के लिए एक प्रमुख और महत्वपूर्ण योगदान दे सकता है।</p>
                <p>राष्ट्रधर्म सेवा संगठन को राष्ट्रीय हित, जनहित, युवाओं के हित और सामाजिक हित में काम करने के लिए 15
                    प्रमुख उद्देश्यों में विभाजित किया गया है:-</p>
                <div class="info">
                    <div class="heading-title">
                        <div class="title">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3 col-md-4" *ngFor="let item of ObjectivesArray">
                            <div class="outer">
                                <div class="inner-box">
                                    <div class="icon">
                                        <i class="{{item.text}}"></i>
                                    </div>
                                    <div class="text">
                                        <h4>{{item.category_name}}</h4>
                                        <p [innerHTML]="item.description"></p>
                                        <a [routerLink]="" id="{{item.id}}" *ngIf="!LoggedIn()"
                                            (click)="onClick($event, item)">आगे पढ़े</a>
                                        <a [routerLink]="['/objective-detail', item.slug]" *ngIf="LoggedIn()">आगे
                                            पढ़े</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div class="modal mission-modal fade show" [style.display]="showModal ? 'block' : 'none'" id="objective-detail"
    tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document"
        style="overflow-y: scroll; max-height:85%;  margin-top: 50px; margin-bottom:50px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="close" (click)="hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <div class="missiondetail-left">
                            <img src="{{imageurl}}{{modaldata.image}}" class="img-fluid">
                            <h3>{{modaldata.category_name}}</h3>
                            <p [innerHTML]="modaldata.description"></p>
                            <a [routerLink]="['/objective-detail', modaldata.slug]" class="view-detail-modal">View
                                Detail</a>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <button *ngIf="LoggedIn()" style="background: #f58634;
                        padding: 4px 16px;
                        color: #fff;
                        white-space: nowrap;
                        font-size: 15px;">Subscribe </button>
                        <div class="missiondetail-right" *ngIf="!LoggedIn()">
                            <h3>{{modaldata.enquiry_form_title}}</h3>
                            <form [formGroup]="ObjectiveForm" *ngIf="showMe">
                                <div class="form-group row" style="display: none;">
                                    <div class="col-sm-12">
                                        <input type="text" class="text-control" [(ngModel)]="modaldata.id"
                                            formControlName="objective_id">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                        <input type="text" class="text-control" formControlName="name"
                                            [ngClass]="{ 'is-invalid': objective_submitted && o.name.errors }">
                                        <div *ngIf="objective_submitted && o.name.errors" class="invalid-login">
                                            <div *ngIf="o.name.errors.required">पूरा नाम आवश्यक है</div>
                                        </div>
                                        <div *ngIf="o.name.touched && o.name.invalid" style="color: red">
                                            <div *ngIf="o.name.errors.required">
                                                पूरा नाम आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6" *ngIf="showMe5">
                                        <label class="label-control">मोबाइल <span style="color: red;">*</span></label>
                                        <input type="number" style="width: 220px; height: 40px;" [(ngModel)]="mob_no2"
                                            disabled class="text-control" [pattern]="mobNumberPattern"
                                            formControlName="contact"
                                            [ngClass]="{ 'is-invalid': objective_submitted && o.contact.errors }">
                                        <p style="color: green;">{{message}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <form [formGroup]="registrationForm">
                                            <div class="form-group row">
                                                <div class="col-sm-6" style="display: none;">
                                                    <label class="label-control">पूरा नाम <span
                                                            style="color: red;">*</span></label>
                                                    <input type="text" class="form-control" formControlName="name"
                                                        [ngClass]="{ 'is-invalid':
                                                        registratiopn_submitted && r.name.errors }">
                                                    <div *ngIf="registratiopn_submitted &&
                                                        r.name.errors" class="invalid-login">
                                                        <!-- <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div> -->
                                                    </div>
                                                    <span *ngIf="r.name.invalid" class="errormssg">पूरा नाम आवश्यक
                                                        है</span>
                                                </div>
                                                <div *ngIf="showMe4">
                                                    <label class="label-control">मोबाइल नंबर
                                                        <span style="color: red;">*</span></label>
                                                    <input type="number" style="width: 234px; height: 40px;"
                                                        class="text-control" [pattern]="mobNumberPattern"
                                                        formControlName="contact" [ngClass]="{ 'is-invalid':
                                                        registratiopn_submitted && r.contact.errors
                                                        }" maxlength="10">
                                                    <!-- <span *ngIf="r.contact.invalid" class="errormssg">मोबाइल नंबर आवश्यक
                                                        है</span> -->
                                                    <div *ngIf="registratiopn_submitted &&
                                                        r.contact.errors" class="invalid-login">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                        <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                            नंबर 10 अंकों का होना चाहिए
                                                        </div>
                                                    </div>
                                                    <div *ngIf="r.contact.touched && r.contact.invalid" style="color: red">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                        <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                            नंबर 10 अंकों का होना चाहिए
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-dark" (click)="registrationSubmit()"
                                                        style="margin-top: 25px;" [hidden]="r.contact.invalid"
                                                        *ngIf="showMe2">अपना
                                                        नंबर
                                                        सत्यापित करें
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <form [formGroup]="OTPVForm" (ngSubmit)="OTPVerifySubmit()"
                                    style="padding: 30px 14px; padding-top: 0px;" *ngIf="showMe3">
                                    <div class="form-group row" style="display: none;">
                                        <div class="col-sm-6">
                                            <label class="label-control">पूरा नाम <span
                                                    style="color: red;">*</span></label>
                                            <input [(ngModel)]="full_name2" type="text" class="form-control"
                                                formControlName="name" [ngClass]="{
                                                'is-invalid':
                                                otpverify_submitted && vrfy.name.errors
                                                }">
                                        </div>
                                        <div class="col-sm-6">
                                            <label class="label-control">
                                                मोबाइल नंबर <span style="color: red;">*</span></label>
                                            <br>
                                            <input type="number" [(ngModel)]="mob_no" class="text-control"
                                                formControlName="contact" [ngClass]="{ 'is-invalid':
                                                otpverify_submitted && vrfy.contact.errors
                                                }">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label class="label-control">6
                                                अंक ओटीपी <span style="color: red;">*</span></label>
                                            <input type="number" class="form-control" placeholder="Enter OTP"
                                                [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid':
                                                otpverify_submitted && vrfy.otp.errors }">
                                            <div *ngIf="otpverify_submitted &&
                                                vrfy.contact.errors" class="invalid-login">
                                                <div *ngIf="vrfy.otp.errors.required">ओटीपी
                                                    आवश्यक है</div>
                                                <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6
                                                    अंकों का होना चाहिए</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <button class="btn btn-dark" type="submit" style="margin-top: 32px;">ओटीपी
                                                सत्यापित करें
                                            </button>
                                        </div>

                                    </div>
                                </form>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                                        <input type="text" class="text-control" formControlName="email"
                                            [ngClass]="{ 'is-invalid': objective_submitted && o.email.errors }">
                                        <!-- <div *ngIf="objective_submitted && o.email.errors" class="invalid-login">
                                            <div *ngIf="o.email.errors.required">ईमेल की आवश्यकता है</div>
                                        </div> -->
                                        <div *ngIf="o.email.touched && o.email.invalid" style="color: red">
                                            <div *ngIf="o.email.errors.required">
                                                ईमेल की आवश्यकता है
                                            </div>
                                            <div *ngIf="o.email.errors.email">
                                                ईमेल एक वैध ईमेल पता होना चाहिए
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="alternate_mobile_number">
                                    </div>
                                    <!-- <div class="col-sm-6">
                                        <label class="label-control">पिन कोड</label>
                                        <input type="number" class="text-control" formControlName="pincode"
                                            [ngClass]="{ 'is-invalid': objective_submitted && o.pincode.errors }">
                                        <div *ngIf="objective_submitted && o.pincode.errors" class="invalid-login">
                                            <div *ngIf="o.pincode.errors.required">Pincode is required</div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">लिंग <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" value="female"> स्त्री
                                        <br>
                                        <div *ngIf="o.gender.touched && o.gender.invalid" style="color: red">
                                            <div *ngIf="o.gender.errors.required">
                                                लिंग आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.gender.invalid" class="errormssg">लिंग आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">क्या आप भारत के
                                            नागरिक हैं ? <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="is_indian_citizen" value="yes">
                                        हां&nbsp;&nbsp;
                                        <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                                        <br>
                                        <div *ngIf="o.is_indian_citizen.touched && o.is_indian_citizen.invalid"
                                            style="color: red">
                                            <div *ngIf="o.is_indian_citizen.errors.required">
                                                क्या आप भारत के नागरिक हैं ? आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.is_indian_citizen.invalid" class="errormssg">क्या आप भारत के नागरिक हैं ? आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">व्हाट्सएप नं
                                        </label>
                                        <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="whatsapp_number">

                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">आवास का पता <span
                                                style="color: red;">*</span></label>
                                        <input type="text" class="text-control" formControlName="address">
                                        <!-- <div *ngIf="objective_submitted && o.address.errors" class="invalid-login">
                                            <div *ngIf="o.address.errors.required">Address is required</div>
                                        </div> -->
                                        <div *ngIf="o.address.touched && o.address.invalid" style="color: red">
                                            <div *ngIf="o.address.errors.required">
                                                आवास का पता आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">वर्तमान निवास का देश <span
                                                style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="country"
                                            (change)="fetchState($event)"
                                            [ngClass]="{ 'is-invalid': objective_submitted && o.country .errors }">
                                            <option value="" selected>देश चुनें</option>
                                            <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="o.country.touched && o.country.invalid" style="color: red">
                                            <div *ngIf="o.country.errors.required">
                                                वर्तमान निवास का देश आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक है</span> -->
                                        <!-- <div *ngIf="objective_submitted && o.country.errors" class="invalid-login">
                                            <div *ngIf="o.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                                        </div> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">राज्य <span style="color: red;">*</span></label>
                                        <select class="text-control" formControlName="state"
                                            (change)="fetchCity($event)"
                                            [ngClass]="{ 'is-invalid': objective_submitted && o.state.errors }">
                                            <option value="" [selected]="true">राज्य चुनें</option>
                                            <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="objective_submitted && o.state.errors" class="invalid-login">
                                            <div *ngIf="o.state.errors.required">State is required</div>
                                        </div> -->
                                        <div *ngIf="o.state.touched && o.state.invalid" style="color: red">
                                            <div *ngIf="o.state.errors.required">
                                                राज्य आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.state.invalid" class="errormssg">राज्य आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">जिला <span style="color: red;">*</span></label>
                                        <select class="text-control" formControlName="city"
                                            [ngClass]="{ 'is-invalid': objective_submitted && o.city.errors }">
                                            <option value="" [selected]="true">जिला चुनें</option>
                                            <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="objective_submitted && o.city.errors" class="invalid-login">
                                            <div *ngIf="o.city.errors.required">City is required</div>
                                        </div> -->
                                        <div *ngIf="o.city.touched && o.city.invalid" style="color: red">
                                            <div *ngIf="o.city.errors.required">
                                                जिला आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.city.invalid" class="errormssg">जिला आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">तहसील का नाम
                                            <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="tehsil" [ngClass]="{ 'is-invalid':
                                        objective_submitted && o.tehsil.errors
                                                }">
                                        <!-- <div *ngIf="objective_submitted &&
                                                o.teshil.errors" class="invalid-login">
                                            <div *ngIf="o.tehsil.errors.required">तहसील का नाम आवश्यक है</div>
                                        </div> -->
                                        <div *ngIf="o.tehsil.touched && o.tehsil.invalid" style="color: red">
                                            <div *ngIf="o.tehsil.errors.required">
                                                तहसील का नाम आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.tehsil.invalid" class="errormssg">तहसील का नाम आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row justify-content-center">
                                    <div class="col-sm-6">
                                        <label>पासवर्ड <span style="color: red;">*</span></label>
                                        <input type="password" class="form-control" [(ngModel)]="password"
                                            formControlName="password" minlength="6">
                                        <!-- <div *ngIf="o.password.touched &&
                                            o.password.invalid" class="alert alert-danger">
                                            <div *ngIf="o.password.errors.required">पासवर्ड की आवश्यकता है।</div>
                                            <div *ngIf="o.password.errors.minlength">पासवर्ड कम से कम 8 वर्णों का होना
                                                चाहिए</div>
                                        </div> -->
                                        <div *ngIf="o.password.touched && o.password.invalid" style="color: red">
                                            <!-- <div *ngIf="new.password.errors.required">
                                                  पासवर्ड की आवश्यकता है।
                                                </div> -->
                                            <div *ngIf="o.password.errors.required">
                                                पासवर्ड कम से कम 8 वर्णों का होना चाहिए
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="o.password.invalid" class="errormssg">पासवर्ड कम से कम 8 वर्णों का
                                            होना चाहिए</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label>पासवर्ड की पुष्टि कीजिये <span style="color: red;">*</span></label>
                                        <input type="password" class="form-control"
                                            formControlName="password_confirmation" pattern="{{password}}"
                                            [(ngModel)]="password_confirmation">
                                        <div *ngIf="o.password_confirmation.touched && o.password_confirmation.invalid"
                                            style="color: red">
                                            <div *ngIf="o.password_confirmation.invalid">
                                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                            </div>
                                            <!-- <div *ngIf="new.password_confirmation.errors.pattern">
                                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                            </div> -->
                                        </div>
                                        <!-- <span *ngIf="o.password_confirmation.invalid" class="errormssg">पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" (click)="clearForm()">रीसेट
                                            करें</button> &nbsp;&nbsp;
                                        <button class="btn btn-dark" [disabled]="ObjectiveForm.invalid"
                                            (click)="ObjectiveSubmit()">अभी
                                            जमा करे</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade otp-modal" id="objective-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="OOTPForm" (ngSubmit)="OOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">ID</label>
                            <input type="number" class="form-control" placeholder="Enter ID" [(ngModel)]="objective_id"
                                readonly formControlName="objective_id">
                        </div>
                        <div class="col-sm-12">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>Please enter the OTP to verify your mobile number</h3>
                                <p>A OTP has sent to {{mob_no}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 Digit OTP</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                formControlName="otp" [ngClass]="{ 'is-invalid': ootp_submitted && oo.otp.errors }">
                            <div *ngIf="ootp_submitted && oo.otp.errors" class="invalid-login">
                                <div *ngIf="oo.otp.errors.required">OTP is required</div>
                                <div *ngIf="oo.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Validate OTP</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <form [formGroup]="OROTPForm" (ngSubmit)="OROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">If you did not received OTP, you can request a new OTP after 30
                                seconds</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend
                                OTP</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>