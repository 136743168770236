import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-organization-founder',
  templateUrl: './organization-founder.component.html',
  styleUrls: ['./organization-founder.component.scss']
})
export class OrganizationFounderComponent implements OnInit {
  enva:any;
  imageurl: any;
  FounderArray: any =[];
  data:any=[];
  constructor(private restAPI: RESTApiService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.fetchFounder();
  }

  fetchFounder(){
    this.restAPI.get(environment.apiURL + environment.founderAPI).subscribe(data =>{
      this.data = data;
      this.FounderArray = this.data.data.managefounder;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
