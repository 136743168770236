<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>इमेज गैलरी</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">होम</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">इमेज गैलरी</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="image-gallery-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-3 col-6 col-md-3 col-lg-3" *ngFor="let item of ImageGalleryArray">
                <div class="gallery-main">
                    <a class="thumbnail">
                        <img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid">
                        <div class="text-right">
                            <small class="text-muted">{{item.title}}</small>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>