<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url();background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <!-- <h3>Upload Your Letter</h3> -->
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">Upload Your Letter</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid">
    <div class="logo">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/left logo.png" alt="..." class="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/right logo.png" alt="..." class="float-right">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="upload-your-letter-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <h3 style="color: #f58634;">अपना पत्र अपलोड करें<img src="assets/images/right logo.png"
                                class="img-thumbnail" style="max-width: 7%; border: 0px solid;"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-5">
                <div class="card">
                    <h5 class="card-header">Number of Letters Sent : <span>{{projectcount}}</span></h5>
                </div>
                <br>
                <table class="center" border="1" cellpadding="5" cellspacing="5" style="font-size: 12px;">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>State Name</th>
                            <!-- <th>District</th>
                            <th>City</th> -->
                            <th>No of Letters</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Andhra Pradesh</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-andhra-pradesh']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>2</td>
                            <td>Arunachal Pradesh</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-arunachal-pradesh']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>3</td>
                            <td>Assam</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-assam']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>4</td>
                            <td>Bihar</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-bihar']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>5</td>
                            <td>Chhattisgarh</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-chhattisgarh']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>6</td>
                            <td>Goa</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-goa']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>7</td>
                            <td>Gujarat</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-gujarat']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>8</td>
                            <td>Haryana</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-haryana']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>9</td>
                            <td>Himachal Pradesh</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-himachal-pradesh']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>10</td>
                            <td>Jharkhand</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-jharkhand']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>11</td>
                            <td>Karnataka</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-karnataka']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>12</td>
                            <td>Kerala</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-kerala']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>13</td>
                            <td>Madhya Pradesh</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-madhya-pradesh']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>14</td>
                            <td>Maharashtra</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-maharashtra']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>15</td>
                            <td>Manipur</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-manipur']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>16</td>
                            <td>Meghalaya</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-meghalaya']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>17</td>
                            <td>Mizoram</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-mizoram']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>18</td>
                            <td>Nagaland</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-nagaland']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>19</td>
                            <td>Odisha</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letters-odisha']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>20</td>
                            <td>Punjab</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-punjab']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>21</td>
                            <td>Rajasthan</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-rajasthan']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>22</td>
                            <td>Sikkim</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-sikkim']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>23</td>
                            <td>Tamil Nadu</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-tamil-nadu']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>24</td>
                            <td>Telangana</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-telangana']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>25</td>
                            <td>Tripura</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-tripura']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>26</td>
                            <td>Uttar Pradesh</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-uttar-pradesh']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>27</td>
                            <td>Uttarakhand </td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-uttarakhand']">0</a></td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>28</td>
                            <td>West Bengal</td>
                            <!-- <td>{{item.city}}</td>
                            <td>{{item.city_slug}}</td> -->
                            <td> <a [routerLink]="['/letter-west-bengal']">0</a></td>
                        </tr>
                    </tbody>
                    <!-- <tbody>
                        <tr *ngFor="let item of recordArray ;let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.state}}</td>
                            <td> <a [routerLink]="['/view-gallery-and-letter']">{{item.letters_count}}</a></td>
                        </tr>
                    </tbody>  -->
                </table>
                <br><br>
            </div>
            <div class="col-sm-7">
                <div class="exposec-sidebar">
                    <div class="form-exposec">
                        <h3 class="classtitle">पत्र अपलोड करें</h3>
                        <br>
                        <form [formGroup]="LWPForm" (ngSubmit)="LWPSubmit()" *ngIf="showMe" class="text-center"
                            style="background: #f2f2f2; padding: 30px 14px">
                            <div class="row form-group">
                                <div class="col-sm-12 ">
                                    <label>पंजीकृत ईमेल / मोबाइल नंबर</label>
                                    <br>
                                    <input class="text-material" formControlName="email" type="text"
                                        [ngClass]="{ 'is-invalid': lwp_submitted && lwp.email.errors }">
                                    <div *ngIf="lwp_submitted && lwp.email.errors" class="invalid-login">
                                        <div *ngIf="lwp.email.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col-sm-12">
                                    <label>पास वर्ड</label>
                                    <br>
                                    <input class="text-material" formControlName="password" type="password"
                                        [ngClass]="{ 'is-invalid': lwp_submitted && lwp.password.errors }">
                                    <div *ngIf="lwp_submitted && lwp.password.errors" class="invalid-login">
                                        <div *ngIf="lwp.password.errors.required">पासवर्ड की आवश्यकता है</div>
                                    </div>
                                    <br>
                                    <a href="#" class="forgotatag" data-dismiss="modal" data-target="#forgot-password"
                                        data-toggle="modal">पासवर्ड भूल गए ?</a>
                                </div>
                            </div>


                            <div class="row form-group mt-lg-10">
                                <div class="col-sm-12">
                                    <button type="submit" class="btn-login">लॉग इन करें</button>
                                </div>
                            </div>
                            <div class="modal-foo">
                                <!-- <button class="btn-joinnow" data-dismiss="modal" data-target="#register" data-toggle="modal">नया उपयोगकर्ता ? अभी पंजीकरण करें</button> -->
                                <a type="button" (click)="toogleTag()" class="forgotatag">नया उपयोगकर्ता ? अभी
                                    पंजीकरण करें</a>
                            </div>
                        </form>
                        <form [formGroup]="registrationForm" style="background: #f2f2f2; padding: 30px 14px"
                            (ngSubmit)="registrationSubmit()" *ngIf="showMe4" class="text-center">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                      registratiopn_submitted && r.name.errors
                              }">
                                    <div *ngIf="registratiopn_submitted &&
                          r.name.errors" class="invalid-login">
                                        <div *ngIf="r.name.errors.required">पूरा
                                            नाम आवश्यक है</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">मोबाइल नंबर
                                        <span style="color: red;">*</span></label>
                                    <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                        formControlName="contact" [ngClass]="{ 'is-invalid':
                              registratiopn_submitted &&
                              r.contact.errors }" maxlength="10">
                                    <div *ngIf="registratiopn_submitted &&
                              r.contact.errors" class="invalid-login">
                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                            नंबर आवश्यक है</div>
                                        <div *ngIf="r.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-dark" type="submit" style="margin-top: 25px;">अपना नंबर
                                        सत्यापित करें
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form [formGroup]="OTPVForm" style="background: #f2f2f2; padding: 30px 14px" (ngSubmit)="OTPVerifySubmit()" *ngIf="showMe3" class="text-center">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input [(ngModel)]="full_name2"  type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid':
                                    otpverify_submitted && vrfy.name.errors
                                            }">
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control" >
                                        मोबाइल नंबर <span style="color: red;">*</span></label>
                                        <br>
                                    <input type="number" [(ngModel)]="mob_no" class="text-control" formControlName="contact" [ngClass]="{ 'is-invalid': otpverify_submitted && vrfy.contact.errors }">            
                                </div>      
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">6
                                        अंक ओटीपी <span style="color: red;">*</span></label>
                                    <input type="number" class="form-control"
                                        placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp"
                                        [ngClass]="{ 'is-invalid':
                                        otpverify_submitted && vrfy.otp.errors }">
                                    <div *ngIf="otpverify_submitted && vrfy.contact.errors" class="invalid-login">
                                        <div *ngIf="vrfy.otp.errors.required">ओटीपी आवश्यक है</div>
                                        <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <button class="btn btn-dark" type="submit"
                                        style="margin-top: 28px;">ओटीपी सत्यापित करें
                                    </button>
                                </div>
                            </div>
                        </form>
                        <!-- <form [formGroup]="registrationForm" (ngSubmit)="registrationSubmit()" *ngIf="!LoggedIn()">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">पूरा नाम <span
                                        style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': registratiopn_submitted && r.name.errors }">
                                    <div *ngIf="registratiopn_submitted && r.name.errors" class="invalid-login">
                                        <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">ईमेल <span
                                        style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': registratiopn_submitted && r.email.errors }">
                                    <div *ngIf="registratiopn_submitted && r.email.errors" class="invalid-login">
                                        <div *ngIf="r.email.errors.required">ईमेल आवश्यक है</div>
                                        <div *ngIf="r.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">मोबाइल नंबर <span
                                        style="color: red;">*</span></label>
                                    <input type="text" class="form-control" maxlength="10" [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': registratiopn_submitted && r.contact.errors }">
                                    <div *ngIf="registratiopn_submitted && r.contact.errors" class="invalid-login">
                                        <div *ngIf="r.contact.errors.required">मोबाइल नंबर आवश्यक है</div>
                                        <div *ngIf="r.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-dark" type="submit">ओटीपी के लिए अनुरोध</button>
                                </div>
                            </div>
                        </form> -->
                        <form [formGroup]="LetterForm" (ngSubmit)="LetterSubmit()" *ngIf="showMe2">
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">नाम<span class="required-span">*</span></label>
                                    <input type="text" [(ngModel)]="full_name" readonly class="form-control" formControlName="first_name" />

                                    <div *ngIf="letter_submitted && l.first_name.errors" class="invalid-login">
                                        <div *ngIf="l.first_name.errors.required">First Name is required</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">उपनाम<span class="required-span">*</span></label>
                                    <input type="text" class="form-control" formControlName="last_name" />

                                    <div *ngIf="letter_submitted && l.last_name.errors" class="invalid-login">
                                        <div *ngIf="l.last_name.errors.required">Last name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">पिता का नाम<span class="required-span">*</span></label>
                                    <input type="text" class="form-control" formControlName="father_name" />

                                    <div *ngIf="letter_submitted && l.father_name.errors" class="invalid-login">
                                        <div *ngIf="l.father_name.errors.required">Father's Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">ईमेल<span class="required-span">*</span></label>
                                    <input type="text" class="form-control" formControlName="email" />
                                    <div *ngIf="letter_submitted && l.email.errors" class="invalid-login">
                                        <div *ngIf="l.email.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">मोबाइल नंबर<span
                                        class="required-span">*</span></label>
                                    <input type="text" class="form-control"
                                        formControlName="contact" [(ngModel)]="mob_no2" readonly maxLength="10" minLength="10" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />

                                    <div *ngIf="letter_submitted && l.mobile_number.errors" class="invalid-login">
                                        <div *ngIf="l.mobile_number.errors.required">मोबाइल नंबर चाहिए</div>
                                    </div>

                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">व्हाट्सएप नंबर<span
                                            class="required-span">*</span></label>
                                    <input type="text" class="form-control" formControlName="whatsapp_number"
                                        maxlength="10" minlength="10"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />

                                    <div *ngIf="letter_submitted && l.whatsapp_number.errors" class="invalid-login">
                                        <div *ngIf="l.whatsapp_number.errors.required">Whatsapp Number is required</div>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <p>लिंग <span style="color: red;">*</span></p>
                                    <label><input type="radio" value="male" formControlName="gender"> पुरुष</label>
                                    &nbsp;&nbsp;
                                    <label><input type="radio" value="female" formControlName="gender"> स्त्री</label>
                                </div>
                                <div class="col-sm-6">
                                    <p>क्या आप भारत के नागरिक हैं ? <span style="color: red;">*</span></p>
                                    <label><input type="radio" value="yes" formControlName="is_indian_citizen">
                                        हां</label>
                                    &nbsp;&nbsp;
                                    <label><input type="radio" value="no" formControlName="is_indian_citizen">
                                        नहीं</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control">आवास का पता <span
                                            class="required-span">*</span></label>
                                    <input type="text" class="form-control" formControlName="address" />

                                    <div *ngIf="letter_submitted && l.address.errors" class="invalid-login">
                                        <div *ngIf="l.address.errors.required">Address is required</div>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">राज्य<span class="required-span">*</span></label>
                                    <select title="state" class="form-control" formControlName="state"
                                        (change)="fetchCity($event)">
                                        <option value="" [selected]="true">राज्य चुनें</option>
                                        <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="letter_submitted && l.state.errors" class="invalid-login">
                                        <div *ngIf="l.state.errors.required">State is required</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">जिला<span class="required-span">*</span></label>
                                    <select class="form-control" formControlName="city" title="city">
                                        <option value="" [selected]="true">जिला चुनें</option>
                                        <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="letter_submitted && l.city.errors" class="invalid-login">
                                        <div *ngIf="l.city.errors.required">City is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पत्र श्रेणी<span class="required-span">*</span></label>
                                    <select class="form-control" formControlName="letter_categories"
                                        title="letter_categories" (change)="fetchCategories2($event)">
                                        <option value="" [selected]="true">
                                            पत्र श्रेणी का चयन करें</option>
                                        <option *ngFor="let item of lettercategoriesArray" value="{{item.id}}">
                                            {{item.name_hindi}}
                                        </option>
                                    </select>
                                    <div *ngIf="letter_submitted && l.letter_categories.errors" class="invalid-login">
                                        <div *ngIf="l.letter_categories.errors.required">City is required</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">पत्र प्रकार<span class="required-span">*</span></label>
                                    <select class="form-control" formControlName="letter_types" title="letter_types">
                                        <option value="" [selected]="true">पत्र प्रकार चुनें</option>
                                        <option *ngFor="let item of lettercategories2Array" value="{{item.id}}">
                                            {{item.name_hindi}}
                                        </option>
                                    </select>
                                    <div *ngIf="letter_submitted && l.letter_types.errors" class="invalid-login">
                                        <div *ngIf="l.letter_types.errors.required">City is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पत्र ब्राउज़ करें<span
                                            class="required-span">*</span></label>
                                    <input class="text-control" type="file" formControlName="letter"
                                        (change)="uploadLetter($event)"
                                        [ngClass]="{ 'is-invalid': letter_submitted && l.letter.errors }" />
                                    <div *ngIf="letter_submitted && l.letter.errors" class="invalid-login">
                                        <div *ngIf="l.letter.errors.required">Letter is required</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">
                                        प्रोफ़ाइल छवि <span class="required-span">*</span></label>
                                    <input type="file" class="text-control" formControlName="image"
                                        (change)="uploadImage($event)"
                                        [ngClass]="{ 'is-invalid': letter_submitted && l.image.errors }" />
                                    <div *ngIf="letter_submitted && l.image.errors" class="invalid-login">
                                        <div *ngIf="l.image.errors.required">Image is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center" >
                                <div class="col-sm-6">
                                    <label>पासवर्ड</label>
                                    <input type="password" class="form-control" [(ngModel)]="pass"
                                        formControlName="password" minlength="6">
                                    <!-- <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                                        'orangered', 'orange']"></password-strength-meter>
                                    <small id="passwordHelpBlock" class="form-text
                                        text-muted">
                
                                        आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                                        चार और कम से कम 8 वर्ण होने चाहिए
                                    </small> -->
                                    <div *ngIf="l.password.touched &&
                                        l.password.invalid" class="alert alert-danger">
                                        <div *ngIf="l.password.errors.required">पासवर्ड की आवश्यकता है।</div>
                                        <div *ngIf="l.password.errors.minlength">पासवर्ड कम से कम 6 वर्णों का होना चाहिए</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label>पासवर्ड की पुष्टि कीजिये</label>
                                    <input type="password" class="form-control" formControlName="password_confirmation">
                                    <div *ngIf="l.password_confirmation.touched &&
                                        l.password_confirmation.invalid" class="alert
                                        alert-danger">
                                        <div *ngIf="mo.password_confirmation.errors.required">Confirm
                                            पासवर्ड की आवश्यकता है.</div>
                                        <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                            पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="label-control"><input type="checkbox" formControlName="acceptTerms"
                                            [ngClass]="{ 'is-invalid': letter_submitted && l.acceptTerms.errors }"> <a
                                            [routerLink]="['/terms-and-conditions']"> &nbsp;मुझे सभी नियम और शर्तें
                                            स्वीकार हैं</a> </label>
                                    <div *ngIf="letter_submitted && l.acceptTerms.errors" class="invalid-login">
                                        Acceptance is required</div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-dark" type="reset">रीसेट</button> &nbsp;&nbsp;
                                    <button class="btn btn-dark" type="submit">ओटीपी पर आगे बढ़ें</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade otp-modal" id="movement-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="MOTPForm" (ngSubmit)="MOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">मोबाइल नंबर
                            </label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>
                                    कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                <p>
                                    एक ओटीपी भेजा गया है{{mob_no}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">पूरा नाम </label>
                            <input type="text" class="form-control" [(ngModel)]="full_name" disabled
                                formControlName="name">
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">ईमेल</label>
                            <input type="text" class="form-control" formControlName="email" disabled
                                [(ngModel)]="email2">
                        </div>
                    </div>
                    <div class="form-group row">

                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label>पासवर्ड</label>
                            <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password">
                            <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                                'orangered', 'orange']"></password-strength-meter>
                            <small id="passwordHelpBlock" class="form-text
                                text-muted">

                                आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                                चार और कम से कम 8 वर्ण होने चाहिए
                            </small>
                            <div *ngIf="mo.password.touched &&
                                mo.password.invalid" class="alert alert-danger">
                                <div *ngIf="mo.password.errors.required">Password
                                    is required.</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>पासवर्ड की पुष्टि कीजिये</label>
                            <input type="password" class="form-control" formControlName="password_confirmation">
                            <div *ngIf="mo.password_confirmation.touched &&
                                mo.password_confirmation.invalid" class="alert
                                alert-danger">
                                <div *ngIf="mo.password_confirmation.errors.required">Confirm
                                    पासवर्ड की आवश्यकता है.</div>
                                <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6
                                अंक ओटीपी</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                formControlName="otp" [ngClass]="{ 'is-invalid':
                                motp_submitted && mo.otp.errors }">
                            <div *ngIf="motp_submitted && mo.contact.errors" class="invalid-login">
                                <div *ngIf="mo.otp.errors.required">ओटीपी आवश्यक है</div>
                                <div *ngIf="mo.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <form [formGroup]="MROTPForm" (ngSubmit)="MROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">यदि आपको ओटीपी प्राप्त नहीं हुआ है, आप 30 . के बाद एक नए ओटीपी का
                                अनुरोध कर सकते हैं सेकंड</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">पुन: भेजें
                                ओटीपी</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>