import { Component, OnInit } from '@angular/core';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organization-structure',
  templateUrl: './organization-structure.component.html',
  styleUrls: ['./organization-structure.component.scss']
})
export class OrganizationStructureComponent implements OnInit {
  OrgStructureData:any=[];
  data:any=[];
  CommitteeArrayLv1:any=[];
  CommitteeArrayLv2:any=[];
  imageurl:any;
  enva:any;
  constructor(private restAPI: RESTApiService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
   }

  ngOnInit(): void {
    this.fetchOrgStructure();
    this.fetchOrgCLv1();
    this.fetchOrgCLv2();
  }

  fetchOrgStructure(){
    this.restAPI.get(environment.apiURL + environment.orgStuctureAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.OrgStructureData = this.data.data.manageorganization;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchOrgCLv1(){
    this.restAPI.get(environment.apiURL + environment.orgNationCommAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.CommitteeArrayLv1 = this.data.data.level1;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }
  fetchOrgCLv2(){
    this.restAPI.get(environment.apiURL + environment.orgNationCommAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.CommitteeArrayLv2 = this.data.data.level2;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }
}
