<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>हमारी पहचान</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="logo-section">
    <div class="container">
        <div class="info">
            <div class="row">
                <div class="col-sm-8">
                    <!-- <div class="row">
                        <div class="col-sm-12">
                            <div [innerHTML]="IdentityArray.content"></div>
                        </div>
                        <div class="col-sm-12 ul-numb" [innerHTML]="IdentityArray.content2"></div>
                    </div> -->
                    <div class="row">
                        <!-- <div class="col-sm-12 mb-3">
                            <div [innerHTML]="IdentityArray.logo_content"></div>
                        </div> -->
                        <!-- <div class="col-sm-12">
                            <div class="identity-content">
                                <h2>{{IdentityArray.logo_title}}</h2>
                                <div class="media">
                                    <div class="media-body" [innerHTML]="IdentityArray.logo_content"></div>
                                    <div class="media-l-h align-self-center">
                                        <img src="{{imageurl}}{{IdentityArray.image1}}" alt="r-icon"
                                            class="img-fluid">
                                    </div>
                                </div>
                                <div class="table-logo" [innerHTML]="IdentityArray.logo_description"></div>
                            </div>
                        </div> -->

                        <div class="col-sm-12">
                            <h1 id="head">› संगठन झण्डा</h1>
                            <div class="identity-content">
                                <h2>{{IdentityArray.flag_text}}</h2>
                                <div class="media">
                                    <div class="media-l-h align-self-center">
                                        <img src="{{imageurl}}{{IdentityArray.image2}}" alt="r-icon" class="img-fluid">
                                        <h4 class="img-heading">Organization Flag</h4>
                                    </div>
                                    <div class="media-body" [innerHTML]="IdentityArray.flag_content">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <h1 id="head">› संगठन नारा</h1>
                            <div class="identity-content">
                                <h2>{{IdentityArray.slogan_text}}</h2>
                                <div class="media">
                                    <div class="media-l-h align-self-center">
                                        <img src="{{imageurl}}{{IdentityArray.image3}}" alt="r-icon" class="img-fluid">
                                    </div>
                                    <div class="media-body" [innerHTML]="IdentityArray.slogan_content">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="identity-content">
                                <h2>{{IdentityArray.dress_text}}</h2>
                                <div class="media">
                                    <div class="media-body" [innerHTML]="IdentityArray.dress_content">

                                    </div>
                                    <div class="media-l-h align-self-center">
                                        <img src="{{imageurl}}{{IdentityArray.image4}}" alt="r-icon" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="">
                                <p>हमारे देश के तीव्रगति से उन्नति, विकास व सुरक्षा के लिए देशहित, जनहित, युवाहित व
                                    समाजहित के कामों को क्रान्ति स्तर पर करना होगा। तभी हम भारतीय विश्व स्तर पर अपने
                                    पिछड़ेपन को दूर कर पायेंगे।</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <section class="boxes-section">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12"
                                    *ngFor="let item of BoxesContentArray | slice:0:4; let i = index">
                                    <div class="tab tab-{{i+1}}">
                                        <i class="main-i {{item.icon_text}}"></i>
                                        <h5>{{item.title}}</h5>
                                        <p>{{item.description}}</p>
                                        <a [routerLink]="[item.link]">{{item.button_name}} <i
                                                class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="col-sm-12">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item">
                                <a class="page-link" tabindex="-1" [routerLink]="['/our-identity']">पिछला</a>
                            </li>
                            <li class="page-item"><a class="page-link" [routerLink]="['/our-identity']">1</a></li>
                            <li class="page-item"><a class="page-link active" style="color: #f58634;"
                                    [routerLink]="['/our-identity-two']">2</a></li>
                            <li class="page-item">
                                <a class="page-link" style="color: #f58634;"
                                    [routerLink]="['/our-identity-two']">आगे</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>