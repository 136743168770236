import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules  } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OrganizationFounderComponent } from './components/organization-founder/organization-founder.component';
import { OurMissionComponent } from './components/our-mission/our-mission.component';
import { OrganizationStructureComponent } from './components/organization-structure/organization-structure.component';
import { OurStrengthComponent } from './components/our-strength/our-strength.component';
import { OurVisionComponent } from './components/our-vision/our-vision.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { JobDetailComponent } from './components/job-detail/job-detail.component';
import { OurIdentityComponent } from './components/our-identity/our-identity.component';
import { NewsComponent } from './components/news/news.component';
import { NewsDetailComponent } from  './components/news-detail/news-detail.component';
import { MissionDetailComponent } from './components/mission-detail/mission-detail.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OurObjectiveComponent } from './components/our-objective/our-objective.component';
import { OurMovementComponent } from './components/our-movement/our-movement.component';
import { MovementDetailComponent } from './components/movement-detail/movement-detail.component';
import { BriberyFreeIndiaComponent } from './components/bribery-free-india/bribery-free-india.component';
import { BfiDetailComponent } from './components/bfi-detail/bfi-detail.component';
import { BustedRevolutionComponent } from './components/busted-revolution/busted-revolution.component';
import { BustedRevolutionFormComponent } from './components/busted-revolution-form/busted-revolution-form.component';
import { DonateUsComponent } from './components/donate-us/donate-us.component';
import { ObjectiveDetailComponent } from './components/objective-detail/objective-detail.component';
import { VideoGalleryComponent } from './components/video-gallery/video-gallery.component';
import { BustedRevolutionDetailComponent } from './components/busted-revolution-detail/busted-revolution-detail.component';
import { ExposeCampaignComponent } from './components/expose-campaign/expose-campaign.component';
import { ExposeCampaignFormComponent } from './components/expose-campaign-form/expose-campaign-form.component';
import { ExposeCampaignDetailComponent } from './components/expose-campaign-detail/expose-campaign-detail.component';
import { CareerCounsellingComponent } from './components/career-counselling/career-counselling.component';
import { CareerCounsellingFormComponent } from './components/career-counselling-form/career-counselling-form.component';
import { DonateUsStep2Component } from './components/donate-us-step2/donate-us-step2.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { MembershipComponent } from './components/membership/membership.component';
import { MembershipFormComponent } from './components/membership-form/membership-form.component';
import { SuggestIndiaComponent } from './components/suggest-india/suggest-india.component';
import { LocateUsComponent } from './components/locate-us/locate-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './components/refund-policy/refund-policy.component';
import { ThankYouDonationComponent } from './components/thank-you-donation/thank-you-donation.component';
import { JobFilterComponent } from './components/job-filter/job-filter.component';
import { CareercounsellingFilterComponent } from './components/careercounselling-filter/careercounselling-filter.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import {AuthGuardService} from './services/Authentication/AuthGuard.service';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserSubscriptionComponent } from './components/user-subscription/user-subscription.component';
import { UserMembershipComponent } from './components/user-membership/user-membership.component';
import { UserUpdatesComponent } from './components/user-updates/user-updates.component';
import { UserBuyMembershipComponent } from './components/user-buy-membership/user-buy-membership.component';
import { UserBustedRevolutionComponent } from './components/user-busted-revolution/user-busted-revolution.component';
import { UserExposeCampaignComponent } from './components/user-expose-campaign/user-expose-campaign.component';
import { UserExposeCampaignChatsComponent } from './components/user-expose-campaign-chats/user-expose-campaign-chats.component';
import { UserBustedRevolutionChatsComponent } from './components/user-busted-revolution-chats/user-busted-revolution-chats.component';
import { BecomeVolunteerComponent } from './components/become-volunteer/become-volunteer.component';
import { OrganizationStructurePageTwoComponent } from './components/organization-structure-page-two/organization-structure-page-two.component';
import { OurIdentityAndolanComponent } from './components/our-identity-andolan/our-identity-andolan.component';
import { AboutUsAndolanComponent } from './components/about-us-andolan/about-us-andolan.component';
import { OurMissionAndolanComponent } from './components/our-mission-andolan/our-mission-andolan.component';
import { OurVisionAndolanComponent } from './components/our-vision-andolan/our-vision-andolan.component';
import { OurStrengthAndolanComponent } from './components/our-strength-andolan/our-strength-andolan.component';
import { CommitteeFounderComponent } from './components/committee-founder/committee-founder.component';
import { CommitteeStructureComponent } from './components/committee-structure/committee-structure.component';
import { GalleryAndolanComponent } from './components/gallery-andolan/gallery-andolan.component';
import { UploadYourLetterComponent } from './components/upload-your-letter/upload-your-letter.component';
import { ViewGalleryAndLetterComponent } from './components/view-gallery-and-letter/view-gallery-and-letter.component';
import { ViewGalleryComponent } from './components/view-gallery/view-gallery.component';
import { ViewLettersComponent } from './components/view-letters/view-letters.component';

import { LetterPunjabComponent } from './components/letter-punjab/letter-punjab.component';
import { LetterRajasthanComponent } from './components/letter-rajasthan/letter-rajasthan.component';
import { LettersAndhraPradeshComponent } from './components/letters-andhra-pradesh/letters-andhra-pradesh.component';
import { LettersArunachalPradeshComponent } from './components/letters-arunachal-pradesh/letters-arunachal-pradesh.component';
import { LettersAssamComponent } from './components/letters-assam/letters-assam.component';
import { LettersBiharComponent } from './components/letters-bihar/letters-bihar.component';
import { LettersChhattisgarhComponent } from './components/letters-chhattisgarh/letters-chhattisgarh.component';
import { LettersGoaComponent } from './components/letters-goa/letters-goa.component';
import { LettersGujaratComponent } from './components/letters-gujarat/letters-gujarat.component';
import { LettersHaryanaComponent } from './components/letters-haryana/letters-haryana.component';
import { LettersHimachalPradeshComponent } from './components/letters-himachal-pradesh/letters-himachal-pradesh.component';
import { LetterSikkimComponent } from './components/letter-sikkim/letter-sikkim.component';
import { LettersJharkhandComponent } from './components/letters-jharkhand/letters-jharkhand.component';
import { LettersKarnatakaComponent } from './components/letters-karnataka/letters-karnataka.component';
import { LettersKeralaComponent } from './components/letters-kerala/letters-kerala.component';
import { LettersMadhyaPradeshComponent } from './components/letters-madhya-pradesh/letters-madhya-pradesh.component';
import { LettersMaharashtraComponent } from './components/letters-maharashtra/letters-maharashtra.component';
import { LettersManipurComponent } from './components/letters-manipur/letters-manipur.component';
import { LettersMeghalayaComponent } from './components/letters-meghalaya/letters-meghalaya.component';
import { LettersMizoramComponent } from './components/letters-mizoram/letters-mizoram.component';
import { LettersNagalandComponent } from './components/letters-nagaland/letters-nagaland.component';
import { LettersOdishaComponent } from './components/letters-odisha/letters-odisha.component';
import { LetterTamilNaduComponent } from './components/letter-tamil-nadu/letter-tamil-nadu.component';
import { LetterTelanganaComponent } from './components/letter-telangana/letter-telangana.component';
import { LetterTripuraComponent } from './components/letter-tripura/letter-tripura.component';
import { LetterUttarakhandComponent } from './components/letter-uttarakhand/letter-uttarakhand.component';
import { LetterUttarPradeshComponent } from './components/letter-uttar-pradesh/letter-uttar-pradesh.component';
import { LetterWestBengalComponent } from './components/letter-west-bengal/letter-west-bengal.component';
import { OuridentityComponent } from './components/ouridentity/ouridentity.component';
import { OurIdentityTwoComponent } from './components/our-identity-two/our-identity-two.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';


const routes: Routes = [
  {path: 'letter-punjab', component: LetterPunjabComponent},
  {path: 'letter-rajasthan', component: LetterRajasthanComponent},
  {path: 'letters-andhra-pradesh', component: LettersAndhraPradeshComponent},
  {path: 'letters-arunachal-pradesh', component: LettersArunachalPradeshComponent},
  {path: 'letters-assam', component: LettersAssamComponent},
  {path: 'letters-bihar', component: LettersBiharComponent},
  {path: 'letters-chhattisgarh', component: LettersChhattisgarhComponent},
  {path: 'letters-goa', component: LettersGoaComponent},
  {path: 'letters-gujarat', component: LettersGujaratComponent},
  {path: 'letters-haryana', component: LettersHaryanaComponent},
  {path: 'letters-himachal-pradesh', component: LettersHimachalPradeshComponent},
  {path: 'letter-sikkim', component: LetterSikkimComponent},
  {path: 'letters-jharkhand', component: LettersJharkhandComponent},
  {path: 'letters-karnataka', component: LettersKarnatakaComponent},
  {path: 'letters-kerala', component: LettersKeralaComponent},
  {path: 'letters-madhya-pradesh', component: LettersMadhyaPradeshComponent},
  {path: 'letters-maharashtra', component: LettersMaharashtraComponent},
  {path: 'letters-manipur', component: LettersManipurComponent},
  {path: 'letters-meghalaya', component: LettersMeghalayaComponent},
  {path: 'letters-mizoram', component: LettersMizoramComponent},
  {path: 'letters-nagaland', component: LettersNagalandComponent},
  {path: 'letters-odisha', component: LettersOdishaComponent},
  {path: 'letter-tamil-nadu', component: LetterTamilNaduComponent},
  {path: 'letter-telangana', component: LetterTelanganaComponent},
  {path: 'letter-tripura', component: LetterTripuraComponent},
  {path: 'letter-uttarakhand', component: LetterUttarakhandComponent},
  {path: 'letter-uttar-pradesh', component: LetterUttarPradeshComponent},
  {path: 'letter-west-bengal', component: LetterWestBengalComponent},
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'organization-founder', component: OrganizationFounderComponent },
  { path: 'our-mission', component: OurMissionComponent },
  { path: 'mission-detail/:slug', component: MissionDetailComponent },
  { path: 'our-strength', component: OurStrengthComponent },
  { path: 'our-identity', component: OurIdentityComponent },
  { path: 'organization-structure', component: OrganizationStructureComponent },
  { path: 'organization-structure-page-2', component: OrganizationStructurePageTwoComponent },
  { path: 'our-vision', component: OurVisionComponent },
  { path: 'our-objective', component: OurObjectiveComponent },
  { path: 'objective-detail/:slug', component: ObjectiveDetailComponent },
  { path: 'our-movement', component: OurMovementComponent },
  { path: 'movement-detail/:slug', component: MovementDetailComponent },
  { path: 'bribery-free-india', component: BriberyFreeIndiaComponent },
  { path: 'bfi-detail/:slug', component: BfiDetailComponent },
  { path: 'busted-revolution', component: BustedRevolutionComponent },
  { path: 'busted-revolution-detail/:slug', component: BustedRevolutionDetailComponent},
  { path: 'busted-revolution-form', component: BustedRevolutionFormComponent },
  { path: 'expose-campaign', component: ExposeCampaignComponent },
  { path: 'expose-campaign-detail/:slug', component: ExposeCampaignDetailComponent},
  { path: 'expose-campaign-form', component: ExposeCampaignFormComponent },
  { path: 'image-gallery', component: ImageGalleryComponent },
  { path: 'video-gallery', component: VideoGalleryComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'locate-us', component: LocateUsComponent },
  { path: 'donate-us', component: DonateUsComponent },
  { path: 'donate-us-step2', component: DonateUsStep2Component },
  { path: 'thank-you-donation', component: ThankYouDonationComponent },
  { path: 'jobs', component: JobsComponent },
  { path: 'job-detail/:slug', component: JobDetailComponent },
  { path: 'job-by-filter/:slug', component: JobFilterComponent },
  { path: 'career-counselling', component: CareerCounsellingComponent },
  { path: 'career-counselling-form', component: CareerCounsellingFormComponent },
  { path: 'career-counselling-by-filter/:slug', component: CareercounsellingFilterComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news-detail/:slug', component: NewsDetailComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-detail/:slug', component: BlogDetailComponent },
  { path: 'membership', component: MembershipComponent },
  { path: 'membership-form', component: MembershipFormComponent },
  { path: 'become-volunteer', component: BecomeVolunteerComponent},
  { path: 'suggest-india', component: SuggestIndiaComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'user-dashboard', component: UserDashboardComponent, canActivate:  [AuthGuardService] },
  { path: 'user-profile', component: UserProfileComponent, canActivate:  [AuthGuardService] },
  { path: 'user-subscription', component: UserSubscriptionComponent, canActivate:  [AuthGuardService] },
  { path: 'user-membership', component: UserMembershipComponent, canActivate:  [AuthGuardService] },
  { path: 'user-updates', component: UserUpdatesComponent, canActivate:  [AuthGuardService] },
  { path: 'user-buy-membership', component: UserBuyMembershipComponent, canActivate:  [AuthGuardService] },
  { path: 'user-busted-revolution', component: UserBustedRevolutionComponent, canActivate:  [AuthGuardService] },
  { path: 'user-expose-campaign', component: UserExposeCampaignComponent, canActivate:  [AuthGuardService] },
  { path: 'user-expose-campaign-chats/:id', component: UserExposeCampaignChatsComponent, canActivate:  [AuthGuardService] },
  { path: 'user-busted-revolution-chats/:id', component: UserBustedRevolutionChatsComponent, canActivate:  [AuthGuardService] },
  { path: 'our-identity-andolan', component: OurIdentityAndolanComponent},
  { path: 'about-us-andolan', component: AboutUsAndolanComponent},
  { path: 'our-mision-andolan', component: OurMissionAndolanComponent},
  { path: 'our-vision-andolan', component: OurVisionAndolanComponent},
  { path: 'our-strength-andolan', component: OurStrengthAndolanComponent},
  { path: 'committee-founder', component: CommitteeFounderComponent},
  { path: 'committee-structure', component: CommitteeStructureComponent},
  { path: 'gallery-andolan', component: GalleryAndolanComponent},
  { path: 'upload-your-letter', component: UploadYourLetterComponent},
  { path: 'view-gallery-and-letter', component: ViewGalleryAndLetterComponent},
  { path: 'view-gallery', component: ViewGalleryComponent},
  { path: 'view-letters', component: ViewLettersComponent},
  { path: 'ouridentity', component: OuridentityComponent},
  { path: 'our-identity-two', component: OurIdentityTwoComponent},
  { path: 'thank-you', component: ThankYouComponent},
  { path: 'payment-gateway', component: PaymentGatewayComponent},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    useHash: true,
    preloadingStrategy: PreloadAllModules},
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
