import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-our-strength',
  templateUrl: './our-strength.component.html',
  styleUrls: ['./our-strength.component.scss']
})
export class OurStrengthComponent implements OnInit {
  strengthArray:any=[];
  data:any=[];
  imageurl:any;
  constructor(private restAPI: RESTApiService) { 
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.fetchStrength();
  }
  fetchStrength() {
    this.restAPI.get(environment.apiURL + environment.strengthAPI).subscribe(data =>{
      this.data = data;
      this.strengthArray = this.data.data.managestrength;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
