<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>जॉब</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item"><a [routerLink]="['/jobs']">जॉब</a></li> -->
                            <li class="breadcrumb-item active" aria-current="page">{{JobData.title}}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="job-detail-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="job-detail-wrap top-lay">
                    <div class="job-detail-box">
                        <div class="job-detail-thumb">
                            <img src="{{imageurl}}{{JobData.image}}" class="img-fluid" alt="">
                        </div>
                        <div class="job-detail-caption">
                            <h4 class="job-detail-title">{{JobData.title}}</h4>
                            <p class="job-detail-location"><i class="fas fa-map-marker"></i>{{JobData.city_name}}, {{JobData.state_name}}</p>
                            <span class="job-detail-designation">{{JobData.category_name}}</span>
                            <div class="rating">
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>    
                    </div>
                    
                    <div class="job-detail-detail-btn">
                        <a href="#" data-target="#applyjob" data-toggle="modal" class="btn btn-apply"><i class="far fa-star"></i>अप्लाई करे</a>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-8">
                <div class="job-detail-l">
                    <div class="row-bottom">
                        <h2 class="detail-title">जॉब के बारे में</h2>
                        <div class="job-desc-content" [innerHTML]="JobData.job_description">

                        </div>
                    </div>
                    <div class="row-bottom">
                        <h2 class="detail-title">आवश्यक योग्यता</h2>
                        <div class="job-skills-content" [innerHTML]="JobData.skill">

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="job-detail-r">
                    <div class="job-list-sidebar-widgets">
                        <div class="job-list-detail-wrap">
                            <div class="job-list-detail-wrap-header">
                                <h4>उम्मीदवार अवलोकन</h4>
                            </div>
                            <div class="job-list-detail-wrap-body">
                                <ul class="job-detail-list">
                                    
                                    <li>
                                        <h5>वेतन की पेशकश की</h5>
                                        <span>Rs. {{JobData.min_salary}} - Rs. {{JobData.max_salary}}</span>
                                    </li>
                                    
                                    <li>
                                        <h5>लिंग</h5>
                                        <span>{{JobData.gender}}</span>
                                    </li>
                                    
                                    <li>
                                        <h5>उद्योग</h5>
                                        <span>{{JobData.category_name}}</span>
                                    </li>
                                    
                                    <li>
                                        <h5>अनुभव</h5>
                                        <span>{{JobData.experiences}}</span>
                                    </li>
                                    
                                    <li>
                                        <h5>योग्यता</h5>
                                        <span>{{JobData.qualification}}</span>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div class="job-list-sidebar-widgets d-none">
                        <div class="job-list-detail-wrap">
                            <div class="job-list-detail-wrap-header">
                                <h4>Share this Job</h4>
                            </div>
                            <div class="job-list-detail-wrap-body">
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal applyjobmodal fade" id="applyjob" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Apply this Job</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="applyForm" (ngSubmit)="applySubmit()">
                    <div class="form-group row">
                        <div class="col-sm-6" style="display: none;">
                            <label class="label label-control">Job ID</label>
                            <input type="text" class="apply-control" formControlName="job_id" [(ngModel)]="JobData.job_id" placeholder="Enter Job ID" [ngClass]="{ 'is-invalid': apply_submitted && j.job_id.errors }">
                            <div *ngIf="apply_submitted && j.job_id.errors" class="invalid-login">
                                <div *ngIf="j.job_id.errors.required">Job ID is required</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label label-control">Name</label>
                            <input type="text" class="apply-control" formControlName="name" placeholder="Enter Name" [ngClass]="{ 'is-invalid': apply_submitted && j.name.errors }">
                            <div *ngIf="apply_submitted && j.name.errors" class="invalid-login">
                                <div *ngIf="j.name.errors.required">Name is required</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label label-control">Email</label>
                            <input type="text" class="apply-control" formControlName="email" placeholder="Enter Email" [ngClass]="{ 'is-invalid': apply_submitted && j.email.errors }">
                            <div *ngIf="apply_submitted && j.email.errors" class="invalid-login">
                                <div *ngIf="j.email.errors.required">ईमेल की आवश्यकता है</div>
                                <div *ngIf="j.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label label-control">Mobile No.</label>
                            <input type="text" class="apply-control" formControlName="contact" [pattern]="mobNumberPattern" placeholder="Enter Mobile No." [ngClass]="{ 'is-invalid': apply_submitted && j.contact.errors }">
                            <div *ngIf="apply_submitted && j.contact.errors" class="invalid-login">
                                <div *ngIf="j.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                <div *ngIf="j.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label label-control">Qualification</label>
                            <select class="apply-control" formControlName="qualification" [ngClass]="{ 'is-invalid': apply_submitted && j.qualification.errors }">
                                <option value="" selected="true">Select Qualification</option>
                                <option>Diploma</option>
                                <option>Upto 9th</option>
                                <option>10th Pass</option>
                                <option>12th Pass</option>
                                <option>Graduation</option>
                                <option>Post Graduation</option>
                            </select>
                            <div *ngIf="apply_submitted && j.qualification.errors" class="invalid-login">
                                <div *ngIf="j.qualification.errors.required">Qualificationis required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label label-control">Experience</label>
                            <select class="apply-control" formControlName="experiences" [ngClass]="{ 'is-invalid': apply_submitted && j.experiences.errors }">
                                <option value="" selected="true">Select Experience</option>
                                <option>Fresher</option>
                                <option>1-3 years</option>
                                <option>3-5 years</option>
                                <option>5+ years</option>
                            </select>
                            <div *ngIf="apply_submitted && j.experiences.errors" class="invalid-login">
                                <div *ngIf="j.experiences.errors.required">Experience is required</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label label-control">CV</label>
                            <input type="file" class="apply-control" formControlName="cv" (change)="uploadFile($event)" [ngClass]="{ 'is-invalid': apply_submitted && j.cv.errors }">
                            <div *ngIf="apply_submitted && j.cv.errors" class="invalid-login">
                                <div *ngIf="j.cv.errors.required">CV is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label label-control">State</label>
                            <select class="apply-control" formControlName="state" (change)="fetchCity($event)" [ngClass]="{ 'is-invalid': apply_submitted && j.state.errors }">
                                <option value="" selected="true">Select State</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="apply_submitted && j.state.errors" class="invalid-login">
                                <div *ngIf="j.state.errors.required">State is required</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label class="label label-control">City</label>
                            <select class="apply-control" formControlName="city" [ngClass]="{ 'is-invalid': apply_submitted && j.city.errors }">
                                <option value="" [selected]="true">Select City</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="apply_submitted && j.city.errors" class="invalid-login">
                                <div *ngIf="j.city.errors.required">City is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-proceed" type="submit">Apply Now</button>
                        </div>
                    </div>
                </form>
                <div *ngIf="loading" class="loading-preloader">
                    <div class="loadin-inner">
                        <i class="fas fa-spinner fa-spin"></i>
                        <h3>Please Wait... While We uploading your files.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>