<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>सजेस्ट इंडिया</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">सजेस्ट इंडिया</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="suggest-india" style="overflow: hidden;">
    <div class="containe-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-9 col-md-9 col-lg-7">
                <div class="inner">
                    <div class="sec-title">
                        <!-- <h2>सजेस्ट इंडिया</h2> -->
                        <div class="lower-text">राष्ट्रधर्म सेवा संगठन के मिशन, लक्ष्य, उददेश्य, क्रानित, आन्दोलन, व
                            अभियान को सफल, सार्थक व व्यापक बनाने के लिए हम, आपका इससे जुड़ें सुझावों, आइडिया (विचारों) व
                            समस्याओं को चाहते है तथा देशहित, जनहित, युवाहित व समाज हित की रक्षा, सुरक्षा, उन्नति, विकास
                            व समृद्धि के लिए योगदान व सहयोग देने हेतु सजेस्ट इंडिया में शामिल हों |</div>
                    </div>
                    <div class="form-contact">
                        <div *ngIf="!LoggedIn()">
                            <form [formGroup]="NewUserFrom" style="background: #f2f2f2; padding: 30px 14px" *ngIf="showMe">
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="name">
                                        <div *ngIf="newuser_submitted && new.name.errors" class="invalid-login">
                                            <div *ngIf="new.name.errors.required">पूरा नाम आवश्यक है</div>
                                        </div>
                                        <div *ngIf="new.name.touched && new.name.invalid" style="color: red">
                                            <div *ngIf="new.name.errors.required">
                                                पूरा नाम आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6" *ngIf="showMe5">
                                        <label class="label-control">मोबाइल नंबर<span style="color: red;">*</span></label>
                                        <br>
                                        <input type="text" style="width: 344px; height: 40px;" [(ngModel)]="mob_no2"
                                            disabled class="text-control" formControlName="contact">
                                        <p style="color: green;">{{message}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <form [formGroup]="registrationForm">
                                            <div class="form-group row">
                                                <div class="col-sm-6" style="display: none;">
                                                    <label class="label-control">पूरा नाम <span
                                                            style="color: red;">*</span></label>
                                                    <input type="text" class="form-control" formControlName="name"
                                                        [ngClass]="{ 'is-invalid':
                                                        registratiopn_submitted && r.name.errors }">
                                                    <div *ngIf="registratiopn_submitted &&
                                                        r.name.errors" class="invalid-login">
                                                        <!-- <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div> -->
                                                    </div>
                                                    <span *ngIf="r.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span>
                                                </div>
                                                <div *ngIf="showMe4">
                                                    <label class="label-control">मोबाइल नंबर
                                                        <span style="color: red;">*</span></label>
                                                    <input style="width: 360px; height: 40px;" type="number"
                                                        class="form-control" [pattern]="mobNumberPattern"
                                                        formControlName="contact" [ngClass]="{ 'is-invalid':
                                                        registratiopn_submitted && r.contact.errors
                                                        }" maxlength="10">
                                                    <!-- <span *ngIf="r.contact.invalid" class="errormssg">मोबाइल नंबर आवश्यक
                                                        है</span> -->
                                                    <div *ngIf="registratiopn_submitted &&
                                                        r.contact.errors" class="invalid-login">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                        <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                            नंबर 10 अंकों का होना चाहिए
                                                        </div>
                                                    </div>
                                                    <div *ngIf="r.contact.touched &&
                                                    r.contact.invalid" style="color: red">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                            <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                                नंबर 10 अंकों का होना चाहिए
                                                            </div>
                                                    </div>
                                                    <button class="btn btn-dark" (click)="registrationSubmit()"
                                                        style="margin-top: 25px;" [hidden]="r.contact.invalid"
                                                        *ngIf="showMe2">अपना
                                                        नंबर
                                                        सत्यापित करें
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <form [formGroup]="OTPVForm" (ngSubmit)="OTPVerifySubmit()"
                                    style="padding: 30px 14px; padding-top: 0px;" *ngIf="showMe3">
                                    <div class="form-group row" style="display: none;">
                                        <div class="col-sm-6">
                                            <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                            <input [(ngModel)]="full_name2" type="text" class="form-control"
                                                formControlName="name" [ngClass]="{
                                                    'is-invalid':
                                                    otpverify_submitted && vrfy.name.errors
                                                    }">
                                        </div>
                                        <div class="col-sm-6">
                                            <label class="label-control">
                                                मोबाइल नंबर <span style="color: red;">*</span></label>
                                            <br>
                                            <input type="number" [(ngModel)]="mob_no" class="text-control"
                                                formControlName="contact" [ngClass]="{ 'is-invalid':
                                                    otpverify_submitted && vrfy.contact.errors
                                                    }">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label class="label-control">6
                                                अंक ओटीपी <span style="color: red;">*</span></label>
                                            <input type="number" class="form-control" placeholder="Enter OTP"
                                                [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid':
                                                    otpverify_submitted && vrfy.otp.errors }">
                                            <div *ngIf="otpverify_submitted &&
                                                    vrfy.contact.errors" class="invalid-login">
                                                <div *ngIf="vrfy.otp.errors.required">ओटीपी
                                                    आवश्यक है</div>
                                                <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6
                                                    अंकों का होना चाहिए</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <button class="btn btn-dark" type="submit" style="margin-top: 28px;">ओटीपी
                                                सत्यापित करें
                                            </button>
                                        </div>
            
                                    </div>
                                </form>
            
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="email"
                                            [ngClass]="{ 'is-invalid': newuser_submitted && new.email.errors }">
                                        <div *ngIf="newuser_submitted && new.email.errors" class="invalid-login">
                                            <div *ngIf="new.email.errors.required">ईमेल आवश्यक है</div>
                                            <div *ngIf="new.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                        </div>
                                        <div *ngIf="new.email.touched && new.email.invalid" style="color: red">
                                            <div *ngIf="new.email.errors.required">
                                                ईमेल आवश्यक है
                                            </div>
                                            <div *ngIf="new.email.errors.email">
                                                ईमेल एक वैध ईमेल पता होना चाहिए
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">वैकल्पिक मोबाइल
                                            नंबर</label>
                                        <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="alternate_mobile_number">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">लिंग <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" value="female"> स्त्री
                                        <br>
                                        <div *ngIf="new.gender.touched && new.gender.invalid" style="color: red">
                                            <div *ngIf="new.gender.errors.required">
                                                लिंग आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.gender.invalid" class="errormssg">लिंग आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">क्या आप भारत के
                                            नागरिक हैं ? <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="is_indian_citizen" value="yes">
                                        हां&nbsp;&nbsp;
                                        <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                                        <br>
                                        <div *ngIf="new.is_indian_citizen.touched && new.is_indian_citizen.invalid"
                                            style="color: red">
                                            <div *ngIf="new.is_indian_citizen.errors.required">
                                                क्या आप भारत के नागरिक
                                                हैं ? आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.is_indian_citizen.invalid" class="errormssg">क्या आप भारत के नागरिक
                                            हैं ? आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">व्हाट्सएप नं</label>
                                        <input type="number" class="form-control" formControlName="whatsapp_number">
            
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">आवास का पता
                                            <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="address" [ngClass]="{ 'is-invalid':
                                                                              newuser_submitted && new.address.errors
                                                                              }">
                                        <div *ngIf="newuser_submitted &&
                                                                      new.address.errors" class="invalid-login">
                                            <div *ngIf="new.address.errors.required">आवास
                                                का पता आवश्यक है</div>
                                        </div>
                                        <div *ngIf="new.address.touched && new.address.invalid" style="color: red">
                                            <div *ngIf="new.address.errors.required">
                                                आवास
                                                का पता आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">वर्तमान निवास का देश <span
                                            style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="country" (change)="fetchState($event)"
                                            [ngClass]="{ 'is-invalid': newuser_submitted && new.country .errors }">
                                            <option value="" selected>देश चुनें</option>
                                            <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="new.country.touched && new.country.invalid" style="color: red">
                                            <div *ngIf="new.country.errors.required">
                                                वर्तमान निवास का देश आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक
                                            है</span> -->
                                        <div *ngIf="newuser_submitted && new.country.errors" class="invalid-login">
                                            <div *ngIf="new.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">राज्य <span style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                            [ngClass]="{ 'is-invalid':
                                                                              newuser_submitted && new.state.errors }">
                                            <option value="" [selected]="true">राज्य
                                                चुनें</option>
                                            <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <span *ngIf="new.state.invalid" class="errormssg">राज्य
                                            आवश्यक है</span> -->
                                        <div *ngIf="new.state.touched && new.state.invalid" style="color: red">
                                            <div *ngIf="new.state.errors.required">
                                                राज्य
                                                आवश्यक है
                                            </div>
                                        </div>
                                        <div *ngIf="newuser_submitted &&
                                                                      new.state.errors" class="invalid-login">
                                            <div *ngIf="new.state.errors.required">राज्य
                                                आवश्यक है</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
            
                                    <div class="col-sm-6">
                                        <label class="label-control">जिला <span style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="city" [ngClass]="{ 'is-invalid':
                                                                              newuser_submitted && new.city.errors }">
                                            <option value="" [selected]="true">जिला
                                                चुनें</option>
                                            <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <span *ngIf="new.city.invalid" class="errormssg">जिला
                                            आवश्यक है</span> -->
                                        <div *ngIf="new.city.touched && new.city.invalid" style="color: red">
                                            <div *ngIf="new.city.errors.required">
                                                जिला
                                                आवश्यक है
                                            </div>
                                        </div>
                                        <div *ngIf="newuser_submitted &&
                                                                      new.city.errors" class="invalid-login">
                                            <div *ngIf="new.city.errors.required">जिला
                                                आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">तहसील का नाम दर्ज करें
                                            <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="tehsil" [ngClass]="{ 'is-invalid':
                                                                      newuser_submitted && new.tehsil.errors
                                                                              }">
                                        <!-- <span *ngIf="new.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक
                                            है</span> -->
                                        <div *ngIf="newuser_submitted &&
                                                                      new.teshil.errors" class="invalid-login">
                                            <div *ngIf="new.tehsil.errors.required">तहसील का नाम दर्ज करें आवश्यक है</div>
                                        </div>
                                        <div *ngIf="new.tehsil.touched && new.tehsil.invalid" style="color: red">
                                            <div *ngIf="new.tehsil.errors.required">
                                                तहसील का नाम दर्ज करें आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row justify-content-center">
                                    <div class="col-sm-6">
                                        <label>पासवर्ड <span style="color: red;">*</span></label>
                                        <input type="password" class="form-control" [(ngModel)]="password"
                                            formControlName="password">
                                        <div *ngIf="new.password.touched && new.password.invalid" style="color: red">
                                            <!-- <div *ngIf="new.password.errors.required">
                                                  पासवर्ड की आवश्यकता है।
                                                </div> -->
                                            <div *ngIf="new.password.errors.required">
                                                पासवर्ड कम से कम 8 वर्णों का होना चाहिए
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>पासवर्ड की पुष्टि कीजिये <span style="color: red;">*</span></label>
                                        <input type="password" class="form-control" formControlName="password_confirmation"
                                            pattern="{{password}}" [(ngModel)]="password_confirmation">
                                        <div *ngIf="new.password_confirmation.touched && new.password_confirmation.invalid"
                                            style="color: red">
                                            <div *ngIf="new.password_confirmation.invalid">
                                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                            </div>
                                            <!-- <div *ngIf="new.password_confirmation.errors.pattern">
                                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" (click)="clearForm()">रीसेट
                                            करें</button> &nbsp;&nbsp;
                                        <button class="btn btn-dark" [disabled]="NewUserFrom.invalid"
                                            (click)="NewuserSubmit()">अभी
                                            जमा करे</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <form [formGroup]="SuggestForm" (ngSubmit)="SuggestSubmit()" *ngIf="LoggedIn()">
                            <div class="form-goroup row">
                                <div class="col-sm-6">
                                    <label class="label-control">दिनांक : <span style="color: red;">*</span></label>
                                    <input type="date" class="form-control" formControlName="date"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.date.errors }">
                                    <div *ngIf="suggest_submitted && s.date.errors" class="invalid-login">
                                        <div *ngIf="s.date.errors.required">दिनांक आवश्यक है</div>
                                    </div>
                                    <span *ngIf="s.date.invalid" class="errormssg">दिनांक आवश्यक है</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="full_name" readonly formControlName="name"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.name.errors }">
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">पिता का नाम <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="father_name"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.father_name.errors }">
                                    <div *ngIf="suggest_submitted && s.father_name.errors" class="invalid-login">
                                        <div *ngIf="s.father_name.errors.required">पिता का नाम आवश्यक है</div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-6">
                                    <label class="label-control">मोबाइल नंबर <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="email2" readonly formControlName="contact"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.contact.errors }">
                                </div> -->
                                

                            </div>
                            <div class="form-group row">
                                
                                <div class="col-sm-6">
                                    <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="email"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.email.errors }">
                                    <div *ngIf="suggest_submitted && s.email.errors" class="invalid-login">
                                        <div *ngIf="s.email.errors.required">ईमेल आवश्यक है</div>
                                        <div *ngIf="s.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">मोबाइल नंबर<span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" disabled [(ngModel)]="mob_no2" formControlName="contact"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.contact.errors }">
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">वैकल्पिक मोबाइल नंबर</label>
                                    <input type="text" class="form-control" [pattern]="mobNumberPattern"
                                        formControlName="alternate_mobile_number">
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">लिंग <span style="color: red;">*</span></label> <br>
                                    <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                                    <input type="radio" formControlName="gender" value="female"> स्त्री
                                    <br>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">व्यवसाय <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="business"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.business.errors }">
                                    <div *ngIf="suggest_submitted && s.business.errors" class="invalid-login">
                                        <div *ngIf="s.business.errors.required">व्यवसाय आवश्यक है</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">व्हाट्सएप नं <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="whatsapp_number">
                                   
                                </div>

                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">फेसबुक युआरएल <span
                                            style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="facebook_url">
                                    
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">क्या आप भारत के नागरिक हैं ? <span
                                            style="color: red;">*</span></label>
                                    <br>
                                    <input type="radio" formControlName="is_indian_citizen" value="yes"> हां&nbsp;&nbsp;
                                    <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                                    <br>

                                </div>

                            </div>

                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">आवास का पता <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="address"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.address.errors }">
                                    <div *ngIf="suggest_submitted && s.address.errors" class="invalid-login">
                                        <div *ngIf="s.address.errors.required">आवास का पता आवश्यक है</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">वर्तमान निवास का देश <span
                                            style="color: red;">*</span></label>
                                    <select class="form-control" formControlName="country" (change)="fetchState($event)"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.country.errors }">
                                        <option value="" selected>देश चुनें</option>
                                        <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="suggest_submitted && s.country.errors" class="invalid-login">
                                        <div *ngIf="s.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-sm-6">
                                    <label class="label-control">राज्य <span style="color: red;">*</span></label>
                                    <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.state.errors }">
                                        <option value="" selected>राज्य चुनें</option>
                                        <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="suggest_submitted && s.state.errors" class="invalid-login">
                                        <div *ngIf="s.state.errors.required">राज्य आवश्यक है</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label class="label-control">जिला <span style="color: red;">*</span></label>
                                    <select class="form-control" formControlName="city"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.city.errors }">
                                        <option value="" [selected]="true">जिला चुनें</option>
                                        <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="suggest_submitted && s.city.errors" class="invalid-login">
                                        <div *ngIf="s.city.errors.required">जिला आवश्यक है</div>
                                    </div>
                                </div>
                                <br><br>
                                <div class="col-sm-6">
                                    <label class="label-control">तहसील का नाम दर्ज करें
                                        <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="tehsil" [ngClass]="{ 'is-invalid':
                                    movement_submitted && s.tehsil.errors
                                            }">
                                    <div *ngIf="movement_submitted &&
                                            s.teshil.errors" class="invalid-login">
                                        <div *ngIf="s.tehsil.errors.required">तहसील का नाम दर्ज करें आवश्यक है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <!-- <div class="col-sm-6">
                                    <input type="radio" formControlName="gender" value="male" (click)="toogleTag1()"> सुझाव/विचार&nbsp;&nbsp;
                                    <input type="radio" formControlName="gender" value="female" (click)="toogleTag2()"> समस्या
                                </div> -->
                                <br>
                                <div class="col-sm-6">
                                    <label class="label-control">सुझाव/विचार <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="subject"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.subject.errors }">
                                    <div *ngIf="suggest_submitted && s.subject.errors" class="invalid-login">
                                        <div *ngIf="s.subject.errors.required">सुझाव/विचार आवश्यक है</div>
                                    </div>
                                </div>
                                <br>
                                <div class="col-sm-6">
                                    <label class="label-control">समस्या <span style="color: red;">*</span></label>
                                    <input type="text" class="form-control" formControlName="feedback"
                                        [ngClass]="{ 'is-invalid': suggest_submitted && s.feedback.errors }">
                                    <div *ngIf="suggest_submitted && s.feedback.errors" class="invalid-login">
                                        <div *ngIf="s.feedback.errors.required">समस्या आवश्यक है</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row justify-content-center">
                                <div class="col-sm-6">
                                    <label>पासवर्ड</label>
                                    <input type="password" class="form-control" [(ngModel)]="password"
                                        formControlName="password" minlength="6">
                                    <div *ngIf="s.password.touched &&
                                        s.password.invalid" class="alert alert-danger">
                                        <div *ngIf="s.password.errors.required">पासवर्ड की आवश्यकता है।</div>
                                        <div *ngIf="s.password.errors.minlength">पासवर्ड कम से कम 8 वर्णों का होना
                                            चाहिए</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <label>पासवर्ड की पुष्टि कीजिये</label>
                                    <input type="password" class="form-control"
                                        formControlName="password_confirmation" pattern="{{password}}"
                                        [(ngModel)]="password_confirmation">
                                    <div *ngIf="s.password_confirmation.touched &&
                                        s.password_confirmation.invalid" class="alert
                                        alert-danger">
                                        <div *ngIf="s.password_confirmation.errors.pattern">
                                            पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                                    </div>
                                </div>
                            </div>
                            
                            <br>
                            <div class="form-group row">
                                <div class="col-sm-12 text-center">
                                    <button class="btn btn-submit" type="reset" value="Reset">अभी रीसेट करें</button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button class="btn btn-submit" type="submit" [disabled]="SuggestForm.invalid">अभी जमा करे</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 col-lg-3 col-md-3 col-12">
                <div class="membership-right-sec">
                    <div class="boxes-section">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="tab">
                                    <i class="fa fa-hand-holding-heart"></i>
                                    <h4>सहयोग करें</h4>
                                    <p>आपका वित्तीय दान भारत के युवाओं की ओर से काम करने का सबसे आसान तरीका है। हम
                                        व्यक्तिगत उपहार, जमा दान, नींव</p>
                                    <a href="#/donate-us">अभी दान कीजिए</a>
                                </div>
                                <div class="tab2">
                                    <i class="fa fa-user"></i>
                                    <h4>संगठन में शामिल हों</h4>
                                    <p>राष्ट्रधर्म सेवा संगठन भारत की सबसे तेजी से बढ़ती सामाजिक संगठन है। आज एक सदस्य
                                        के रूप में शामिल हों और भारत </p>
                                    <a href="#/suggest-india">सदस्य बनें </a>
                                </div>
                                <div class="tab3">
                                    <i class="fa fa-bullhorn"></i>
                                    <h4>हमारे अभियान</h4>
                                    <p>गुप्त रूप से कार्यरत और सक्रिय कामकाजी और सेवानिवृत्त अधिकारी, क्लर्क, सैनिक,
                                        कर्मचारी, रिश्वतखोरी में भ्रष्ट को</p>
                                    <a href="#/expose-campaign">अभियान में भाग लें </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>