import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/_helpers/mustmatch.validator';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  UserProfileForm: FormGroup;
  UserSecurityForm:FormGroup;
  statesArray: any=[];
  data:any = [];
  UserDetailsArray:any=[];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
  cityArray: any = [];
  userprof_submitted = false;
  usersecurity_submitted = false;
  constructor(private restAPI:RESTApiService,
    private router:Router,
    private formBuilder: FormBuilder,
    private toast: ToastrService) { }

  ngOnInit(): void {
    this.UserProfileForm = this.formBuilder.group({
      image: [''],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]]
    });
    this.UserSecurityForm = this.formBuilder.group({
      current_password: ['', Validators.required],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]]
      }, {
        validator: MustMatch('new_password', 'confirm_password')
    });
    this.fetchState();
    this.fetchUserDetails();
  }

  get up() { return this.UserProfileForm.controls; }
  get us() { return this.UserSecurityForm.controls; }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.UserProfileForm.patchValue({
      image: file
    });
    this.UserProfileForm.get('image').setValue(file);
    console.log(file);
  }

  UserProfileSubmit(){
    this.userprof_submitted = true;
    // stop here if form is invalid
    if (this.UserProfileForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("image", this.UserProfileForm.get('image').value);
      formData.append("name", this.UserProfileForm.get('name').value);
      formData.append("email", this.UserProfileForm.get('email').value);
      formData.append("contact", this.UserProfileForm.get('contact').value);
      formData.append("dob", this.UserProfileForm.get('dob').value);
      formData.append("gender", this.UserProfileForm.get('gender').value);
      formData.append("address", this.UserProfileForm.get('address').value);
      formData.append("state", this.UserProfileForm.get('state').value);
      formData.append("city", this.UserProfileForm.get('city').value);
      //let data = this.CC2Form.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserProfileUpdateAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success('Profile Update Successfully');
        this.userprof_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchState() {
    this.restAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  UserSecuritySubmit(){
    this.usersecurity_submitted = true;
    // stop here if form is invalid
    if (this.UserSecurityForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.UserSecurityForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserSecurityUpdateAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('Your Password has Changed successfully');
        this.UserSecurityForm.reset();
        this.usersecurity_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchUserDetails() {
    this.restAPI.get(environment.apiURL + environment.UserfetchDetails).subscribe(data => {
      this.data = data;
      let e={"target":{"value":this.data.data.user.state}};
      console.log(e);
      this.fetchCity(e);
      this.UserDetailsArray = this.data.data.user;
      console.log(this.UserDetailsArray);
    }, err => {
      console.log(err);
    });
  }

}
