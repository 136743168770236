<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>संगठन संरचना</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        </ol>
                      </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="strength-page-section">
    <div class="container">
        <div class="heading-title">
            <div class="title">
                <h3>आर डी एस एस <span>में आपका स्वागत है</span></h3>
                <div [innerHTML]="OrgStructureData.description"></div>
                <h3 [innerHTML]="OrgStructureData.title"></h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-3" *ngFor="let item of CommitteeArrayLv1">
                <div class="card">
					<div class="card-body">
						<img src="{{imageurl}}{{item.image}}" class="img-fluid" alt="profile">
						<h5>{{item.name}}</h5>
						<p>{{item.designation}}</p>
					</div>
				</div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-3" *ngFor="let item of CommitteeArrayLv2">
                <div class="card">
					<div class="card-body">
						<img src="{{imageurl}}{{item.image}}" class="img-fluid" alt="profile">
						<h5>{{item.name}}</h5>
						<p>{{item.designation}}</p>
					</div>
				</div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12">
                <div class="info" [innerHTML]="OrgStructureData.objective_content">
        
                </div>
              </div>
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title">
                        <h3 [innerHTML]="OrgStructureData.heading"></h3>
                     </div>
                </div>
                <div class="info" [innerHTML]="OrgStructureData.table_structure_content">

                </div>
            </div>

            <div class="col-sm-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-center">
                  <li class="page-item disabled">
                    <a class="page-link" tabindex="-1">पिछला</a>
                  </li>
                  <li class="page-item"><a class="page-link">1</a></li>
                  <li class="page-item"><a class="page-link" [routerLink]="['/organization-structure-page-2']">2</a></li>
                  <li class="page-item">
                    <a class="page-link" [routerLink]="['/organization-structure-page-2']">आगे</a>
                  </li>
                </ul>
              </nav>
            </div>
        </div>
    </div>
</section>
