<section class="slider-section">
	<div id="myCarousel" class="carousel slide carousel-fade" data-interval="4000" data-ride="carousel">
		<div class="carousel-inner">
			<div class="carousel-item" [ngClass]="{'active' : i == 0}" *ngFor="let item of SliderArray let i = index">
				<img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid" style="width:100%;">
				<div class="mask flex-center">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-md-7 col-12 order-md-1 order-2">
								<div class="content"><!--
									<i class="fas fa-quote-left quote_start"></i>-->
								<h4 class="light_text" style="color:{{item.title_colour_code}};">{{item.title}} </h4>
									<p style="color:{{item.paragrapgh_colour_code}};">{{item.slider_description}}</p>
									<a [routerLink]="item.button_link">यहाँ क्लिक करे और आगे पढ़े</a>
								</div>
							</div>
							<div class="col-md-5 col-12 order-md-2 order-1"><img src="" class="mx-auto"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> 
			<span class="carousel-control-prev-icon" aria-hidden="true"></span> 
			<span class="sr-only">Previous</span> 
		</a> 
		<a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> 
			<span class="carousel-control-next-icon" aria-hidden="true"></span> 
			<span class="sr-only">Next</span> 
		</a> 
	</div>
</section>