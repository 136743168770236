<section class="page-header-section" style="background:
    linear-gradient(#020202c7, #02020273),
    url(breadcrumb.jpg);background-repeat: no-repeat;
    background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>हमारा आंदोलन</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य
                                    पृष्ठ</a></li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="movement-page-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <h3 class="classtit">युवा जागरण व जन जागरण हेतु</h3>
                <figure class="movement-box" *ngFor="let item of movementArray">
                    <div class="movement-box-inner">
                        <div class="inner-box">
                            <h3>{{item.title}}</h3>
                            <p>{{item.short_description}}</p>
                            <!-- <div class="location-date">
                                <ul>
                                    <li><i class="fas fa-map-marker"></i> {{item.location}}</li>
                                    <li><i class="fas fa-calendar"></i> {{item.created_at}}</li>
                                </ul>
                            </div> -->
                        </div>
                        <div class="inner-box align-self-center">
                            <div class="read-more-btn">
                                <a [routerLink]="['/movement-detail',
                                    item.slug]" class="btn btn-vd">आगे पढ़े</a>
                            </div>
                        </div>
                    </div>
                </figure>
            </div>
            <div class="col-sm-6">
                <br>
                <div class="movement-sidebar">
                    <div class="form-movement">
                        <button *ngIf="LoggedIn()" style="background: #f58634;
                        padding: 4px 16px;
                        color: #fff;
                        white-space: nowrap;
                        font-size: 15px;">Subscribe </button>
                        <div class="form-movement" *ngIf="!LoggedIn()">
                            <br>
                            <h3 class="classtitle">सदस्य के रूप में शामिल हों और
                                हमारे<br>आंदोलन का हिस्सा बनें</h3>
                            <br>
                            <form [formGroup]="NewUserFrom" style="background: #f2f2f2; padding: 30px 14px" *ngIf="showMe">
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="name">
                                        <div *ngIf="newuser_submitted && new.name.errors" class="invalid-login">
                                            <div *ngIf="new.name.errors.required">पूरा नाम आवश्यक है</div>
                                        </div>
                                        <div *ngIf="new.name.touched && new.name.invalid" style="color: red">
                                            <div *ngIf="new.name.errors.required">
                                                पूरा नाम आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6" *ngIf="showMe5">
                                        <label class="label-control">मोबाइल नंबर<span style="color: red;">*</span></label>
                                        <br>
                                        <input type="text" style="width: 288px; height: 40px;" [(ngModel)]="mob_no2"
                                            disabled class="text-control" formControlName="contact">
                                        <p style="color: green;">{{message}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <form [formGroup]="registrationForm">
                                            <div class="form-group row">
                                                <div class="col-sm-6" style="display: none;">
                                                    <label class="label-control">पूरा नाम <span
                                                            style="color: red;">*</span></label>
                                                    <input type="text" class="form-control" formControlName="name"
                                                        [ngClass]="{ 'is-invalid':
                                                        registratiopn_submitted && r.name.errors }">
                                                    <div *ngIf="registratiopn_submitted &&
                                                        r.name.errors" class="invalid-login">
                                                        <!-- <div *ngIf="r.name.errors.required">पूरा नाम आवश्यक है</div> -->
                                                    </div>
                                                    <span *ngIf="r.name.invalid" class="errormssg">पूरा नाम आवश्यक है</span>
                                                </div>
                                                <div *ngIf="showMe4">
                                                    <label class="label-control">मोबाइल नंबर
                                                        <span style="color: red;">*</span></label>
                                                    <input style="width: 288px; height: 40px;" type="number"
                                                        class="form-control" [pattern]="mobNumberPattern"
                                                        formControlName="contact" [ngClass]="{ 'is-invalid':
                                                        registratiopn_submitted && r.contact.errors
                                                        }" maxlength="10">
                                                    <!-- <span *ngIf="r.contact.invalid" class="errormssg">मोबाइल नंबर आवश्यक
                                                        है</span> -->
                                                    <div *ngIf="registratiopn_submitted &&
                                                        r.contact.errors" class="invalid-login">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                        <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                            नंबर 10 अंकों का होना चाहिए
                                                        </div>
                                                    </div>
                                                    <div *ngIf="r.contact.touched &&
                                                    r.contact.invalid" style="color: red">
                                                        <div *ngIf="r.contact.errors.required">मोबाइल
                                                            नंबर आवश्यक है</div>
                                                            <div *ngIf="r.contact.errors.pattern">मोबाइल
                                                                नंबर 10 अंकों का होना चाहिए
                                                            </div>
                                                    </div>
                                                    <button class="btn btn-dark" (click)="registrationSubmit()"
                                                        style="margin-top: 25px;" [hidden]="r.contact.invalid"
                                                        *ngIf="showMe2">अपना
                                                        नंबर
                                                        सत्यापित करें
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <form [formGroup]="OTPVForm" (ngSubmit)="OTPVerifySubmit()"
                                    style="padding: 30px 14px; padding-top: 0px;" *ngIf="showMe3">
                                    <div class="form-group row" style="display: none;">
                                        <div class="col-sm-6">
                                            <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                            <input [(ngModel)]="full_name2" type="text" class="form-control"
                                                formControlName="name" [ngClass]="{
                                                    'is-invalid':
                                                    otpverify_submitted && vrfy.name.errors
                                                    }">
                                        </div>
                                        <div class="col-sm-6">
                                            <label class="label-control">
                                                मोबाइल नंबर <span style="color: red;">*</span></label>
                                            <br>
                                            <input type="number" [(ngModel)]="mob_no" class="text-control"
                                                formControlName="contact" [ngClass]="{ 'is-invalid':
                                                    otpverify_submitted && vrfy.contact.errors
                                                    }">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label class="label-control">6
                                                अंक ओटीपी <span style="color: red;">*</span></label>
                                            <input type="number" class="form-control" placeholder="Enter OTP"
                                                [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid':
                                                    otpverify_submitted && vrfy.otp.errors }">
                                            <div *ngIf="otpverify_submitted &&
                                                    vrfy.contact.errors" class="invalid-login">
                                                <div *ngIf="vrfy.otp.errors.required">ओटीपी
                                                    आवश्यक है</div>
                                                <div *ngIf="vrfy.otp.errors.pattern">ओटीपी 6
                                                    अंकों का होना चाहिए</div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <button class="btn btn-dark" type="submit" style="margin-top: 28px;">ओटीपी
                                                सत्यापित करें
                                            </button>
                                        </div>
    
                                    </div>
                                </form>
    
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="email"
                                            [ngClass]="{ 'is-invalid': newuser_submitted && new.email.errors }">
                                        <div *ngIf="newuser_submitted && new.email.errors" class="invalid-login">
                                            <div *ngIf="new.email.errors.required">ईमेल आवश्यक है</div>
                                            <div *ngIf="new.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                        </div>
                                        <div *ngIf="new.email.touched && new.email.invalid" style="color: red">
                                            <div *ngIf="new.email.errors.required">
                                                ईमेल आवश्यक है
                                            </div>
                                            <div *ngIf="new.email.errors.email">
                                                ईमेल एक वैध ईमेल पता होना चाहिए
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.email.invalid" class="errormssg">ईमेल आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">वैकल्पिक मोबाइल
                                            नंबर</label>
                                        <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="alternate_mobile_number">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">लिंग <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" value="female"> स्त्री
                                        <br>
                                        <div *ngIf="new.gender.touched && new.gender.invalid" style="color: red">
                                            <div *ngIf="new.gender.errors.required">
                                                लिंग आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.gender.invalid" class="errormssg">लिंग आवश्यक है</span> -->
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">क्या आप भारत के
                                            नागरिक हैं ? <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="is_indian_citizen" value="yes">
                                        हां&nbsp;&nbsp;
                                        <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                                        <br>
                                        <div *ngIf="new.is_indian_citizen.touched && new.is_indian_citizen.invalid"
                                            style="color: red">
                                            <div *ngIf="new.is_indian_citizen.errors.required">
                                                क्या आप भारत के नागरिक
                                                हैं ? आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.is_indian_citizen.invalid" class="errormssg">क्या आप भारत के नागरिक
                                            हैं ? आवश्यक है</span> -->
                                    </div>
                                </div>
                                <div class="form-group row">
                                    
                                    <div class="col-sm-6">
                                        <label class="label-control">व्हाट्सएप नं</label>
                                        <input type="number" class="form-control" formControlName="whatsapp_number">
    
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">आवास का पता
                                            <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="address" [ngClass]="{ 'is-invalid':
                                                                              newuser_submitted && new.address.errors
                                                                              }">
                                        <div *ngIf="newuser_submitted &&
                                                                      new.address.errors" class="invalid-login">
                                            <div *ngIf="new.address.errors.required">आवास
                                                का पता आवश्यक है</div>
                                        </div>
                                        <div *ngIf="new.address.touched && new.address.invalid" style="color: red">
                                            <div *ngIf="new.address.errors.required">
                                                आवास
                                                का पता आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.address.invalid" class="errormssg">आवास का पता आवश्यक है</span> -->
                                    </div>
                                </div>
                                
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">वर्तमान निवास का देश <span
                                            style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="country" (change)="fetchState($event)"
                                            [ngClass]="{ 'is-invalid': newuser_submitted && new.country .errors }">
                                            <option value="" selected>देश चुनें</option>
                                            <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <div *ngIf="new.country.touched && new.country.invalid" style="color: red">
                                            <div *ngIf="new.country.errors.required">
                                                वर्तमान निवास का देश आवश्यक है
                                            </div>
                                        </div>
                                        <!-- <span *ngIf="new.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक
                                            है</span> -->
                                        <div *ngIf="newuser_submitted && new.country.errors" class="invalid-login">
                                            <div *ngIf="new.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">राज्य <span style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="state" (change)="fetchCity($event)"
                                            [ngClass]="{ 'is-invalid':
                                                                              newuser_submitted && new.state.errors }">
                                            <option value="" [selected]="true">राज्य
                                                चुनें</option>
                                            <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <span *ngIf="new.state.invalid" class="errormssg">राज्य
                                            आवश्यक है</span> -->
                                        <div *ngIf="new.state.touched && new.state.invalid" style="color: red">
                                            <div *ngIf="new.state.errors.required">
                                                राज्य
                                                आवश्यक है
                                            </div>
                                        </div>
                                        <div *ngIf="newuser_submitted &&
                                                                      new.state.errors" class="invalid-login">
                                            <div *ngIf="new.state.errors.required">राज्य
                                                आवश्यक है</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
    
                                    <div class="col-sm-6">
                                        <label class="label-control">जिला <span style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="city" [ngClass]="{ 'is-invalid':
                                                                              newuser_submitted && new.city.errors }">
                                            <option value="" [selected]="true">जिला
                                                चुनें</option>
                                            <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <span *ngIf="new.city.invalid" class="errormssg">जिला
                                            आवश्यक है</span> -->
                                        <div *ngIf="new.city.touched && new.city.invalid" style="color: red">
                                            <div *ngIf="new.city.errors.required">
                                                जिला
                                                आवश्यक है
                                            </div>
                                        </div>
                                        <div *ngIf="newuser_submitted &&
                                                                      new.city.errors" class="invalid-login">
                                            <div *ngIf="new.city.errors.required">जिला
                                                आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">तहसील का नाम दर्ज करें
                                            <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="tehsil" [ngClass]="{ 'is-invalid':
                                                                      newuser_submitted && new.tehsil.errors
                                                                              }">
                                        <!-- <span *ngIf="new.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक
                                            है</span> -->
                                        <div *ngIf="newuser_submitted &&
                                                                      new.teshil.errors" class="invalid-login">
                                            <div *ngIf="new.tehsil.errors.required">तहसील का नाम दर्ज करें आवश्यक है</div>
                                        </div>
                                        <div *ngIf="new.tehsil.touched && new.tehsil.invalid" style="color: red">
                                            <div *ngIf="new.tehsil.errors.required">
                                                तहसील का नाम दर्ज करें आवश्यक है
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row justify-content-center">
                                    <div class="col-sm-6">
                                        <label>पासवर्ड <span style="color: red;">*</span></label>
                                        <input type="password" class="form-control" [(ngModel)]="password"
                                            formControlName="password">
                                        <div *ngIf="new.password.touched && new.password.invalid" style="color: red">
                                            <!-- <div *ngIf="new.password.errors.required">
                                                  पासवर्ड की आवश्यकता है।
                                                </div> -->
                                            <div *ngIf="new.password.errors.required">
                                                पासवर्ड कम से कम 8 वर्णों का होना चाहिए
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>पासवर्ड की पुष्टि कीजिये <span style="color: red;">*</span></label>
                                        <input type="password" class="form-control" formControlName="password_confirmation"
                                            pattern="{{password}}" [(ngModel)]="password_confirmation">
                                        <div *ngIf="new.password_confirmation.touched && new.password_confirmation.invalid"
                                            style="color: red">
                                            <div *ngIf="new.password_confirmation.invalid">
                                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                            </div>
                                            <!-- <div *ngIf="new.password_confirmation.errors.pattern">
                                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" (click)="clearForm()">रीसेट
                                            करें</button> &nbsp;&nbsp;
                                        <button class="btn btn-dark" [disabled]="NewUserFrom.invalid"
                                            (click)="NewuserSubmit()">अभी
                                            जमा करे</button>
                                    </div>
                                </div>
                            </form>
                            <!-- <form [formGroup]="movementForm" style="background: #f2f2f2; padding: 30px 14px"
                                *ngIf="showMe2">
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">पूरा नाम <span style="color: red;">*</span></label>
                                        <input [(ngModel)]="full_name" readonly type="text" class="form-control"
                                            formControlName="name">
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">मोबाइल नंबर<span
                                                style="color: red;">*</span></label> <br>
                                        <input type="text" [(ngModel)]="mob_no2" readonly class="text-control"
                                             formControlName="contact">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">ईमेल <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': movement_submitted && m.email.errors }">
                                        <div *ngIf="movement_submitted && m.email.errors" class="invalid-login">
                                            <div *ngIf="m.email.errors.required">ईमेल आवश्यक है</div>
                                            <div *ngIf="m.email.errors.email">ईमेल एक वैध ईमेल पता होना चाहिए</div>
                                        </div>
                                        <span *ngIf="m.email.invalid" class="errormssg">ईमेल आवश्यक है</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">वैकल्पिक मोबाइल
                                            नंबर</label>
                                        <input type="number" class="form-control" [pattern]="mobNumberPattern"
                                            formControlName="alternate_mobile_number">
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">व्हाट्सएप नं</label>
                                        <input type="number" class="form-control" formControlName="whatsapp_number">
                                        
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">लिंग <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="gender" value="male"> पुरुष&nbsp;&nbsp;
                                        <input type="radio" formControlName="gender" value="female"> स्त्री
                                        <br>
                                        <span *ngIf="m.gender.invalid" class="errormssg">लिंग आवश्यक है</span>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">क्या आप भारत के
                                            नागरिक हैं ? <span style="color: red;">*</span></label>
                                        <br>
                                        <input type="radio" formControlName="is_indian_citizen" value="yes">
                                        हां&nbsp;&nbsp;
                                        <input type="radio" formControlName="is_indian_citizen" value="no"> नहीं
                                        <br>
                                        <span *ngIf="m.is_indian_citizen.invalid" class="errormssg">क्या आप भारत के नागरिक हैं ? आवश्यक है</span>
                                    </div>
                                </div>


                                <div class="form-group row">
                                    <div class="col-sm-12">
                                        <label class="label-control">आवास का पता
                                            <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="address" [ngClass]="{ 'is-invalid':
                                                movement_submitted && m.address.errors
                                                }">
                                        <div *ngIf="movement_submitted &&
                                                m.address.errors" class="invalid-login">
                                            <div *ngIf="m.address.errors.required">आवास
                                                का पता आवश्यक है</div>
                                        </div>
                                        <span *ngIf="m.address.invalid" class="errormssg">आवास का पता आवश्यक है</span>
                                    </div>
                                    
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6">
                                        <label class="label-control">वर्तमान निवास का देश <span
                                                class="required-span">*</span></label>
                                        <select class="form-control" formControlName="country"
                                            (change)="fetchState($event)"
                                            [ngClass]="{ 'is-invalid': movement_submitted && m.country .errors }">
                                            <option value="" selected>देश चुनें</option>
                                            <option *ngFor="let item of countryArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <br>
                                        <span *ngIf="m.country.invalid" class="errormssg">वर्तमान निवास का देश आवश्यक है</span>
                                        <div *ngIf="movement_submitted && m.country.errors" class="invalid-login">
                                            <div *ngIf="m.country.errors.required">वर्तमान निवास का देश आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">राज्य <span style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="state"
                                            (change)="fetchCity($event)" [ngClass]="{ 'is-invalid':
                                                movement_submitted && m.state.errors }">
                                            <option value="" [selected]="true">राज्य
                                                चुनें</option>
                                            <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <br>
                                        <span *ngIf="m.state.invalid" class="errormssg">राज्य
                                            आवश्यक है</span>
                                        <div *ngIf="movement_submitted &&
                                                m.state.errors" class="invalid-login">
                                            <div *ngIf="m.state.errors.required">राज्य
                                                आवश्यक है</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    
                                    <div class="col-sm-6">
                                        <label class="label-control">जिला <span style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="city" [ngClass]="{ 'is-invalid':
                                                movement_submitted && m.city.errors }">
                                            <option value="" [selected]="true">जिला
                                                चुनें</option>
                                            <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                        <br>
                                        <span *ngIf="m.city.invalid" class="errormssg">जिला
                                            आवश्यक है</span>
                                        <div *ngIf="movement_submitted &&
                                                m.city.errors" class="invalid-login">
                                            <div *ngIf="m.city.errors.required">जिला
                                                आवश्यक है</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="label-control">तहसील का नाम दर्ज करें
                                            <span style="color: red;">*</span></label>
                                        <input type="text" class="form-control" formControlName="tehsil" [ngClass]="{ 'is-invalid':
                                        movement_submitted && m.tehsil.errors
                                                }">
                                        <span *ngIf="m.tehsil.invalid" class="errormssg">तहसील का नाम दर्ज करें आवश्यक है</span>
                                        <div *ngIf="movement_submitted &&
                                                m.teshil.errors" class="invalid-login">
                                            <div *ngIf="m.tehsil.errors.required">तहसील का नाम दर्ज करें आवश्यक है</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row justify-content-center">
                                    <div class="col-sm-6">
                                        <label>पासवर्ड</label>
                                        <input type="password" class="form-control" [(ngModel)]="password"
                                            formControlName="password">
                                        <span *ngIf="m.password.invalid" class="errormssg">पासवर्ड कम से कम 8 वर्णों का
                                            होना चाहिए</span>
                                        <div *ngIf="m.password.touched &&
                                                m.password.invalid" class="alert alert-danger">
                                            <div *ngIf="m.password.errors.required">पासवर्ड की आवश्यकता है।</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>पासवर्ड की पुष्टि कीजिये</label>
                                        <input type="password" class="form-control"
                                            formControlName="password_confirmation" pattern="{{password}}" [(ngModel)]="password_confirmation">
                                        <div *ngIf="m.password_confirmation.touched &&
                                                m.password_confirmation.invalid" class="alert
                                                alert-danger">
                                            <div *ngIf="m.password_confirmation.errors.pattern">
                                                पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-12 text-center">
                                        <button class="btn btn-dark" (click)="clearForm()">रीसेट
                                            करें</button> &nbsp;&nbsp;
                                        <button class="btn btn-dark" [disabled]="movementForm.invalid" (click)="movementSubmit()">अभी
                                            जमा करे</button>
                                    </div>
                                </div>
                            </form> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="movement-sidebar">
            <div class="boxes-section">
                <div class="row">
                    <div class="col-sm-3" *ngFor="let item of BoxesContentArray
                        | slice:0:4; let i= index">
                        <div class="tab tab-{{i+1}}">
                            <i class="main-i {{item.icon_text}}"></i>
                            <h5>{{item.title}}</h5>
                            <p>{{item.description}}</p>
                            <a [routerLink]="[item.link]">{{item.button_name}}
                                <i class="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade otp-modal" id="movement-verification" data-keyboard="false" data-backdrop="static" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="MOTPForm" (ngSubmit)="MOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">मोबाइल नंबर
                            </label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>
                                    कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                <p>
                                    एक ओटीपी भेजा गया है{{mob_no}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6">
                            <label class="label-control">पूरा नाम </label>
                            <input type="text" class="form-control" [(ngModel)]="full_name" disabled
                                formControlName="name">
                        </div>
                        <div class="col-sm-6">
                            <label class="label-control">ईमेल</label>
                            <input type="text" class="form-control" formControlName="email" disabled
                                [(ngModel)]="email2">
                        </div>
                    </div>
                    <div class="form-group row">

                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label>पासवर्ड</label>
                            <input type="password" class="form-control" [(ngModel)]="pass" formControlName="password">
                            <password-strength-meter [password]="pass" [minPasswordLength]="8" [colors]="['darkred',
                                'orangered', 'orange']"></password-strength-meter>
                            <small id="passwordHelpBlock" class="form-text
                                text-muted">

                                आपके पासवर्ड में कम से कम एक अपरकेस, एक विशेष
                                चार और कम से कम 8 वर्ण होने चाहिए
                            </small>
                            <div *ngIf="mo.password.touched &&
                                mo.password.invalid" class="alert alert-danger">
                                <div *ngIf="mo.password.errors.required">Password
                                    is required.</div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label>पासवर्ड की पुष्टि कीजिये</label>
                            <input type="password" class="form-control" formControlName="password_confirmation">
                            <div *ngIf="mo.password_confirmation.touched &&
                                mo.password_confirmation.invalid" class="alert
                                alert-danger">
                                <div *ngIf="mo.password_confirmation.errors.required">Confirm
                                    पासवर्ड की आवश्यकता है.</div>
                                <div *ngIf="mo.password_confirmation.errors.mustMatch">
                                    पासवर्ड और कन्फर्म पासवर्ड का मिलान होना चाहिए।</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6
                                अंक ओटीपी</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern"
                                formControlName="otp" [ngClass]="{ 'is-invalid':
                                motp_submitted && mo.otp.errors }">
                            <div *ngIf="motp_submitted && mo.contact.errors" class="invalid-login">
                                <div *ngIf="mo.otp.errors.required">ओटीपी आवश्यक है</div>
                                <div *ngIf="mo.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <form [formGroup]="MROTPForm" (ngSubmit)="MROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no"
                                readonly formControlName="contact">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span class="wish-otp">यदि आपको ओटीपी प्राप्त नहीं हुआ है, आप 30 . के बाद एक नए ओटीपी का
                                अनुरोध कर सकते हैं सेकंड</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">पुन: भेजें
                                ओटीपी</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>