import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { MembershipData } from 'src/app/models/MembershipData';

@Component({
  selector: 'app-membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss']
})
export class MembershipFormComponent implements OnInit {
  Membership3Form:FormGroup;
  data:any=[];
  loading: boolean;
  membership_submitted = false;
  data1:MembershipData;
  MemPackagesArray:any=[];
  name:any;email: any;otp:any;contact:any;id:any;state:any;city:any; //for Params
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) { }

  ngOnInit(): void {
    this.Membership3Form = this.formBuilder.group({
      contact: ['', [Validators.required]],
      package_id: ['', [Validators.required]],
      otp: ['', [Validators.required]],
      id_number: ['', [Validators.required]],
      photo_id_type: ['', [Validators.required]],
      document1: ['', [Validators.required]],
      address_id_number: ['', [Validators.required]],
      address_type: ['', [Validators.required]],
      document2: ['', [Validators.required]],
      image: ['', [Validators.required]],
      about_yourself: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });

    this.route.paramMap.subscribe(params => {
      this.contact = params.get('contact');
      this.otp = params.get('otp');
    });
    this.fetchMembershipPackage();
  }

  uploadImage(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.Membership3Form.patchValue({
      image: file
    });
    this.Membership3Form.get('image').setValue(file);
    console.log(file);
  }

  fetchMembershipPackage(){
    this.RestAPI.get(environment.apiURL + environment.MembershipPackageApi).subscribe(data => {
      this.data = data;
      this.MemPackagesArray = this.data.data.membership;
      console.log(this.MemPackagesArray);
    }, err => {
      console.log(err);
    });
  }

  uploadDoc1(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.Membership3Form.patchValue({
      document1: file
    });
    this.Membership3Form.get('document1').setValue(file);
    console.log(file);
  }

  uploadDoc2(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.Membership3Form.patchValue({
      document2: file
    });
    this.Membership3Form.get('document2').setValue(file);
    console.log(file);
  }

  get m() { return this.Membership3Form.controls; }

  Membership3Submit(){
    this.membership_submitted = true;
    // stop here if form is invalid
    if (this.Membership3Form.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("contact", this.Membership3Form.get('contact').value);
      formData.append("package_id", this.Membership3Form.get('package_id').value);
      formData.append("about_yourself", this.Membership3Form.get('about_yourself').value);
      formData.append("image", this.Membership3Form.get('image').value);
      formData.append("document1", this.Membership3Form.get('document1').value);
      formData.append("document2", this.Membership3Form.get('document2').value);
      formData.append("photo_id_type", this.Membership3Form.get('photo_id_type').value);
      formData.append("address_type", this.Membership3Form.get('address_type').value);
      formData.append("id_number", this.Membership3Form.get('id_number').value);
      formData.append("address_id_number", this.Membership3Form.get('address_id_number').value);
      this.loading = true; // Add this line
      this.RestAPI.post(environment.apiURL + environment.MembershipFormStep3API, formData).subscribe(data => {
        console.log(this.data1);
        this.toast.success('Congratulations! You Form has been submitted Successfully');
        this.router.navigate(["/membership"]);
        this.Membership3Form.reset();
        this.membership_submitted  = false;
        this.loading = false; // Add this line
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }
}
