import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
declare var jQuery: any;
@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit {
  enva:any;
  data:any = [];
  JobData:any = [];
  applyForm: FormGroup;
  otpForm: FormGroup;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  OTPPattern = "^[0-9]{4}$";
  otp_submitted = false;
  Jobslug:any;
  statesArray:any=[];
  cityArray:any=[];
  apply_submitted = false;
  imageurl:any;
  loading: boolean;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.Jobslug = params['params'].slug;
        console.log(this.Jobslug)
      }
    });
    this.fetchJobPostbySlug();

    this.applyForm = this.formBuilder.group({
      job_id: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      qualification: ['', [Validators.required]],
      experiences: ['', [Validators.required]],
      cv: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]]
    });
    this.fetchState();
  }
  get j() { return this.applyForm.controls; }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.applyForm.patchValue({
      cv: file
    });
    this.applyForm.get('cv').setValue(file);
    console.log(file);
  }

  applySubmit(){
    this.apply_submitted = true;
    // stop here if form is invalid
    if (this.applyForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("job_id", this.applyForm.get('job_id').value);
      formData.append("name", this.applyForm.get('name').value);
      formData.append("email", this.applyForm.get('email').value);
      formData.append("contact", this.applyForm.get('contact').value);
      formData.append("qualification", this.applyForm.get('qualification').value);
      formData.append("experiences", this.applyForm.get('experiences').value);
      formData.append("cv", this.applyForm.get('cv').value);
      formData.append("state", this.applyForm.get('state').value);
      formData.append("city", this.applyForm.get('city').value);
      this.loading = true; // Add this line
      //let data = this.CC2Form.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.JobApplyFormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success('Thank You For Applying. We will get back to you soon');
        jQuery('#applyjob').modal('hide');
        this.router.navigate(['/jobs']);
        this.apply_submitted  = false;
        this.loading = false; // Add this line
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }


  fetchJobPostbySlug() {
    this.RestAPI.get(environment.apiURL + environment.JobPostDetailAPI + this.Jobslug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.JobData = this.data.data.jobs[0];
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchState() {
    this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }
}
