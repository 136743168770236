import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Data } from 'src/app/models/data';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { HttpHeaders } from '@angular/common/http';
import { MustMatch } from '../../_helpers/mustmatch.validator';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
import { ConfirmedValidator } from 'src/app/_helpers/confirmed.validators';
declare var jQuery: any;

@Component({
  selector: 'app-expose-campaign',
  templateUrl: './expose-campaign.component.html',
  styleUrls: ['./expose-campaign.component.scss']
})
export class ExposeCampaignComponent implements OnInit {
  message: any;
  password: any;
  password_confirmation: any;
  NewUserFrom: FormGroup;
  newuser_submitted = false;
  full_name2: any;
  lwp_submitted = false;
  LWPForm: FormGroup; 
  showMe: boolean= true;
  showMe2: boolean= true;
  showMe3: boolean= false;
  showMe4: boolean= true;
  showMe5: boolean= false;
  OTPVForm: FormGroup;
  pass: any;
  otpverify_submitted = false;
  motp_submitted = false;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  enva:any;
  imageurl:any;
  data1:Data;
  ExposeCForm:FormGroup;
  ECOTPForm:FormGroup;
  EROTPForm:FormGroup;
  data:any=[];
  data2:Data;
  ExposeCArray:any=[];
  cityArray:any=[];
  disableBtn = false;
  BoxesContentArray:any=[];
  statesArray:any=[];
  ExposeC_submitted = false;
  ecotp_submitted = false;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";   
  //Data Store
  mob_no:number;
  mob_no2:number;
  email: string;
  name: string;
  state: string;
  city: string;
  otp:any;
  contact:number;
  countryArray: any = [];
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService, private restAPI: RESTApiService,  private auth:AuthenticationService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.ExposeCForm = this.formBuilder.group({
      name: ['', Validators.required],
      father_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      alternate_mobile_number: [''],
      business: ['', [Validators.required]],
      whatsapp_number: ['', [Validators.required]],
      adhaar_number: ['', [Validators.required]],
      facebook_url: [''],
      educational_qualification: [''],
      extra_information: [''],
      recording_file: [''],
      can_contact_for_information: [''],
      can_upload_evidence_to_busted: [''],
      can_take_legal_action: [''],
      newspaper_file: [''],
      is_name_confidentail: ['', [Validators.required]],
      country: ['', [Validators.required]],
      member_type: ['', [Validators.required]],
      member_role: ['', [Validators.required]],
      date: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      profession: ['', [Validators.required]],
      contribution: [''],
      address: ['', [Validators.required]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.registrationForm = this.formBuilder.group({
      name: [''],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
    })
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.ECOTPForm = this.formBuilder.group({
      contact: [''],
      name: ['' ],
      email: ['', []],
      otp: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    }
    );
    this.EROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });

    this.NewUserFrom = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });
    this.fetchCountry();
    // this.fetchState();
    this.fetchExposeC();
    this.fetchboxes();
  }

  get c() { return this.ExposeCForm.controls; }
  get mo() { return this.ECOTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get lwp() { return this.LWPForm.controls; }
  get vrfy() { return this.OTPVForm.controls;}
  get new() { return this.NewUserFrom.controls; }


  fetchExposeC(){
    this.RestAPI.get(environment.apiURL + environment.ExposeContentAPI).subscribe(data => {
      this.data = data;
      this.ExposeCArray = this.data.data.expose_content;
      console.log(this.ExposeCArray);
    }, err => {
      console.log(err);
    });
  }

  uploadIdentityCard(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.ExposeCForm.patchValue({
      identity_card: file
    });
    this.ExposeCForm.get('identity_card').setValue(file);
    console.log(file);
  }

  uploadNewspaperFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.ExposeCForm.patchValue({
      newspaper_file: file
    });
    this.ExposeCForm.get('newspaper_file').setValue(file);
    console.log(file);
  }
  uploadRecordingFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.ExposeCForm.patchValue({
      recording_file: file
    });
    this.ExposeCForm.get('recording_file').setValue(file);
    console.log(file);
  }
  ExposeCSubmit() {
    this.ExposeC_submitted = true;
    if (this.ExposeCForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("alternate_mobile_number", this.ExposeCForm.get('alternate_mobile_number').value);
      formData.append('identity_card', this.ExposeCForm.get('identity_card').value);
      formData.append('newspaper_file', this.ExposeCForm.get('newspaper_file').value);
      formData.append('recording_file', this.ExposeCForm.get('recording_file').value);
      formData.append("name", this.ExposeCForm.get('name').value);
      formData.append("email", this.ExposeCForm.get('email').value);
      formData.append("contact", this.ExposeCForm.get('contact').value);
      formData.append("dob", this.ExposeCForm.get('dob').value);
      formData.append("gender", this.ExposeCForm.get('gender').value);
      formData.append("address", this.ExposeCForm.get('address').value);
      formData.append("state", this.ExposeCForm.get('state').value);
      formData.append("city", this.ExposeCForm.get('city').value);
      formData.append("adhaar_number", this.ExposeCForm.get('adhaar_number').value);
      formData.append("father_name", this.ExposeCForm.get('father_name').value);
      formData.append("whatsapp_number", this.ExposeCForm.get('whatsapp_number').value);
      formData.append("referral_detail", this.ExposeCForm.get('referral_detail').value);
      formData.append("date", this.ExposeCForm.get('date').value);
      formData.append("member_organization", this.ExposeCForm.get('member_organization').value);
      formData.append("member_level", this.ExposeCForm.get('member_level').value);
      formData.append("member_role", this.ExposeCForm.get('member_role').value);
      formData.append("member_type", this.ExposeCForm.get('member_type').value);
      formData.append("pincode", this.ExposeCForm.get('pincode').value);
      formData.append("country", this.ExposeCForm.get('country').value);
      formData.append("educational_qualification", this.ExposeCForm.get('educational_qualification').value);
      formData.append("facebook_url", this.ExposeCForm.get('facebook_url').value);
      formData.append("is_indian_citizen", this.ExposeCForm.get('is_indian_citizen').value);
      this.restAPI.post(environment.apiURL + environment.ExposeCFormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success('Your Letter has submitted Successfully. Thank You');
        this.ExposeCForm.reset();
        this.ExposeC_submitted = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  // ExposeCSubmit(){
  //   this.ExposeC_submitted = true;
  //   if (this.ExposeCForm.invalid) {
  //     this.toast.error('कृपया सभी फ़ील्ड जांचें');
  //       return;
  //   }else{
  //     this.data1 = this.ExposeCForm.getRawValue();
  //     this.RestAPI.post(environment.apiURL + environment.ExposeCFormAPI, this.data1).subscribe(data => {
  //       this.mob_no = this.data1.contact;
  //       this.email = this.data1.email;
  //       this.name = this.data1.name;
  //       this.state = this.data1.state;
  //       this.city = this.data1.city;
  //       console.log(this.data1);
  //       this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
  //       this.ExposeCForm.reset();
  //       this.ExposeC_submitted  = false;
  //       this.showMe = true;
  //       this.showMe4 = false;
  //       setTimeout(()=>{  
  //         this.disableBtn = true;
  //       }, 30000);
  //     }, err => {
  //       this.toast.error(err.error.error);
  //       console.log(err);
  //     });
  //   }
  // }

  ECOTPSubmit(){
    this.ecotp_submitted = true;
    if (this.ECOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data2 = this.ECOTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, this.data2).subscribe(data => {
        this.contact = this.data2.contact;
        this.otp = this.data2.otp;
        console.log(this.data2);
        this.toast.success('नंबर सत्यापित हो गया है');
        this.ECOTPForm.reset();
        this.ecotp_submitted  = false;
        this.showMe2 = false;
        this.showMe3 = false;
        this.showMe4 = true;

        this.router.navigate(["/thank-you"]);
        this.router.navigate(["/expose-campaign-form", {contact: this.contact, otp: this.otp, email: this.email, name: this.name, state: this.state, city: this.city}]);
      }, err => {
        this.toast.error("सही ओटीपी दर्ज करें");
        console.log(err);
      });
    }
  }

  EROTPSubmit(){
    // stop here if form is invalid
    if (this.EROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.EROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchCountry() {
    this.RestAPI.get(environment.apiURL + environment.countryAPI).subscribe(data => {
      this.data = data;
      this.countryArray = this.data.data.countries;
      console.log(this.countryArray);
    }, err => {
      console.log(err);
    });
  }
  fetchState(e) {
    this.RestAPI.get(environment.apiURL + environment.stateAPI1 + e.target.value).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  fetchboxes(){
    this.RestAPI.get(environment.apiURL + environment.HomepageAPI).subscribe(data =>{
      this.data = data;
      this.BoxesContentArray = this.data.data.homepage_box;
      console.log(this.BoxesContentArray);
    }, err => {
      console.log(err);
    });
  }

  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }

  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.full_name2 = this.data1.name;
        console.log(this.mob_no);
        // this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        // 
        this.registratiopn_submitted = false;
        this.message = "ओटीपी सफलतापूर्वक भेजा गया"
        // this.showMe2= false;
        this.showMe4 = false;
        this.showMe5 = true;
        this.showMe3 = true;
        
        // jQuery('#exposec-verification').modal('show');
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error("मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ");
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe2 = true
    this.showMe = false
  }
  // toogleTag2()
  // {
  //   this.showMe2 = true
  //   this.showMe = false
  // }
  LWPSubmit(){
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
        return;
    } else{
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data:any) => {
        if(data.success==false){
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token',data.data.token);
          this.router.navigate(["/expose-campaign"]);
          /* jQuery('#userlogin').modal('hide'); */
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }

  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें ओटीपी 6 अंकों का होना चाहिए');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        this.mob_no = this.data.contact;
        console.log(data);
        // this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.message = "नंबर सत्यापित हो गया है"
        this.showMe2 = false;
        this.showMe3 = false;
        this.showMe4 = true;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }

  NewuserSubmit(){
    this.newuser_submitted = true;
    // stop here if form is invalid
    if (this.NewUserFrom.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.NewUserFrom.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        // this.mob_no = this.data1.contact;
        // console.log(this.mob_no);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.NewUserFrom.reset();
        this.newuser_submitted  = false;
        this.showMe = false;
        this.showMe4 = false;
        // jQuery('#bfi-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  clearForm() {
    this.NewUserFrom.get('email').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('address').reset();
    this.NewUserFrom.get('state').reset();
    this.NewUserFrom.get('is_indian_citizen').reset();
    this.NewUserFrom.get('gender').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('whatsapp_number').reset();
    this.NewUserFrom.get('alternate_mobile_number').reset();
    this.NewUserFrom.get('city').reset();
    this.NewUserFrom.get('country').reset();
    this.NewUserFrom.get('tehsil').reset();
    this.NewUserFrom.get('password_confirmation').reset();
    this.NewUserFrom.get('pancard').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }

}
