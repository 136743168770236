<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url();background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>सेना भर्ती आन्दोलन </h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">हमारी ताकत </li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid">
    <div class="logo">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/left logo.png" alt="..." class="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/right logo.png" alt="..." class="float-right">
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</div>
<br><br>
<section class="letter-bhejo-bharti-laao-andolan-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <h3  style="color: #f58634; margin-top: -70px;">हमारी ताकत &nbsp;<img src="assets/images/right logo.png" class="img-thumbnail"
                            style="max-width: 7%; border: 0px solid;"></h3>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8 exposec-sidebar">
                <div class="media-body" style="text-align: justify;">
                    <h4>{{OurStrenghtArray.content_title}}</h4>
                    <h5>{{OurStrenghtArray.content_subtitle}}</h5>
                    <div [innerHTML]="OurStrenghtArray.content_hindi"></div>
                    <!-- <div class="location-date">
                        <ul>
                            <li><i class="fas fa-map-marker"></i> {{AboutUsArray.updated_at}}</li>
                            <li><i class="fas fa-calendar"></i> {{AboutUsArray.created_at}}</li>
                        </ul>
                    </div> -->
                </div>
                <br><br><br>
                <owl-carousel-o class="logo-owl-carou" [options]="customOptions">
                    <ng-template carouselSlide>
                        <youtube-player class="ytply" videoId="{{videoArray.url}}" suggestedQuality="highres" [height]="200"
                            [width]="250"></youtube-player>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="exposec-sidebar">
                    <!-- <h4 style="text-align: center;">Sub Menu</h4> -->
                    <hr class="dotted">
                    <div class="">
                        <button type="button" class="btn btn-secondary"> <a [routerLink]="['/committee-founder']">समिति संस्थापक
                                </a></button>
                       
                        <button type="button" class="btn btn-secondary"> <a
                                [routerLink]="['/about-us-andolan']">हमारे बारे में </a></button>
     
                        <button type="button" class="btn btn-secondary"> <a
                                [routerLink]="['/our-vision-andolan']">हमारी दृष्टि </a></button>
             
                        <button type="button" class="btn btn-secondary"><a [routerLink]="['/our-mision-andolan']">हमारा उद्देश्य</a></button>
                    
                        <button type="button" class="btn btn-secondary"><a [routerLink]="['/our-identity-andolan']">हमारी पहचान</a></button>
                       
                        <!-- <button type="button" class="btn btn-secondary"><a [routerLink]="['/gallery-andolan']">गैलरी </a></button>
                        <br><br> -->
                        <button type="button" class="btn btn-secondary"><a [routerLink]="['/committee-structure']">समिति संरचना</a></button>
                    </div>
                </div>
                <hr class="solid">
                <div class="exposec-sidebar">
                    <div class="">
                        <button value="Download PDF File" (click)="downloadFile()" type="button"
                            class="btn btn-secondary">Download Sample Letter Format</button>
                      
                        <button type="button" class="btn btn-secondary"> <a
                                [routerLink]="['/upload-your-letter']">Upload your Letter</a></button>
                  
                        <button type="button" class="btn btn-secondary"><a
                                [routerLink]="['/view-gallery-and-letter']">View Gallery & Letters</a></button>
                    </div>
                </div>
                <!-- <hr class="address">
                <h4 style="text-align: center;">Address</h4>
                <hr class="dotted">
                <div class="foo-ul">
                    <ul class="style-foo-two">
                        <li><i class="fas fa-map-marker-alt"></i> Address</li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</section>
<br>