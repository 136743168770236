import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-expose-campaign-detail',
  templateUrl: './expose-campaign-detail.component.html',
  styleUrls: ['./expose-campaign-detail.component.scss']
})
export class ExposeCampaignDetailComponent implements OnInit {
  enva:any;
  imageurl:any;
  ExposeCSlug:any;
  ExposeCArray:any=[];
  ExposeCImagesArray:any=[];
  ExposeCVideosArray:any=[];
  data: any=[];
  ExposeCDetailArray:any=[];
  constructor(private route: ActivatedRoute,
    private router:Router,
    private RestAPI: RESTApiService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
  
   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
      }
  });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.ExposeCSlug = params['params'].slug;
        console.log(this.ExposeCSlug)
      }
    });
    this.fetchExposeCbySlug();
    this.fetchExposeC();
  }

  fetchExposeCbySlug(){
    this.RestAPI.get(environment.apiURL + environment.ExposeCDetailAPI + this.ExposeCSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.ExposeCDetailArray = this.data.data.expose_campaign[0];
      this.ExposeCImagesArray = this.data.data.image;
      this.ExposeCVideosArray = this.data.data.video;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchExposeC(){
    this.RestAPI.get(environment.apiURL + environment.ExposeCAPI).subscribe(data => {
      this.data = data;
      this.ExposeCArray = this.data.data.expose;
      console.log(this.ExposeCArray);
    }, err => {
      console.log(err);
    });
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }
}
