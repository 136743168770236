import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MembershipData } from 'src/app/models/MembershipData';
import { ActivatedRoute, Router } from '@angular/router';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
declare var jQuery: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  enva : any;
  imageurl:any;
  contact:any;otp:any;mob_no:any; //for data
  disableBtn=false;
  volunteers1_submitted = false;
  volunteers2_submitted = false;
  VolunteerFormS1:FormGroup;
  VolunteerROTPForm:FormGroup;
  VolunteerFormS2:FormGroup;
  data1:MembershipData;
  data2:MembershipData;
  generalData:any = [];
  data:any = [];
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$"; 
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  countData:any;
  constructor(private toast: ToastrService,
    private formBuilder: FormBuilder,
    private router:Router,
    private restAPI: RESTApiService) { }

  ngOnInit(): void {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.fetchGeneralSetting();
    this.fetchVisitors();
    this.VolunteerFormS1 = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.VolunteerFormS2 = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required]
    });
    this.VolunteerROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
  }

  get vs1() { return this.VolunteerFormS1.controls; }
  get vs2() { return this.VolunteerFormS2.controls; }

  fetchGeneralSetting(){
    this.restAPI.get(environment.apiURL + environment.generalSettingAPI).subscribe(data =>{
      this.data = data;
      this.generalData = this.data.data.generalsetting;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  fetchVisitors(){
    this.restAPI.get(environment.apiURL + environment.VisitorAPI).subscribe(data => {
      this.data = data;
      this.countData = this.data.count;
     console.log(this.data.count);
    }, err => {
      console.log(err);
    });
  }

  VolunteerFormS1Submit(){
    this.volunteers1_submitted = true;
    // stop here if form is invalid
    if (this.VolunteerFormS1.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.VolunteerFormS1.getRawValue();
      this.restAPI.post(environment.apiURL + environment.VolunteerFormStep1API, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        console.log(this.data1);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.VolunteerFormS1.reset();
        this.volunteers1_submitted  = false;
        jQuery('#join-volunteer').modal('hide');
        jQuery('#volunteer-otp').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000); 
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  VolunteerFormS2Submit(){
    this.volunteers2_submitted = true;
    // stop here if form is invalid
    if (this.VolunteerFormS2.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data2 = this.VolunteerFormS2.getRawValue();
      this.restAPI.post(environment.apiURL + environment.VolunteerFormStep2API, this.data2).subscribe(data => {
        this.otp = this.data2.otp;
        this.contact = this.data2.contact;
        console.log(this.data2);
        this.toast.success('Mobile No. has been Verified Successfully');
        this.VolunteerFormS2.reset();
       jQuery('#volunteer-otp').modal('hide');
        this.volunteers2_submitted  = false;
        this.router.navigate(["/become-volunteer", {contact: this.contact, otp: this.otp}]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  
  VolunteerROTPSubmit(){
    // stop here if form is invalid
    if (this.VolunteerROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.VolunteerROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.VolunteerFormResendAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
}
