<section class="page-header-section" style="
    background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  ">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>भुगतान गेटवे</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/']">मुख्य पृष्ठ</a>
                            </li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">सहयोग करें</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container" style="background-color: #EEEEEE;">
    <div class="row">
        <div class="col-md-12" >
            <!-- <h2>PayU Payment Form</h2> -->
            <div class="container mt-5 d-flex justify-content-center">
                <form [formGroup]="OTPVForm">
                    <div class="card p-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="total-amount">कुल राशि</h5>
                            <div class="amount-container"><span class="amount-text"><span class="dollar-sign">₹ </span ><input type="number" formControlName="amount" [(ngModel)]="donation_amount3"/></span></div>
                        </div>
                        <div class="pt-4"> <label class="d-flex justify-content-between"> <span class="label-text label-text-cc-number">खाता धारक का नाम</span></label> <input formControlName="name" type="text" class="form-control credit-card-number"> </div>
                        <div class="pt-4"> <label class="d-flex justify-content-between"> <span class="label-text label-text-cc-number">कार्ड नंबर</span></label> <input formControlName="cardno" type="tel" name="credit-number" class="form-control credit-card-number" maxlength="19"> </div>
                        <div class="d-flex justify-content-between pt-4">
                            <div> <label><span class="label-text">समाप्ति</span> </label> <input type="date" name="expiry-date" class="form-control expiry-class" placeholder="MM / YY"> </div>
                            <div> <label><span class="label-text">CVV</span></label> <input type="tel" name="cvv-number" class="form-control cvv-class" maxlength="4" pattern="\d*"> </div>
                        </div>
                        <div class="d-flex justify-content-between pt-5 align-items-center"> <button type="button" class="btn cancel-btn">Cancel</button> <button type="button" (click)="OTPVerifySubmit()" class="btn payment-btn">Make Payment</button> </div>
                    </div>
                </form>
            </div>
            <br><br>
            <!-- <form [formGroup]="payuform" ngNoForm action="https://test.payu.in/_payment" name="payuform" method="POST" target="payment_popup">
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label class="label-control">name </label><br />
                        <input type="text" class="text-control" formControlName="firstname" />
                    </div>
                    <div class="col-sm-6">
                        <label class="label-control">दान राशि </label><br />
                        <input type="text" [(ngModel)]="donation_amount2" disabled class="text-control" formControlName="amount" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label class="label-control">key </label><br />
                        <input type="text" class="text-control" formControlName="key" />
                    </div>
                    <div class="col-sm-6">
                        <label class="label-control">productinfo </label><br />
                        <input type="text" class="text-control" formControlName="productinfo" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label class="label-control">email </label><br />
                        <input type="text" class="text-control" formControlName="email" />
                    </div>
                    <div class="col-sm-6">
                        <label class="label-control">surl </label><br />
                        <input type="text" class="text-control" formControlName="surl" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label class="label-control">furl </label><br />
                        <input type="text" class="text-control" formControlName="furl" />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-6">
                        <label class="label-control">phone</label><br />
                        <input type="text" class="text-control" formControlName="phone" />
                    </div>
                    <div class="col-sm-6">
                        <label class="label-control">hash </label><br />
                        <input type="text" class="text-control" formControlName="hash" />
                    </div>
                </div>
                <button *ngIf="disablePaymentButton" type="button"  class="btn btn-default" (click)="confirmPayment()">Confirm</button>
                <button *ngIf="!disablePaymentButton" type="submit" class="btn btn-danger">Submit</button>
            </form> -->
        </div>
    </div>
</div>
<div class="container">
    <div class="col-md-4">
        <!-- <form ngNoForm action="https://test.payu.in/_payment  -H" name="payuform" method="POST" target="payment_popup"  onsubmit="window.open('about:blank','payment_popup','width=900,height=500');">
          <div class="form-group">
            <label for="productInfo">Product Name:</label>
            <input type="text" class="form-control" id="productInfo" name="productinfo" [(ngModel)]="payuform.productinfo">
          </div>
          <div class="form-group">
            <label for="firstname">Name:</label>
            <input type="text" class="form-control" id="firstname" name="firstname" [(ngModel)]="payuform.firstname">
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" class="form-control" id="email" name="email" [(ngModel)]="payuform.email">
          </div>
          <div class="form-group">
            <label for="email">Phone:</label>
            <input type="number" class="form-control" id="phone" name="phone" [(ngModel)]="payuform.phone">
          </div>
          <div class="form-group">
            <label for="amount">Amount:</label>
            <input type="number" class="form-control" id="amount" name="amount" [(ngModel)]="payuform.amount">
          </div>
          <textarea name="surl" id="surl" ng-model="surl" rows="2" cols="2" hidden [(ngModel)]="payuform.surl"></textarea>
          <textarea name="furl" id="furl" ng-model="furl" rows="2" cols="2" hidden [(ngModel)]="payuform.furl"></textarea>
          <textarea name="key" id="key" ng-model="key" rows="2" cols="2" hidden [(ngModel)]="payuform.key"></textarea>
          <textarea name="hash" id="hash" ng-model="hash" rows="2" cols="2" hidden [(ngModel)]="payuform.hash"></textarea>
          <textarea name="txnid" id="txnid" ng-model="txnid" rows="2" cols="2" hidden [(ngModel)]="payuform.txnid"></textarea>
          <textarea name="service_provider" id="service_provider" rows="2" cols="2" hidden></textarea>
          <button *ngIf="disablePaymentButton" type="button"  class="btn btn-default" (click)="confirmPayment()">Confirm</button>
          <button *ngIf="!disablePaymentButton" type="submit" class="btn btn-danger">Submit</button>
        </form> -->
      </div>
    <!-- <div class="offset-md-3 col-md-6 text-center">
      <div class="card bg-light text-dark">
  
        <div class="card-header align-header-center"><b>Payment Form</b></div>
  
        <div class="card-body">
          <form [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
  
            <div class="form-group">
              <label class="form-label">First Name :</label>
              <input type="text" formControlName="firstname" required>
            </div>
  
            <div class="form-group">
              <label class="form-label">Last Name:</label>
              <input type="text" formControlName="lastname" required>
            </div>
  
            <div class="form-group">
              <label class="form-label">Email :</label>
              <input type="email" formControlName="email" email required>
            </div>
  
            <div class="form-group">
              <label class="form-label">Phone :</label>
              <input type="number" formControlName="phone" [maxlength]="10" [minLength]="10" required>
            </div>
  
            <div class="form-group">
              <label class="form-label">Amount :</label>
              <input type="number" formControlName="amount" required>
            </div>
  
            <div class="form-group">
              <label class="form-label">Product Info :</label>
              <input type="text" formControlName="productinfo" required>
            </div>
  
            <div class="form-group align-header-center">
              <button type="submit" class="btn btn-primary" [disabled]="!paymentForm.valid">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div> -->
  </div>