import { Component, OnInit } from '@angular/core';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var jQuery: any;
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styles:[
    `.modal{background: rgba(0,0,0, .5);`
  ],
  styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit {
  enva: any;
  package_id:any;
  imageurl:any;
  MemPackagesArray:any=[];
  EmailVerificationModal: boolean;
  data:any=[];
  UserDetailsMArray:any=[];
  UserDetailsArray:any=[];
  EVForm:FormGroup;
  ev_submitted = false;
  constructor(
    private restAPI:RESTApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastrService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }
  

  ngOnInit(): void {
    this.EVForm = this.formBuilder.group({
      email: ['', Validators.required],
      otp: ['', Validators.required]
    });
    this.fetchUserDetails();
    this.fetchMembershipPackage();
  }

  get ev() { return this.EVForm.controls; }

  Logout() {
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigate(["/home"]);
  }

  fetchMembershipPackage(){
    this.restAPI.get(environment.apiURL + environment.MembershipPackageApi).subscribe(data => {
      this.data = data;
      this.MemPackagesArray = this.data.data.membership;
      console.log(this.MemPackagesArray);
    }, err => {
      console.log(err);
    });
  }

  membershipbuy(event, item){
    jQuery('#purchase-membership').modal('hide');
    this.package_id = item.package_id;
    this.router.navigate(["/user-buy-membership", {package_id: this.package_id}]);
  }

  membershiprenewal(event, item){
    jQuery('#renewal-membership').modal('hide');
    this.package_id = item.package_id;
    this.restAPI.get(environment.apiURL + environment.UserRenewalMembershipAPI + '/' + this.package_id).subscribe(data => {
      this.data = data;
      console.log(this.data);
      this.toast.success('Your Membership has been renewed successfully. Enjoyed!');
      this.fetchUserDetails();
    }, err => {
      console.log(err);
    });
  }

  fetchUserDetails() {
    this.restAPI.get(environment.apiURL + environment.UserfetchDetails).subscribe(data => {
      this.data = data;
      this.UserDetailsArray = this.data.data.user;
      this.UserDetailsMArray = this.data.data.membership_detail;
      console.log(this.UserDetailsArray);
    }, err => {
      console.log(err);
    });
  }

  verifyemail(){
    this.restAPI.get(environment.apiURL + environment.UserEmailVerification).subscribe(data => {
      this.EmailVerificationModal = true;
      this.toast.success('OTP has been sent to your registered email');
    }, err => {
      console.log(err);
    });
  }

  hideemailverify(){
    this.EmailVerificationModal = false;
  }

  EVSubmit(){
    this.ev_submitted = true;
    // stop here if form is invalid
    if (this.EVForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.EVForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserEmailVerificationS2, data).subscribe(data => {
        console.log(data);
        this.toast.success('Your Email is verified');
        this.EVForm.reset();
        this.EmailVerificationModal = false;
        this.ev_submitted  = false;
        this.fetchUserDetails();
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
}
