import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-bfi-detail',
  templateUrl: './bfi-detail.component.html',
  styleUrls: ['./bfi-detail.component.scss']
})
export class BfiDetailComponent implements OnInit {
  enva:any;
  imageurl:any;
  data:any=[];
  BFISlug:any;
  BFIArray:any=[];
  BFIImagesArray:any=[];
  BFIVideosArray:any=[];
  BFIDetailArray:any=[];
  constructor(private route: ActivatedRoute,
    private router:Router,
    private RestAPI: RESTApiService) { 
      this.enva = environment.assetsURL;
      this.imageurl = environment.imageURL;
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
     }
    
     this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
           // trick the Router into believing it's last link wasn't previously loaded
           this.router.navigated = false;
        }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.BFISlug = params['params'].slug;
        console.log(this.BFISlug)
      }
    });
    this.fetchBFI();
    this.fetchBFIbySlug();
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }

  fetchBFIbySlug(){
    this.RestAPI.get(environment.apiURL + environment.BFIDetailAPI + this.BFISlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.BFIDetailArray = this.data.data.bbi[0];
      this.BFIImagesArray = this.data.data.image;
      this.BFIVideosArray = this.data.data.video;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchBFI() {
    this.RestAPI.get(environment.apiURL + environment.BFIAPI).subscribe(data => {
      this.data = data;
      this.BFIArray = this.data.data.bbi;
      console.log(this.BFIArray);
    }, err => {
      console.log(err);
    });
  }

}
