<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>जॉब</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">जॉब</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="jobs-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-7 col-md-7 col-lg-8">
                <div class="job-list-filters">
                    <h3 class="showing-result-h">ओपनिंग - {{JobSlug}}
                        <div class="sortby-listing"  style="display: none;">
                            <label class="label-sort">Sort By:</label>
                            <select class="form-sorting">
                                <option>Sort By</option>
                                <option>Latest</option>
                                <option>Oldest</option>
                            </select>
                        </div>
                        <div class="sortby-listing">
                            <button class="btn btn-dark btn-sm" (click)="clearfilter()" type="button">क्लियर करे</button>
                        </div>
                        <div class="sortby-listing align-self-center">
                            <label class="label-sort">केटेगरी:</label>
                            <select class="form-sorting" (change)="onChange($event.target.value)">
                                <option value="" selected="true">सेलेक्ट केटेगरी</option>
                                <option *ngFor="let item of JobCategoryArray" value="{{item.slug}}">{{item.category_name}}</option>
                            </select>
                        </div>
                    </h3>
                </div>
                <div class="job-listing">
                    <div class="row">
                        <div class="col-sm-12" *ngFor="let item of jobArray">
                            <div class="job-list-main">
                                <div class="job-list-wrap">
                                    <div class="job-list-thumb">
                                        <a [routerLink]="['/job-detail',item.slug]"><img src="{{imageurl}}{{item.image}}" class="img-fluid" alt=""></a>
                                    </div>
                                    <div class="job-list-caption">
                                        <h4><a [routerLink]="['/job-detail',item.slug]">{{item.title}}</a><span class="jb-status {{item.job_type}}">{{item.job_type}}</span></h4>
                                        <ul>
                                            <li><i class="far fa-building"></i>{{item.category_name}}</li>
                                            <li><i class="fas fa-map-marker"></i> {{item.city_name}}, {{item.state_name}} </li>
                                        </ul>
                                        <p [innerHTML]="item.job_description"></p>
                                    </div>
                                </div>
                                
                                <div class="job-list-btn">
                                    <a [routerLink]="['/job-detail',item.slug]" class="btn btn-shortlist"><i class="fas fa-arrow-right"></i>और देखे</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="job-listing-paginate">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li class="page-item"><a class="active page-link">1</a></li>
                            <li class="page-item"><a class="page-link">2</a></li>
                            <li class="page-item"><a class="page-link">3</a></li>
                        </ul>
                    </nav>
                </div>-->
            </div>
            <div class="col-sm-5 col-md-5 col-lg-4">
                <div class="job-list-sidebar-widgets">
                    <div class="job-list-detail-wrap">
                        <div class="job-list-detail-wrap-header">
                            <h4>सुचना पाने के लिए</h4>
                        </div>
                        <div class="job-list-detail-wrap-body">
                            <form [formGroup]="jobAlertForm" (ngSubmit)="jobAlertSubmit()" *ngIf="!LoggedIn()">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" [ngClass]="{ 'is-invalid': jobalert_submitted && j.name.errors }">  
                                    <div *ngIf="jobalert_submitted && j.name.errors" class="invalid-login">
                                        <div *ngIf="j.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="text" class="form-control" placeholder="Enter Email" formControlName="email" [ngClass]="{ 'is-invalid': jobalert_submitted && j.email.errors }">
                                    <div *ngIf="jobalert_submitted && j.email.errors" class="invalid-login">
                                        <div *ngIf="j.email.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Mobile No.</label>
                                    <input type="text" class="form-control" placeholder="Enter Mobile No." formControlName="contact" [ngClass]="{ 'is-invalid': jobalert_submitted && j.contact.errors }">
                                    <div *ngIf="jobalert_submitted && j.contact.errors" class="invalid-login">
                                        <div *ngIf="j.contact.errors.required">ईमेल की आवश्यकता है</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Category</label>
                                    <select class="form-control" formControlName="job_category" [ngClass]="{ 'is-invalid': jobalert_submitted && j.job_category.errors }">
                                        <option value="" selected="true">Choose Category</option>
                                        <option value="{{item.id}}" *ngFor="let item of JobCategoryArray">{{item.category_name}}</option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.job_category.errors" class="invalid-login">
                                        <div *ngIf="j.job_category.errors.required">Category is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Job Type</label>
                                    <select class="form-control" formControlName="job_type" [ngClass]="{ 'is-invalid': jobalert_submitted && j.job_type.errors }">
                                        <option value="" selected="true">Choose Type</option>
                                        <option value="part-time">Part Time</option>
                                        <option value="full-time">Full Time</option>
                                        <option value="all">Both</option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.job_type.errors" class="invalid-login">
                                        <div *ngIf="j.job_type.errors.required">Job Type is required</div>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <label>State</label>
                                    <select class="form-control" formControlName="state" (change)="fetchCity($event)" [ngClass]="{ 'is-invalid': jobalert_submitted && j.state.errors }">
                                        <option value="" selected="true">Select State</option>
                                        <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.state.errors" class="invalid-login">
                                        <div *ngIf="j.state.errors.required">State is required</div>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <label>City</label>
                                    <select class="form-control" formControlName="city" [ngClass]="{ 'is-invalid': jobalert_submitted && j.city.errors }">
                                        rs }">
                                        <option value="" [selected]="true">Select City</option>
                                        <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                                    </select>
                                    <div *ngIf="jobalert_submitted && j.city.errors" class="invalid-login">
                                        <div *ngIf="j.city.errors.required">City is required</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary">Proceed to OTP</button>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade otp-modal" id="joba-verification" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="JAOTPForm" (ngSubmit)="JAOTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-12">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>Please enter the OTP to verify your mobile number</h3>
                                <p>A OTP has sent to {{mob_no}}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 Digit OTP</label>
                            <input type="number" class="form-control" placeholder="Enter OTP" [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid': jaotp_submitted && jao.otp.errors }">
                            <div *ngIf="jaotp_submitted && jao.otp.errors" class="invalid-login">
                                <div *ngIf="jao.otp.errors.required">OTP is required</div>
                                <div *ngIf="jao.otp.errors.pattern">OTP should be 6 digit</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">Validate OTP</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <form [formGroup]="JROTPForm" (ngSubmit)="JROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span  class="wish-otp">If you did not received OTP, you can request a new OTP after 30 seconds</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend OTP</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>