<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>वीडियो गैलरी</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">वीडियो गैलरी</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="image-gallery-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-3" *ngFor="let item of VideoGalleryArray">
                <div class="gallery-main">
                    <youtube-player   videoId="{{item.url}}" suggestedQuality="highres" [height]="200" [width]="250"></youtube-player>
                    <h3>{{item.title}}</h3>
                </div>
            </div>
        </div>
    </div>
</section>