<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url();background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>सेना भर्ती आन्दोलन</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">समिति संरचना</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid">
    <div class="logo">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/left logo.png" alt="..." class="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-7"></div>
            <div class="col-sm-2">
                <div class="container" id="logoo">
                    <div class="row">
                        <div class="col-sm-3">
                            <img id="logo" src="assets/images/right logo.png" alt="..." class="float-right">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="strength-page-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <h3 style="color: #f58634; margin-top: -55px;">समिति संरचना &nbsp; <img src="assets/images/right logo.png" class="img-thumbnail"
                                style="max-width: 7%; border: 0px solid;"></h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <div class="heading-title">
                    <div class="title" style="text-align: justify;">
                        <!-- <h3><img src="assets/images/logo circle.png" class="img-flag"></h3> -->
                        <div [innerHTML]="OrgStructureData.content_hindi"></div>
                        <h3 [innerHTML]="OrgStructureData.title"></h3>
                    </div>
                </div>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="exposec-sidebar">
                    <!-- <h4 style="text-align: center;">Sub Menu</h4> -->
                    <hr class="dotted">
                    <div class="">
                        <button type="button" class="btn btn-secondary"> <a [routerLink]="['/committee-founder']">समिति
                                संस्थापक
                            </a></button>
                        <button type="button" class="btn btn-secondary"> <a
                                [routerLink]="['/our-identity-andolan']">हमारी पहचान </a></button>
                        <button type="button" class="btn btn-secondary"> <a [routerLink]="['/our-vision-andolan']">हमारी
                                दृष्टि </a></button>
                        <button type="button" class="btn btn-secondary"><a [routerLink]="['/our-mision-andolan']">हमारा
                                उद्देश्य</a></button>
                        <button type="button" class="btn btn-secondary"> <a
                                [routerLink]="['/our-strength-andolan']">हमारी ताकत</a></button>
                        <!-- <button type="button" class="btn btn-secondary"><a [routerLink]="['/gallery-andolan']">गैलरी
                            </a></button>
                        <br><br> -->
                        <button type="button" class="btn btn-secondary"> <a
                            [routerLink]="['/about-us-andolan']">हमारे बारे में </a></button>
                    </div>
                </div>
                <hr class="solid">
                <div class="exposec-sidebar">
                    <div class="">
                        <button value="Download PDF File" (click)="downloadFile()" type="button"
                            class="btn btn-secondary">Download Sample Letter
                            Format</button>
                        <button type="button" class="btn btn-secondary"> <a
                                [routerLink]="['/upload-your-letter']">Upload your Letter</a></button>
                        <button type="button" class="btn btn-secondary"><a
                                [routerLink]="['/view-gallery-and-letter']">View Gallery & Letters</a></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>