<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>उपयोगकर्ता डैशबोर्ड</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">उपयोगकर्ता डैशबोर्ड</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="dashboard-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-3">
                <app-user-sidebar></app-user-sidebar>
            </div>
            <div class="col-sm-9">
                <div class="main-area-dash">
                    <h3 class="head-tit">डैशबोर्ड</h3>
                    <section class="dashboard-area">
                        <div class="welcomeletter-main">
                            <div class="welcome-head">
                                <h3>स्वागत हे</h3>
                                <h4>{{UserDetailsArray.name}}</h4>
                            </div>
                            <div class="welcome-p" [innerHTML]="WelcomeLetterArray.content">
                                
                            </div>
                            <div class="welcome-footer">
                                <h4><span>सादर</span>आर दी इस इस</h4>
                            </div> 
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>