import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Data } from 'src/app/models/data';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { JobData } from 'src/app/models/JobData';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
import { MustMatch } from '../../_helpers/mustmatch.validator';

declare var jQuery: any;
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  showMe: boolean= true;
  showMe2: boolean= false;
  showMe3: boolean= false;
  showMe4: boolean= false;
  full_name2: any;
  bfi_submitted = false;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  pass: any;
  motp_submitted = false;
  sendotp_submitted = false;
  votp_submitted = false;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  SendOTPForm: FormGroup;
  enva:any;
  data:any = [];
  data1:Data;
  Jobdata:JobData;
  mob_no:any;
  mob_no2:any;
  statesArray:any=[];
  cityArray:any=[];
  jobArray: any = [];
  disableBtn = false;
  i = 0;
  filtercategory: any;
  imageurl:any;
  JobCategoryArray:any=[];
  jobalert_submitted = false;
  jaotp_submitted = false;
  JROTPForm:FormGroup;
  JAOTPForm:FormGroup;
  jobAlertForm: FormGroup;
  Filterbycat:any=[];
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";   
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$"; 
  constructor(private restAPI: RESTApiService,
    private formBuilder: FormBuilder,
    private router:Router, private RestAPI: RESTApiService,
    private toast: ToastrService, private auth: AuthenticationService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.jobAlertForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      contact: ['', Validators.required],
      job_category: ['', Validators.required],
      job_type: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required]
    });
    this.SendOTPForm = this.formBuilder.group ({
      contact: ['', Validators.required]
    })
    this.registrationForm = this.formBuilder.group({
      name: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
    });
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.JAOTPForm = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required]
    });
    this.JROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.fetchJobCategory();
    this.fetchJobPost();
    this.fetchState();
  }

  get j() { return this.jobAlertForm.controls; }
  get jao() { return this.JAOTPForm.controls; }
  get v() { return this.JAOTPForm.controls; }
  get sn() { return this.SendOTPForm.controls;}
  get r() { return this.registrationForm.controls;}
  get vrfy() { return this.OTPVForm.controls;}
  get lwp() { return this.LWPForm.controls; }
  
  fetchJobCategory(){
    this.restAPI.get(environment.apiURL + environment.objectivesAPI).subscribe(data =>{
      this.data = data;
      this.JobCategoryArray = this.data.data.objective;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  onChange(val) {
    console.log(val);
    this.router.navigate(["/job-by-filter", val]);
  }
  LoggedIn(){
    return localStorage.getItem('token')!= null;
  }

  FilterByCategory(category){
    for(let i = 0; i < 7; i ++){
      if(this.jobArray[i].category==category){
        console.log("##", this.jobArray[i]);
        this.filtercategory.push(this.jobArray[i]);
      }
    }
  }

  fetchJobPost(){
    this.restAPI.get(environment.apiURL + environment.JobAPI).subscribe(data =>{
      this.data = data;
      this.jobArray = this.data.data.jobpost;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  jobAlertSubmit(){
    this.jobalert_submitted = true;
    // stop here if form is invalid
    if (this.jobAlertForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.jobAlertForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.JobAlertFormAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        console.log(this.data1);
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.jobAlertForm.reset();
        this.jobalert_submitted  = false;
        // jQuery('#joba-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000); 
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  SendOTPSubmit(){
    this.sendotp_submitted = true;
    // stop here if form is invalid
    if (this.JROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.JROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        // this.SendOTPForm.reset();
      //  jQuery('#bfi-verification').modal('hide');
        this.sendotp_submitted  = false;
        // this.router.navigate(["/thank-you"]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  JAOTPSubmit(){
    this.jaotp_submitted = true;
    // stop here if form is invalid
    if (this.JAOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.JAOTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(this.data);
        this.toast.success('Thank You For Job Alert Subscription.!');
        this.JAOTPForm.reset();
       jQuery('#joba-verification').modal('hide');
        this.jaotp_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  JROTPSubmit(){
    // stop here if form is invalid
    if (this.JROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.JROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }


  fetchState() {
    this.restAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }
  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.full_name2 = this.data1.name;
        this.email2 = this.data1.email;
        console.log(this.mob_no);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        /*  */
        this.registratiopn_submitted = false;
        this.showMe4 = false;
        this.showMe3 = true;
        
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/jobs"]);
          // jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('नंबर सत्यापित हो गया है');
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;
        this.showMe2 = true;
        this.showMe3 = false;
        this.showMe4 = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    this.showMe = false
    /* this.showMe2 = true */
    this.showMe4 = true
  }

}
