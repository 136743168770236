import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Data } from 'src/app/models/data';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../../_helpers/mustmatch.validator';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { Router } from '@angular/router';
declare var jQuery: any;
@Component({
  selector: 'app-middle-header',
  templateUrl: './middle-header.component.html',
  styleUrls: ['./middle-header.component.scss']
})
export class MiddleHeaderComponent implements OnInit {
  showMe: boolean= false;
  showMe2: boolean= false;
  showMe3: boolean= false;
  enva: any;
  imageurl:any;
  UserDetailsArray:any=[];
  pass:any;
  generalData: any =[];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";   
  data : any = [];
  data1:Data;
  statesArray: any=[];
  mob_no:any;
  cityArray: any = [];
  RROTPForm: FormGroup;
  ForgotForm:FormGroup;
  forgot_submitted = false;
  LWPForm: FormGroup;  //login with password
  ForgotOTPForm:FormGroup;
  ForgotROTPForm:FormGroup;
  forgotrotp_submitted = false;
  fotp_submitted = false;
  lwp_submitted = false;
  ROTPForm: FormGroup;
  disableBtn = false;
  rrotp_submitted = false;
  rotp_submitted = false;
  register_submitted = false;
  RegisterForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private restAPI: RESTApiService,
    private router: Router,
    private auth:AuthenticationService) {
    this.imageurl = environment.imageURL;    
    this.enva = environment.assetsURL;
   }

  ngOnInit(): void {
    this.RegisterForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });
    this.ROTPForm = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required]
    });
    this.RROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.ForgotForm = this.formBuilder.group({
      contact: ['', [Validators.required]]
    });
    this.ForgotOTPForm = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_pass: ['', [Validators.required]]
      }, {
        validator: MustMatch('password', 'confirm_pass')
    });
    this.ForgotROTPForm = this.formBuilder.group({
      contact: ['', [Validators.required]]
    });
    this.fetchGeneralSetting();
    this.fetchState();
    this.fetchUserDetails();
  }

  get r() { return this.RegisterForm.controls; }
  get ro() { return this.ROTPForm.controls; }
  get rr() { return this.RROTPForm.controls; }
  get lwp() { return this.LWPForm.controls; }
  get f() { return this.ForgotForm.controls; }
  get fo() { return this.ForgotOTPForm.controls; }
  get for() { return this.ForgotROTPForm.controls; }


  fetchGeneralSetting(){
    this.restAPI.get(environment.apiURL + environment.generalSettingAPI).subscribe(data =>{
      this.data = data;
      this.generalData = this.data.data.generalsetting;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  fetchState() {
    this.restAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  RegisterSubmit(){
    this.register_submitted = true;
    // stop here if form is invalid
    if (this.RegisterForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      //let data = this.movementForm.getRawValue();
      this.data1 = this.RegisterForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserRegisterAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        console.log(this.mob_no);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.RegisterForm.reset();
        this.register_submitted  = false;
        jQuery('#register').modal('hide');
        jQuery('#user-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  ROTPSubmit(){
    this.rotp_submitted = true;
    // stop here if form is invalid
    if (this.ROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.ROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserOTPRegisterAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('Registration Successfully');
        this.ROTPForm.reset();
       jQuery('#user-verification').modal('hide');
        this.rotp_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  Logout() {
    localStorage.removeItem('token');
    localStorage.clear();
    this.router.navigate(["/home"]);
  }

  RROTPSubmit(){
    this.rrotp_submitted = true;
    // stop here if form is invalid
    if (this.RROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.RROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserRegisterResendOTP, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        this.rrotp_submitted  = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  LWPSubmit(){
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
        return;
    } else{
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data:any) => {
        if(data.success==false){
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token',data.data.token);
          this.router.navigate(["/user-dashboard"]);
          jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }

  ForgotSubmit(){
    this.forgot_submitted = true;
    // stop here if form is invalid
    if (this.ForgotForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      //let data = this.movementForm.getRawValue();
      this.data1 = this.ForgotForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserForgotStep1Form, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        console.log(this.mob_no);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.ForgotForm.reset();
        this.forgot_submitted  = false;
        this.showMe3 = false;
        jQuery('#forgot-password').modal('hide');
        jQuery('#fuser-verification').modal('show');
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  ForgotOTPSubmit(){
    this.fotp_submitted = true;
    // stop here if form is invalid
    if (this.ForgotOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.ForgotOTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserForgotStep2Form, data).subscribe(data => {
        console.log(data);
        this.toast.success('Password Changed Successfully. Please Login');
        this.ForgotOTPForm.reset();
       jQuery('#fuser-verification').modal('hide');
        this.fotp_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  ForgotROTPSubmit(){
    this.forgotrotp_submitted = true;
    // stop here if form is invalid
    if (this.ForgotROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.ForgotROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserForgotROTPForm, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        this.forgotrotp_submitted  = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }

  fetchUserDetails() {
    this.restAPI.get(environment.apiURL + environment.UserfetchDetails).subscribe(data => {
      this.data = data;
      this.UserDetailsArray = this.data.data.user;
      console.log(this.UserDetailsArray);
    }, err => {
      console.log(err);
    });
  }

  toogleTag()
  {
    this.showMe = true
    this.showMe2 = false
  }
  toogleTag2()
  {
    this.showMe2 = true
    this.showMe = false
  }
  toogleTag3()
  {
    this.showMe2 = false
    this.showMe = false
    this.showMe3 = true
  }
}
