import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sena-bharti-student-union',
  templateUrl: './sena-bharti-student-union.component.html',
  styleUrls: ['./sena-bharti-student-union.component.scss']
})
export class SenaBhartiStudentUnionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
