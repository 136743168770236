import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
declare var jQuery: any;
@Component({
  selector: 'app-user-subscription',
  templateUrl: './user-subscription.component.html',
  styles:[
    `.modal{background: rgba(0,0,0, .5);`
  ],
  styleUrls: ['./user-subscription.component.scss']
})
export class UserSubscriptionComponent implements OnInit {
  // Mission Subscribe
  enva:any;
  MissionFormData:any = [];
  JobCategoryArray:any=[];
  statesArray:any=[];
  cityArray:any=[];
  MSForm: FormGroup;
  OPForm:FormGroup;
  OForm:FormGroup;
  ms_submitted = false;
  JobAlertFormData:any=[];
  UserSubscriptionOther:any=[];
  UserSubscriptionJobAlerts:any=[];
  MissionSubscribeModal : boolean;
  OtherSubscribeModal:boolean;
  JobAlertDeletemodal:boolean;
  ObjectiveSubscribeModal: boolean;
  UserSubscriptionMission:any=[];
  jobalert_submitted = false;
  UserSubscriptionObjective:any=[];
  ObjectiveFormData:any=[];
  OtherFormData:any=[];
  JADForm:FormGroup;
  data:any=[];
  jobAlertForm: FormGroup;
  constructor(private restAPI:RESTApiService,
    private router:Router,
    private formBuilder: FormBuilder,
    private toast: ToastrService) { 
      this.enva = environment.assetsURL;
    }

  ngOnInit(): void {
    this.jobAlertForm = this.formBuilder.group({
      job_category: ['', Validators.required],
      job_type: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required]
    });
    
    this.MSForm = this.formBuilder.group({
      mission_id: ['', Validators.required]
    });
    this.OForm = this.formBuilder.group({
      objective_id: ['', Validators.required]
    });
    this.OPForm = this.formBuilder.group({
      type: ['', Validators.required]
    });    
    this.JADForm = this.formBuilder.group({
      alert_id: ['', Validators.required]
    });
    this.fetchJobCategory();
    this.fetchUserSubscriptions();
    this.fetchState();
  }
  get j() { return this.jobAlertForm.controls; }
  get ms() { return this.MSForm.controls; }
  get o() { return this.OForm.controls; }
  get op() { return this.OPForm.controls; }

  MSSubmit(){
    // stop here if form is invalid
    if (this.MSForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.MSForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserMissionSubscriptionAPi, data).subscribe(data => {
        console.log(data);
        this.toast.success('Thank You, Your Request has been taken!');
        this.MSForm.reset();
        this.MissionSubscribeModal = false; 
        this.fetchUserSubscriptions();
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchUserSubscriptions() {
    this.restAPI.get(environment.apiURL + environment.UserSubscriptionAPI).subscribe(data => {
      this.data = data;
      this.UserSubscriptionMission = this.data.data.missions;
      this.UserSubscriptionObjective = this.data.data.objectives;
      this.UserSubscriptionOther = this.data.data.other;
      this.UserSubscriptionJobAlerts = this.data.data.jobs;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  

OSubmit() {
  // stop here if form is invalid
  if (this.OForm.invalid) {
  this.toast.error('कृपया सभी फ़ील्ड जांचें');
    return;
  } else{
  let data = this.OForm.getRawValue();
  this.restAPI.post(environment.apiURL + environment.UserObjectiveSubscriptionAPi, data).subscribe(data => {
    console.log(data);
    this.toast.success('Thank You, Your Request has been taken!');
    this.OForm.reset();
    this.ObjectiveSubscribeModal = false; 
    this.fetchUserSubscriptions();
  }, err => {
    this.toast.error(err.error.error);
    console.log(err);
  });
  }
}

  OPSubmit(){
    // stop here if form is invalid
  if (this.OPForm.invalid) {
    this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else{
    let data = this.OPForm.getRawValue();
    this.restAPI.post(environment.apiURL + environment.UserOtherSubscriptionAPi, data).subscribe(data => {
      console.log(data);
      this.toast.success('Thank You, Your Request has been taken!');
      this.OPForm.reset();
      this.OtherSubscribeModal = false; 
      this.fetchUserSubscriptions();
    }, err => {
      this.toast.error(err.error.error);
      console.log(err);
    });
    }
  }

  jobAlertSubmit(){
    if (this.jobAlertForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
      } else{
      let data = this.jobAlertForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserAddJobAlertAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('A New Job Alert has been added! Thank You');
        this.jobAlertForm.reset();
        jQuery('#add-jobalert').modal('hide');
        this.fetchUserSubscriptions();
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
      }
  }


  JADSubmit(){
    if (this.JADForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
      } else{
      let data = this.JADForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.UserDeleteJobAlertAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('Job Alert has been deleted successfully.');
        this.JADForm.reset();
        this.JobAlertDeletemodal = false; 
        this.fetchUserSubscriptions();
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
      }
  }

  MissionSubscribe(event, item)
  {
    this.MissionSubscribeModal = true;
    this.MissionFormData = item;
    console.log(this.MissionFormData);
  }

  ObjectiveSubscribe(event, item){
    this.ObjectiveSubscribeModal = true;
    this.ObjectiveFormData = item;
    console.log(this.ObjectiveFormData);
  }

  OtherSubscribe(event,item){
    this.OtherSubscribeModal = true;
    this.OtherFormData = item;
    console.log(this.OtherFormData);
  }

  hideobjective()
  {
    this.ObjectiveSubscribeModal = false; 
  }

  hideother() {
    this.OtherSubscribeModal = false; 
  }

  hide()
  {
    this.MissionSubscribeModal = false; 
  }

  JobAlertDelete(event, item){
    this.JobAlertDeletemodal = true;
    this.JobAlertFormData = item;
    console.log(this.JobAlertFormData);
  }


  hidejobalert()
  {
    this.JobAlertDeletemodal = false; 
  }

  fetchJobCategory(){
    this.restAPI.get(environment.apiURL + environment.objectivesAPI).subscribe(data =>{
      this.data = data;
      this.JobCategoryArray = this.data.data.objective;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  fetchState() {
    this.restAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }
}
