import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-user-busted-revolution',
  templateUrl: './user-busted-revolution.component.html',
  styleUrls: ['./user-busted-revolution.component.scss']
})
export class UserBustedRevolutionComponent implements OnInit {
  data: any=[];
  BR2Form: FormGroup;
  BustedSummaryArray:any=[];
  BR2_submitted = false;
  enva:any;
  loading: boolean;
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private RestAPI : RESTApiService) { 
      this.enva = environment.assetsURL;
    }

  ngOnInit(): void {
    this.BR2Form = this.formBuilder.group({
      citizen_india: ['yes', [Validators.required]],
      other_information: ['', [Validators.required]],
      video: ['', [Validators.required]],
      image: ['', [Validators.required]],
      confidential_name: ['no', [Validators.required]],
      information_connected: ['no', [Validators.required]],
      media_gallery: ['no', [Validators.required]],
      legal_action: ['no', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });
    this.fetchMovementSummary();
  }

  uploadImage(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.BR2Form.patchValue({
      image: file
    });
    this.BR2Form.get('image').setValue(file);
    console.log(file);
  }

  get b() { return this.BR2Form.controls; }

  uploadVideo(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.BR2Form.patchValue({
      video: file
    });
    this.BR2Form.get('video').setValue(file);
    console.log(file);
  }

  BR2Submit(){
    this.BR2_submitted = true;
    // stop here if form is invalid
    if (this.BR2Form.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("citizen_india", this.BR2Form.get('citizen_india').value);
      formData.append("other_information", this.BR2Form.get('other_information').value);
      formData.append("image", this.BR2Form.get('image').value);
      formData.append("video", this.BR2Form.get('video').value);
      formData.append("confidential_name", this.BR2Form.get('confidential_name').value);
      formData.append("information_connected", this.BR2Form.get('information_connected').value);
      formData.append("media_gallery", this.BR2Form.get('media_gallery').value);
      formData.append("legal_action", this.BR2Form.get('legal_action').value);
      this.loading = true; // Add this line
      //let data = this.BR2Form.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.UserBustedFormAPI, formData).subscribe(data => {
        console.log(data);
        this.toast.success("Thank Your for the submission. We'll get back to you soon!");
        this.BR2_submitted  = false;
        this.loading = false; // Add this line
        this.router.navigate(["/user-dashboard"]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
        this.loading = false;
      });
    }
  }

  fetchMovementSummary() {
    this.RestAPI.get(environment.apiURL + environment.UserMovementSummaryAPI).subscribe(data => {
      this.data = data;
      this.BustedSummaryArray = this.data.data.busted;
      console.log(this.BustedSummaryArray);
    }, err => {
      console.log(err);
    });
  }

}
