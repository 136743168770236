import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-objective-detail',
  templateUrl: './objective-detail.component.html',
  styleUrls: ['./objective-detail.component.scss']
})
export class ObjectiveDetailComponent implements OnInit {
  enva:any;
  data: any=[];
  imageurl:any;
  ObjectivesArray:any[];
  ObjectiveSlug:any;
  ObjectiveJobArray:any=[];
  ObjectiveDetailArray:any=[];
  constructor(private route: ActivatedRoute,
    private router:Router,
    private RestAPI: RESTApiService) { 
      this.enva = environment.assetsURL;
      this.imageurl = environment.imageURL;
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;
     }
    
     this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
           // trick the Router into believing it's last link wasn't previously loaded
           this.router.navigated = false;
        }
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.ObjectiveSlug = params['params'].slug;
        console.log(this.ObjectiveSlug)
      }
    });
    this.fetchObjectivebySlug();
    this.fetchObjectives();
    this.fetchObjectiveJobbySlug();
  }

  onImgError(event) { 
    event.target.src = environment.assetsURL + 'assets/images/noimage.jpg';
  }

  fetchObjectives(){
    this.RestAPI.get(environment.apiURL + environment.objectivesAPI).subscribe(data =>{
      this.data = data;
      this.ObjectivesArray = this.data.data.objective;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  fetchObjectivebySlug(){
    this.RestAPI.get(environment.apiURL + environment.ObjectiveDetailAPI + this.ObjectiveSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.ObjectiveDetailArray = this.data.data.objective[0];
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchObjectiveJobbySlug(){
    this.RestAPI.get(environment.apiURL + environment.ObjectiveJobAPI + this.ObjectiveSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.ObjectiveJobArray = this.data.data.Jobs;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }
}
