<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <h3>वालंटियर फार्म</h3>
            <div class="breadcrumb-sec">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">वालंटियर फार्म</li> -->
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</section>

<section class="membership-form">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="heading-title">
                    <div class="title text-center">
                        <h3>स्वयंसेवक बनें</h3>
                        <p>हम उत्साही नागरिकों का एक समूह हैं जो राष्ट्र में बदलाव लाने के लिए दृढ़ हैं। आज से संपर्क करें और हमारी पहल का हिस्सा बनें।
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <form [formGroup]="VolunteerForm" (ngSubmit)="VolunteerSubmit()">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">First Name</label>
                            <input type="text" class="text-control" placeholder="Enter First Name" formControlName="first_name"  [ngClass]="{ 'is-invalid': volunteer_submitted && v.first_name.errors }">
                            <div *ngIf="volunteer_submitted && v.first_name.errors" class="invalid-login">
                                <div *ngIf="v.first_name.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Last Name</label>
                            <input type="text" class="text-control" placeholder="Enter Last Name" formControlName="last_name" [ngClass]="{ 'is-invalid': volunteer_submitted && v.last_name.errors }">
                            <div *ngIf="volunteer_submitted && v.last_name.errors" class="invalid-login">
                                <div *ngIf="v.last_name.errors.required">Last Name is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Mobile No.</label>
                            <input type="text" class="text-control" readonly placeholder="Enter Mobile No." [(ngModel)]="contact" formControlName="contact" [ngClass]="{ 'is-invalid': volunteer_submitted && v.contact.errors }">
                            <div *ngIf="volunteer_submitted && v.contact.errors" class="invalid-login">
                                <div *ngIf="v.contact.errors.required">मोबाइल नंबर चाहिए</div>
                            </div>
                        </div>
                        <div class="col-sm-4" style="display: none;">
                            <label class="label-control">OTP</label>
                            <input type="text" class="text-control" readonly placeholder="Enter OTP" [(ngModel)]="otp" formControlName="otp" [ngClass]="{ 'is-invalid': volunteer_submitted && v.otp.errors }">
                            <div *ngIf="volunteer_submitted && v.otp.errors" class="invalid-login">
                                <div *ngIf="v.otp.errors.required">OTP is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Email Id</label>
                            <input type="text" class="text-control" placeholder="Enter Email Id" formControlName="email" [ngClass]="{ 'is-invalid': volunteer_submitted && v.email.errors }">
                            <div *ngIf="volunteer_submitted && v.email.errors" class="invalid-login">
                                <div *ngIf="v.email.errors.required">ईमेल की आवश्यकता है</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Address</label>
                            <input type="text" class="text-control" placeholder="Enter Address" formControlName="address" [ngClass]="{ 'is-invalid': volunteer_submitted && v.address.errors }">
                            <div *ngIf="volunteer_submitted && v.address.errors" class="invalid-login">
                                <div *ngIf="v.address.errors.required">Address is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">State</label>
                            <select class="text-control" formControlName="state"  (change)="fetchCity($event)" [ngClass]="{ 'is-invalid': volunteer_submitted && v.state.errors }">
                                <option value="" [selected]="true">Select State</option>
                                <option *ngFor="let item of statesArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="volunteer_submitted && v.state.errors" class="invalid-login">
                                <div *ngIf="v.state.errors.required">State is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">City</label>
                            <select class="text-control" formControlName="city" [ngClass]="{ 'is-invalid': volunteer_submitted && v.city.errors }">
                                <option value="" [selected]="true">Select City</option>
                                <option *ngFor="let item of cityArray" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="volunteer_submitted && v.city.errors" class="invalid-login">
                                <div *ngIf="v.city.errors.required">City is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">Pincode</label>
                            <input type="text" class="text-control" placeholder="Enter Pincode" formControlName="pincode" [ngClass]="{ 'is-invalid': volunteer_submitted && v.pincode.errors }">
                            <div *ngIf="volunteer_submitted && v.pincode.errors" class="invalid-login">
                                <div *ngIf="v.pincode.errors.required">Pincode is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">D.O.B</label>
                            <input type="date" class="text-control" placeholder="Enter D.O.B" formControlName="dob" [ngClass]="{ 'is-invalid': volunteer_submitted && v.dob.errors }">
                            <div *ngIf="volunteer_submitted && v.dob.errors" class="invalid-login">
                                <div *ngIf="v.dob.errors.required">DOB is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Gender</label>
                            <select class="text-control" formControlName="gender" [ngClass]="{ 'is-invalid': volunteer_submitted && v.gender.errors }">
                                <option value="" selected>Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">female</option>
                            </select>
                            <div *ngIf="volunteer_submitted && v.gender.errors" class="invalid-login">
                                <div *ngIf="v.gender.errors.required">Gender is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">Father's Name</label>
                            <input type="text" class="text-control" placeholder="Enter Father's Name" formControlName="father_name"  [ngClass]="{ 'is-invalid': volunteer_submitted && v.father_name.errors }">
                            <div *ngIf="volunteer_submitted && v.father_name.errors" class="invalid-login">
                                <div *ngIf="v.father_name.errors.required">Father's Name is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">Aadhaar Number</label>
                            <input type="text" class="text-control" placeholder="Enter Aadhaar Number" formControlName="adhaar_number" [ngClass]="{ 'is-invalid': volunteer_submitted && v.adhaar_number.errors }">
                            <div *ngIf="volunteer_submitted && v.adhaar_number.errors" class="invalid-login">
                                <div *ngIf="v.adhaar_number.errors.required">Aadhaar Number is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Aadhaar Document</label>
                            <input type="file" class="text-control" formControlName="adhaar_doc" (change)="upload_adhaar($event)" [ngClass]="{ 'is-invalid': volunteer_submitted && v.adhaar_doc.errors }">
                            <div *ngIf="volunteer_submitted && v.adhaar_doc.errors" class="invalid-login">
                                <div *ngIf="v.adhaar_doc.errors.required">Aadhaar Document is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">PAN Number</label>
                            <input type="text" class="text-control" placeholder="Enter PAN Number" formControlName="pan_number" [ngClass]="{ 'is-invalid': volunteer_submitted && v.pan_number.errors }">
                            <div *ngIf="volunteer_submitted && v.pan_number.errors" class="invalid-login">
                                <div *ngIf="v.pan_number.errors.required">PAN Number is required</div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label class="label-control">PAN Document</label>
                            <input type="file" class="text-control" formControlName="pan_doc" (change)="upload_pan($event)" [ngClass]="{ 'is-invalid': volunteer_submitted && v.pan_doc.errors }">
                            <div *ngIf="volunteer_submitted && v.pan_doc.errors" class="invalid-login">
                                <div *ngIf="v.pan_doc.errors.required">PAN Document is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label class="label-control">Other Document Title</label>
                            <input type="text" class="text-control" placeholder="Enter Other Document Title" formControlName="other_doc_title" [ngClass]="{ 'is-invalid': volunteer_submitted && v.other_doc_title.errors }">
                            <div *ngIf="volunteer_submitted && v.other_doc_title.errors" class="invalid-login">
                                <div *ngIf="v.other_doc_title.errors.required">Other Document Title is required</div>
                            </div>
                        </div>

                        <div class="col-sm-4">
                            <label class="label-control">Other Document</label>
                            <input type="file" class="text-control" formControlName="pan_doc" (change)="upload_other($event)" [ngClass]="{ 'is-invalid': volunteer_submitted && v.other_doc.errors }">
                            <div *ngIf="volunteer_submitted && v.other_doc.errors" class="invalid-login">
                                <div *ngIf="v.other_doc.errors.required">Other Document is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <label class="label-control"><input type="checkbox" formControlName="acceptTerms"> &nbsp;&nbsp; I am above 18 years , and not enrolled as a government employee. I am not a member of any other political party registered with the Election Commission of India. I am not a member with any organization whose views, policies or actions are in conflict with the objective of the party. I have not been convicted of any offense involving moral turpitude. I hereby consent to receiving any communication from the party either in writing, electronically and/or in any audio-visual format via phone (including SMS/MMS), email and/or at my address.</label>
                            <div *ngIf="volunteer_submitted && v.acceptTerms.errors" class="invalid-login">
                                <div *ngIf="v.acceptTerms.errors.required">Acceptance is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button type="submit" class="btn btn-dark">Submit Now</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<div *ngIf="loading" class="loading-preloader">
    <div class="loadin-inner">
        <i class="fas fa-spinner fa-spin"></i>
        <h3>Please Wait... While We uploading your files.</h3>
    </div>
</div>