<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>हमारी ताकत</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                        </ol>
                      </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="strength-page-section2">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="info" [innerHTML]="strengthArray.content"></div>
            </div>

            <div class="col-sm-12">
                <h5 style="color: #039c54; font-weight: bold;">› हमारा दृढ़ संकल्प -</h5>
                <p style="font-weight: bold; padding-top: 5px;">"जीयेंगे-मरेंगे, ये वेतन तेरे लिए।।," <br>
                    "दर्द भी सहेंगे, जख्म भी सहेंगे, ये वतन तेरे लिए।,"<br>
                    "हर सितम भी सहेंगे, ये वतन तेरे लिए।,"<br>
                    "जान भी देंगे, कुर्बानी भी देंगे, ये वतन तेरे लिए।"</p>
                <!-- <div class="info text-cp" [innerHTML]="strengthArray.content1"></div> -->
            </div>

            <div class="col-sm-4 d-none">
                <div class="strength-image-flag">
                    <img src="{{imageurl}}{{strengthArray.image}}" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>