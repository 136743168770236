import { Component, OnInit } from '@angular/core';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  imageurl:any;
  data:any=[];
  ImageGalleryArray:any=[];
  constructor(private restAPI: RESTApiService) { }

  ngOnInit(): void {
    this.imageurl = environment.imageURL;
    this.fetchImageGallery();
  }

  fetchImageGallery(){
    this.restAPI.get(environment.apiURL + environment.ImageGalAPI).subscribe(data =>{
      this.data = data;
      this.ImageGalleryArray = this.data.data.imagegallery;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

}
