import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RESTApiService } from '../../services/RESTApi/restapi-service.service';
import { ActivatedRoute, Router,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {
  enva:any;
  data:any =[];
  NewsArray:any=[];
  NewsData:any=[];
  NewsSlug:any;
  countNewsViews:any;
  imageurl:any;
  constructor(private route: ActivatedRoute,
    private router:Router,
    private restAPI: RESTApiService) {
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
   }
   this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
       // trick the Router into believing it's last link wasn't previously loaded
       this.router.navigated = false;
      }
    });
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params['params'].slug) {
        this.NewsSlug = params['params'].slug;
        console.log(this.NewsSlug)
      }
    });
    this.fetchNewsbySlug();
    this.fetchNews();
    this.fetchNewsVisitors();
  }

  fetchNewsbySlug() {
    this.restAPI.get(environment.apiURL + environment.NewsDetailAPI + this.NewsSlug).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.NewsData = this.data.data.newspost[0];
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }

  fetchNewsVisitors(){
    this.restAPI.get(environment.apiURL + environment.NewsViewsDetailAPI + this.NewsSlug).subscribe(data => {
      this.data = data;
      this.countNewsViews = this.data.views;
     console.log(this.data.views);
    }, err => {
      console.log(err);
    });
  }

  fetchNews() {
    this.restAPI.get(environment.apiURL + environment.NewsAPI).subscribe(data =>{
      this.data = data;
      //console.log(this.data);
      this.NewsArray = this.data.data.newspost;
      console.log(this.data);
      }, err =>{
        console.log(err);
      });
  }


}
