import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  
  constructor(private auth: AuthenticationService, private router: Router,) {
  }
  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }
  canActivate(router, state: RouterStateSnapshot) {
    if (!this.LoggedIn()) {
      this.router.navigateByUrl('/home');
      return false;
    }
    switch (state.url) {
      case '/user-dashboard' :
      console.log(!this.LoggedIn());
      if (!this.LoggedIn()) {
        this.router.navigateByUrl('/home');
        return false;
      }
      return true;
      break;
      case '/user-profile' :
      console.log(!this.LoggedIn());
      if (!this.LoggedIn()) {
        this.router.navigateByUrl('/home');
        return false;
      }
      break;
      default :
      return true;
      break;
    }
    return true;
  }
}
