<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>समाचार</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['/']">मुख्य पृष्ठ</a></li>
                            <!-- <li class="breadcrumb-item active" aria-current="page">समाचार</li> -->
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="main-articles-slider">
    <div class="container">
        <div class="row">
            <div class="col-sm-6" *ngFor="let item of NewsSlider | slice:0:1">
                <div class="mr-main"> 
                    <a [routerLink]="['/news-detail',item.slug]"> <img src="{{imageurl}}{{item.image}}" class="img-fluid"></a>
                    <div class="overlay-content">
                        <h3><a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a></h3>
                        <div class="category-post-meta">
                            <ul>
                                <li><a [routerLink]="['/news-detail',item.slug]">एडमिन</a></li>
                                <!--<li><a [routerLink]="['/news-detail',item.slug]">April 18, 2016</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="mr-main1" *ngFor="let item of NewsSlider | slice:1:2"> 
                    <a [routerLink]="['/news-detail',item.slug]"> <img src="{{imageurl}}{{item.image}}" class="img-fluid"></a>
                    <div class="overlay-content">
                        <h3><a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a></h3>
                        <div class="category-post-meta">
                            <ul>
                                <li><a [routerLink]="['/news-detail',item.slug]">एडमिन</a></li>
                                <!--<li><a [routerLink]="['/news-detail',item.slug]">April 18, 2016</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="mr-main1" *ngFor="let item of NewsSlider | slice:3:4"> 
                    <a [routerLink]="['/news-detail',item.slug]"> <img src="{{imageurl}}{{item.image}}" class="img-fluid"></a>
                    <div class="overlay-content">
                        <h3><a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a></h3>
                        <div class="category-post-meta">
                            <ul>
                                <li><a [routerLink]="['/news-detail',item.slug]">एडमिन</a></li>
                                 <!--<li><a [routerLink]="['/news-detail',item.slug]">April 18, 2016</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="news-widgets">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="category-widg">
                    <div class="category-title cat1" *ngFor="let item of CatNewsOneArray | slice:0:1">
                        <strong>{{item.category_name}}</strong>
                    </div>
                    <div class="category-main">
                        <div class="category-grid" *ngFor="let item of CatNewsOneArray | slice:0:1">
                            <div class="main-img">
                                <a [routerLink]="['/news-detail',item.slug]"><img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid"></a>
                            </div>
                            <div class="overlay-content">
                                <h3><a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a></h3>
                                <div class="category-post-meta">
                                    <ul>
                                        <li><a [routerLink]="['/news-detail',item.slug]">एडमिन</a></li>
                                 <!--<li><a [routerLink]="['/news-detail',item.slug]">April 18, 2016</a></li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="category-list">
                        <ul>
                            <li *ngFor="let item of CatNewsOneArray | slice:1:5">
                                <a [routerLink]="['/news-detail',item.slug]">
                                    <div class="media">
                                        <img src="{{imageurl}}{{item.image}}" alt="images" class="img-fluid">
                                        <div class="media-body">
                                            <h6>{{item.title}}</h6>
                                            <p>{{item.content}}</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="category-widg">
                    <div class="category-title cat2" *ngFor="let item of CatNewsTwoArray | slice:0:1">
                        <strong>{{item.category_name}}</strong>
                    </div>
                    <div class="category-main">
                        <div class="category-grid" *ngFor="let item of CatNewsTwoArray | slice:0:1">
                            <div class="main-img">
                                <a [routerLink]="['/news-detail',item.slug]"><img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid"></a>
                            </div>
                            <div class="overlay-content">
                                <h3><a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a></h3>
                                <div class="category-post-meta">
                                    <ul>
                                        <li><a [routerLink]="['/news-detail',item.slug]">एडमिन</a></li>
                                 <!--<li><a [routerLink]="['/news-detail',item.slug]">April 18, 2016</a></li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="category-list">
                        <ul>
                            <li *ngFor="let item of CatNewsTwoArray | slice:1:5">
                                <a [routerLink]="['/news-detail',item.slug]">
                                    <div class="media">
                                        <img src="{{imageurl}}{{item.image}}" alt="images" class="img-fluid">
                                        <div class="media-body">
                                            <h6>{{item.title}}</h6>
                                            <p>{{item.content}}</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="category-widg">
                    <div class="category-title cat3" *ngFor="let item of CatNewsThreeArray | slice:0:1">
                        <strong>{{item.category_name}}</strong>
                    </div>
                    <div class="category-main">
                        <div class="category-grid" *ngFor="let item of CatNewsThreeArray | slice:0:1">
                            <div class="main-img">
                                <a [routerLink]="['/news-detail',item.slug]"><img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid"></a>
                            </div>
                            <div class="overlay-content">
                                <h3><a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a></h3>
                                <div class="category-post-meta">
                                    <ul>
                                        <li><a [routerLink]="['/news-detail',item.slug]">एडमिन</a></li>
                                 <!--<li><a [routerLink]="['/news-detail',item.slug]">April 18, 2016</a></li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="category-list">
                        <ul>
                            <li *ngFor="let item of CatNewsThreeArray | slice:1:5">
                                <a [routerLink]="['/news-detail',item.slug]">
                                    <div class="media">
                                        <img src="{{imageurl}}{{item.image}}" alt="images" class="img-fluid">
                                        <div class="media-body">
                                            <h6>{{item.title}}</h6>
                                            <p>{{item.content}}</p>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- latestNews Section -->
<section class="latest-news-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="category-title">
                    <strong>ताज़ा समाचार</strong>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3" *ngFor="let item of LatestNewsArray">
                <div class="category-grid">
                    <div class="news-img">
                        <a [routerLink]="['/news-detail',item.slug]"><img src="{{imageurl}}{{item.image}}" alt="{{item.title}}" class="img-fluid"></a>
                        <span class="category-tag">{{item.category_name}}</span>
                    </div>
                    <div class="news-con">
                        <h3><a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a></h3>
                        <ul>
                            <li><i class="fas fa-user"></i> एडमिन</li>
                            <!--<li><i class="fas fa-clock"></i> 12.10.2020 10:00PM</li>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
