import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http'; 
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr'; 
import {  RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { YouTubePlayerModule } from "@angular/youtube-player";
import {GoogleMapsModule} from '@angular/google-maps';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { AuthGuardService } from './services/Authentication/AuthGuard.service';
//Components
import { AppComponent } from './app.component';
import { TopHeaderComponent } from './components/top-header/top-header.component';
import { MiddleHeaderComponent } from './components/middle-header/middle-header.component';
import { HomeComponent } from './components/home/home.component';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HomeTestimonialsComponent } from './components/home-testimonials/home-testimonials.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { OrganizationFounderComponent } from './components/organization-founder/organization-founder.component';
import { OurMissionComponent } from './components/our-mission/our-mission.component';
import { OrganizationStructureComponent } from './components/organization-structure/organization-structure.component';
import { OurStrengthComponent } from './components/our-strength/our-strength.component';
import { OurVisionComponent } from './components/our-vision/our-vision.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { JobDetailComponent } from './components/job-detail/job-detail.component';
import { OurIdentityComponent } from './components/our-identity/our-identity.component';
import { NewsComponent } from './components/news/news.component';
import { MissionDetailComponent } from './components/mission-detail/mission-detail.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { NewsDetailComponent } from './components/news-detail/news-detail.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OurObjectiveComponent } from './components/our-objective/our-objective.component';
import { OurMovementComponent } from './components/our-movement/our-movement.component';
import { BriberyFreeIndiaComponent } from './components/bribery-free-india/bribery-free-india.component';
import { MovementDetailComponent } from './components/movement-detail/movement-detail.component';
import { BfiDetailComponent } from './components/bfi-detail/bfi-detail.component';
import { BustedRevolutionComponent } from './components/busted-revolution/busted-revolution.component';
import { BustedRevolutionFormComponent } from './components/busted-revolution-form/busted-revolution-form.component';
import { DonateUsComponent } from './components/donate-us/donate-us.component';
import { ObjectiveDetailComponent } from './components/objective-detail/objective-detail.component';
import { VideoGalleryComponent } from './components/video-gallery/video-gallery.component';
import { BustedRevolutionDetailComponent } from './components/busted-revolution-detail/busted-revolution-detail.component';
import { ExposeCampaignComponent } from './components/expose-campaign/expose-campaign.component';
import { ExposeCampaignDetailComponent } from './components/expose-campaign-detail/expose-campaign-detail.component';
import { ExposeCampaignFormComponent } from './components/expose-campaign-form/expose-campaign-form.component';
import { CareerCounsellingComponent } from './components/career-counselling/career-counselling.component';
import { CareerCounsellingFormComponent } from './components/career-counselling-form/career-counselling-form.component';
import { DonateUsStep2Component } from './components/donate-us-step2/donate-us-step2.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailComponent } from './components/blog-detail/blog-detail.component';
import { MembershipComponent } from './components/membership/membership.component';
import { MembershipFormComponent } from './components/membership-form/membership-form.component';
import { SuggestIndiaComponent } from './components/suggest-india/suggest-india.component';
import { LocateUsComponent } from './components/locate-us/locate-us.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { RefundPolicyComponent } from './components/refund-policy/refund-policy.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ThankYouDonationComponent } from './components/thank-you-donation/thank-you-donation.component';
import { JobFilterComponent } from './components/job-filter/job-filter.component';
import { CareercounsellingFilterComponent } from './components/careercounselling-filter/careercounselling-filter.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserSidebarComponent } from './components/user-sidebar/user-sidebar.component';
import { UserSubscriptionComponent } from './components/user-subscription/user-subscription.component';
import { UserMembershipComponent } from './components/user-membership/user-membership.component';
import { UserUpdatesComponent } from './components/user-updates/user-updates.component';
import { UserBuyMembershipComponent } from './components/user-buy-membership/user-buy-membership.component';
import { UserBustedRevolutionComponent } from './components/user-busted-revolution/user-busted-revolution.component';
import { UserExposeCampaignComponent } from './components/user-expose-campaign/user-expose-campaign.component';
import { UserExposeCampaignChatsComponent } from './components/user-expose-campaign-chats/user-expose-campaign-chats.component';
import { UserBustedRevolutionChatsComponent } from './components/user-busted-revolution-chats/user-busted-revolution-chats.component';
import { BecomeVolunteerComponent } from './components/become-volunteer/become-volunteer.component';
import { OrganizationStructurePageTwoComponent } from './components/organization-structure-page-two/organization-structure-page-two.component';
import { StudentUnionFounderComponent } from './components/student-union-founder/student-union-founder.component';
import { OurIdentityAndolanComponent } from './components/our-identity-andolan/our-identity-andolan.component';
import { OurVisionAndolanComponent } from './components/our-vision-andolan/our-vision-andolan.component';
import { SenaBhartiStudentUnionComponent } from './components/sena-bharti-student-union/sena-bharti-student-union.component';
import { OurMissionAndolanComponent } from './components/our-mission-andolan/our-mission-andolan.component';
import { OurStrengthAndolanComponent } from './components/our-strength-andolan/our-strength-andolan.component';
import { AboutUsAndolanComponent } from './components/about-us-andolan/about-us-andolan.component';
import { CommitteeFounderComponent } from './components/committee-founder/committee-founder.component';
import { GalleryAndolanComponent } from './components/gallery-andolan/gallery-andolan.component';
import { CommitteeStructureComponent } from './components/committee-structure/committee-structure.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { DownloadFileService } from './services/download-flie.service';
import { UploadYourLetterComponent } from './components/upload-your-letter/upload-your-letter.component';
import { ViewGalleryAndLetterComponent } from './components/view-gallery-and-letter/view-gallery-and-letter.component';
import { ViewGalleryComponent } from './components/view-gallery/view-gallery.component';
import { ViewLettersComponent } from './components/view-letters/view-letters.component';
import { LettersAndhraPradeshComponent } from './components/letters-andhra-pradesh/letters-andhra-pradesh.component';
import { LettersArunachalPradeshComponent } from './components/letters-arunachal-pradesh/letters-arunachal-pradesh.component';
import { LettersAssamComponent } from './components/letters-assam/letters-assam.component';
import { LettersBiharComponent } from './components/letters-bihar/letters-bihar.component';
import { LettersChhattisgarhComponent } from './components/letters-chhattisgarh/letters-chhattisgarh.component';
import { LettersGoaComponent } from './components/letters-goa/letters-goa.component';
import { LettersGujaratComponent } from './components/letters-gujarat/letters-gujarat.component';
import { LettersHaryanaComponent } from './components/letters-haryana/letters-haryana.component';
import { LettersHimachalPradeshComponent } from './components/letters-himachal-pradesh/letters-himachal-pradesh.component';
import { LettersJharkhandComponent } from './components/letters-jharkhand/letters-jharkhand.component';
import { LettersKarnatakaComponent } from './components/letters-karnataka/letters-karnataka.component';
import { LettersKeralaComponent } from './components/letters-kerala/letters-kerala.component';
import { LettersMadhyaPradeshComponent } from './components/letters-madhya-pradesh/letters-madhya-pradesh.component';
import { LettersMaharashtraComponent } from './components/letters-maharashtra/letters-maharashtra.component';
import { LettersManipurComponent } from './components/letters-manipur/letters-manipur.component';
import { LettersMeghalayaComponent } from './components/letters-meghalaya/letters-meghalaya.component';
import { LettersMizoramComponent } from './components/letters-mizoram/letters-mizoram.component';
import { LettersNagalandComponent } from './components/letters-nagaland/letters-nagaland.component';
import { LettersOdishaComponent } from './components/letters-odisha/letters-odisha.component';
import { LetterPunjabComponent } from './components/letter-punjab/letter-punjab.component';
import { LetterRajasthanComponent } from './components/letter-rajasthan/letter-rajasthan.component';
import { LetterSikkimComponent } from './components/letter-sikkim/letter-sikkim.component';
import { LetterTamilNaduComponent } from './components/letter-tamil-nadu/letter-tamil-nadu.component';
import { LetterTelanganaComponent } from './components/letter-telangana/letter-telangana.component';
import { LetterTripuraComponent } from './components/letter-tripura/letter-tripura.component';
import { LetterUttarPradeshComponent } from './components/letter-uttar-pradesh/letter-uttar-pradesh.component';
import { LetterUttarakhandComponent } from './components/letter-uttarakhand/letter-uttarakhand.component';
import { LetterWestBengalComponent } from './components/letter-west-bengal/letter-west-bengal.component';
import { OuridentityComponent } from './components/ouridentity/ouridentity.component';
import { OurIdentityTwoComponent } from './components/our-identity-two/our-identity-two.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { PaymentGatewayComponent } from './components/payment-gateway/payment-gateway.component';
@NgModule({
  declarations: [
    AppComponent,
    TopHeaderComponent,
    MiddleHeaderComponent,
    HomeComponent,
    HomeSliderComponent,
    FooterComponent,
    HomeTestimonialsComponent,
    NavigationComponent,
    OrganizationFounderComponent,
    OurMissionComponent,
    OrganizationStructureComponent,
    OurStrengthComponent,
    OurVisionComponent,
    JobsComponent,
    JobDetailComponent,
    OurIdentityComponent,
    NewsComponent,
    MissionDetailComponent,
    ImageGalleryComponent,
    ContactUsComponent,
    NewsDetailComponent,
    NotFoundComponent,
    OurObjectiveComponent,
    OurMovementComponent,
    BriberyFreeIndiaComponent,
    MovementDetailComponent,
    BfiDetailComponent,
    BustedRevolutionComponent,
    BustedRevolutionFormComponent,
    DonateUsComponent,
    ObjectiveDetailComponent,
    VideoGalleryComponent,
    BustedRevolutionDetailComponent,
    ExposeCampaignComponent,
    ExposeCampaignDetailComponent,
    ExposeCampaignFormComponent,
    CareerCounsellingComponent,
    CareerCounsellingFormComponent,
    DonateUsStep2Component,
    BlogComponent,
    BlogDetailComponent,
    MembershipComponent,
    MembershipFormComponent,
    SuggestIndiaComponent,
    LocateUsComponent,
    TermsAndConditionsComponent,
    RefundPolicyComponent,
    PrivacyPolicyComponent,
    ThankYouDonationComponent,
    JobFilterComponent,
    CareercounsellingFilterComponent,
    UserDashboardComponent,
    UserProfileComponent,
    UserSidebarComponent,
    UserSubscriptionComponent,
    UserMembershipComponent,
    UserUpdatesComponent,
    UserBuyMembershipComponent,
    UserBustedRevolutionComponent,
    UserExposeCampaignComponent,
    UserExposeCampaignChatsComponent,
    UserBustedRevolutionChatsComponent,
    BecomeVolunteerComponent,
    OrganizationStructurePageTwoComponent,
    StudentUnionFounderComponent,
    OurIdentityAndolanComponent,
    OurVisionAndolanComponent,
    SenaBhartiStudentUnionComponent,
    OurMissionAndolanComponent,
    OurStrengthAndolanComponent,
    AboutUsAndolanComponent,
    CommitteeFounderComponent,
    GalleryAndolanComponent,
    CommitteeStructureComponent,
    JoinUsComponent,
    UploadYourLetterComponent,
    ViewGalleryAndLetterComponent,
    ViewGalleryComponent,
    ViewLettersComponent,
    LettersAndhraPradeshComponent,
    LettersArunachalPradeshComponent,
    LettersAssamComponent,
    LettersBiharComponent,
    LettersChhattisgarhComponent,
    LettersGoaComponent,
    LettersGujaratComponent,
    LettersHaryanaComponent,
    LettersHimachalPradeshComponent,
    LettersJharkhandComponent,
    LettersKarnatakaComponent,
    LettersKeralaComponent,
    LettersMadhyaPradeshComponent,
    LettersMaharashtraComponent,
    LettersManipurComponent,
    LettersMeghalayaComponent,
    LettersMizoramComponent,
    LettersNagalandComponent,
    LettersOdishaComponent,
    LetterPunjabComponent,
    LetterRajasthanComponent,
    LetterSikkimComponent,
    LetterTamilNaduComponent,
    LetterTelanganaComponent,
    LetterTripuraComponent,
    LetterUttarPradeshComponent,
    LetterUttarakhandComponent,
    LetterWestBengalComponent,
    OuridentityComponent,
    OurIdentityTwoComponent,
    ThankYouComponent,
    PaymentGatewayComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    GoogleMapsModule,
    PasswordStrengthMeterModule,
    YouTubePlayerModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    })
  ],
  providers: [AuthGuardService, DownloadFileService],
  bootstrap: [AppComponent]
})
export class AppModule { }
