<section class="page-header-section" style="background: linear-gradient(#020202c7, #02020273), url(breadcrumb.jpg);background-repeat: no-repeat;
background-size: cover;">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>{{BFIDetailArray.title}}</h3>
                <div class="breadcrumb-sec">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a [routerLink]="['']">मुख्य पृष्ठ</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/bribery-free-india']">घूसखोरी मुक्त भारत</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{{BFIDetailArray.title}}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="bfi-detail-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <div class="bfi-div">
                    <div class="bfi-img">
                        <img src="{{imageurl}}{{BFIDetailArray.image}}" (error) ="onImgError($event)" alt="{{BFIDetailArray.title}}" title="{{BFIDetailArray.title}}" class="img-fluid">
                    </div>
                    
                    <div class="bfi-content">
                        <h3>{{BFIDetailArray.title}}</h3>
                        <hr />
                        <div [innerHTML]="BFIDetailArray.description">
                            
                        </div>
                    </div>

                    <div class="bfi-img-gal">
                        <h4><span>इमेज गैलरी</span></h4>
                        <div class="row">
                            <div class="col-sm-3 col-6 col-md-3" *ngFor="let item of BFIImagesArray">
                                <a [routerLink]="['']" class="thumbnail">
                                    <img src="{{imageurl}}{{item.bbi_image}}" title="{{item.image_title}}" alt="{{item.image_title}}" class="img-fluid">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="bfi-vdo-gal">
                        <h4><span>वीडियो गैलरी</span></h4>
                        <div class="row">
                            <div class="col-sm-4 col-6 col-md-4" *ngFor="let item of BFIVideosArray">
                                <youtube-player class="ytply" videoId="{{item.video_url}}" suggestedQuality="highres" [height]="200" [width]="250"></youtube-player>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-4">
                <div class="bfi-sidebar">
                    <div class="bfi-sidebar-widgets">
                        <div class="bfi-detail-wrap">
                            <div class="bfi-detail-wrap-header">
                                <h4>रिलेटेड पोस्ट्स</h4>
                            </div>
                            <div class="bfi-detail-wrap-body">
                                <ul class="bfi-post">
                                    <li *ngFor="let item of BFIArray | slice:0:3">
                                        <div class="bfi-li">
                                            <div class="bfi-img">
                                                <a [routerLink]="['/bfi-detail', item.slug]"><img src="{{imageurl}}{{item.image}}" (error) ="onImgError($event)" alt="{{item.title}}"></a>
                                            </div>
                                            <div class="bfi-cont">
                                                <h6><a [routerLink]="['/bfi-detail', item.slug]">{{item.title}}</a></h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>