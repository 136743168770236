<app-home-slider></app-home-slider>

<!-- News Section -->
<section class="news-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="latest-home">
					<div class="heading">
						<h6>ताज़ी खबरे:</h6>
					</div>
					<marquee behavior="scroll" onmouseover="this.stop();" onmouseout="this.start();">
						<ul class="news">
							<li *ngFor="let item of HomePageNewsArray">
								<a [routerLink]="['/news-detail',item.slug]">{{item.title}}</a>
							</li>
						</ul>
					</marquee>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Important section -->
<section class="important-section-s2">
	<div class="container">
		<div class="row">
			<div class="col-sm-3 col-6 col-md-3" *ngFor="let item of HomePageBoxArray | slice:0:4; let i = index">
				<div class="tab tab-{{i+1}}">
					<i class="main-i {{item.icon_text}}"></i>
					<h5>{{item.title}}</h5>
					<p>{{item.description}}</p>
					<a [routerLink]="[item.link]">{{item.button_name}} <i class="fas fa-chevron-right"></i></a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- About section -->
<section class="about-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="title">
					<h3 style="color: #f58634;" [innerHTML]="HomepageAboutArray.title"></h3>
				</div>
			</div>
			<div class="col-sm-12">
				<div class="about">
					<p [innerHTML]="HomepageAboutArray.content"></p>
				</div>
				<div class="control">
					<a [routerLink]="[HomepageAboutArray.link]">{{HomepageAboutArray.icon_text}}</a>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Management Committee section -->
<section class="committee-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 card-deck">
				<div class="card" *ngFor="let item of TeamArray">
					<div class="card-body">
						<img src="{{imageurl}}{{item.image}}" (error) ="onImgError($event)" class="ing-fluid" alt="profile">
						<h5>{{item.title}}</h5>
						<p>{{item.designation}}</p>
					</div>
					<div class="card-footer">
						<a [routerLink]="['/organization-structure']">आगे पढ़े</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Donation section -->
<section class="volunteer-section">
	<div class="container">
		<div class="row justify-content-center">
			<div class="donate" *ngFor="let item of HomePageWidgetsArray | slice:0:1">
				<h2 class="title">{{item.title}}</h2>
				<p>{{item.content}}</p>
				<a href="#" data-target="#join-volunteer" data-toggle="modal" class="btn">Join Now</a>
			</div>
		</div>
	</div>
</section>
<section class="mission-home">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 text-center">
				<div class="section-title m-3">
					<h4>हमारा मिशन &amp; सेवाएं</h4>
					<p>हम आपके कल्याण के लिए काम करते हैं &amp; विकास</p>
				</div>
			</div>
			<div class="col-sm-12">
				<owl-carousel-o [options]="customMOptions" class="card-deck">
                    <ng-template carouselSlide *ngFor="let item of MissionArrayCarouse">
                        <div class="outer">
							<div class="inner-box">
								<div class="icon">
									<i class="{{item.text}}"></i>
								</div>
								<div class="text">
									<h4>{{item.heading}}</h4>
									<p>{{item.description}}</p>
									<a [routerLink]="['/objective-detail', item.slug]">आगे पढ़े</a>
								</div>
							</div>
						</div>
                    </ng-template>
                </owl-carousel-o>
			</div>
		</div>
	</div>
</section>
<!-- Event News Section -->
<section class="event-news-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-4">
				<div class="event-sec">
					<h4 class="title"><span>ताज़ा ब्लॉग</span></h4>
					<ul>
						<li *ngFor="let item of HomePageBlogArray | slice:0:3">
							<a [routerLink]="['/blog-detail',item.slug]">
								<div class="media">
									<img src="{{imageurl}}{{item.image}}" (error) ="onImgError($event)" alt="{{item.heading}}" class="img-fluid">
									<div class="media-body">
										<h6>{{item.heading}}</h6>
										<p>{{item.content}}</p>
									</div>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div class="col-sm-4">
				<div class="event-sec">
					<h4 class="title"><span>वेलकम वीडियो</span></h4>
					<div class="video-main" *ngFor="let item of HomePageVideoArray | slice:0:1">
						<youtube-player class="ytply" videoId="{{item.url}}" suggestedQuality="highres" [height]="200" [width]="250"></youtube-player>
                    	<div class="view-btn"><a [routerLink]="['/video-gallery']">और देखे</a></div>
					</div>
				</div>
			</div>

			<div class="col-sm-4">
				<div class="event-sec">
					<h4 class="title"><span>समाचार और अपडेट</span></h4>
					<ul>
						<li *ngFor="let item of HomePageNewsArray | slice:0:3">
							<a [routerLink]="['/news-detail', item.slug]">
								<div class="media">
									<img src="{{imageurl}}{{item.image}}" (error) ="onImgError($event)" alt="{{item.title}}" class="img-fluid">
									<div class="media-body">
										<h6>{{item.title}}</h6>
										<p>{{item.short_description}}</p>
									</div>
								</div>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- Donation section -->
<section class="donation-section">
	<div class="container">
		<div class="row justify-content-center">
			<div class="donate" *ngFor="let item of HomePageWidgetsArray | slice:1:2">
				<h2 class="title">{{item.title}}</h2>
				<p>{{item.content}}</p>
				<a [routerLink]="[item.button_link]" class="btn">दान दे</a>
			</div>
		</div>
	</div>
</section>
<!-- Vission and Mission -->
<section class="vission-mission-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="row">
					<div class="col-sm-4" *ngFor="let item of HomePageBoxArray | slice:4:7">
						<div class="outer">
							<div class="inner">
								<i class="{{item.icon_text}}"></i>
								<h6>{{item.title}}</h6>
								<p>{{item.description}}</p>
								<a [routerLink]="[item.link]">आगे पढ़े</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- image & Video Section -->
<section class="img-video-section">
	<div class="container">
		<div class="row justify-content-center">
			<div class="section-title">
				<h4>इमेज और वीडियो गैलरी</h4>
				<p>हमारी ताज़ा गैलरी और वीडियो देखे</p>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<div class="row">
					<div class="col-sm-3 col-md-3 col-4" *ngFor="let item of ImageGalleryArray | slice:0:8">
						<div class="gallery-main">
							<a class="thumbnail">
								<img src="{{imageurl}}{{item.image}}" (error) ="onImgError($event)" alt="{{item.title}}" class="img-fluid">
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 text-center">
				<div class="video-main" *ngFor="let item of HomePageVideoArray | slice:1:2">
					<youtube-player class="ytply" videoId="{{item.url}}" suggestedQuality="highres" [height]="200" [width]="250"></youtube-player>
					<div class="view-btn"><a [routerLink]="['/video-gallery']">और देखे</a></div>
				</div>
			</div>
		</div>
	</div>
</section>
<app-home-testimonials></app-home-testimonials>
<!-- logo section -->
<section class="logo-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<owl-carousel-o class="logo-owl-carou" [options]="customOptions">
					<ng-template carouselSlide *ngFor="let item of HomePageFooLogo">
						<img src="{{imageurl}}{{item.image}}" alt="logo" class="img-fluid">
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</div>
</section>
