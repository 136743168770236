import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { MembershipData } from 'src/app/models/MembershipData';
import { HttpHeaders } from '@angular/common/http';
import { MustMatch } from '../../_helpers/mustmatch.validator';
import { ConfirmedValidator } from 'src/app/_helpers/confirmed.validators';
import { AuthenticationService } from "../../services/Authentication/authentication.service";
declare var jQuery: any;
@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styles: [
    `.modal{background: rgba(0,0,0, .5);z-index:999999999;}`
  ],
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {
  showMe7: boolean = false
  cardShow: boolean = true;
  UploadSSForm: FormGroup;
  uploadss_submitted = false;
  donation_name: any;
  donation_whatsapp: any;
  donation_alternate: any;
  mob_no3: any;
  donation_email: any;
  donation_gender: any;
  donation_is_indian_citizen: any;
  donation_country: any;
  donation_state: any;
  donation_city: any;
  donation_tehsil: any;
  donation_amount: any;
  donation_profession: any;
  donation_address: any;
  donation_pancard: any;
  password: any;
  password_confirmation: any;
  donation_amount3: any;
  NewUserFrom: FormGroup;
  newuser_submitted = false;
  showMeMemberHide: boolean  = true;
  showMeMember: boolean = false;
  // showMe: boolean= true;
  showMe2: boolean = true;
  showMe3: boolean = false;
  showMe4: boolean = true;
  showMe5: boolean = false;
  showMe6: boolean = false;
  showMe8: boolean = false;
  showMe9: boolean = true;
  message: any;
  full_name2: any;
  otpverify_submitted = false;
  movement_submitted = false;
  lwp_submitted = false;
  LWPForm: FormGroup;
  VOTPForm: FormGroup;
  OTPVForm: FormGroup;
  pass: any;
  motp_submitted = false;
  email2: any; 
  full_name: any;
  registrationForm: FormGroup;
  registratiopn_submitted = false;
  TodayDate = "30-05-2022";
  date1 = new Date();
  currentYear = this.date1.getUTCFullYear();
  currentMonth = this.date1.getUTCMonth() + 1;
  currentDay = this.date1.getUTCDay();
  FinalMonth: any;
  FinalDay: any;
  otp: any; contact: any; //for data
  BoxesContentArray: any = [];
  data2: MembershipData;
  data1: MembershipData;
  membership_submitted = false;
  MemPackagesArray: any = [];
  membershipotp_submitted = false;
  MembershipForm: FormGroup;
  disableBtn = false;
  mob_no:number;
  mob_no2:number;
  MOTPForm: FormGroup;
  MROTPForm: FormGroup;
  statesArray: any = [];
  countryArray: any = [];
  cityArray: any = [];
  data: any = [];
  MembershipContentData: any = [];
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private RestAPI: RESTApiService, private restAPI: RESTApiService, private auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    if (this.currentMonth < 10) {
      this.FinalMonth = "0" + this.currentMonth;
    } else {
      this.FinalMonth = this.currentMonth
    }
    if (this.currentDay < 10) {
      this.FinalDay = "0" + this.currentDay;
    } else {
      this.FinalDay = this.currentDay;
    }

    this.TodayDate = this.FinalDay + "-" + this.FinalMonth + "-" + this.currentYear;

    this.MembershipForm = this.formBuilder.group({
      name: ['', Validators.required],
      father_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      alternate_mobile_number: ['', [Validators.required]],
      business: ['', [Validators.required]],
      whatsapp_number: ['', [Validators.required]],
      adhaar_number: ['', [Validators.required]],
      facebook_url: ['', [Validators.required]],
      educational_qualification: ['', [Validators.required]],
      identity_card: ['', [Validators.required]],
      country: ['', [Validators.required]],
      member_type: ['', [Validators.required]],
      member_role: ['', [Validators.required]],
      date: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      contribution: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
    this.MOTPForm = this.formBuilder.group({
      contact: [''],
      name: ['' ],
      email: ['', []],
      otp: ['', Validators.required],
      password: ['', []],
      password_confirmation: ['', []]
    }
    );
    this.OTPVForm = this.formBuilder.group({
      name: [''],
      contact: ['', [Validators.required]],
      otp: ['', Validators.required]
    });
    this.LWPForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.MROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.NewUserFrom = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      state: ['', [Validators.required]],
      alternate_mobile_number: [''],
      whatsapp_number: [''],
      pancard: [''],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      city: ['', [Validators.required]],
      profession: ['', [Validators.required]],
      amount: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('password', 'password_confirmation')
    });

    this.UploadSSForm = this.formBuilder.group({
      payment_mode: ['0', [Validators.required]],
      payment_receipt: [''],
      user_id: [''],
      online: [''],
      name: ['', [Validators.required]],
      email: [''],
      contact: [''],
      address: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      tehsil: ['', [Validators.required]],
      profession: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      is_indian_citizen: ['', [Validators.required]],
      whatsapp_number: [''],
      alternate_mobile_number: [''],
      pancard: [''],
      amount: ['0', [Validators.required]]
    });
    this.registrationForm = this.formBuilder.group({
      name: [''],
      // email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
    })
    // this.fetchState();
    this.fetchMemContent();
    this.fetchboxes();
    this.fetchCountry();
  }

  get m() { return this.MembershipForm.controls; }
  get mo() { return this.MOTPForm.controls; }
  get r() { return this.registrationForm.controls;}
  get vrfy() { return this.OTPVForm.controls;}
  get lwp() { return this.LWPForm.controls; }
  get new() { return this.NewUserFrom.controls; }
  get ss() { return this.UploadSSForm.controls; }

  fetchboxes() {
    this.RestAPI.get(environment.apiURL + environment.HomepageAPI).subscribe(data => {
      this.data = data;
      this.BoxesContentArray = this.data.data.homepage_box;
      console.log(this.BoxesContentArray);
    }, err => {
      console.log(err);
    });
  }

  // MembershipSubmit() {
  //   this.membership_submitted = true;
  //   if ('name') {
  //     this.toast.error('कृपया सभी फ़ील्ड जांचें');
  //     return;
  //   } else {
  //     var formData: any = new FormData();
  //     const headers = new HttpHeaders();
  //     headers.append('Content-Type', 'multipart/form-data');
  //     headers.append('Accept', 'application/json');
  //     formData.append("alternate_mobile_number", this.MembershipForm.get('alternate_mobile_number').value);
  //     formData.append('identity_card', this.MembershipForm.get('identity_card').value);
  //     formData.append("name", this.MembershipForm.get('name').value);
  //     formData.append("email", this.MembershipForm.get('email').value);
  //     formData.append("contact", this.MembershipForm.get('contact').value);
  //     formData.append("dob", this.MembershipForm.get('dob').value);
  //     formData.append("gender", this.MembershipForm.get('gender').value);
  //     formData.append("address", this.MembershipForm.get('address').value);
  //     formData.append("state", this.MembershipForm.get('state').value);
  //     formData.append("city", this.MembershipForm.get('city').value);
  //     formData.append("adhaar_number", this.MembershipForm.get('adhaar_number').value);
  //     formData.append("father_name", this.MembershipForm.get('father_name').value);
  //     formData.append("whatsapp_number", this.MembershipForm.get('whatsapp_number').value);
  //     formData.append("referral_detail", this.MembershipForm.get('referral_detail').value);
  //     formData.append("date", this.MembershipForm.get('date').value);
  //     formData.append("member_organization", this.MembershipForm.get('member_organization').value);
  //     formData.append("member_level", this.MembershipForm.get('member_level').value);
  //     formData.append("member_role", this.MembershipForm.get('member_role').value);
  //     formData.append("member_type", this.MembershipForm.get('member_type').value);
  //     formData.append("pincode", this.MembershipForm.get('pincode').value);
  //     formData.append("country", this.MembershipForm.get('country').value);
  //     formData.append("educational_qualification", this.MembershipForm.get('educational_qualification').value);
  //     formData.append("facebook_url", this.MembershipForm.get('facebook_url').value);
  //     formData.append("is_indian_citizen", this.MembershipForm.get('is_indian_citizen').value);
  //     this.restAPI.post(environment.apiURL + environment.MembershipFormStep1API, formData).subscribe(data => {
  //       console.log(data);
  //       this.toast.success('Your Letter has submitted Successfully. Thank You');
  //       this.MembershipForm.reset();
  //       this.membership_submitted = false;
  //     }, err => {
  //       this.toast.error(err.error.error);
  //       console.log(err);
  //     });
  //   }
  // }


  MembershipSubmit() {
    this.membership_submitted = true;
    if (this.MembershipForm.invalid) {

      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      var formData: any = new FormData();
      this.data1 = this.MembershipForm.getRawValue();
      // formData.append("image", this.MembershipForm.get('identity_card').value);
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        // this.mob_no = this.data1.contact;
        // console.log(this.data1);
        this.toast.success('Your Form has submitted Successfully. Thank You');
        this.MembershipForm.reset();
        this.membership_submitted = false;
        // jQuery('#membership-verification').modal('show');
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }


  uploadIdentityCard(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.MembershipForm.patchValue({
      identity_card: file
    });
    this.MembershipForm.get('identity_card').setValue(file);
    console.log(file);
  }
  fetchMemContent() {
    this.RestAPI.get(environment.apiURL + environment.MembershipContentAPI).subscribe(data => {
      this.data = data;
      //console.log(this.data);
      this.MembershipContentData = this.data.data.membership_content;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  MOTPSubmit() {
    this.membershipotp_submitted = true;
    // stop here if form is invalid
    if (this.MOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      this.data2 = this.MOTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, this.data2).subscribe(data => {
        this.otp = this.data2.otp;
        this.contact = this.data2.contact;
        console.log(this.data2);
        this.toast.success('फ़ॉर्म सफलतापूर्वक सबमिट किया गया');
        this.MOTPForm.reset();
        this.showMe2 = true;
        // jQuery('#membership-verification').modal('hide');
        this.membershipotp_submitted = false;
        // this.router.navigate(["/thank-you"]);
      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }

  MROTPSubmit() {
    // stop here if form is invalid
    if (this.MROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.MROTPForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newotpResendOtpAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchCountry() {
    this.restAPI.get(environment.apiURL + environment.countryAPI).subscribe(data => {
      this.data = data;
      this.countryArray = this.data.data.countries;
      console.log(this.countryArray);
    }, err => {
      console.log(err);
    });
  }

  fetchState(e) {
    this.restAPI.get(environment.apiURL + environment.stateAPI1 + e.target.value).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  // fetchState() {
  //   this.RestAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
  //     this.data = data;
  //     this.statesArray = this.data.data.state;
  //     console.log(this.statesArray);
  //   }, err => {
  //     console.log(err);
  //   });
  // }

  fetchCity(e) {
    console.log(e);
    this.RestAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }
  registrationSubmit() {
    this.registratiopn_submitted = true;
    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      //let data = this.registrationForm.getRawValue();
      this.data1 = this.registrationForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.sendRegistrationOtpAPI, this.data1).subscribe(data => {
        this.mob_no = this.data1.contact;
        this.mob_no2 = this.data1.contact;
        this.full_name = this.data1.name;
        this.email2 = this.data1.email;
        this.full_name2 = this.data1.name;
        this.mob_no3 = this.data1.contact;
        console.log(this.mob_no);
        // this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        /*  */
        this.message = "ओटीपी सफलतापूर्वक भेजा गया"
        this.registratiopn_submitted = false;
        this.showMe2 = false;
        this.showMe3 = true;
        this.showMe4 = false;
        this.showMe5 = true;
        /* jQuery('#movement-verification').modal('show'); */
        setTimeout(() => {
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error('मोबाइल नंबर पहले से पंजीकृत है, कृपया लॉगिन करें ');
        console.log(err);
      });
    }
  }

  LWPSubmit() {
    this.lwp_submitted = true;
    // stop here if form is invalid
    if (this.LWPForm.invalid) {
      this.toast.error('सभी फ़ील्ड आवश्यक हैं');
      return;
    } else {
      const data: any = {
        email: this.LWPForm.get('email').value,
        password: this.LWPForm.get('password').value
      };

      this.auth.login(data).subscribe((data: any) => {
        if (data.success == false) {
          this.toast.error('उपयोगकर्ता नहीं मिला। कृपया दर्ज करें');
        } else {
          this.toast.success('सफलतापूर्वक लॉगिन ।');
          this.lwp_submitted = false;
          localStorage.setItem('token', data.data.token);
          this.router.navigate(["/user-dashboard"]);
          // jQuery('#userlogin').modal('hide');
        }
      }, (err) => {
        this.toast.error(err.error.error);
        console.error(err);
      });
    }
    console.log(this.auth.getUserDetails());
  }
  OTPVerifySubmit() {
    this.otpverify_submitted = true;
    // stop here if form is invalid
    if (this.OTPVForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
      return;
    } else {
      let data = this.OTPVForm.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.verifyOtpAPI, data).subscribe(data => {
        console.log(data);
        // this.toast.success('नंबर सत्यापित हो गया है');
        this.message = "नंबर सत्यापित हो गया है"
        this.OTPVForm.reset();
        /* jQuery('#movement-verification').modal('hide'); */
        this.otpverify_submitted = false;

        this.showMe2 = true;
        this.showMe3 = false;
        this.showMe4 = false;
        /* this.router.navigate(["/thank-you"]); */

      }, err => {
        this.toast.error('सही ओटीपी दर्ज करें');
        console.log(err);
      });
    }
  }
  toogleTag()
  {
    /* this.showMe2 = true */
    this.showMe4 = true
  }
  toggle() {
    this.showMe6 = true
  }
  toggle2() {
    this.showMe6 = false
  }
  toggle3() {
    this.showMe7 = true
    this.showMe8 = false
    this.showMe9 = true
  }
  toggle4() {
    this.showMe7 = false
    this.showMe8 = true
    this.showMe9 = false
  }

  toggle5(){
    this.showMeMemberHide = true
    this.showMeMember = false
    this.cardShow = true
  }
  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }
  NewuserSubmit(){
    this.newuser_submitted = true;
    // stop here if form is invalid
    if (this.NewUserFrom.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.NewUserFrom.getRawValue();
      this.RestAPI.post(environment.apiURL + environment.newregisterAPI, this.data1).subscribe(data => {
        // this.mob_no = this.data1.contact;
        // console.log(this.mob_no);
        this.donation_name = this.data1.name;
        this.donation_email = this.data1.email;
        this.donation_gender = this.data1.gender;
        this.donation_is_indian_citizen = this.data1.is_indian_citizen;
        this.donation_country = this.data1.country;
        this.donation_state = this.data1.state;
        this.donation_city = this.data1.city;
        this.donation_tehsil = this.data1.tehsil;
        this.donation_amount = this.data1.amount;
        this.donation_profession = this.data1.profession;
        this.donation_address = this.data1.address;
        this.donation_pancard = this.data1.pancard;
        this.donation_alternate = this.data1.alternate_mobile_number;
        this.donation_whatsapp = this.data1.whatsapp_number;
        this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
        this.NewUserFrom.reset();
        this.newuser_submitted  = false;
        this.cardShow = false;
        // jQuery('#bfi-verification').modal('show');
        this.showMeMemberHide = false;
        this.showMeMember = true;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  clearForm() {
    this.NewUserFrom.get('email').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('address').reset();
    this.NewUserFrom.get('state').reset();
    this.NewUserFrom.get('is_indian_citizen').reset();
    this.NewUserFrom.get('gender').reset();
    this.NewUserFrom.get('password').reset();
    this.NewUserFrom.get('whatsapp_number').reset();
    this.NewUserFrom.get('alternate_mobile_number').reset();
    this.NewUserFrom.get('city').reset();
    this.NewUserFrom.get('country').reset();
    this.NewUserFrom.get('tehsil').reset();
    this.NewUserFrom.get('password_confirmation').reset();
    this.registrationForm.get('name').reset();
    this.registrationForm.get('contact').reset();
  }

  uploadImage(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.UploadSSForm.patchValue({
      payment_receipt: file
    });
    this.UploadSSForm.get('payment_receipt').setValue(file);
    console.log(file);
  }

  // UploadssSubmit() {
  //   this.uploadss_submitted = true;
  //   if (this.UploadSSForm.invalid) {
  //     this.toast.error('कृपया फ़ील्ड जांचें');
  //     return;
  //   } else {
  //     let data = this.UploadSSForm.getRawValue();
  //     this.RestAPI.post(environment.apiURL + environment.previewFromAPI, data).subscribe(data => {
  //       var formData: any = new FormData();
  //       const headers = new HttpHeaders();
  //       headers.append('Content-Type', 'multipart/form-data');
  //       headers.append('Accept', 'application/json');
  //       formData.append("payment_mode", this.UploadSSForm.get('payment_receipt').value);
  //       console.log(data);
  //       this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
  //       this.UploadSSForm.reset();
  //       /* jQuery('#movement-verification').modal('hide'); */
  //       this.uploadss_submitted = false;
  //       /* this.router.navigate(["/thank-you"]); */

  //     }, err => {
  //       this.toast.error(err.error.error);
  //       console.log(err);
  //     });
  //   }

  //   // if (this.UploadSSForm.invalid) {
  //   //   this.toast.error('कृपया फ़ील्ड जांचें');
  //   //   return;
  //   // } else {
  //   //   var formData: any = new FormData();
  //   //   const headers = new HttpHeaders();
  //   //   headers.append('Content-Type', 'multipart/form-data');
  //   //   headers.append('Accept', 'application/json');
  //   //   formData.append("payment_mode", this.UploadSSForm.get('payment_receipt').value);
  //   //   formData.append("payment_mode", this.UploadSSForm.get('bank_transfer').value);
  //   //   formData.append("name", this.UploadSSForm.get('name').value);
  //   //   formData.append("address", this.UploadSSForm.get('address').value);
  //   //   formData.append("country", this.UploadSSForm.get('country').value);
  //   //   formData.append("city", this.UploadSSForm.get('city').value);
  //   //   formData.append("tehsil", this.UploadSSForm.get('tehsil').value);
  //   //   formData.append("profession", this.UploadSSForm.get('profession').value);
  //   //   formData.append("amount", this.UploadSSForm.get('amount').value);

  //   //   this.restAPI.post(environment.apiURL + environment.previewFromAPI, formData).subscribe(data => {
  //   //     console.log(data);
  //   //     this.toast.success('सफलतापूर्वक जमा। धन्यवाद');
  //   //     this.UploadSSForm.reset();
  //   //     this.uploadss_submitted = false;
  //   //   }, err => {
  //   //     this.toast.error(err.error.error);
  //   //     console.log(err);
  //   //   });
  //   // }
  // }
  UploadssSubmit() {
    this.uploadss_submitted = true;
    // if (this.UploadSSForm.invalid) {
    //   this.toast.error('कृपया फ़ील्ड जांचें');
    //   return;
    // } else {
    //   let data = this.UploadSSForm.getRawValue();
    //   this.RestAPI.post(environment.apiURL + environment.previewFromAPI, data).subscribe(data => {
    //     // var formData: any = new FormData();
    //     // const headers = new HttpHeaders();
    //     // headers.append('Content-Type', 'multipart/form-data');
    //     // headers.append('Accept', 'application/json');
    //     // formData.append("payment_mode", this.UploadSSForm.get('payment_receipt').value);
    //     // console.log(data);
    //     this.objective_id = this.data1.objective_id;
    //     this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
    //     this.UploadSSForm.reset();
    //     this.uploadss_submitted = false;

    //   }, err => {
    //     this.toast.error(err.error.error);
    //     console.log(err);
    //   });
    // }

    if (this.UploadSSForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें');
      return;
    } else {
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("payment_mode", this.UploadSSForm.get('payment_mode').value);
      formData.append("payment_receipt", this.UploadSSForm.get('payment_receipt').value);
      formData.append("online", this.UploadSSForm.get('online').value);
      formData.append("name", this.UploadSSForm.get('name').value);
      formData.append("address", this.UploadSSForm.get('address').value);
      formData.append("country", this.UploadSSForm.get('country').value);
      formData.append("state", this.UploadSSForm.get('state').value);
      formData.append("city", this.UploadSSForm.get('city').value);
      formData.append("tehsil", this.UploadSSForm.get('tehsil').value);
      formData.append("profession", this.UploadSSForm.get('profession').value);
      formData.append("amount", this.UploadSSForm.get('amount').value);
      formData.append("pancard", this.UploadSSForm.get('pancard').value);
      formData.append("user_id", this.UploadSSForm.get('user_id').value);
      this.restAPI.post(environment.apiURL + environment.previewFromAPI, formData).subscribe(data => {
        console.log(data);
        // this.user_id = this.data1.user_id;
        this.toast.success('सफलतापूर्वक जमा। धन्यवाद');
        this.UploadSSForm.reset();
        this.router.navigate(["/thank-you-donation"]);
        this.uploadss_submitted = false;
        this.showMeMemberHide = true
        this.showMeMember = false
        this.donation_amount3 = this.data1.amount;
        this.cardShow = true
        
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
  UploadssSubmit2() {
    this.uploadss_submitted = true;
    // if (this.UploadSSForm.invalid) {
    //   this.toast.error('कृपया फ़ील्ड जांचें');
    //   return;
    // } else {
    //   let data = this.UploadSSForm.getRawValue();
    //   this.RestAPI.post(environment.apiURL + environment.previewFromAPI, data).subscribe(data => {
    //     // var formData: any = new FormData();
    //     // const headers = new HttpHeaders();
    //     // headers.append('Content-Type', 'multipart/form-data');
    //     // headers.append('Accept', 'application/json');
    //     // formData.append("payment_mode", this.UploadSSForm.get('payment_receipt').value);
    //     // console.log(data);
    //     this.objective_id = this.data1.objective_id;
    //     this.toast.success('फॉर्म सफलतापूर्वक जमा किया गया।');
    //     this.UploadSSForm.reset();
    //     this.uploadss_submitted = false;

    //   }, err => {
    //     this.toast.error(err.error.error);
    //     console.log(err);
    //   });
    // }

    if (this.UploadSSForm.invalid) {
      this.toast.error('कृपया फ़ील्ड जांचें');
      return;
    } else {
      var formData: any = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      formData.append("payment_mode", this.UploadSSForm.get('payment_mode').value);
      formData.append("payment_receipt", this.UploadSSForm.get('payment_receipt').value);
      formData.append("online", this.UploadSSForm.get('online').value);
      formData.append("name", this.UploadSSForm.get('name').value);
      formData.append("address", this.UploadSSForm.get('address').value);
      formData.append("country", this.UploadSSForm.get('country').value);
      formData.append("state", this.UploadSSForm.get('state').value);
      formData.append("city", this.UploadSSForm.get('city').value);
      formData.append("tehsil", this.UploadSSForm.get('tehsil').value);
      formData.append("profession", this.UploadSSForm.get('profession').value);
      formData.append("amount", this.UploadSSForm.get('amount').value);
      formData.append("pancard", this.UploadSSForm.get('pancard').value);
      formData.append("user_id", this.UploadSSForm.get('user_id').value);
      this.restAPI.post(environment.apiURL + environment.previewFromAPI, formData).subscribe(data => {
        console.log(data);
        // this.user_id = this.data1.user_id;
        this.toast.success('सफलतापूर्वक जमा। धन्यवाद');
        this.UploadSSForm.reset();
        // this.router.navigate(["/payment-gateway"]);
        window.open('https://pmny.in/JIvnCRJBcXqs', '_blank');
        this.uploadss_submitted = false;
        this.showMeMemberHide = true
        this.showMeMember = false
        this.donation_amount3 = this.data1.amount;
        this.cardShow = true
        
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }
}