import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';
import { Data } from 'src/app/models/data';


declare var jQuery: any;
@Component({
  selector: 'app-our-mission',
  templateUrl: './our-mission.component.html',
  styles:[
    `.modal{background: rgba(0,0,0, .5);`
  ],
  styleUrls: ['./our-mission.component.scss']
})
export class OurMissionComponent implements OnInit {
  //Modal data details
  modaldata:any = [];


  mob_no: any;
  showModal : boolean;
  mission_id:any;
  enva:any;
  imageurl:any;
  data1:Data;
  mrotp_submitted = false;
  disableBtn = false;
  data:any=[];
  cityArray:any[];
  statesArray:any[];
  MOTPForm:FormGroup;
  MROTPForm:FormGroup;
  motp_submitted = false;
  mission_submitted = false;
  MissionForm:FormGroup;  
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";  
  OTPPattern = "^((\\+91-?)|0)?[0-9]{6}$";  
  MissionArray:any=[];
  MissionProblemArray:any=[];
  constructor(private restAPI:RESTApiService,
    private formBuilder: FormBuilder,
    private toast: ToastrService) { 
    this.enva = environment.assetsURL;
    this.imageurl = environment.imageURL;
  }

  ngOnInit(): void {
    this.MissionForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contact: ['', [Validators.required]],
      address: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pincode: ['', [Validators.required]],
      mission_id: ['', [Validators.required]]
    });

    this.MOTPForm = this.formBuilder.group({
      contact: ['', Validators.required],
      otp: ['', Validators.required],
      mission_id: ['', Validators.required]
    });
    this.MROTPForm = this.formBuilder.group({
      contact: ['', Validators.required]
    });
    this.fetchMission();
    this.fetchMissionPoblems();
    this.fetchState();
  }


  get m() { return this.MissionForm.controls; }
  get mo() { return this.MOTPForm.controls; }

  fetchMission(){
    this.restAPI.get(environment.apiURL + environment.missionAPI).subscribe(data =>{
      this.data = data;
      this.MissionArray = this.data.data.managemission;
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  fetchMissionPoblems(){
    this.restAPI.get(environment.apiURL + environment.missionProblemAPI).subscribe(data =>{
      this.data = data;
      this.MissionProblemArray = this.data.data[0];
      console.log(this.data);
    }, err => {
      console.log(err);
    });
  }

  MissionSubmit(){
    this.mission_submitted = true;
    // stop here if form is invalid
    if (this.MissionForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      //let data = this.MissionForm.getRawValue();
      this.data1 = this.MissionForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.MissionPFormAPI, this.data1).subscribe(data => {
        console.log(this.mission_id);
        this.mob_no = this.data1.contact;
        this.mission_id = this.data1.mission_id;
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया');
        this.MissionForm.reset();
        this.mission_submitted  = false;
       jQuery('#mission-verification').modal('show');
       setTimeout(()=>{  
        this.disableBtn = true;
      }, 30000); 
       this.showModal = false; 
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  MOTPSubmit(){
    this.motp_submitted = true;
    // stop here if form is invalid
    if (this.MOTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.MOTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.MPOTPFormAPI, data).subscribe(data => {
        console.log(data);
        this.toast.success('फ़ॉर्म सफलतापूर्वक सबमिट किया गया');
        this.MOTPForm.reset();
       jQuery('#mission-verification').modal('hide');
        this.motp_submitted  = false;
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  MROTPSubmit(){
    this.mrotp_submitted = true;
    // stop here if form is invalid
    if (this.MROTPForm.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      let data = this.MROTPForm.getRawValue();
      this.restAPI.post(environment.apiURL + environment.MissionResendOTP, data).subscribe(data => {
        console.log(data);
        this.toast.success('ओटीपी सफलतापूर्वक भेजा गया।');
        this.disableBtn = false;
        this.mrotp_submitted  = false;
        setTimeout(()=>{  
          this.disableBtn = true;
        }, 30000);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

  fetchState() {
    this.restAPI.get(environment.apiURL + environment.stateAPI).subscribe(data => {
      this.data = data;
      this.statesArray = this.data.data.state;
      console.log(this.statesArray);
    }, err => {
      console.log(err);
    });
  }

  fetchCity(e) {
    console.log(e);
    this.restAPI.get(environment.apiURL + environment.cityAPI + e.target.value).subscribe(data => {
      this.data = data;
      this.cityArray = this.data.data.city;
      console.log(this.cityArray);
    }, err => {
      console.log(err);
    });
  }

  onClick(event, item)
  {
    this.showModal = true;
    this.modaldata = item;
    console.log(this.modaldata);
  }

  hide()
  {
    this.showModal = false; 
  }

  LoggedIn() {
    return localStorage.getItem('token')!= null;
  }
}
