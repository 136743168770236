<footer>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="footer-logo-top">
                    <a [routerLink]="['/']"><img src="{{imageurl}}{{generalData.image}}" class="img-fluid"></a>
                </div>
            </div>
        </div>
        <div class="footer-middle"> 
            <div class="row">
                <div class="col-sm-3">
                    <h3 class="foo-heading">ढूंढे</h3>
                    <div class="foo-ul">
                        <ul class="style-foo-one">
                            <li><a [routerLink]="['/donate-us']">सहयोग करें</a></li>
                            <li><a [routerLink]="['/membership']">संगठन में शामिल हों</a></li>
                            <li><a [routerLink]="['/jobs']">जॉब की सुचना</a></li>
                            <li><a [routerLink]="['/career-counselling']">जॉब काउंसलिंग</a></li>
                            <li><a href="#" data-target="#join-volunteer" data-toggle="modal">वालंटियर बने</a></li>
                        </ul>
                    </div>
                    <h3 class="foo-heading mt-4">फॉलो करे</h3>
                    <div class="foo-ul">
                        <ul class="social-foo">
                            <li><a [href]="[generalData.facebook_link]" appExternalUrl><i class="fab fa-facebook-f"></i></a><li>
                            <li><a [href]="[generalData.twitter_link]" appExternalUrl><i class="fab fa-twitter"></i></a><li>
                            <li><a [href]="[generalData.youtube_link]" appExternalUrl><i class="fab fa-youtube"></i></a><li>
                            <li><a [href]="[generalData.instagram_link]" appExternalUrl><i class="fab fa-instagram"></i></a><li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <h3 class="foo-heading">और कुछ</h3>
                    <div class="foo-ul">
                        <ul class="style-foo-two">
                            <li><a [routerLink]="['/']">होम</a></li>
                            <li><a [routerLink]="['/organization-founder']">संगठन संस्थापक</a></li>
                            <li><a [routerLink]="['/our-movement']">हमारे आंदोलन</a></li>
                            <li><a [routerLink]="['/expose-campaign']">पर्दाफाश अभियान </a></li>
                            <li><a [routerLink]="['/jobs']">जॉब ढूंढे</a></li>
                            <li><a [routerLink]="['/contact-us']">संपर्क करे</a></li>
                        </ul>
                    </div>
                    <h3 class="foo-heading mt-4">ज़रूरत लिंक्स</h3>
                    <div class="foo-ul">
                        <ul class="style-foo-two">
                            <li><a [routerLink]="['/terms-and-conditions']">शर्तें &amp; शर्तेँ</a></li>
                            <li><a [routerLink]="['/privacy-policy']">गोपनीयता नीति</a></li>
                            <li><a [routerLink]="['/refund-policy']">धन वापसी नीति</a></li>
                            <li><a [routerLink]="['/']">साइटमैप</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-4">
                    <h3 class="foo-heading">हमारे बारे में</h3>
                    <div class="foo-ul">
                        <ul class="style-foo-three">
                            <li><a [routerLink]="['/organization-founder']">संगठन संस्थापक</a></li>
                            <li><a [routerLink]="['/our-identity']">हमारी पहचान</a></li>
                            <li><a [routerLink]="['/our-vision']">हमारी दृष्टि</a></li>
                            <li><a [routerLink]="['/our-mission']">हमारी लक्ष्य</a></li>
                            <li><a [routerLink]="['/our-strength']">हमारी ताकत</a></li>
                            <li><a [routerLink]="['/our-objective']">हमारा उद्देश्य</a></li>
                            <li><a [routerLink]="['/organization-structure']">संगठन संरचना</a></li>
                        </ul>
                    </div>
                    <h3 class="foo-heading mt-4">हमसे जुड़ें</h3>
                    <div class="foo-ul">
                        <ul class="style-foo-three">
                            <li><a [routerLink]="['/our-movement']">हमारे आंदोलन</a></li>
                            <li><a [routerLink]="['/bribery-free-india']">घूसखोरी मुक्त भारत</a></li>
                            <li><a [routerLink]="['/busted-revolution']">भंडाफोड़ क्रांति</a></li>
                            <li><a [routerLink]="['/expose-campaign']">पर्दाफाश अभियान</a></li>
                            <li><a [routerLink]="['/membership']">संगठन में शामिल हों</a></li>
                            <li><a [routerLink]="['/jobs']">जॉब ढूंढे</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-3">
                    <h3 class="foo-heading">संपर्क करे</h3>
                    <div class="foo-ul">
                        <ul class="style-foo-two">
                            <li><i class="fas fa-map-marker-alt"></i> {{generalData.address}}</li>
                            <li><i class="fas fa-phone"></i> {{generalData.contact}}</li>
                            <li><i class="far fa-envelope"></i> {{generalData.email}}</li>
                        </ul>
                    </div>
                    <h3 class="foo-heading mt-5">आगंतुक खिडकी</h3>
                    <div class="foo-ul">
                        <div class="visitor-counts">
                            <h3>आगंतुक: <span>{{countData}}</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6" style="padding-right: 180px;">
                    <p>कॉपीराइट &#169;
                        2022, सर्वाधिकार सुरक्षित राष्ट्रधर्म सेवा संगठन 
                        भारत</p>
                </div>
                <div class="col-sm-6" style="padding-top: 10px;
                padding-left: 135px;">
                    <a href="https://www.webmingo.com/" target="_blank" style="text-decoration: none;">Web Mingo IT Solutions Pvt Ltd द्वारा डिज़ाइन और रखरखाव की गई वेबसाइट</a>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="sticky-container">
    <ul class="sticky">
        <li>
            <img src="/assets/images/icon/facebook-circle.png" width="32" height="32">
            <p><a [href]="[generalData.facebook_link]" appExternalUrl target="_blank">Like Us on<br>Facebook</a></p>
        </li>
        <li>
            <img src="/assets/images/icon/twitter-circle.png" width="32" height="32">
            <p><a [href]="[generalData.twitter_link]" appExternalUrl target="_blank">Follow Us on<br>Twitter</a></p>
        </li>
        <li>
            <img src="/assets/images/icon/instagram-circle.png" width="32" height="32">
            <p><a [href]="[generalData.instagram_link]" appExternalUrl target="_blank">Follow Us on<br>Instagram</a></p>
        </li>
        <li>
            <img src="/assets/images/icon/linkedin-circle.png" width="32" height="32">
            <p><a [href]="[generalData.linkedin_link]" appExternalUrl target="_blank">Follow Us on<br>LinkedIn</a></p>
        </li>
        <li>
            <img src="/assets/images/icon/youtube-circle.png" width="32" height="32">
            <p><a [href]="[generalData.youtube_link]" appExternalUrl target="_blank">Subscribe on<br>YouYube</a></p>
        </li>
        <li>
            <img src="/assets/images/icon/whatsapp-circle.png" width="32" height="32">
            <p><a href="https://wa.link/bq4bqd" appExternalUrl target="_blank">Connect with us<br>on Whatsapp</a></p>
        </li>
    </ul>
</div>

<div class="modal fade otp-modal" id="join-volunteer" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="VolunteerFormS1" (ngSubmit)="VolunteerFormS1Submit()">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>वालंटियर बने</h3>
                                <p>आपके मोबाइल नंबर पर एक ओटीपी भेजा जाएगा</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-8">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" placeholder="मोबाइल नंबर।" [pattern]="mobNumberPattern" formControlName="contact" [ngClass]="{ 'is-invalid': volunteers1_submitted && vs1.contact.errors }">
                            <div *ngIf="volunteers1_submitted && vs1.contact.errors" class="invalid-login">
                                <div *ngIf="vs1.contact.errors.required">मोबाइल नंबर चाहिए</div>
                                <div *ngIf="vs1.contact.errors.pattern">मोबाइल नंबर 10 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">ओटीपी पर आगे बढ़ें</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade otp-modal" id="volunteer-otp" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">ओटीपी सत्यापन</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="VolunteerFormS2" (ngSubmit)="VolunteerFormS2Submit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">मोबाइल नंबर</label>
                            <input type="text" class="form-control" placeholder="मोबाइल नंबर" [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="head-main-otp">
                                <h3>कृपया अपना मोबाइल नंबर सत्यापित करने के लिए ओटीपी दर्ज करें</h3>
                                <p>एक ओटीपी भेजा गया है {{mob_no}}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group row justify-content-center">
                        <div class="col-sm-6">
                            <label class="label-control">6 अंकों का ओटीपी</label>
                            <input type="number" class="form-control" placeholder="ओटीपी दर्ज करें" [pattern]="OTPPattern" formControlName="otp" [ngClass]="{ 'is-invalid': volunteers2_submitted && vs2.otp.errors }">
                            <div *ngIf="volunteers2_submitted && vs2.otp.errors" class="invalid-login">
                                <div *ngIf="vs2.otp.errors.required">ओटीपी आवश्यक है</div>
                                <div *ngIf="vs2.otp.errors.pattern">ओटीपी 6 अंकों का होना चाहिए</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12 text-center">
                            <button class="btn btn-dark btn-validate" type="submit">ओटीपी सत्यापित करें</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <form [formGroup]="VolunteerROTPForm" (ngSubmit)="VolunteerROTPSubmit()">
                    <div class="form-group row" style="display: none;">
                        <div class="col-sm-6">
                            <label class="label-control">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="Enter Mobile No." [(ngModel)]="mob_no" readonly formControlName="contact">
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-sm-8 text-center">
                            <span  class="wish-otp">If you did not received OTP, you can request a new OTP after 30 seconds</span><br>
                        </div>
                        <div class="col-sm-4 text-center align-self-center">
                            <button class="btn btn-dark btn-sm" type="submit" [disabled]="!disableBtn">Resend OTP</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>