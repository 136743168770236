import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from 'src/app/models/data';
import { RESTApiService } from 'src/app/services/RESTApi/restapi-service.service';

@Component({
  selector: 'app-donate-us-step2',
  templateUrl: './donate-us-step2.component.html',
  styleUrls: ['./donate-us-step2.component.scss']
})
export class DonateUsStep2Component implements OnInit {
  email: string; amount: string;mobile:string; //For Param
  enva:any;
  data1:Data;
  mobNumberPattern = "^[A-Z0-9]{10}$"; 
  donatestep2_submitted = false;
  donateStep2Form:FormGroup;  
  constructor(private formBuilder: FormBuilder,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router:Router,
    private restAPI : RESTApiService) { 
      this.enva = environment.assetsURL;
    }

  ngOnInit(): void {
    this.donateStep2Form = this.formBuilder.group({
      amount: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      automatic_payment: ['', Validators.required],
      no_of_month: ['', [Validators.required]],
      address: ['', [Validators.required]],
      donate_to: ['', [Validators.required]],
      pan_number: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    });
    this.route.paramMap.subscribe(params => {
      this.email = params.get('email');
      this.amount = params.get('amount');
      this.mobile = params.get('mobile');
    });
  }

  get d() { return this.donateStep2Form .controls; }

  donateSubmitStep2(){
    this.donatestep2_submitted = true;
    // stop here if form is invalid
    if (this.donateStep2Form.invalid) {
      this.toast.error('कृपया सभी फ़ील्ड जांचें');
        return;
    }else{
      this.data1 = this.donateStep2Form.getRawValue();
      this.restAPI.post(environment.apiURL + environment.DonationStep2FormAPi, this.data1).subscribe(data => {
        console.log(this.data1);
        this.toast.success('Donation Subscription Successfully');
        this.donateStep2Form.reset();
        this.donatestep2_submitted  = false;
        this.router.navigate(["/donate-us"]);
      }, err => {
        this.toast.error(err.error.error);
        console.log(err);
      });
    }
  }

}
